import './sidebar.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { SidebarHeightContext } from '../layoutAccount/LayoutAccount';

function Sidebar() {
	let location = useLocation();
	const [currentPage, setCurrentPage] = useState(
		location.pathname.split('/')[1]
	);

	useEffect(() => {
		// 取得目前網頁位置
		let array = location.pathname.split('/');
		setCurrentPage(array[1]);
		//console.log(currentPage);
		//console.log(array[1]);
	}, [currentPage, location.pathname]);

	// 自定義sidebar高度
	const sidebarHeightContext = useContext(SidebarHeightContext);

	// 取得新訊息提示
	//$.ajax({
	//    type: 'GET',
	//    url: langURL + '/account/getSidebarBadge',
	//    dataType: 'json',
	//    success: function (jData) {
	//        // console.log("ajax success");
	//        // console.log(jData);
	//        if (jData.status == 'success') {
	//            if (jData.countList.DrillCount != '0') $(".badge-sidebarDrill").text(jData.countList.DrillCount);
	//            if (jData.countList.FriendCount != '0') $(".badge-sidebarFriend").text(jData.countList.FriendCount);
	//            if (jData.countList.GroupCount != '0') $(".badge-sidebarGroup").text(jData.countList.GroupCount);
	//        }
	//    },
	//    error: function (errorMsg) {
	//        console.log("ajax error");
	//        console.log(errorMsg);
	//    }
	//});

	return (
		<article
			id="sidebar"
			style={{
				height:
					sidebarHeightContext.sidebarHeight === '100% - confirmBtn'
						? 'calc(100% - 80px)'
						: '100%',
			}}
		>
			<div className="d-flex flex-column align-items-center">
				<h2 className="px-4 pb-3 mb-2 border-bottom">账户管理</h2>
				<ul className="list-unstyled">
					<li
						data-link="baseinfo"
						className={currentPage === 'baseinfo' ? 'active' : ''}
					>
						<Link to="/baseinfo">基本数据</Link>
					</li>
					<li
						data-link="pwdreset"
						className={currentPage === 'pwdreset' ? 'active' : ''}
					>
						<Link to="/pwdreset">变更密码</Link>
					</li>
					<li
						data-link="product"
						className={currentPage === 'product' ? 'active' : ''}
					>
						<Link to="/product">我的产品</Link>
					</li>
					<li
						data-link="member"
						className={currentPage === 'member' ? 'active' : ''}
					>
						<Link to="/member/productvip">用户家族</Link>
					</li>
					<li
						data-link="accountDelete"
						className={
							currentPage === 'accountDelete' ? 'active' : ''
						}
					>
						<Link to="/accountDelete">删除帐号</Link>
					</li>
				</ul>
			</div>
		</article>
	);
}

export default Sidebar;
