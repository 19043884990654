import './appUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { checkAndSetFile } from '../checkAndSetFile';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';
import dayjs from 'dayjs';

const AppUpdateDetail = () => {
	const navigate = useNavigate();

	const [appVersion, setAppVersion] = useState('');
	const [appFile, setAppFile] = useState(null);
	const [releaseNote, setReleaseNote] = useState('');
	const [developNote, setDevelopNote] = useState('');
	const [prerequisite, setPrerequisite] = useState('');
	const [releaseTime, setReleaseTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [updateTime, setUpdateTime] = useState('');
	const [demand, setDemand] = useState('');
	const [hyperLink, setHyperLink] = useState('');
	const [rowStamp, setRowStamp] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState(0);

	const [appGuid, setAppGuid] = useState('');
	const [osCode, setOsCode] = useState('');
	const [appName, setAppName] = useState('');
	const [type, setType] = useState('');
	const [appCode, setAppCode] = useState('');
	const [appReposGuid, setAppReposGuid] = useState('');

	useEffect(() => {
		setAppGuid(
			window.location.search.split('?')[1].split('&')[0].split('=')[1]
		);
		setOsCode(
			window.location.search.split('?')[1].split('&')[1].split('=')[1]
		);
		setAppName(
			decodeURI(
				window.location.search.split('?')[1].split('&')[2].split('=')[1]
			)
		);
		setType(
			window.location.search.split('?')[1].split('&')[3].split('=')[1]
		);

		setAppReposGuid(
			window.location.search.split('?')[1]?.split('&')[4].split('=')[1]
		);
	}, [window.location.search]);

	useEffect(() => {
		const getAppCode = async () => {
			await axios
				.get(`web/sys/app/${appGuid}`)
				.then((res) => {
					setAppCode(res.data.result.appCode);
				})
				.catch((err) => console.log(err));
		};

		getAppCode();
	}, [appGuid]);
	// console.log(appCode);

	let isChecked = false;
	const checkAppVersionValid = async (appGuid, osCode, version) => {
		await axios
			.get(
				`web/sys/checkAppVersionValid?appguid=${appGuid}&osCode=${osCode}&appVersion=${version}`
			)
			.then((res) => {
				if (res.data === false && version !== '') {
					Swal.fire({
						icon: 'error',
						title: `${version} 版本號重複，請重新輸入`,
					});
					setAppVersion('');

					isChecked = false;
				} else {
					isChecked = true;
				}
			});
	};

	//edit getInfo
	useEffect(() => {
		if (type === 'edit')
			(async () => {
				await axios
					.get(`web/sys/appRepos/${appReposGuid}`)
					.then((res) => {
						const sysApp = res.data.result;
						console.log(sysApp);

						const releaseTimeFormat =
							sysApp.releaseTime.toLocaleString().split(':')[0] +
							':' +
							sysApp.releaseTime.toLocaleString().split(':')[1];
						const endTimeFormat =
							sysApp.endTime.toLocaleString().split(':')[0] +
							':' +
							sysApp.endTime.toLocaleString().split(':')[1];

						setAppVersion(sysApp.version);
						setReleaseNote(sysApp.releaseNote);
						setDevelopNote(sysApp.developNote);
						setOsCode(sysApp.osCode);
						setPrerequisite(sysApp.osPrerequisite);
						setReleaseTime(releaseTimeFormat);
						setEndTime(endTimeFormat);
						setUpdateTime(sysApp.updateTime);
						setDemand(sysApp.demand);
						setHyperLink(sysApp.hyperLink);
						setRowStamp(sysApp.rowStamp);
					})
					.catch((err) => console.log(err));
			})();
	}, [appReposGuid]);

	// delete
	const handleDelete = async (e) => {
		e.preventDefault();

		await Swal.fire({
			html: `<h4>確定要刪除?</h4>`,
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: '取消',
			showConfirmButton: true,
			confirmButtonText: '確定',
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((res) => {
			if (res.dismiss === 'cancel') {
				console.log('cancelled');
				return;
			} else {
				console.log('confirmed delete');

				axios
					.delete(`web/sys/appRepos/${appReposGuid}`)
					.then((res) => {
						navigate(
							`/appupdate?appGuid=${appGuid}&osCode=${osCode}&appName=${appName}`
						);
					})
					.catch((err) => console.log(err));
			}
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const form = new FormData(e.target);
		form.append('appGuid', appGuid);
		form.append('osCode', osCode);

		if (type === 'edit') {
			//edit
			setIsLoading(true);

			await axios
				.put(`web/sys/appRepos/${appReposGuid}`, form, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					// 追蹤上傳進度
					onUploadProgress: (progressEvent) => {
						if (progressEvent.lengthComputable) {
							const percent =
								(progressEvent.loaded / progressEvent.total) *
								100;
							setProgress(Math.round(percent));
						}
					},
				})
				.then((res) => {
					console.log(res.data);
					setIsLoading(false);
					navigate(
						`/appupdate?appGuid=${appGuid}&osCode=${osCode}&appName=${appName}`
					);
				})
				.catch((err) => {
					setIsLoading(false);
					Swal.fire({
						icon: 'error',
						title: '網路不穩，儲存失敗',
						text: '請再次儲存。',
					});
					console.log(err);
				});
		} else if (type === 'create') {
			//create
			await checkAppVersionValid(appGuid, osCode, appVersion);

			if (isChecked === false) {
				// appVersion是否重複
			} else if (appFile === null && osCode !== '0') {
				Swal.fire({
					icon: 'info',
					title: '請上傳apk檔案',
				});
			} else {
				setIsLoading(true);

				await axios
					.post(`web/sys/appRepos`, form, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						// 追蹤上傳進度
						onUploadProgress: (progressEvent) => {
							if (progressEvent.lengthComputable) {
								const percent =
									(progressEvent.loaded /
										progressEvent.total) *
									100;
								setProgress(Math.round(percent));
							}
						},
					})

					.then((res) => {
						console.log(res.data);
						setIsLoading(false);
						navigate(
							`/appupdate?appGuid=${appGuid}&osCode=${osCode}&appName=${appName}`
						);
					})
					.catch((err) => {
						setIsLoading(false);
						Swal.fire({
							icon: 'error',
							title: '網路不穩，儲存失敗',
							text: '請再次儲存。',
						});
						console.log(err);
					});
			}
		}
	};

	const osCodeName = {
		0: 'iOS',
		1: 'Android',
		2: 'HarmonyOS(鸿蒙)',
	};

	return (
		<>
			<LoadingAnimate isLoading={isLoading} progress={progress} />

			<section id="section-main">
				<form id="container-appUpdateDetail" onSubmit={handleSubmit}>
					<div className="d-flex">
						<h5 className="fw-bold mb-4">{appName}</h5>
						<button
							className={`btnDelete btn btn-outline-danger px-4 ms-auto me-2 ${
								type === 'create' && 'd-none'
							}`}
							onClick={(e) => {
								handleDelete(e);
							}}
						>
							刪除
						</button>
						<button
							className={`btnCancel btn btn-outline-primary px-4 me-2 ${
								type === 'create' && 'ms-auto'
							}`}
							onClick={(e) => {
								e.preventDefault();
								navigate(
									`/appupdate?appGuid=${appGuid}&osCode=${osCode}&appName=${appName}`
								);
							}}
						>
							取消
						</button>
						<button className="btn btn-primary text-white px-4">
							儲存
						</button>
					</div>
					<div className="manageFileWrapper bg-white p-4 rounded-4 mb-3">
						<input
							id="rowStamp"
							name="rowStamp"
							className="d-none"
							defaultValue={rowStamp}
						/>
						<h6 className="fw-bold mb-3">
							{osCodeName[osCode]}版本管理
						</h6>

						<div className="row">
							<div className="col-4">
								<label
									htmlFor="version"
									className="fontSize75Rem text-grey4 mb-2"
								>
									版本號：[主版本號].[次版本號].[修訂版本號]
								</label>
								<input
									id="version"
									name="version"
									className={`form-control mb-3`}
									value={appVersion || ''}
									onChange={(e) =>
										setAppVersion(e.target.value)
									}
									// onBlur={(e) => checkApp(e.target.value)}
									required
									disabled={type === 'edit'}
								/>
							</div>
							<div className="col-4">
								<label
									htmlFor="osPrerequisite "
									className="fontSize75Rem text-grey4 mb-2"
								>
									最低OS版本要求
								</label>
								<input
									type="text"
									id="osPrerequisite"
									name="osPrerequisite"
									className="form-control"
									value={prerequisite || ''}
									onChange={(e) =>
										setPrerequisite(e.target.value)
									}
									required
								/>
								<span className="fontSize75Rem text-grey4">
									[主版本號].[次版本號].[修訂版本號]
								</span>
							</div>
						</div>
						<div className="row">
							<h6 className="fontSize75Rem text-grey4 mb-2">
								APP下载路径(URL)
							</h6>
							<div className="fileBox d-flex align-items-center mb-3">
								<div
									className={`fileView d-flex bg-grey1 text-primary px-2 py-1 ms-0 rounded-3`}
									style={{ width: '55%' }}
								>
									<span
										className={`fontSize75Rem ${
											(appFile === null &&
												type === 'create' &&
												'd-none') ||
											(osCode === '0' && 'd-none')
										}`}
									>
										{hyperLink}
									</span>
								</div>
								<label htmlFor="file">
									<div
										className={`btn btn-primary text-white px-2 py-1 fontSize875Rem ${
											osCode === '0' && 'btn-secondary'
										}`}
										style={{
											width: '94px',
											cursor:
												osCode === '0'
													? 'auto'
													: 'pointer',
										}}
									>
										<svg
											id="icn_Create"
											className="me-2 mb-1"
											viewBox="0 0 12 12"
											width="12"
											height="12"
											fill="#fff"
										>
											<defs> </defs>
											<path
												className="cls-1"
												id="_Color"
												d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
												data-name=" ↳Color"
											></path>
										</svg>
										<span>上傳檔案</span>
									</div>
									<input
										type="file"
										className="form-control d-none"
										id="file"
										name="file"
										onChange={(e) => {
											if (
												checkAndSetFile(e.target, [
													'.apk',
													'.aab',
												])
											) {
												setAppFile(e.target.files[0]);
												setHyperLink(
													`${
														window.location
															.protocol +
														'\\\\' +
														window.location.host
													}${
														window.location
															.hostname ===
															'localhost' &&
														'\\storage'
													}\\download\\app\\${appCode}\\${osCode}\\${
														appVersion || '版本號'
													}\\${
														e.target.files[0]?.name
													}`
												);
											}
										}}
										disabled={osCode === '0'}
									/>
								</label>
							</div>
						</div>
						<hr className="bg-grey3 mt-2" />
						<div className="row">
							<div className="col-4">
								<label
									htmlFor="releaseNote"
									className="fontSize75Rem text-grey4 mb-2"
								>
									版本註記
								</label>
								<textarea
									type="text"
									id="releaseNote"
									name="releaseNote"
									className="form-control mb-1"
									rows="3"
									value={releaseNote || ''}
									onChange={(e) =>
										setReleaseNote(e.target.value)
									}
								/>
							</div>
							<div className="col-4">
								<label
									htmlFor="developNote"
									className="fontSize75Rem text-grey4 mb-2"
								>
									開發人員註記
								</label>
								<textarea
									type="text"
									id="developNote"
									name="developNote"
									className="form-control"
									rows="3"
									value={developNote || ''}
									onChange={(e) =>
										setDevelopNote(e.target.value)
									}
								/>
							</div>
						</div>
					</div>

					<div className="infoWrapper bg-white p-4 rounded-4">
						<div className="infoTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold mb-0">版本資訊</h6>
						</div>
						<div className="infoBottom row">
							<div className="col-4">
								<label
									htmlFor="demand"
									className="fontSize75Rem mb-2"
								>
									更新要求
								</label>
								<select
									className="form-control mb-3"
									name="demand"
									id="demand"
									onChange={(e) => setDemand(e.target.value)}
									value={demand || ''}
									required
								>
									<option value="" disabled>
										-- 請選擇 --
									</option>
									<option value="0">關閉</option>
									<option value="1">選擇性更新</option>
									<option value="2">一定要更新</option>
								</select>
								<label
									htmlFor="updateTime"
									className="fontSize75Rem mb-2"
								>
									创建/修改时间
								</label>
								<input
									type="datetime-local"
									id="updateTime"
									name="updateTime"
									className="form-control mb-3"
									value={dayjs(
										updateTime || new Date()
									).format('YYYY-MM-DD HH:mm:ss')}
									readOnly
								/>
							</div>
							<div className="col-4">
								<label
									htmlFor="releaseTime"
									className="fontSize75Rem mb-2"
								>
									发布时间
								</label>
								<input
									type="datetime-local"
									id="releaseTime"
									name="releaseTime"
									className="form-control mb-3"
									value={releaseTime || ''}
									onChange={(e) =>
										setReleaseTime(e.target.value)
									}
									required
								/>
								<label
									htmlFor="endTime"
									className="fontSize75Rem mb-2"
								>
									结束时间
								</label>
								<input
									type="datetime-local"
									id="endTime"
									name="endTime"
									className="form-control"
									value={endTime || ''}
									onChange={(e) => setEndTime(e.target.value)}
									required
								/>
							</div>
						</div>
					</div>
				</form>
			</section>
		</>
	);
};

export default AppUpdateDetail;
