import './exploreUpdateChange.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { checkAndSetFile } from '../checkAndSetFile';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';

const RenderIndexList = () => {
    //#region [States initializations]
    const [isLoading, setIsLoading] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageFile, setImageFile] = useState(null);

    const [displayOrder, setDisplayOrder] = useState('');
    const [category, setCategory] = useState('');
    const [languageCode, setLanguageCode] = useState('');
    const [title, setTitle] = useState('');
    const [videoURL, setVideoURL] = useState('');
    const [coverPath, setCoverPath] = useState(
        '../images/explore/defaultCardImage.png'
    );
    const [releaseTime, setReleaseTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [status, setStatus] = useState(null);
    const [rowStamp, setRowStamp] = useState('');

    const paramsGuid = useParams('appGuid');
    const navigate = useNavigate();

    //#endregion

    //#region [function]

    const handleSetImage = (e) => {
        const strArr = e.target.files[0].name.split('.');
        const imgType = strArr[strArr.length - 1];
        setCoverPath(URL.createObjectURL(e.target.files[0]));
        setImageName(
            `${
                paramsGuid.videoGuid ? paramsGuid.videoGuid : '[guid]'
            }.${imgType}`
        );
        setImageFile(URL.createObjectURL(e.target.files[0]));
    };

    // edit delete
    const handleDeleteItem = async () => {
        await axios
            .delete(`web/backstage/onlinevideo/${paramsGuid.videoGuid}`)
            .then((res) => {
                console.log(res.data);
                navigate('/exploreupdate');
            })

            .catch((err) => console.log(err));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        // coverPath 不是 input, 需要手動加進 formData 裡
        form.append('coverPath', coverPath);

        if (Object.keys(paramsGuid).length) {
            //edit
            setCoverPath(null);
            setIsLoading(true);

            await axios
                .put(`web/backstage/onlinevideo/${paramsGuid.videoGuid}`, form)
                .then((res) => {
                    console.log(res.data);
                    setIsLoading(false);
                    navigate('/exploreupdate');
                })

                .catch((err) => {
                    setIsLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: '網路不穩，儲存失敗',
                        text: '請再次儲存。',
                    });
                    console.log(err);
                });
        } else {
            //create
            if (imageFile === null) {
                Swal.fire({
                    icon: 'info',
                    title: '請上傳圖片',
                });
            } else {
                setIsLoading(true);

                await axios
                    .post(`web/backstage/onlinevideo`, form)
                    .then((res) => {
                        console.log(res.data);
                        setIsLoading(false);
                        navigate('/exploreupdate');
                    })

                    .catch((err) => {
                        setIsLoading(false);
                        Swal.fire({
                            icon: 'error',
                            title: '網路不穩，儲存失敗',
                            text: '請再次儲存。',
                        });
                        console.log(err);
                    });
            }
        }
    };

    //#endregion

    //#region [useEffect]

    // getDetails
    useEffect(() => {
        const fetchOnlineVideoDetails = async () => {
            await axios
                .get(`web/backstage/onlinevideo/${paramsGuid.videoGuid}`)
                .then((res) => {
                    console.log(res.data.result);

                    const data = res.data.result;

                    data.releaseTime =
                        data.releaseTime.toLocaleString().split(':')[0] +
                        ':' +
                        data.releaseTime.toLocaleString().split(':')[1];

                    data.endTime =
                        data.endTime.toLocaleString().split(':')[0] +
                        ':' +
                        data.endTime.toLocaleString().split(':')[1];

                    setDisplayOrder(data.displayOrder);
                    setCategory(data.category);
                    setLanguageCode(data.languageCode);
                    setTitle(data.title);
                    setVideoURL(data.videoURL);
                    setCoverPath(data.coverPath);
                    setReleaseTime(data.releaseTime);
                    setEndTime(data.endTime);
                    setStatus(data.status);
                    setRowStamp(data.rowStamp);

                    setImageName(
                        data.coverPath.split('/')[
                            data.coverPath.split('/').length - 1
                        ]
                    );
                });
        };
        if (Object.keys(paramsGuid).length) {
            fetchOnlineVideoDetails();
        }
    }, [paramsGuid]);

    //#endregion

    return (
        <>
            <LoadingAnimate isLoading={isLoading} />

            <section id="section-main">
                <form
                    id="container-exploreUpdateChange"
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex">
                        <h5 className="fw-bold mb-4">網上展示廳</h5>
                        <button
                            className="btnDelete btn btn-outline-danger px-4 ms-auto me-2"
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                        >
                            刪除
                        </button>
                        <button
                            className="btnCancel btn btn-outline-primary px-4 me-2"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/exploreupdate');
                            }}
                        >
                            取消
                        </button>
                        <button className="btn btn-primary text-white px-4">
                            儲存
                        </button>
                    </div>
                    {/* <div className="d-flex"></div> */}
                    <div className="manageFileWrapper bg-white p-4 rounded-4 mb-3">
                        <input
                            id="rowStamp"
                            name="rowStamp"
                            className="d-none"
                            defaultValue={rowStamp}
                        />
                        <div className="d-flex">
                            <div className="col-6">
                                <h6 className="fw-bold mb-3">檔案管理</h6>
                                <h6 className="fontSize75Rem mb-2">影片名稱</h6>
                                <input
                                    id="title"
                                    name="title"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <h6 className="fontSize75Rem mb-3">
                                    附加檔案 |
                                </h6>
                                <h6 className="fontSize75Rem mb-2">
                                    影片封面照片
                                </h6>
                                <div className="fileBox d-flex align-items-center mb-2 w-100">
                                    <div
                                        className={`fileView d-flex bg-grey1 text-primary px-2 py-1 w-100 rounded-3`}
                                    >
                                        <span
                                            className={`fontSize75Rem ${
                                                coverPath === null &&
                                                Object.keys(paramsGuid)
                                                    .length === 0 &&
                                                'd-none'
                                            }`}
                                        >
                                            {imageName
                                                ? imageName
                                                : '[guid].[檔案類型]'}
                                        </span>
                                    </div>
                                    <label
                                        htmlFor="file"
                                        style={{
                                            minWidth: '134px',
                                            height: '32px',
                                            fontSize: '14px',
                                        }}
                                    >
                                        <div className="btn btn-primary text-white px-4 py-1">
                                            <svg
                                                id="icn_Create"
                                                className="me-2 mb-1"
                                                viewBox="0 0 12 12"
                                                width="12"
                                                height="12"
                                                fill="#fff"
                                            >
                                                <defs> </defs>
                                                <path
                                                    className="cls-1"
                                                    id="_Color"
                                                    d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
                                                    data-name=" ↳Color"
                                                ></path>
                                            </svg>
                                            <span>上傳圖片</span>
                                        </div>
                                        <input
                                            type="file"
                                            className="form-control d-none"
                                            id="file"
                                            name="file"
                                            onChange={(e) =>
                                                checkAndSetFile(e.target, [
                                                    '.jpg',
                                                    '.png',
                                                ]) && handleSetImage(e)
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="guideline mb-3">
                                    建議採用 16:9 的長寬比且大小在 2 MB
                                    以內的圖片，並以 JPG或PNG 等圖片格式上傳。
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="imageSection">
                                    <div className="">
                                        <div className="imageCard">
                                            <img
                                                src={coverPath}
                                                alt="cover path"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 className="fontSize75Rem mb-2">影片封面位址</h6>
                        <div className="fileView bg-grey1  px-2 py-1 w-80 rounded-3 mb-3">
                            <span className="fontSize75Rem">
                                {`storage/Video/${imageName || '[檔案名稱]'}`}
                            </span>
                        </div>
                        <h6 className="fontSize75Rem mb-2">影片位址</h6>
                        <input
                            type="text"
                            id="videoURL"
                            name="videoURL"
                            className="form-control w-80"
                            required=""
                            value={videoURL}
                            onChange={(e) => setVideoURL(e.target.value)}
                        />
                    </div>

                    <div className="infoWrapper bg-white p-4 rounded-4">
                        <div className="infoTop d-flex align-items-center mb-3 ">
                            <h6 className="fw-bold">檔案資訊</h6>
                        </div>
                        <div className="infoBottom row">
                            <div className="col-4">
                                <label
                                    htmlFor="status"
                                    className="fontSize75Rem mb-2"
                                >
                                    型態
                                </label>
                                <select
                                    className="form-control mb-3 "
                                    name="category"
                                    id="category"
                                    onChange={(e) =>
                                        setCategory(e.target.value)
                                    }
                                    value={category || ''}
                                    required
                                >
                                    <option value="" disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value="0">"0"：發球機維護</option>
                                    <option value="1">
                                        "1"：發球機打球影片維護
                                    </option>
                                    <option value="2">"2"：宣傳影片</option>
                                </select>
                                <label
                                    htmlFor="prerequisite"
                                    className="fontSize75Rem mb-2"
                                >
                                    發佈時間
                                </label>
                                <input
                                    type="datetime-local"
                                    id="releaseTime"
                                    name="releaseTime"
                                    className="form-control"
                                    value={releaseTime || ''}
                                    onChange={(e) =>
                                        setReleaseTime(e.target.value)
                                    }
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="releaseTime"
                                    className="fontSize75Rem mb-2"
                                >
                                    語言代碼
                                </label>
                                <select
                                    className="form-control mb-3"
                                    name="languageCode"
                                    id="languageCode"
                                    onChange={(e) =>
                                        setLanguageCode(e.target.value)
                                    }
                                    value={languageCode || ''}
                                    required
                                >
                                    <option value="" disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value="ZF">"ZF"：繁體中文</option>
                                    <option value="ZH">"ZH"：簡體中文</option>
                                    <option value="EN">"EN"：英文</option>
                                </select>
                                <label
                                    htmlFor="endTime"
                                    className="fontSize75Rem mb-2"
                                >
                                    結束時間
                                </label>
                                <input
                                    type="datetime-local"
                                    id="endTime"
                                    name="endTime"
                                    className="form-control"
                                    value={endTime || ''}
                                    onChange={(e) => setEndTime(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="demand"
                                    className="fontSize75Rem mb-2"
                                >
                                    顯示順序
                                </label>
                                <input
                                    type="number"
                                    id="displayOrder"
                                    name="displayOrder"
                                    max={10000}
                                    className="form-control mb-3"
                                    value={displayOrder || ''}
                                    onChange={(e) =>
                                        setDisplayOrder(e.target.value)
                                    }
                                    required
                                />
                                <label
                                    htmlFor="demand"
                                    className="fontSize75Rem mb-2"
                                >
                                    狀態
                                </label>
                                <select
                                    className="form-control"
                                    name="status"
                                    id="status"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status || ''}
                                    required
                                >
                                    <option value="" disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value="0">0：停用</option>
                                    <option value="1">1：啟用</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default RenderIndexList;
