import './privacy.scss';
import React from 'react';
import { Link } from 'react-router-dom';

/// 隱私政策
/// Author : YL
function Privacy() {
    return (
        <div className="container-fluid p-0">
            <article
                id="container-privacy"
                className="d-flex flex-column align-items-center px-0 mx-auto"
            >
                <div className="outerWrapper">
                    <div className="wrapper">
                        <div className="col-12 desc px-3">
                            <div className="text-grey5 ps-0 mb-4 mb-lg-5 row justify-content-center">
                                <main className="">
                                    <h1>宾乐达运动隐私政策</h1>
                                    <section className="section0">
                                        <div className="subSection subSession1">
                                            <div className="wordWrap">
                                                <p>
                                                    海南翔睿德科技有限公司（简称“翔睿德”或“我们”，地址：“海南省海口市国家高新技术产业开发区南海大道266号创业孵化中心A楼5层A26-72室”）非常重视用户您的隐私，在此特别提醒您（简称“用户”或“您”）认真阅读并充分理解本隐私权政策（简称“本政策”）。
                                                </p>
                                                <p>
                                                    本政策解释翔睿德如何收集、使用和披露关于您或与您相关联的信息（简称“个人信息”）。本政策适用于我们的网站和服务（简称“我们的服务”或“本服务”），包括但不限于synerter.com.cn
                                                    和 synerter.cn
                                                    （简称“我们的网站”或“本网站”）、您使用的宾乐达智能设备（简称“本设备”）、及为本设备提供支持的在线应用程序（简称“应用程序”）等各项服务内容。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="section1">
                                        <h2>1. 我们收集哪些信息：</h2>
                                        <div className="subSection subSection0">
                                            <p>
                                                为了向您提供我们的服务，我们需要您提供使用该服务所必需的信息。我们只会收集为实现具体、特定、明确及合法的目的所必需的信息，并且确保不会对这些信息进行与上述目的不相符的进一步处理。您有权自行选择是否提供我们请求的信息，但多数情况下，如果您拒绝，我们可能无法向您提供相应的服务，也无法回应您遇到的问题。
                                            </p>
                                            <p>
                                                根据您选择的服务，我们可能收集以下信息中的一种或多种：
                                            </p>
                                        </div>

                                        <div className="subSection subSection1">
                                            <p className="subtitle">
                                                1.1
                                                您主动提供给我们的个人信息。我们会根据您选择的服务，收集您在使用相应服务时需要提供的个人信息。例如：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    1.1.1
                                                    若您使用云同步服务，您可能会上传云同步的相关资料；若您创建帐号，您可能会提供您的生日、性别、帐号安全设置等相关信息；
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.1.2
                                                    若您参与市场促销活动，您可能会向我们提供您的昵称、电子邮件地址、照片、视频或其他所需的信息；
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.1.3
                                                    若您参与我们的活动或获奖，您可能会向我们提供您的姓名、手机号码和地址。
                                                </p>
                                            </div>
                                        </div>

                                        <div className="subSection subSection2">
                                            <p className="subtitle">
                                                1.2
                                                我们在您使用服务过程中收集的信息。例如：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    1.2.1
                                                    您在使用本服务时生成的信息：例如用户等级、签到信息、浏览记录、收藏、分享、搜索关键词、系统语言、国家和地区、网络状态、应用列表、地区、互联网协议
                                                    (IP) 地址等。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.2.2
                                                    位置信息（仅适用于特定服务/功能）：若您使用和位置相关的服务，我们可能采集与您设备的模糊或精确位置相关的各类信息。例如地区、国家代码、城市代码、移动网络代码、移动国家代码、小区标识、经纬度信息、时区设置和语言设置。您可以随时在手机设置（设置-权限）中关闭每个应用的位置服务。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.2.3 其他信息： 环境特征值
                                                    (ECV)，即从用户帐号、设备标识、连接Wi-Fi时产生的信息和地理位置信息。
                                                </p>
                                            </div>
                                        </div>

                                        <div className="subSection subSection3">
                                            <p className="subtitle">
                                                1.3
                                                来源于第三方的信息。在一些法律允许的情况下，我们可能从第三方处获得您的个人信息。例如：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    1.3.1
                                                    出于安全和防欺诈的目的，针对特定账号与服务，在您授权的前提下，通过合法来源核实您的信息（如电话号码）。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.3.2
                                                    我们还可能从第三方社交网络服务中获取某些信息，例如账户、昵称、头像及邮箱（例如，当您使用社交网络账户登录本服务时）。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.3.3
                                                    他人提供的与您有关的信息，例如其他用户在使用电子商场为您购买产品时，向我们提供的您的收货地址。
                                                </p>
                                            </div>
                                        </div>
                                        <div className="subSection subSection4">
                                            <p className="subtitle">
                                                1.4
                                                非个人信息。我们还可能收集其他无法直接或间接地识别到特定个人、且根据当地适用法律不属于个人信息的信息。这类信息被称为非个人信息。我们可能收集、使用、转移和披露非个人信息。例如：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    1.4.1
                                                    我们可能包含您使用特定服务时产生的统计类数据（如匿名设备相关信息、日活事件、页面访问事件、页面访问时长事件、会话事件）。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.4.2
                                                    网络监控数据如请求时长、请求与错误请求数等。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.4.3
                                                    应用崩溃事件（如应用程序崩溃后自动生成的日志等）。
                                                </p>
                                            </div>
                                        </div>
                                        <p>
                                            收集此类信息的目的在于改善我们向您提供的服务。我们收集信息的类别和数量将取决于您如何使用我们的产品或服务。
                                        </p>
                                    </section>

                                    <section className="section2">
                                        <h2>
                                            2. 我们收集的个人信息将如何被使用。
                                        </h2>
                                        <div className="subSection subSection1">
                                            <p>
                                                我们收集个人信息的目的在于向您提供产品和/或服务，并且保证我们遵守适用的相关法律、法规及其他规范性文件。这包括：
                                            </p>
                                            <p className="subtitle">
                                                2.1
                                                提供、处理、维护、改善、开发我们的产品或提供给您的服务，例如交付、激活、验证、售后、客户支持和广告宣传。
                                            </p>

                                            <p className="subtitle">
                                                2.2
                                                用于防丢失和防欺诈等目的的安全保障，协助识别用户、验证身份等。
                                            </p>
                                            <p className="subtitle">
                                                2.3
                                                处理您关于设备与服务的提问或请求，
                                                例如解答客户询问、发送系统及应用程序的消息通知。
                                            </p>
                                            <p className="subtitle">
                                                2.4
                                                进行相关的推广活动，例如提供推广与促销的资料和更新。
                                                除非适用的法律另有规定，如果您不再希望接收某些类别的推广信息，您可以通过该信息中提供的退出方式来拒绝此类服务。
                                            </p>
                                            <p className="subtitle">
                                                2.5
                                                内部目的，如数据分析、研究和开发与我们产品或服务的使用相关的统计信息，以更好地改进我们的产品或服务。
                                            </p>
                                            <p className="subtitle">
                                                2.6
                                                优化设备的性能，例如分析应用程序的内存使用情况或应用的CPU利用率。
                                            </p>
                                            <p className="subtitle">
                                                2.7
                                                其他征得您同意的目的关于我们如何使用您的信息（其中可能包含个人信息），下面提供了更多详细示例：理、维护、改善、开发我们的产品或提供给您的服务，例如交付、激活、验证、售后、客户支持和广告宣传。
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    2.7.1
                                                    激活、注册您购买的产品或服务。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.7.2
                                                    创建及维护您的帐号：您通过网站或移动设备创建用户帐号时提供的个人信息，可用于建立您个人帐号和资料页。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.7.3 处理您的订购单：
                                                    与电子商务订单相关的信息可用于处理订购单和相关的售后服务，包括客户支持和重新发货。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.7.4
                                                    收集用户反馈：您选择提供的反馈对帮助我们改进服务非常重要。为了及时处理您提供的反馈，我们可能会使用您所提供的个人信息与您联系，并保留记录用于解决问题及改进服务。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.7.5
                                                    发送通知：我们可能会将您的个人信息用于发送重要通知，例如有关订单的通知和我们的条款、条件和政策的变更。由于此类信息对您和我们之间的沟通至关重要，不建议您拒绝接收此类信息。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.7.6
                                                    进行促销活动：如果您通过我们的社交媒体平台参与了抽奖、比赛或类似的促销活动，我们可能会使用您提供的个人信息以为您发送此类奖励。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.7.7
                                                    提供个性化用户服务和内容：为了保护您的隐私，我们会使用一个唯一标识符而不是您的姓名、电子邮件或其他可以直接识别您的信息，以为您提供个性化的产品、服务和活动，包括广告。
                                                </p>
                                            </div>
                                        </div>
                                        <p>
                                            我们可能将这些信息与其他信息结合起来（包括跨不同服务或设备如电脑、手机、智能电视和其他联网设备中的信息），用于提供和改进我们的产品、服务、内容和广告宣传。
                                        </p>
                                    </section>

                                    <section className="section3">
                                        <h2>
                                            3. 我们如何使用 Cookie 和同类技术
                                        </h2>
                                        <div className="subSection subSection1">
                                            <p>
                                                我们和第三方服务提供商及业务合作伙伴使用Cookie、标签和脚本等技术。这些技术用于分析趋势、管理网站、追踪您的网站活动并收集关于整个用户群体的统计信息。我们会收到通过以上技术收集的个体或汇总的分析报告。这些技术帮助我们更好地了解您的行为，使我们了解您浏览我们网站的哪些部分，衡量广告和网络搜索的效果并加以改善。
                                            </p>
                                            <p className="subtitle">
                                                3.1
                                                日志文件：和大部分网站一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网协议
                                                (IP)
                                                地址、浏览器类型、互联网服务供应商
                                                (ISP)、引用/退出页面、操作系统、日期/时间戳和/或点击流数据。我们不会把自动收集的日志数据与我们收集的其他信息进行关联。
                                            </p>
                                            <p className="subtitle">
                                                3.2
                                                本地存储：我们使用本地存储对象
                                                (LSO)，例如HTML来存储内容和偏好，在我们的网站上提供某些功能，或根据您的网页浏览行为投放广告的第三方也会通过HTML来收集和存储相关信息。各种浏览器提供了各自的管理工具来删除HTML本地存储对象。
                                            </p>
                                            <p className="subtitle">
                                                3.3
                                                广告cookie：我们与第三方服务提供商及业务合作伙伴合作，在我们的网站上展示广告或管理我们在其他网站上的广告。我们的第三方服务提供商和业务合作伙伴可能使用广告类cookie来收集您的在线活动和个人兴趣信息，用于向您提供与您的用户画像和兴趣爱好高度相关的广告。我们会在向您提供广告服务之前，获取您明确的事前同意。如果您不希望该信息被用于向您投放针对您兴趣的广告，您可以通过管理您的Cookie
                                                设置来选择退出。
                                            </p>
                                            <p className="subtitle">
                                                3.4
                                                移动分析：在某些移动应用中，我们使用分析类cookie来收集有关访问者如何使用我们网站的信息。这类cookie收集的信息包括您使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据以及应用程序发生崩溃的情况。我们不会将存储于分析软件内的信息与您在移动应用程序中提交的任何个人信息相关联。
                                            </p>
                                        </div>
                                    </section>

                                    <section className="section4">
                                        <h2>
                                            4.
                                            我们如何共享、转让、公开披露您的个人信息
                                        </h2>
                                        <div className="subSection subSection1">
                                            <p className="subtitle">
                                                4.1
                                                共享：我们不会将任何个人信息出售给第三方。我们有时可能会向第三方共享您的个人信息，以便提供或改进我们的产品或服务，包括根据您的要求提供产品或服务。下面提供了有关数据共享的具体信息。
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    4.1.1
                                                    您主动选择的共享：在获得您的明确同意或主动选择的情况下，与您指定的第三方共享您授权/请求范围内的信息。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    4.1.2
                                                    与我们集团共享信息：为了顺利地从事商业经营，以向您提供产品或服务的全部功能，我们可能不时与其他的关联公司共享您的个人信息。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    4.1.3
                                                    与第三方服务提供商与业务合作伙伴共享：为保证向您提供本隐私政策所述的产品和服务，我们可能会在必要时与我们的第三方服务提供商和业务合作伙伴共享您的个人信息。这包括我们的物流供应商、数据中心、数据存储设施、客户服务供应商、广告和营销服务供应商以及其他业务合作伙伴。这些第三方可能代表我们或出于本隐私政策的一项或多项目的处理您的个人信息。我们保证仅出于正当、合法、必要、特定、明确的目的共享为您提供服务所必要的个人信息。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    4.1.4
                                                    其他：根据法律法规规定、法律程序、诉讼和/或公共机构和政府部门依法提出的要求，我们可能有必要披露您的个人信息。如果就国家安全、执法或具有公众重要性的其他事宜而言，披露是必须的或适当的，我们也可能会披露关于您的信息。例如，我们可能与下列人员共享您的个人信息。
                                                </p>
                                                <p>
                                                    (1)
                                                    我们的会计师、审计师、律师或类似的顾问，当我们要求他们提供专业建议时。
                                                </p>
                                                <p>
                                                    (2)
                                                    投资者以及其他相关第三方，如果发生实际或潜在的出售或其他公司交易，且与我们集团内实体相关时。
                                                </p>
                                                <p>
                                                    (3)
                                                    本隐私政策中详述的其他第三方或以其他方式通知您的第三方，如经您授权就特定信息进行披露。
                                                </p>
                                            </div>
                                        </div>
                                        <div className="subSection subSection2">
                                            <p className="subtitle">
                                                4.2
                                                转让：除以下情形外，我们不会将您的信息转让给任何主体：
                                            </p>
                                            <p>(1) 获得您的明确同意。</p>
                                            <p>
                                                (2)
                                                如果我们参与合并、收购或出售其全部或部分资产，可能会影响到您的个人信息，我们将通过电子邮件和/或在我们网站上或其他适当方式发布醒目通知，告知对您个人信息的所有权、使用权方面的任何变化，以及您可能拥有的关于您个人信息的任何选择。
                                            </p>
                                            <p>
                                                (3)
                                                在本隐私政策中已解释的或以其他方式通知您的情况下。
                                            </p>
                                        </div>
                                        <div className="subSection subSection3">
                                            <p className="subtitle">
                                                4.3
                                                公开披露：在以下情况下，我们可能会公开披露您的个人信息：
                                            </p>
                                            <p>
                                                (1)
                                                当我们需要公布促销、比赛或抽奖活动的获胜者时，我们只会公布有限的信息。
                                            </p>
                                            <p>
                                                (2)
                                                当我们已获得您的明确同意，或您通过我们的服务如社交媒体页面或公共论坛披露了信息。
                                            </p>
                                            <p>
                                                (3)
                                                基于法律或合理依据的公开披露：包括法律法规规范、法律程序、诉讼或应政府主管部门要求。
                                            </p>
                                        </div>
                                    </section>

                                    <section className="section5">
                                        <h2>
                                            5. 我们如何保存和保护您的个人信息
                                        </h2>
                                        <div className="subSection subSection1">
                                            <p className="subtitle">
                                                5.1 我们的安全措施
                                            </p>
                                            <p>
                                                我们致力于保护您的个人信息的安全。为了防止未经授权的访问、披露或其他类似风险，我们落实了所有法律规定的物理、电子和管理措施流程，以保障我们从您的移动设备和我们网站上收集的信息的安全。我们将确保依据适用的法律保护您的个人信息。
                                            </p>
                                            <p className="subtitle">
                                                5.2 您能做什么?
                                            </p>
                                            <p>
                                                您可以通过不向任何人（除非此人经您正式授权）披露您的登录密码或帐号信息，您可以为我们设置唯一的密码，以防止其他网站密码泄露危害您在我们的帐号安全。无论何时，请不要向任何人（包括自称是我们客服的人士）透露您收到的验证码。无论您以我们帐号用户的身份登录我们网站，尤其是在他人的计算机或公共互联网终端上登录时，您总应当在会话结束后注销。
                                            </p>
                                            <p>
                                                我们不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生其他任何互联网用户未经授权使用您帐号的情况或其他任何安全漏洞，您应当立即通知我们。您的协助将有助于我们保护您个人信息的私密性。
                                            </p>
                                            <p className="subtitle">
                                                5.3 访问您的设备上的其他功能
                                            </p>
                                            <p>
                                                我们的应用程序可能会访问您设备上的某些功能，例如使电子邮件启动联系人、短信存储和Wi-Fi网络状态等功能。这些信息用于允许这些应用程序在您的设备上运行，并且允许您与其互动。
                                            </p>
                                            <p className="subtitle">
                                                5.4 保留政策
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    5.4.1
                                                    我们基于本隐私政策以及特定产品或服务的单独隐私政策中所述的信息收集的目的所必需的期间，或者遵守适用法律的要求保留个人信息。具体保留时长可参见特定服务或相关产品页面。个人信息在完成收集目的，或在我们确认过您的删除或注销申请后，或我们终止运营相应产品或服务后，我们将停止保留，并做删除或匿名化处理。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    5.4.2
                                                    根据您的司法管辖区要求，对于我们出于公共利益、科学、历史研究或统计目的而处理的个人信息可能会有例外情况。基于适用法律或您的要求，在必要和允许的情况下，即使进一步的数据处理与最初的收集目的无关，我们可能会继续保留此类信息超过其标准保留期。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="section6">
                                        <h2>6. 您的权利</h2>
                                        <p>您可以控制您的个人信息。</p>
                                        <div className="subSection subSection1">
                                            <p className="subtitle">
                                                6.1 控制设置
                                            </p>
                                            <p>
                                                我们意识到每个人对隐私权的关注各不相同。因此，我们提供了一些示例，说明我们提供的各种方式，供您选择，以限制收集、使用、披露或处理您的个人信息，并控制您的隐私权设置：
                                            </p>
                                            <p>
                                                (1)
                                                打开或者关闭用户体验计划和位置访问功能。
                                            </p>
                                            <p>(2) 登入或注销我们帐号。</p>
                                            <p>
                                                (3) 打开或关闭我们云同步功能。
                                            </p>
                                            <p>
                                                (4)
                                                删除存储在我们云中的任何信息。
                                            </p>
                                            <p>
                                                (5)
                                                打开或关闭其他处理敏感信息或个人信息的服务和功能。
                                            </p>
                                            <p>
                                                如果您之前因为上述目的同意我们使用您的个人信息，您可以通过访问我们的网站来改变您的决定。
                                            </p>
                                        </div>
                                        <div className="subSection subSection2">
                                            <p className="subtitle">
                                                6.2 您对您的个人信息享有的权利
                                            </p>
                                            <p>
                                                根据您所适用的国家或地区法律法规，您有权要求访问、更正、删除我们持有的与您相关的任何个人信息（以下简称请求）。这些权利将受适用法律规定的特定排除和例外情况的限制。
                                                如您满足以下条件，我们将有效地处理您的请求：
                                            </p>
                                            <p>
                                                (1)
                                                请求是通过我们专有的请求渠道提交的，并为保护您的信息安全，您的请求应以书面形式提出。
                                            </p>
                                            <p>
                                                (2)
                                                提供足够的信息使我们可以验证您的身份，确保请求人是所请求信息主体本人或合法授权人。
                                            </p>
                                            <p>
                                                一旦我们获得充分信息确认可处理您的请求时，我们将在适用数据保护法律规定的时间内对您的请求做出回应。
                                                我们有权在适用法律规定的豁免情况，或有其他因适用法律赋予我们的相应权利的情况下拒绝处理或仅回应部分您的请求，例如某些请求明显没有根据、明显过度，或需要披露第三方的信息的请求。某些情况下，我们可能会在适用法律允许时收取相应费用。如果我们认为删除信息的请求在某些方面可能导致我们无法合法地将该信息用于确立、行使或辩护适用法律允许的法律主张和理由，该请求也有可能被拒绝。
                                            </p>
                                        </div>
                                        <div className="subSection subSection3">
                                            <p className="subtitle">
                                                6.3 撤销同意
                                            </p>
                                            <p>
                                                您可以通过提交请求撤销之前出于特定目的授予我们的同意，包括收集、使用和/或披露我们掌握或控制的您的个人信息。根据您所使用的具体服务，您可以通过访问我们的网站来进行相关操作。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，此后不再收集、使用和/或披露您的个人信息。
                                            </p>
                                            <p>
                                                取决于您撤销同意的范围，可能导致您不能继续享受我们的产品或服务。但您撤回同意或授权的决定，不会影响我们此前基于您的同意而开展的个人信息处理活动的有效性。
                                            </p>
                                        </div>
                                        <div className="subSection subSection4">
                                            <p className="subtitle">
                                                6.4 注销服务或帐号
                                            </p>
                                            <p>
                                                如您希望注销特定的产品或服务，您可以通过访问我们的网站来联系我们。
                                            </p>
                                            <p>
                                                如您希望注销帐号，由于注销帐号的操作将使您无法使用我们全线产品或服务，请您谨慎操作。在某些特定情况下，取消可能会被阻止或延迟。
                                            </p>
                                            <p>
                                                当您通过第三方帐号授权登录我们的网站时，需要向第三方申请注销帐号。
                                            </p>
                                        </div>
                                    </section>

                                    <section className="section7">
                                        <h2>7. 未成年人信息的保护</h2>
                                        <p>
                                            我们认为监督孩子使用我们的产品或服务是家长或监护人的责任。但是，我们不直接向儿童提供服务，也不将儿童的个人信息用于营销目的。
                                        </p>
                                        <p>
                                            如果您是家长或监护人，并且您认为未成年人向我们提交了个人信息，可以通过访问我们的网站来联系我们，以确保此类个人信息被立即删除，并取消未成年人对任何适用的我们服务的订阅。
                                        </p>
                                    </section>

                                    <section className="section8">
                                        <h2>8. 第三方网站和服务</h2>
                                        <p>
                                            本政策不适用于第三方提供的产品或服务。取决于您所使用的我们产品或服务，其中可能包括第三方的产品或服务，涉及语音支持、相机处理、视频播放、系统清理及安全相关服务、游戏、统计、社交、分享、推送、信息过滤、输入法等类型，
                                            其中一些会以第三方网站的链接形式提供，还有一些会以SDK、API等形式接入。当您使用这些产品或服务时，也可能收集您的信息。因此，我们强烈建议您花时间阅读该第三方的隐私政策，就像阅读本政策一样。
                                            我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。本政策也不适用于通过我们的服务链接的其他网站。
                                        </p>
                                    </section>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default Privacy;
