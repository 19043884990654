import './supportQuestion.scss';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SupportTop from '../SupportTop';
import axios from 'axios';
import SupportBottom from '../SupportBottom';
// import { IntlContext } from '../../../components/I18nWrapper/I18nWrapper';
import { scrollToLocation } from '../../../components/utils/utils';

function SupportQuestion() {
	// originFaq為資料庫原始值，保留讓每次使用者"搜尋"時能重用
	const [originFaq, setOriginFaq] = useState([]);

	// faq為頁面真正顯示的文字，將隨使用者搜尋改變
	const [faq, setFaq] = useState([]);

	// 使用者輸入的字句，目的讓服務支持其他頁面的搜尋文字，能傳給常用問題頁面
	const searchText = localStorage.getItem('searchText');

	// 判斷是否render結束，再控制+- img隱藏/顯示
	const [isChange, setIsChange] = useState(false);

	const navigate = useNavigate();

	// 多國語言
	// const intlContext = useContext(IntlContext);

	// let language = intlContext.locale;
	// let languageCode = 'ZH';

	// if (language === 'zh-TW') {
	//     languageCode = 'ZF';
	// } else if (language === 'en') {
	//     languageCode = 'EN';
	// } else {
	//     languageCode = 'ZH';
	// }
	// console.log('language', language);
	// console.log('languageCode', languageCode);

	// 從資料庫得到Faq
	const getFaq = async () => {
		await axios
			.get(`web/service/faq?languageCode=${navigator.language}`)
			.then((res) => {
				// console.log(res.data.result);
				setOriginFaq(res.data.result);
				setFaq(res.data.result);
			})
			.catch((err) => {
				console.log(err);
				navigate('/error');
			});
	};

	useEffect(() => {
		getFaq();
	}, [navigator.language]);

	// 使用者搜尋功能，依搜尋條件重設Faq
	const getNewFaq = useCallback(() => {
		const gettingNewFaq = () => {
			let newFaq = [];

			// 每次重新搜尋使用originFaq
			if (originFaq.length !== 0) {
				originFaq.forEach((item) => {
					let obj = {};
					obj.category = item.category;

					// filter: question || answer 有包含關鍵字
					obj.qaList = item.qaList.filter((value) => {
						return (
							value.question.includes(searchText) ||
							value.answer.includes(searchText)
						);
					});

					// 排除外層文字
					obj.qaList.length !== 0 && newFaq.push(obj);
				});
			}

			setFaq(newFaq);
			console.log(newFaq);

			// 迫使render後，再改變img
			setIsChange(!isChange);
		};

		gettingNewFaq();
	}, [originFaq, searchText]);

	useEffect(() => {
		getNewFaq();
	}, [getNewFaq]);

	// 在render結束後，控制dom
	useEffect(() => {
		// 1.+- 圖片變化
		// 清空搜尋文字時，回復初始狀態
		if (searchText === '') {
			// 有底色的問題/答案框 隱藏
			['.faqSecondWrapper', '.faqSecondAnswer'].forEach((element) => {
				document
					.querySelectorAll(element)
					.forEach((item) => item.classList.add('d-none'));
			});

			//內外層+img顯示
			['.icnMoreplusBlue', '.icnMoreplusBlack'].forEach((element) => {
				document
					.querySelectorAll(element)
					.forEach((item) => item.classList.remove('d-none'));
			});

			//內外層-img隱藏
			['.icnMoreplusBlue2', '.icnMoreplusBlack2'].forEach((element) => {
				document
					.querySelectorAll(element)
					.forEach((item) => item.classList.add('d-none'));
			});
		} else {
			// 有底色的問題/答案框 顯示
			['.faqSecondWrapper', '.faqSecondAnswer'].forEach((element) => {
				document
					.querySelectorAll(element)
					.forEach((item) => item.classList.remove('d-none'));
			});

			//內外層+img隱藏
			['.icnMoreplusBlue', '.icnMoreplusBlack'].forEach((element) => {
				document
					.querySelectorAll(element)
					.forEach((item) => item.classList.add('d-none'));
			});

			//內外層-img顯示
			['.icnMoreplusBlue2', '.icnMoreplusBlack2'].forEach((element) => {
				document
					.querySelectorAll(element)
					.forEach((item) => item.classList.remove('d-none'));
			});
		}

		// 2.標記文字
		if (
			document.querySelectorAll('.faqSecondQuestion').length !== 0 &&
			searchText !== ''
		) {
			// faqSecondQuestion 需要把前方的+- img補上
			document.querySelectorAll('.faqSecondQuestion').forEach((item) => {
				if (item.textContent.includes(searchText)) {
					// console.log(item.innerHTML);
					// console.log(item.textContent);

					item.innerHTML =
						'<img class="icnMoreplusBlack me-2 me-lg-3 d-none" src="./images/support/supportQuestion/icnMoreplusBlack.png" alt="icnMoreplusBlack"><img class="icnMoreplusBlack2 me-2 me-lg-3" src="./images/support/supportQuestion/icnMoreplusBlack2.png" alt="icnMoreplusBlack2">' +
						item.textContent.replaceAll(
							searchText,
							`<span class='textActive'>${searchText}</span>`
						);
				}
			});

			// faqSecondAnswer
			document.querySelectorAll('.faqSecondAnswer').forEach((item) => {
				if (item.textContent.includes(searchText)) {
					item.innerHTML = item.textContent.replaceAll(
						searchText,
						`<span class='textActive'>${searchText}</span>`
					);
				}
			});
		}

		// 3.定位到搜尋答案位置
		if (searchText !== '') scrollToLocation('.section2');
	}, [isChange, searchText]);

	console.log(faq);

	return (
		<div className="container-fluid p-0 bg-white">
			<article
				id="container-supportQuestion"
				className="d-flex flex-column align-items-center px-0 mx-auto"
			>
				<SupportTop getNewFaq={getNewFaq} />
				<section className="supportQuestion-section section2 w-100 row bg-white">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h2 className="px-6 mx-auto mb-3 text-grey5">
							常见问题
						</h2>
						<h6 className="px-6 mx-auto mb-0 text-grey5">
							查找常见问题，快速定位故障，提供有效解决方案
						</h6>
					</div>
				</section>
				<section className="supportQuestion-section section3 w-100 row mb-10 mb-lg-14">
					{faq.map((item) => {
						return (
							<div key={item.category} className="px-0">
								<div
									className="faqTitle border-bottom border-grey2 d-flex align-items-center px-3 px-lg-0"
									onClick={(e) => {
										e.target.nextSibling.classList.toggle(
											'd-none'
										);

										//img+號
										e.target.children[0].classList.toggle(
											'd-none'
										);

										//img-號
										e.target.children[1].classList.toggle(
											'd-none'
										);
									}}
								>
									<img
										className={`icnMoreplusBlue me-2`}
										src={`./images/support/supportQuestion/icnMoreplusBlue.png`}
										alt={`icnMoreplusBlue`}
									/>
									<img
										className={`icnMoreplusBlue2 me-2 d-none`}
										src={`./images/support/supportQuestion/icnMoreplusBlue2.png`}
										alt={`icnMoreplusBlue2`}
									/>
									{item.category}
								</div>
								<div className="faqSecondWrapper d-flex flex-column bg-background ps-3 pe-6 px-lg-11 py-5 pt-lg-8 pb-lg-7 d-none">
									{item.qaList.map((element, index) => {
										const isLastElement =
											index === item.qaList.length - 1;

										return (
											<div
												key={element.qaGuid}
												className={`d-flex flex-column ${
													isLastElement
														? 'mb-0'
														: 'mb-5'
												}`}
											>
												<div
													className="faqSecondQuestion d-flex align-items-center"
													onClick={(e) => {
														e.target.nextSibling.classList.toggle(
															'd-none'
														);

														//img+號
														e.target.children[0].classList.toggle(
															'd-none'
														);

														//img-號
														e.target.children[1].classList.toggle(
															'd-none'
														);
													}}
												>
													<img
														className={`icnMoreplusBlack me-2 me-lg-3`}
														src={`./images/support/supportQuestion/icnMoreplusBlack.png`}
														alt={`icnMoreplusBlack`}
													/>
													<img
														className={`icnMoreplusBlack2 me-2 me-lg-3 d-none`}
														src={`./images/support/supportQuestion/icnMoreplusBlack2.png`}
														alt={`icnMoreplusBlack2`}
													/>
													{/* 序號 */}
													{index + 1 + '. '}
													{/* 問題 */}
													{element.question}
												</div>

												{/* 答案 */}
												<div className="faqSecondAnswer ps-5 ps-lg-6 text-grey3 mt-3 d-none">
													{element.answer}
												</div>
											</div>
										);
									})}

									<div className="collapseWrapper pt-7 pt-lg-10 text-end">
										<button
											className="collapseWrapperBtn border-0 bg-transparent text-primary"
											onClick={(e) => {
												e.target.parentNode.parentNode.classList.add(
													'd-none'
												);

												//最外層+號
												e.target.parentNode.parentNode.previousSibling.children[0].classList.toggle(
													'd-none'
												);

												//最外層-號
												e.target.parentNode.parentNode.previousSibling.children[1].classList.toggle(
													'd-none'
												);
											}}
										>
											收起
										</button>
									</div>
								</div>
							</div>
						);
					})}
				</section>
				<div
					className={`supportQuestion-section section4 w-100 row mb-10 mb-lg-14 ${
						faq.length === 0 ? '' : 'd-none'
					}`}
				>
					<div className="h6 text-center">
						抱歉,没有搜索到相关内容
					</div>
				</div>
				<SupportBottom
					leftCol={{
						title: `下载中心`,
						text: `查看更多内容`,
						link: `/support/downloadcenter`,
					}}
					rightCol={{
						title: `联系我们`,
						text: `开始咨询`,
						link: `/support/contactus`,
					}}
				/>
			</article>
		</div>
	);
}

export default SupportQuestion;
