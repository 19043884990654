import './accountDelete.scss';
import React, {
	useState,
	useEffect,
	useRef,
	useLayoutEffect,
	useContext,
} from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import { SidebarHeightContext } from '../../../components/layoutAccount/LayoutAccount';
import { Visibility, VisibilityOff, Sync } from '@material-ui/icons';
import { axiosApi } from '../../../api';

function AccountDelete() {
	const [cookies, setCookie] = useCookies(['loginUser']);
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();
	const sidebarHeightContext = useContext(SidebarHeightContext);

	// if (!guid) {
	//     setCookie('loginUser', { status: null }, { path: '/' });
	//     setCookie('loginAuto', 'false', { path: '/' });
	//     navigate('/login?next=accountDelete');
	// }

	// 自定義sidebar高度(由於confirmBtn在外面)
	// const sidebarHeightContext = useContext(SidebarHeightContext);
	// sidebarHeightContext.setSidebarHeight('100% - confirmBtn');
	// console.log('sidebarHeight', sidebarHeightContext.sidebarHeight);

	const swalWithCustomStyling = Swal.mixin({
		customClass: {
			container: 'accountDelete-container-class',
			popup: 'accountDelete-popup-class',
			header: 'accountDelete-header-class',
			title: 'accountDelete-title-class',
			closeButton: 'accountDelete-close-button-class',
			icon: 'accountDelete-icon-class',
			image: 'accountDelete-image-class',
			content: 'accountDelete-content-class',
			input: 'accountDelete-input-class',
			actions: 'accountDelete-actions-class',
			confirmButton: 'btn btn-success accountDelete-confirm-button-class',
			cancelButton: 'btn btn-danger accountDelete-cancel-button-class',
			footer: 'accountDelete-footer-class',
		},
		buttonsStyling: false,
	});

	useEffect(() => {
		// 自定義sidebar高度(由於confirmBtn在外面)
		sidebarHeightContext.setSidebarHeight('100% - confirmBtn');
		// console.log(sidebarHeightContext.sidebarHeight);
	}, []);

	const confirmationAlert = () => {
		swalWithCustomStyling
			.fire({
				title: '',
				text: '删除帐号是永久且不可复原的。确认要删除此帐号吗?',
				html: '删除帐号是永久且不可复原的。 <br />确认要删除此帐号吗?',
				icon: 'warning',
				iconColor: '#CC3300',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonText: '确认删除',
				cancelButtonText: '取消',
				reverseButtons: true,
			})
			.then(function (result) {
				console.log(result);
				if (result.isConfirmed) {
					handleDeleteAccount();
				} else {
				}
			});
	};

	// Delete Account
	const handleDeleteAccount = async () => {
		// if (!guid) {
		//     setCookie('loginUser', { status: null }, { path: '/' });
		//     setCookie('loginAuto', 'false', { path: '/' });
		//     navigate('/login');
		// }

		axiosApi(`/web/user/accountdelete`, 'post', { password })
			.then((res) => {
				const status = res.data.status;
				if (status === '0') {
					setCookie('loginUser', { status: null }, { path: '/' });
					setCookie('loginAuto', 'false', { path: '/' });
					navigate('/login');
				} else if (status === '1') {
					swalWithCustomStyling.fire({
						title: '操作失败， 查无此帐号/密码错误',
						text: '请重新登录后再尝试',
						icon: 'error',
						confirmButtonText: '确认',
					});
					setCookie('loginUser', { status: null }, { path: '/' });
					setCookie('loginAuto', 'false', { path: '/' });
					navigate('/login');
				} else {
					swalWithCustomStyling.fire({
						title: '操作失败',
						text: '请重新登录后再尝试',
						icon: 'error',
						confirmButtonText: '确认',
					});
					setCookie('loginUser', { status: null }, { path: '/' });
					setCookie('loginAuto', 'false', { path: '/' });
					navigate('/login');
				}
			})
			.catch((err) => {
				swalWithCustomStyling.fire({
					title: '操作失败，密码错误',
					text: '请重新登录后再尝试',
					icon: 'error',
					confirmButtonText: '确认',
				});
			});

		// await axios({
		//     method: 'delete',
		//     url: '/web/user/accountdelete',
		//     headers: { 'Content-Type': 'application/json' },
		//     data,
		// })
		//     .then((res) => {
		//         const status = res.data.status;
		//         if (status === '0') {
		//             setCookie('loginUser', { status: null }, { path: '/' });
		//             setCookie('loginAuto', 'false', { path: '/' });
		//             navigate('/login');
		//         } else if (status === '1') {
		//             swalWithCustomStyling.fire({
		//                 title: '操作失败， 查无此帐号/密码错误',
		//                 text: '请重新登录后再尝试',
		//                 icon: 'error',
		//                 confirmButtonText: '确认',
		//             });
		//             setCookie('loginUser', { status: null }, { path: '/' });
		//             setCookie('loginAuto', 'false', { path: '/' });
		//             navigate('/login');
		//         } else {
		//             swalWithCustomStyling.fire({
		//                 title: '操作失败',
		//                 text: '请重新登录后再尝试',
		//                 icon: 'error',
		//                 confirmButtonText: '确认',
		//             });
		//             setCookie('loginUser', { status: null }, { path: '/' });
		//             setCookie('loginAuto', 'false', { path: '/' });
		//             navigate('/login');
		//         }
		//     })
		//     .catch((err) => {
		//         swalWithCustomStyling.fire({
		//             title: '操作失败，密码错误',
		//             text: '请重新登录后再尝试',
		//             icon: 'error',
		//             confirmButtonText: '确认',
		//         });
		//     });
	};

	// 儲存資料
	async function handleSubmit(e) {
		e.preventDefault();
		const accessToken = localStorage.getItem('accessToken');
		// console.log('accessToken', accessToken);

		if (!password) {
			swalWithCustomStyling.fire({
				title: '操作失败，输入密码',
				text: '',
				icon: 'error',
				confirmButtonText: '确认',
			});
		} else {
			try {
				confirmationAlert();
			} catch (err) {
				console.log(err);
			}
		}
	}

	// 密碼顯示切換
	function switchPassword() {
		setShowPassword(!showPassword);
	}

	return (
		<article id="container-accountDelete">
			<div className="articleBoxWithSideBox">
				<h1 className="containTitle">删除宾乐达帐号</h1>
				<section className="row g-3 mb-3">
					<div className="col-12 warningWord1">
						您想要删除您的宾乐达帐号吗？
					</div>
					<div className="col-12 warningWord2">
						请注意，帐号删除将同步清除您帐号中的全部设置与相关数据，且无法回复。
					</div>
					<div className="col-9 col-sm-6 warningWord2 mt-6">
						<label htmlFor="password" className="form-label">
							请输入删除本宾乐达帐号的密码
						</label>
						<div className="input-group d-flex">
							<input
								className="form-control"
								type={showPassword ? 'text' : 'password'}
								id="password"
								placeholder="请输入密码"
								value={password || ''}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>

							<button
								type="button"
								className="btn btn-color-outline-auxiliary"
								id="passwordSwitch"
								tabIndex="-1"
								onClick={switchPassword}
							>
								{showPassword ? (
									<Visibility />
								) : (
									<VisibilityOff />
								)}
							</button>
						</div>
					</div>
				</section>
			</div>
			{/*submit button */}
			<div className="text-center">
				<button
					type="submit"
					id="btn-confirm"
					className="btn btn-color-main rounded-pill"
					onClick={handleSubmit}
				>
					删除帐号
				</button>
			</div>
		</article>
	);
}

export default AccountDelete;
