import './authUpdateGroupName.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

function AuthUpdateGroupName() {
    const name = useRef();
    const navigate = useNavigate();
    const [authGroupName, setAuthGroupName] = useState(); //權限分組名稱
    const paramsGuid = useParams('authorizationGuid');
    // console.log(paramsGuid);
    const [rowStamp, setRowStamp] = useState();

    // 1. 如果url上的authorizationGuid，存在於資料庫，則將其AuthGroupName與RowStamp儲存
    const getAuthGroupList = useCallback(() => {
        const gettingAuthGroupList = async () => {
            await axios
                .get(`web/SysAuthorization/authorization`)
                .then((res) => {
                    // console.log(res.data.result);
                    res.data.result.forEach((el) => {
                        if (
                            el.authorizationGuid ===
                            paramsGuid.authorizationGuid
                        ) {
                            setAuthGroupName(el.name);
                            setRowStamp(el.rowStamp);
                        }
                    });
                })
                .catch((err) => console.log(err));
        };

        gettingAuthGroupList();
    }, []);

    useEffect(() => {
        getAuthGroupList();
    }, [getAuthGroupList]);

    const handleSubmit = async () => {
        // 透過url是否有paramsGuid判斷create or edit
        if (Object.keys(paramsGuid).length) {
            // 1. edit 權限分組
            await axios
                .put(
                    `web/SysAuthorization/authorization/${paramsGuid.authorizationGuid}`,
                    {
                        name: name.current.value,
                        rowStamp: rowStamp,
                    }
                )
                .then(() => {
                    navigate(`/authUpdate`);
                })
                .catch((err) => console.log(err));
        } else {
            // 2. create 權限分組
            await axios
                .post(`web/SysAuthorization/authorization`, {
                    name: name.current.value,
                })
                .then(() => {
                    navigate(`/authUpdate`);
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <section id="section-main">
            <div id="container-authUpdateGroupName" className="container">
                <div className="d-flex align-items-center mb-3">
                    <h5 className="fw-bold mb-0">权限设定</h5>

                    <div className="submitBtnWrapper ms-auto">
                        <button
                            className="btn btn-outline-primary me-2"
                            onClick={() => navigate(`/authUpdate`)}
                        >
                            取消
                        </button>
                        <button
                            className="btn btn-primary text-white"
                            onClick={() => handleSubmit()}
                        >
                            儲存
                        </button>
                    </div>
                </div>

                <div className="authWrapper row">
                    <div className="authLeftWrapper col-12 px-2">
                        <div className="bg-white px-4 py-3 rounded-4">
                            <div className="row">
                                <div className="col-4">
                                    {Object.keys(paramsGuid).length ? (
                                        <h6 className="fw-bold">
                                            编辑权限分组
                                        </h6>
                                    ) : (
                                        <h6 className="fw-bold">
                                            新增权限分组
                                        </h6>
                                    )}

                                    <label
                                        htmlFor="name"
                                        className="text-grey4 fontSize75Rem mb-2"
                                    >
                                        群组名称
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        ref={name}
                                        className="form-control mb-3"
                                        value={authGroupName || ''}
                                        onChange={(e) =>
                                            setAuthGroupName(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AuthUpdateGroupName;
