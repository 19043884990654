import './usersUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as dayjs from 'dayjs';
import { axiosApi } from '../../../api';

const UsersUpdateDetail = () => {
	const navigate = useNavigate();
	const paramsGuid = useParams('userGuid');
	const [rowStamp, setRowStamp] = useState('');
	const [avatarData, setAvatarData] = useState('');
	const [avatarImg, setAvatarImg] = useState('');
	const [provinceList, setProvinceList] = useState([]);
	const [provincialCityList, setProvincialCityList] = useState([]);
	const [isDeleteAvatar, setIsDeleteAvatar] = useState(false);
	const [userInfo, setUserInfo] = useState({
		userGuid: '',
		photoLink: '',
		fullname: '',
		userID: '',
		lastname: '',
		firstname: '',
		gender: '',
		birthday: '',
		phone1CountryCode: '',
		phone1: '',
		email1: '',
		email2: '',
		firstLearningYear: '',
		status: '',
		addrCountry: '',
		addrProvince: '',
		addrCity: '',
		addrStreet: '',
		addrZip: '',
		userLevelCode: '',
		levelExpiration: '',
		family: '',
		familyMax: '',
		getOfficialMSG: '',
		userDrillModifyTime: '',
		lockoutEnd: '',
		accessFailedCount: '',
		createTime: '',
		lastLoginTime: '',
		lastLoginIP: '',
		securityStampEnd: '',
		securityStamp: '',
	});

	//#region function

	const checkUserIdValid = async (userID) => {
		if (userID !== '') {
			await axios
				.get(
					`web/sysUser/checkUserID/${paramsGuid.userGuid}?userID=${userID}`
				)
				.then((res) => {
					const isValid = res.data;
					// console.log(isValid);
					if (!isValid) {
						Swal.fire({
							icon: 'error',
							title: `用户名(ID)重複，請重新輸入`,
						});
						setUserInfo({ ...userInfo, userID: '' });
					} else {
						// setOldUserID(userID);
					}
				});
		}
	};

	const checkEmailValid = async (email) => {
		if (email !== '') {
			await axios
				.get(
					`web/sysUser/checkEmail/${paramsGuid.userGuid}?email1=${email}`
				)
				.then((res) => {
					const isValid = res.data;
					if (!isValid && email !== '') {
						Swal.fire({
							icon: 'error',
							title: `电话重複，請重新輸入`,
						});
						setUserInfo({ ...userInfo, email1: '' });
					} else {
						// setOldUserID(userID);
					}
				});
		}
	};

	const checkPhone1Valid = async (countryCode, phone) => {
		await axios
			.get(
				`web/sysUser/checkPhone/${paramsGuid.userGuid}?countryCode=${countryCode}&phone=${phone}`
			)
			.then((res) => {
				const isValid = res.data;
				if (!isValid && phone !== '') {
					Swal.fire({
						icon: 'error',
						title: `电话重複，請重新輸入`,
					});
					setUserInfo({ ...userInfo, phone: '' });
				} else {
					// setOldUserID(userID);
				}
			});
	};

	const handleAvatar = (file) => {
		if (file) {
			// console.log(file);
			const blobURL = URL.createObjectURL(file);
			setUserInfo({ ...userInfo, photoLink: blobURL });
			setAvatarImg(blobURL);
			setIsDeleteAvatar(false);
		}
	};

	const handleRemoveAvatar = () => {
		setUserInfo({ ...userInfo, photoLink: '' });
		setAvatarImg('./images/user-default.png');
		setIsDeleteAvatar(true);
		document.getElementById('file').value = '';
	};

	const handleAddress = (countryCode) => {
		if (countryCode !== 'CN') {
			setUserInfo({
				...userInfo,
				addrCountry: countryCode,
				addrProvince: '',
				addrCity: '',
			});
		} else {
			setUserInfo({ ...userInfo, addrCountry: countryCode });
		}
	};

	// Province
	const getProvinceList = (input) => {
		// console.log(input);
		let locale = navigator.language;
		switch (locale) {
			case 'zh-TW': {
				locale = 'ZF';
				break;
			}
			case 'en': {
				locale = 'EN';
				break;
			}
			default: {
				locale = 'ZH';
				break;
			}
		}

		if (input === 'CN') {
			axios(`/web/sys/provinceList/?countryCode=${input}`).then(
				(data) => {
					// Province
					setProvinceList(data.data.provinceList);
				}
			);
		} else {
			setProvinceList([]);
		}
	};

	// City
	const getCityList = (input) => {
		let locale = navigator.language;
		switch (locale) {
			case 'zh-TW': {
				locale = 'ZF';
				break;
			}
			case 'en': {
				locale = 'EN';
				break;
			}
			default: {
				locale = 'ZH';
				break;
			}
		}

		axios(
			`/web/sys/provincialCityList/?countryCode=${userInfo.addrCountry}&addrProvince=${input}`
		).then((data) => {
			// City
			setProvincialCityList(data.data.provincialCityList);
		});
	};

	const handleFileUpload = (e) => {
		const dataUrl = e.target.value;
		if (!dataUrl) return;
		const fileReader = new FileReader();
		const file = e.target.files.item(0);
		// console.log(file);
		const fileType = file.type.split('/')[1].toLowerCase();
		const fileLoad = (e) => {
			// console.log(e.target.result);
			const imgSize = e.target.result.length * 0.75 - 2;
			if (imgSize > 2097152) {
				alert('档案大小 : 最大 2 MB');
			} else if (
				fileType !== 'jpg' &&
				fileType !== 'png' &&
				fileType !== 'jpeg'
			) {
				alert('档案限制 : JPG, PNG');
			} else {
				setAvatarData(file);
				handleAvatar(file);
			}
		};

		fileReader.addEventListener('load', fileLoad);
		fileReader.readAsDataURL(file);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const form = new FormData(e.target);
		const data = Object.fromEntries(form.entries());
		// 每一列的 KEY 和 VALUE
		for (const [key, value] of form.entries()) {
			data[key] = value;
			// if (!value) {
			// 	delete data[key];
			// }
		}
		data['file'] = avatarData;

		const formData = new FormData();
		Object.keys(data).forEach((key) => {
			if (!data[key]) data[key] = '';

			formData.append(key, data[key]);
		});

		await axios
			.put(`web/sysUser/UserInfo/${paramsGuid.userGuid}`, formData)
			.then((res) => {
				// console.log(res.data);
				res.status === 200 && navigate('/usersUpdate');
			})
			.catch((err) => console.log(err));
	};

	// delete
	const handleDelete = async (e) => {
		e.preventDefault();

		await Swal.fire({
			html: `<h4>確定要刪除?</h4>`,
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: '取消',
			showConfirmButton: true,
			confirmButtonText: '確定',
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((res) => {
			if (res.dismiss === 'cancel') {
				console.log('cancelled');
				return;
			} else {
				console.log('confirmed delete');

				axiosApi(
					`web/sysUser/userInfo/${paramsGuid.userGuid}`,
					'delete'
				)
					.then((res) => navigate(`/usersUpdate`))
					.catch((err) => console.log(err));
			}
		});
	};

	//#endregion

	//#region useEffect

	//edit getInfo
	useEffect(() => {
		if (Object.keys(paramsGuid).length)
			(async () => {
				await axios
					.get(`web/sysUser/userInfo/${paramsGuid.userGuid}`)
					.then((res) => {
						// console.log(res);
						const userInfo = res.data.result;
						const {
							photoLink,
							fullname,
							userID,
							lastname,
							firstname,
							gender,
							birthday,
							phone1CountryCode,
							phone1,
							email1,
							email2,
							firstLearningYear,
							status,
							addrCountry,
							addrProvince,
							addrCity,
							addrStreet,
							addrZip,
							userLevelCode,
							levelExpiration,
							family,
							familyMax,
							getOfficialMSG,
							userDrillModifyTime,
							lockoutEnd,
							accessFailedCount,
							createTime,
							lastLoginTime,
							lastLoginIP,
							securityStampEnd,
							securityStamp,
							provinceList,
							provincialCityList,
							rowStamp,
						} = userInfo;

						setAvatarImg(userInfo.photoLink);
						setRowStamp(rowStamp);
						setProvinceList(provinceList);
						setProvincialCityList(provincialCityList);
						setUserInfo({
							...userInfo,
							photoLink,
							fullname,
							userID,
							lastname,
							firstname,
							gender,
							birthday: dayjs(birthday).format('YYYY-MM-DD'),
							phone1CountryCode,
							phone1,
							email1,
							email2,
							firstLearningYear,
							status,
							addrCountry,
							addrProvince,
							addrCity,
							addrZip,
							addrStreet,
							userLevelCode,
							levelExpiration:
								levelExpiration &&
								dayjs(levelExpiration).format(
									'YYYY-MM-DD HH:mm:ss'
								),
							family,
							familyMax,
							getOfficialMSG,
							userDrillModifyTime:
								userDrillModifyTime &&
								dayjs(userDrillModifyTime).format(
									'YYYY-MM-DD HH:mm:ss'
								),
							lockoutEnd:
								lockoutEnd &&
								dayjs(lockoutEnd).format('YYYY-MM-DD HH:mm:ss'),
							accessFailedCount,
							createTime:
								createTime &&
								dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
							lastLoginTime: dayjs(lastLoginTime).format(
								'YYYY-MM-DD HH:mm:ss'
							),
							lastLoginIP,
							securityStampEnd:
								securityStampEnd &&
								dayjs(securityStampEnd).format(
									'YYYY-MM-DD HH:mm:ss'
								),
							securityStamp,
						});
					})
					.catch((err) => navigate('/usersUpdate'));
			})();
	}, [paramsGuid]);

	//#endregion

	return (
		<>
			<section id="section-main">
				<form id="container-userUpdateDetail" onSubmit={handleSubmit}>
					<div className="d-flex">
						<h5 className="fw-bold mb-4">用户管理</h5>
						{Object.keys(paramsGuid).length && (
							<button
								className={`btnDelete btn btn-outline-danger px-4 ms-auto me-2`}
								onClick={(e) => {
									handleDelete(e);
								}}
							>
								刪除
							</button>
						)}
						<button
							className="btnCancel btn btn-outline-primary px-4 me-2"
							onClick={(e) => {
								e.preventDefault();
								navigate('/usersUpdate');
							}}
						>
							取消
						</button>
						<button className="btn btn-primary text-white px-4">
							儲存
						</button>
					</div>
					<div className="infoWrapper bg-white p-4 rounded-4 mb-3">
						<div className="row">
							<div className="col-6 manageFileWrapper bg-white p-4 rounded-4 mb-3">
								<input
									id="rowStamp"
									name="rowStamp"
									className="d-none"
									defaultValue={rowStamp}
								/>
								<input
									id="isDeleteAvatar"
									name="isDeleteAvatar"
									className="d-none"
									value={isDeleteAvatar}
									readOnly
								/>
								<h6 className="fw-bold mb-3">用户管理</h6>
								<label
									htmlFor="fullname"
									className="fontSize75Rem mb-2"
								>
									显示名称
								</label>
								<input
									id="fullname"
									name="fullname"
									className="form-control mb-3 w-100"
									value={userInfo.fullname || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											fullname: e.target.value,
										})
									}
								/>
								<label
									htmlFor="userID"
									className="fontSize75Rem mb-2"
								>
									用户名(ID)
								</label>
								<input
									id="userID"
									name="userID"
									className="form-control mb-3 w-100"
									value={userInfo.userID || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											userID: e.target.value,
										})
									}
									// check userId
									onBlur={(e) =>
										checkUserIdValid(e.target.value)
									}
								/>
								<div className="row">
									<div className="col-6">
										<label
											htmlFor="lastname"
											className="fontSize75Rem mb-2"
										>
											姓
										</label>
										<input
											id="lastname"
											name="lastname"
											className="form-control mb-3 w-100"
											value={userInfo.lastname || ''}
											onChange={(e) =>
												setUserInfo({
													...userInfo,
													lastname: e.target.value,
												})
											}
										/>
									</div>
									<div className="col-6">
										<label
											htmlFor="firstname"
											className="fontSize75Rem mb-2"
										>
											名
										</label>
										<input
											id="firstname"
											name="firstname"
											className="form-control mb-3 w-100"
											value={userInfo.firstname || ''}
											onChange={(e) =>
												setUserInfo({
													...userInfo,
													firstname: e.target.value,
												})
											}
										/>
									</div>
								</div>
								<label
									htmlFor="gender"
									className="fontSize75Rem mb-3"
								>
									性别
								</label>
								<select
									className="form-control mb-3 w-100"
									name="gender"
									id="gender"
									value={userInfo.gender || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											gender: e.target.value,
										})
									}
								>
									<option value="1">男</option>
									<option value="2">女</option>
								</select>
								<label
									htmlFor="birthday"
									className="fontSize75Rem mb-2"
								>
									生日
								</label>
								<input
									type="date"
									id="birthday"
									name="birthday"
									className="form-control mb-3 w-100"
									value={userInfo.birthday || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											birthday: e.target.value,
										})
									}
								/>

								<div className="row">
									<div className="col-5">
										<label
											htmlFor="phone1CountryCode"
											className="fontSize75Rem mb-2"
										>
											电话国码
										</label>
										<select
											className="form-control mb-3 w-100"
											name="phone1CountryCode"
											id="phone1CountryCode"
											value={
												userInfo.phone1CountryCode || ''
											}
											onChange={(e) => {
												setUserInfo({
													...userInfo,
													phone1CountryCode:
														e.target.value,
												});
												checkPhone1Valid(
													e.target.value,
													userInfo.phone1
												);
											}}
										>
											<option value="86">+86 中国</option>
											<option value="886" disabled>
												+886 台湾
											</option>
											<option value="852" disabled>
												+852 香港
											</option>
										</select>
									</div>
									<div className="col-7">
										<label
											htmlFor="phone1"
											className="fontSize75Rem mb-2"
										>
											电话号码
										</label>
										<input
											id="phone1"
											name="phone1"
											className="form-control mb-3 w-100"
											value={userInfo.phone1 || ''}
											onChange={(e) =>
												setUserInfo({
													...userInfo,
													phone1: e.target.value,
												})
											}
											onBlur={(e) =>
												checkPhone1Valid(
													userInfo.phone1CountryCode,
													e.target.value
												)
											}
										/>
									</div>
								</div>
								<label
									htmlFor="email1"
									className="fontSize75Rem mb-2"
								>
									邮箱1
								</label>
								<input
									id="email1"
									name="email1"
									className="form-control mb-3 w-100"
									value={userInfo.email1 || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											email1: e.target.value,
										})
									}
									onBlur={(e) =>
										checkEmailValid(e.target.value)
									}
								/>
								<label
									htmlFor="email2"
									className="fontSize75Rem mb-2"
								>
									邮箱2
								</label>
								<input
									id="email2"
									name="email2"
									className="form-control mb-3 w-100"
									value={userInfo.email2 || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											email2: e.target.value,
										})
									}
								/>
								<label
									htmlFor="firstLearningYear"
									className="fontSize75Rem mb-2"
								>
									球龄(年)
								</label>
								<input
									type="number"
									id="firstLearningYear"
									name="firstLearningYear"
									className="form-control mb-3 w-100"
									value={userInfo.firstLearningYear || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											firstLearningYear: e.target.value,
										})
									}
								/>
								<label
									htmlFor="status"
									className="fontSize75Rem mb-2"
								>
									账号状态
								</label>
								<select
									className="form-control mb-3 "
									name="status"
									id="status"
									value={userInfo.status || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											status: e.target.value,
										})
									}
									required
								>
									<option value="0">"0"：未启用</option>
									<option value="1">"1"：启用中</option>
									<option value="2">"2"：停用中</option>
									<option value="4">"4"：删除</option>
								</select>
							</div>
							<div className="col-6 manageFileWrapper bg-white p-4 rounded-4 mb-3">
								<div className="photo-wrapper">
									{/*<!-- 圖片 -->*/}
									<div className="photoInfo">
										<div className="outer_container">
											<div className="inner_container">
												<img
													src={
														avatarImg ||
														'./images/user-default.png'
													}
													id="imagePho"
													alt=""
												/>
											</div>
										</div>
										<div className="text-center photoBtnWrapper">
											<label
												id="avatar-btn"
												htmlFor="file"
												className="btnCancel btn btn-outline-primary ms-auto my-2"
											>
												变更头像照片
											</label>
											<input
												type="file"
												id="file"
												name="file"
												onChange={(e) => {
													handleFileUpload(e);
												}}
												className="d-none"
												accept=".PNG, .JPG"
											/>
											<div
												className="btnCancel btn btn-outline-danger ms-auto mb-2"
												onClick={handleRemoveAvatar}
											>
												移除头像照片
											</div>

											<p>
												档案大小 : 最大 2 MB
												<br />
												档案限制 : JPG, JPEG, PNG
											</p>
											<div
												className="invalid-feedback"
												id="avatar-info"
											></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="infoWrapper bg-white p-4 rounded-4 mb-3">
						<div className="infoTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold">居住地</h6>
						</div>
						<div className="infoBottom row mb-5">
							<div className="col-4">
								<label
									htmlFor="addrCountry"
									className="fontSize75Rem mb-2"
								>
									国家/地区
								</label>
								<select
									className="form-control mb-3 "
									name="addrCountry"
									id="addrCountry"
									value={userInfo.addrCountry || ''}
									onChange={(e) => {
										handleAddress(e.target.value);
										getProvinceList(e.target.value);
									}}
								>
									<option value="">请选择</option>
									<option value="CN">中国</option>
									<option value="TW">台湾</option>
									<option value="HK">香港</option>
								</select>
								<label
									htmlFor="addrProvince"
									className="fontSize75Rem mb-2"
								>
									省/市
								</label>
								<select
									className="form-control mb-3 "
									name="addrProvince"
									id="addrProvince"
									value={userInfo.addrProvince || ''}
									onChange={(e) => {
										setUserInfo({
											...userInfo,
											addrProvince: e.target.value,
										});
										getCityList(e.target.value);
									}}
									disabled={userInfo.addrCountry !== 'CN'}
								>
									<option value="">请选择</option>
									{provinceList?.map((el) => {
										return (
											<option key={el} value={el}>
												{el}
											</option>
										);
									})}
								</select>
							</div>
							<div className="col-4">
								<label
									htmlFor="addrCity"
									className="fontSize75Rem mb-2"
								>
									市/县
								</label>
								<select
									className="form-control mb-3 "
									name="addrCity"
									id="addrCity"
									value={userInfo.addrCity || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											addrCity: e.target.value,
										})
									}
									disabled={userInfo.addrCountry !== 'CN'}
								>
									<option value="">请选择</option>
									{provincialCityList?.map((el) => {
										return (
											<option key={el} value={el}>
												{el}
											</option>
										);
									})}
								</select>
								<label
									htmlFor="addrZip"
									className="fontSize75Rem mb-2"
								>
									邮政编码
								</label>
								<input
									type="number"
									id="addrZip"
									name="addrZip"
									className="form-control mb-3 w-100"
									value={userInfo.addrZip || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											addrZip: e.target.value,
										})
									}
								/>
							</div>
							<div className="col-4">
								<label
									htmlFor="addrStreet"
									className="fontSize75Rem mb-2"
								>
									详细地址
								</label>
								<textarea
									type="text"
									id="addrStreet"
									name="addrStreet"
									className="form-control"
									value={userInfo.addrStreet || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											addrStreet: e.target.value,
										})
									}
								/>
							</div>
						</div>
					</div>

					<div className="infoWrapper bg-white p-4 rounded-4 mb-3">
						<div className="infoTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold">用户权益</h6>
						</div>
						<div className="infoBottom row mb-5">
							<div className="col-4">
								<label
									htmlFor="userLevelCode"
									className="fontSize75Rem mb-2"
								>
									用户等级
								</label>
								{/* "0"：一般用戶
								"1"：付費用戶(Premium或稱VIP用戶) */}
								<select
									className="form-control mb-3 "
									name="userLevelCode"
									id="userLevelCode"
									value={userInfo.userLevelCode || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											userLevelCode: e.target.value,
										})
									}
									required
								>
									<option value="0">"0"：一般用戶</option>
									<option value="1">
										"1"：付費用戶(Premium或稱VIP用戶)
									</option>
								</select>
								<label
									htmlFor="levelExpiration"
									className="fontSize75Rem mb-2"
								>
									用户等级到期日
								</label>
								<input
									type="datetime-local"
									id="levelExpiration"
									name="levelExpiration"
									className="form-control"
									value={userInfo.levelExpiration || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											levelExpiration: e.target.value,
										})
									}
									required
								/>
							</div>
							<div className="col-4">
								<label
									htmlFor="family"
									className="fontSize75Rem mb-2"
								>
									家族成员/管理员
								</label>
								<select
									className="form-control mb-3"
									name="family"
									id="family"
									value={userInfo.family || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											family: e.target.value,
										})
									}
									required
								>
									{/* "0"：否
									"1"：家族成員
									"2"：家族管理員 */}
									<option value="0">"0"：否</option>
									<option value="1">"1"：家族成員</option>
									<option value="2">"2"：家族管理員</option>
								</select>
								<label
									htmlFor="familyMax"
									className="fontSize75Rem mb-2"
								>
									家族人数上限
								</label>
								<input
									type="number"
									id="familyMax"
									name="familyMax"
									className="form-control"
									value={userInfo.familyMax || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											familyMax: e.target.value,
										})
									}
								/>
							</div>
							<div className="col-4">
								<label
									htmlFor="getOfficialMSG"
									className="fontSize75Rem mb-2"
								></label>
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										value={userInfo.getOfficialMSG}
										checked={
											userInfo.getOfficialMSG === '1' ||
											userInfo.getOfficialMSG === true
												? true
												: false
										}
										id="getOfficialMSG"
										name="getOfficialMSG"
										onChange={(e) =>
											setUserInfo({
												...userInfo,
												getOfficialMSG:
													!userInfo.getOfficialMSG,
											})
										}
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault"
									>
										同意收到官网活动讯息
									</label>
								</div>
							</div>
						</div>
					</div>

					<div className="infoWrapper bg-white p-4 rounded-4">
						<div className="infoTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold">系统资讯</h6>
						</div>
						<div className="infoBottom row mb-5">
							<div className="col-4">
								<label
									htmlFor="userDrillModifyTime"
									className="fontSize75Rem mb-2"
								>
									自编球谱修改时间
								</label>
								{/* "0"：一般用戶
								"1"：付費用戶(Premium或稱VIP用戶) */}
								<input
									type="datetime-local"
									id="userDrillModifyTime"
									name="userDrillModifyTime"
									className="form-control mb-3"
									value={userInfo.userDrillModifyTime || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											userDrillModifyTime: e.target.value,
										})
									}
									disabled
								/>
								<label
									htmlFor="lockoutEnd"
									className="fontSize75Rem mb-2"
								>
									帐号锁定的自动解锁时间
								</label>
								<input
									type="datetime-local"
									id="lockoutEnd"
									name="lockoutEnd"
									className="form-control mb-3"
									value={userInfo.lockoutEnd || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											lockoutEnd: e.target.value,
										})
									}
								/>
								<label
									htmlFor="accessFailedCount"
									className="fontSize75Rem mb-2"
								>
									登入失败的累积次数
								</label>
								<input
									type="number"
									id="accessFailedCount"
									name="accessFailedCount"
									className="form-control"
									value={userInfo.accessFailedCount || 0}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											accessFailedCount: e.target.value,
										})
									}
									required
								/>
							</div>
							<div className="col-4">
								<label
									htmlFor="createTime"
									className="fontSize75Rem mb-2"
								>
									建档时间
								</label>
								<input
									type="datetime-local"
									id="createTime"
									name="createTime"
									className="form-control mb-3"
									value={userInfo.createTime || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											createTime: e.target.value,
										})
									}
									required
									disabled
								/>
								<label
									htmlFor="lastLoginTime"
									className="fontSize75Rem mb-2"
								>
									最后登入时间
								</label>
								<input
									type="datetime-local"
									id="lastLoginTime"
									name="lastLoginTime"
									className="form-control mb-3"
									value={userInfo.lastLoginTime || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											lastLoginTime: e.target.value,
										})
									}
									required
									disabled
								/>
								<label
									htmlFor="lastLoginIP"
									className="fontSize75Rem mb-2"
								>
									最后登入IP地址
								</label>
								<input
									id="lastLoginIP"
									name="lastLoginIP"
									className="form-control"
									value={userInfo.lastLoginIP || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											lastLoginIP: e.target.value,
										})
									}
									disabled
								/>
							</div>
							<div className="col-4">
								<label
									htmlFor="securityStampEnd"
									className="fontSize75Rem mb-2"
								>
									安全标记期限
								</label>
								<input
									type="datetime-local"
									id="securityStampEnd"
									name="securityStampEnd"
									className="form-control mb-3"
									value={userInfo.securityStampEnd || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											securityStampEnd: e.target.value,
										})
									}
									disabled
								/>
								<label
									htmlFor="securityStamp"
									className="fontSize75Rem mb-2"
								>
									安全标记
								</label>
								<textarea
									type="text"
									id="securityStamp"
									name="securityStamp"
									className="form-control"
									value={userInfo.securityStamp || ''}
									onChange={(e) =>
										setUserInfo({
											...userInfo,
											securityStamp: e.target.value,
										})
									}
									disabled
								/>
							</div>
						</div>
					</div>
				</form>
			</section>
		</>
	);
};

export default UsersUpdateDetail;
