import './forgotPwd.scss';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { axiosApi } from '../../../api';

function ForgotPwd() {
	const navigate = useNavigate();

	const [page, setPage] = useState(1);

	const [accountType, setAccountType] = useState('0');
	const [email, setEmail] = useState('');
	const [countryCode, setCountryCode] = useState('86');
	const [phoneCode, setPhoneCode] = useState('');

	const [countdownBtnDisabled, setCountdownBtnDisabled] = useState();

	const [newPWD, setNewPWD] = useState('');
	const [confirmPWD, setConfirmPWD] = useState('');
	const [verificationCode, setVerificationCode] = useState('');

	const emailReg =
		/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
	const phoneCodeReg = /^[0-9]{6,15}$/;
	const PWDReg = /^[A-Za-z0-9]{6,20}$/;
	const verificationCodeReg = /^[0-9]{6}$/;

	// 切換登入帳號類型
	function accountChange(e) {
		e.preventDefault();

		if (accountType === '0') {
			setAccountType('1');
			setEmail('');
			document.querySelector('#email-info').textContent = '';
			document.querySelector('#email').style.borderColor = '#ced4da';
		} else if (accountType === '1') {
			setAccountType('0');
			setPhoneCode('');
			document.querySelector('#phoneCode-info').textContent = '';
			document.querySelector('#phoneCode').style.borderColor = '#ced4da';
		}
	}

	// 寄送驗證碼
	const sendVerificationCode = async () => {
		const emailPost = {
			accountType: '0',
			email: email,
			locale: navigator.language,
		};

		const phonePost = {
			accountType: '1',
			countryCode: countryCode,
			phoneCode: phoneCode,
			locale: navigator.language,
		};

		try {
			axiosApi(
				'/web/user/sendverificationcode',
				'post',
				accountType === '0' ? emailPost : phonePost
			)
				.then((res) => {
					//console.log(res.data);
					if (res.data.status === '0') {
						setPage(2);
						setVerificationCode('');
						setCountdownBtnDisabled(10);
					} else {
						document.querySelector(
							`${
								accountType === '0'
									? '#email-info'
									: '#phoneCode-info'
							}`
						).textContent = '账号不存在';

						document.querySelector(
							`${accountType === '0' ? '#email' : '#phoneCode'}`
						).style.borderColor = '#CC3300';
					}
				})
				.catch((err) => console.log(err));

			// const res = await axios.post(
			//     '/web/user/sendverificationcode',
			//     accountType === '0' ? emailPost : phonePost
			// );
			// //console.log(res.data);
			// if (res.data.status === '0') {
			//     setPage(2);
			//     setVerificationCode('');
			//     setCountdownBtnDisabled(10);
			// } else {
			//     document.querySelector(
			//         `${accountType === '0' ? '#email-info' : '#phoneCode-info'}`
			//     ).textContent = intl.formatMessage({
			//         id: 'auth.accountNoExist',
			//         defaultMessage: '账号不存在',
			//     });

			//     document.querySelector(
			//         `${accountType === '0' ? '#email' : '#phoneCode'}`
			//     ).style.borderColor = '#CC3300';
			// }
		} catch (error) {
			console.log(error);
		}
	};

	// 確認驗證碼
	const checkVerificationCode = async () => {
		const emailPost = {
			accountType: '0',
			email: email,
			verificationCode: verificationCode,
		};

		const phonePost = {
			accountType: '1',
			countryCode: countryCode,
			phoneCode: phoneCode,
			verificationCode: verificationCode,
		};

		try {
			axiosApi(
				'/web/user/checkVerificationCode',
				'post',
				accountType === '0' ? emailPost : phonePost
			)
				.then((res) => {
					if (res.data.status === '0') {
						setPage(3);
					} else if (res.data.status === '1') {
						document.querySelector(
							'#verificationCode-info'
						).textContent = '验证码错误';

						document.querySelector(
							'#verificationCode'
						).style.borderColor = '#CC3300';
					}
				})
				.catch((err) => console.log(err));
			// await axios
			//     .post(
			//         '/web/user/checkVerificationCode',
			//         accountType === '0' ? emailPost : phonePost
			//     )
			//     .then((res) => {
			//         if (res.data.status === '0') {
			//             setPage(3);
			//         } else if (res.data.status === '1') {
			//             document.querySelector(
			//                 '#verificationCode-info'
			//             ).textContent = intl.formatMessage({
			//                 id: 'startup.verificationCodeIncorrect',
			//                 defaultMessage: '验证码错误',
			//             });

			//             document.querySelector(
			//                 '#verificationCode'
			//             ).style.borderColor = '#CC3300';
			//         }
			//     });
		} catch (error) {
			console.log(error);
		}
	};

	// 重設密碼 sweetAlert
	let timerInterval;

	const successAlert = () => {
		Swal.fire({
			title: '更新成功',

			html: '<b></b>' + ' 秒后自动跳至登入画面',

			icon: 'success',
			timer: 5300,
			timerProgressBar: true,
			didOpen: () => {
				Swal.showLoading();
				const b = Swal.getHtmlContainer().querySelector('b');
				timerInterval = setInterval(() => {
					b.textContent = parseInt(Swal.getTimerLeft() / 1000);
				}, 100);
			},
			willClose: () => {
				clearInterval(timerInterval);
			},
		}).then((result) => {
			navigate('/login');

			if (result.dismiss === Swal.DismissReason.timer) {
				// console.log('I was closed by the timer');
				navigate('/login');
			}
		});
	};

	const errorAlert = () => {
		Swal.fire({
			title: '設定新密碼失敗',
			text: '請您再次確認',
			icon: 'error',
		});
	};

	//// 獲取網址列資訊
	// let location = useLocation();
	// //console.log("location", location.search);

	// let queryData = qs.parse(location.search, { ignoreQueryPrefix: true });
	// //console.log(queryData);

	// useEffect(() => {
	// 	setUserGuid(queryData.ug);
	// 	setSecurityStamp(queryData.ss);
	// }, [queryData.ug, queryData.ss]);
	// //console.log(userGuid);
	// //console.log(securityStamp);

	function pageDataConfirm(confirm) {
		switch (confirm) {
			case 1: // page1:檢查帳號並發送驗證碼
				// 前端檢查帳號格式
				// email
				if (accountType === '0') {
					if (!emailReg.test(email)) {
						document.querySelector('#email-info').textContent =
							'格式错误 请重新输入';
						document.querySelector('#email').style.borderColor =
							'#CC3300';
					} else {
						document.querySelector('#email-info').textContent = '';
						sendVerificationCode();

						document.querySelector('#email').style.borderColor =
							'#ced4da';
					}
					// phone
				} else if (accountType === '1') {
					if (!phoneCodeReg.test(phoneCode)) {
						document.querySelector('#phoneCode-info').textContent =
							'格式错误 请重新输入';

						document.querySelector('#phoneCode').style.borderColor =
							'#CC3300';
					} else {
						document.querySelector('#phoneCode-info').textContent =
							'';
						document.querySelector('#phoneCode').style.borderColor =
							'#ced4da';
						sendVerificationCode();
					}
				}
				break;
			case 2: // page2:確認驗證碼
				if (!verificationCodeReg.test(verificationCode)) {
					document.querySelector(
						'#verificationCode-info'
					).textContent = '格式错误 请重新输入';
					document.querySelector(
						'#verificationCode'
					).style.borderColor = '#CC3300';
				} else {
					document.querySelector(
						'#verificationCode-info'
					).textContent = '';
					document.querySelector(
						'#verificationCode'
					).style.borderColor = '#ced4da';
					checkVerificationCode();
				}

				break;
			case 3: // page3:設定新密碼
				// 重設使用者密碼，表單驗證提交
				async function handleSubmit() {
					let newPWDValidated = false;
					let confirmPWDValidated = false;

					try {
						//驗證欄位 顯示錯誤訊息
						const emailResetPasswordData = {
							accountType: accountType,
							email: email,
							verificationCode: verificationCode,
							password: newPWD,
						};

						const phoneResetPasswordData = {
							accountType: accountType,
							countryCode: countryCode,
							phoneCode: phoneCode,
							verificationCode: verificationCode,
							password: newPWD,
						};

						if (!PWDReg.test(newPWD)) {
							document.querySelector('#newPWD-info').textContent =
								'格式错误 请重新输入';
							document.querySelector(
								'#newPWD'
							).style.borderColor = '#CC3300';
						} else {
							document.querySelector('#newPWD-info').textContent =
								'';
							document.querySelector(
								'#newPWD'
							).style.borderColor = '#ced4da';
							newPWDValidated = true;
						}

						if (!PWDReg.test(confirmPWD)) {
							document.querySelector(
								'#confirmPWD-info'
							).textContent = '格式错误 请重新输入';
							document.querySelector(
								'#confirmPWD'
							).style.borderColor = '#CC3300';
						} else {
							document.querySelector(
								'#confirmPWD-info'
							).textContent = '';
							confirmPWDValidated = true;
							document.querySelector(
								'#confirmPWD'
							).style.borderColor = '#ced4da';
						}

						if (newPWD !== confirmPWD) {
							document.querySelector(
								'#confirmPWD-info'
							).textContent = '新密码 与 确认新密码 必须相同';
							document.querySelector(
								'#confirmPWD'
							).style.borderColor = '#CC3300';
						} else if (newPWDValidated && confirmPWDValidated) {
							document.querySelector(
								'#confirmPWD-info'
							).textContent = '';
							document.querySelector(
								'#confirmPWD'
							).style.borderColor = '#ced4da';

							axiosApi(
								`/web/user/resetpassword`,
								'put',
								accountType === '0'
									? emailResetPasswordData
									: phoneResetPasswordData
							)
								.then((res) => {
									if (res.data.status === '0') {
										successAlert();
									}
								})
								.catch((err) => {
									console.log(err);
								});
							// const res = await axios.put(
							//     `/web/user/resetpassword`,
							//     accountType === '0'
							//         ? emailResetPasswordData
							//         : phoneResetPasswordData
							// );

							// if (res.data.status === '0') {
							//     successAlert();
							// }
						}
					} catch (error) {
						console.log(error);
						errorAlert();
					}
				}

				handleSubmit();
				break;
			default:
				break;
		}
	}

	//倒數計時
	const [secondsBackToLogin, setSecondsBackToLogin] = useState();
	useEffect(() => {
		const interval = setInterval(() => {
			if (secondsBackToLogin >= 0) {
				setSecondsBackToLogin(
					(secondsBackToLogin) => secondsBackToLogin - 1
				);
			}
		}, 1000);

		if (secondsBackToLogin === 0) {
			//window.location.replace('/login');
			navigate('/login');
		}

		return () => clearInterval(interval);
	}, [secondsBackToLogin, navigate]);
	//console.log(secondsBackToLogin);

	//驗證碼重新发送 限制點擊
	// console.log('a', countdownBtnDisabled);
	useEffect(() => {
		// 解決useEffect慢一秒的問題
		document.querySelector('#resendVerifyBtn').textContent =
			countdownBtnDisabled;
		document.querySelector('#resendVerifyBtn').setAttribute('disabled', '');

		const interval = setInterval(() => {
			if (countdownBtnDisabled > 0) {
				document
					.querySelector('#resendVerifyBtn')
					.setAttribute('disabled', '');
				document.querySelector('#resendVerifyBtn').textContent =
					countdownBtnDisabled;
				// console.log('b', countdownBtnDisabled);

				setCountdownBtnDisabled(
					(countdownBtnDisabled) => countdownBtnDisabled - 1
				);
			} else if (countdownBtnDisabled === 0) {
				document
					.querySelector('#resendVerifyBtn')
					.removeAttribute('disabled');
				document.querySelector('#resendVerifyBtn').textContent =
					'重新获取';
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [countdownBtnDisabled]);

	return (
		<div className="container-fluid p-0">
			<article
				id="container-forgotPwd"
				className="py-lg-5 d-flex flex-column align-items-center justify-content-lg-center bg-background "
			>
				<h2 className="pageTitle-mobile d-block d-lg-none">
					{page === 3 ? '設定密碼' : '忘记密码'}
				</h2>
				<div className="articleBox d-flex flex-column justify-content-start">
					<h2 className="pageTitle d-none d-lg-block">
						{page === 3 ? '設定密碼' : '忘记密码'}
					</h2>

					<section
						className={`row g-3 ${page !== 1 && 'd-none'}`}
						id="forgotPwdPage_1"
					>
						<div className="col-12 contentBox">
							<div className="row g-3">
								{/* email */}
								<div
									className={`col-12 ${
										accountType === '1' && 'd-none'
									}`}
								>
									{/* <div className="custom-control custom-radio">
											<input
												type="radio"
												id="customRadio1"
												name="accountType"
												className="custom-control-input"
												value="0"
											/>
											<label
												className="custom-control-label"
												htmlFor="customRadio1"
											>
												<FormattedMessage
													id="user.email"
													defaultMessage="电子邮箱"
												/>
											</label>
										</div> */}
									{/* <label className="form-label" htmlFor="email">
										<FormattedMessage
											id="user.email"
											defaultMessage="电子邮箱"
										/>
									</label> */}

									<input
										type="email"
										className="form-control form-control-lg"
										id="email"
										autoComplete="off"
										placeholder="请输入邮箱"
										required
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
									/>
									<div
										className="invalid-feedback"
										id="email-info"
									></div>
								</div>
								{/* 電話 */}
								<div
									className={`col-12 ${
										accountType === '0' && 'd-none'
									}`}
								>
									{/* <div className="custom-control custom-radio">
											<input
												type="radio"
												id="customRadio2"
												name="accountType"
												className="custom-control-input"
												value="1"
											/>
											<label
												className="custom-control-label"
												htmlFor="customRadio2"
											>
												<FormattedMessage
													id="auth.phoneNumber"
													defaultMessage="手机号"
												/>
											</label>
										</div> */}
									{/* <label
										className="form-label"
										htmlFor="phoneCode"
									>
										<FormattedMessage
											id="auth.phoneNumber"
											defaultMessage="手机号"
										/>
									</label> */}
									<div className="row">
										<div className="col-4 col-lg-4">
											<select
												className="form-select form-select-lg"
												id="countryCode"
												value={countryCode}
												onChange={(e) =>
													setCountryCode(
														e.target.value
													)
												}
											>
												<option value="86">+86</option>
												<option value="886">
													+886
												</option>
												<option value="852">
													+852
												</option>
											</select>
										</div>
										<div className="col-8 col-lg-8">
											<input
												type="text"
												className="form-control form-control-lg"
												id="phoneCode"
												placeholder="手机号码"
												required
												value={phoneCode}
												onChange={(e) =>
													setPhoneCode(e.target.value)
												}
											/>
											<div
												className="invalid-feedback"
												id="phoneCode-info"
											></div>
										</div>
									</div>
								</div>
								{/* 提示文字 */}
								<div className="promptMessageBox col-12">
									<p>
										你正在为账户重置登录密码，请输入你需要找回登录密码的邮箱。
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 mb-3">
							<div className="confirmBtnBox">
								<button
									className="confirmBtn btn btn-size-l btn-color-main rounded-pill"
									onClick={() => pageDataConfirm(1)}
								>
									确定
								</button>

								{/*切換登入 / 註冊*/}
								<div className="changeBox text-start">
									<Link
										to=""
										className={`text-decoration-none ${
											accountType === '1' && 'd-none'
										}`}
										onClick={accountChange}
									>
										手机号获取验证码
									</Link>
									<Link
										to=""
										className={`text-decoration-none ${
											accountType === '0' && 'd-none'
										}`}
										onClick={accountChange}
									>
										电子邮箱获取验证码
									</Link>
								</div>
							</div>
						</div>
					</section>
					<section
						className={`row g-3 ${page !== 2 && 'd-none'}`}
						id="forgotPwdPage_2"
					>
						<div className="col-12 contentBox">
							<div className="row g-3">
								{/*<!-- 帳號驗證碼 -->*/}
								<div className="col-12">
									{/* <label htmlFor="verificationCode">
										<FormattedMessage
											id="auth.verificationCode"
											defaultMessage="验证码"
										/>
									</label> */}
									<div className="row">
										<div className="col-8">
											<input
												className="form-control form-control-lg"
												type="text"
												id="verificationCode"
												name="verificationCode"
												placeholder="请输入验证码"
												value={verificationCode}
												onChange={(e) =>
													setVerificationCode(
														e.target.value
													)
												}
											/>
										</div>
										<div className="col-4">
											<button
												type="button"
												className="btn btn-color-auxiliary"
												id="resendVerifyBtn"
												onClick={sendVerificationCode}
											>
												重新获取
											</button>
										</div>
									</div>
									<div
										className="invalid-feedback"
										id="verificationCode-info"
									></div>
								</div>
								<div id="col-12 promptMessageBox">
									<p>
										验证码已发送到您的邮箱，有效时间为10分钟，请耐心等候。
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 text-center">
							<button
								className="btn btn-size-l btn-color-main rounded-pill"
								onClick={() => pageDataConfirm(2)}
							>
								确定
							</button>
						</div>
					</section>
					<section
						className={`row g-3 ${page !== 3 && 'd-none'}`}
						id="forgotPwdPage_3"
					>
						<div className="col-12 contentBox">
							<div className="row g-3">
								{/*<!-- 新密碼 -->*/}
								<div className="col-12">
									<input
										type="password"
										className="form-control form-control-lg"
										id="newPWD"
										name="newPWD"
										placeholder="请输入新密码"
										pattern="[a-zA-Z0-9]{6,16}"
										required
										autoComplete="off"
										onChange={(e) =>
											setNewPWD(e.target.value)
										}
									/>
									<p className="m-1 text-grey3">
										输入6-16位密码，英文区分大小写
									</p>
									<div
										className="invalid-feedback"
										id="newPWD-info"
									></div>
								</div>
								{/*<!-- 确认新密码 -->*/}
								<div className="col-12">
									<input
										type="password"
										className="form-control form-control-lg"
										id="confirmPWD"
										name="confirmPWD"
										placeholder="请再输入一次密码"
										pattern="[a-zA-Z0-9]{6,16}"
										required
										autoComplete="off"
										onChange={(e) =>
											setConfirmPWD(e.target.value)
										}
									/>
									<div
										className="invalid-feedback"
										id="confirmPWD-info"
									></div>
								</div>
							</div>
						</div>
						<div className="col-12 text-center">
							<button
								className="btn btn-size-l btn-color-main rounded-pill"
								onClick={() => pageDataConfirm(3)}
							>
								确定
							</button>
						</div>
					</section>
				</div>
			</article>
		</div>
	);
}

export default ForgotPwd;
