import './baseInfo.scss';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { AccountContext } from '../../../App';
import { axiosApi } from '../../../api';

/// 个人资料
/// Author : YL
function BaseInfo() {
	// 取得基本資料
	// const emailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
	// const [data, setData] = useState({
	//     addrCity: '',
	//     addrCountry: '',
	//     addrProvince: '',
	//     addrStreet: '',
	//     addrZip: '',
	//     birthday: '',
	//     countryList: '',
	//     email1: '',
	//     firstLearningYear: '',
	//     firstname: '',
	//     fullname: '',
	//     gender: '',
	//     lastname: '',
	//     phone1: '',
	//     phone1CountryCode: '',
	//     photoLink: '',
	//     provinceList: '',
	//     provincialCityList: '',
	//     status: '',
	//     userID: '',
	// });
	const [userNickName, setUserNickName] = useState('');
	const [userUserId, setUserUserId] = useState('');
	const [userFamilyName, setUserFamilyName] = useState('');
	const [userFirstName, setUserFirstName] = useState('');
	const [userGender, setUserGender] = useState('');
	const [userBirthday, setUserBirthday] = useState('');
	const [userFirstLearningYear, setUserFirstLearningYear] = useState('');
	const [userPhone1CountryCode, setUserPhone1CountryCode] = useState('');
	const [userPhone1, setUserPhone1] = useState('');
	const [userEmail1, setUserEmail1] = useState('');
	const [userAddrCountryCode, setUserAddrCountryCode] = useState('');
	const [userAddrCountry, setUserAddrCountry] = useState('');
	const [userAddrProvince, setUserAddrProvince] = useState('');
	const [userAddrCity, setUserAddrCity] = useState('');
	const [userAddrStreet, setUserAddrStreet] = useState('');
	const [userAddrZip, setUserAddrZip] = useState('');
	const [avatarImg, setAvatarImg] = useState('./images/user-default.png');
	const [avatarData, setAvatarData] = useState('');
	const refAccountType = useRef('');
	const refEmailValid = useRef(false);
	const isUserIdValidRef = useRef(false);

	const [countryList, setCountryList] = useState(null);
	const [provinceList, setProvinceList] = useState(null);
	const [provincialCityList, setProvincialCityList] = useState(null);
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies(['loginUser']);

	const [seconds, setSeconds] = useState('Click me plz');
	const [rerun, setRerun] = useState(false);

	// Modal初始化設定
	const [modalShow, setModalShow] = useState(false);
	const [modalType, setModalType] = useState('initial');
	const [modalTitle, setModalTitle] = useState('');
	const [modalId, setModalId] = useState('');

	const [newEmail, setNewEmail] = useState('');
	const [newCountryCode, setNewCountryCode] = useState('');
	const [newPhone, setNewPhone] = useState('');
	const [veriCode, setVeriCode] = useState('');

	const accountContext = useContext(AccountContext);
	const { accountInfo, getAccountInfo, homePageIndex, setHomePageIndex } =
		accountContext;

	// before update
	const switchFirstLearningYear = (data, year) => {
		let learningYear;
		if (year) {
			const nowYear = new Date().getYear();
			if (year < 100) {
				learningYear = nowYear - year + 1900;
				data.firstLearningYear = learningYear;
			} else if (year > 1000) {
				learningYear = nowYear + 1900 - year;
				return learningYear;
			}
		}
	};

	// userId unique check
	const checkUserId = () => {
		const userIdReg = /^[A-Za-z0-9]{6,16}$/;
		const API_URL = '/web/user/checkuseraccountunique';
		let uniqueCheckObj = {
			accountType: refAccountType.current,
			userID: userUserId,
		};
		if (!(refAccountType.current && refAccountType.current === '2')) return;
		// console.log(refAccountType.current, refAccountType.current);
		if (!userIdReg.test(userUserId)) {
			if (userUserId === '') {
				document.getElementById('userID-info').textContent = '';
			} else {
				document.getElementById('userID-info').textContent =
					'ID为6~16字的英文数字组合';
			}
		} else {
			axios.post(API_URL, uniqueCheckObj).then((res) => {
				// console.log(res.data);
				if (res.data.status === '1') {
					document.getElementById('userID-info').textContent =
						'此使用者 ID 已被使用';
				} else {
					document.getElementById('userID-info').textContent = '';
					isUserIdValidRef.current = true;
				}
			});
		}
		if (document.getElementById('userID-info').textContent === '') {
			isUserIdValidRef.current = true;
		}
	};

	const checkBirthday = () => {
		const today = new Date();
		const userBday = new Date(userBirthday);
		if (userBday > today) {
			document.getElementById('birthday-info').textContent = '生日错误';
			return false;
		}
		document.getElementById('birthday-info').textContent = '';
		return true;
	};

	const checkFullName = (firstname, lastname) => {
		if (!firstname && !lastname) {
			document.getElementById('lastname-info').textContent =
				'请输入您的显示名称';
			return false;
		} else {
			document.getElementById('lastname-info').textContent = '';
			return true;
		}
	};

	const invalidInputAlert = () => {
		Swal.fire({
			title: '基本资料存盘失败',
			text: '请输入正确基本数据',
			icon: 'warning',
		});
	};

	// axios update user data
	const axiosUpdateUserData = async (newUserData) => {
		// console.log('newUserData', newUserData);
		// console.log(newUserData['firstLearningYear']);
		// newUserData['firstLearningYear'] =
		// 	newUserData['firstLearningYear'].toString();
		try {
			const url = `/web/user`;
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				contentType: false, //required
				processData: false, // required
				mimeType: 'multipart/form-data',
			};
			axiosApi(url, 'put', newUserData, config).then((res) => {
				if (res.data.status === '1') {
					errorAlert();
				} else if (res.data.status === '0') {
					// console.log('newUserData', newUserData);
					updateUserInfoSuccessAlert();

					// 更新navmenu使用者名稱
					accountContext.getAccountInfo();
					//window.location.reload();
				}
			});
		} catch (error) {
			console.log(error);
		}
		// const url = `/web/user`;
		// const res = await axios.put(url, newUserData, config);
	};

	const errorAlert = () => {
		Swal.fire({
			title: '更新失敗',
			text: '未能成功更新会员基本数据',
			icon: 'error',
		});
	};

	const updateUserInfoSuccessAlert = () => {
		Swal.fire({
			title: '更新成功',
			text: '成功更新会员基本数据',
			icon: 'success',
		});
	};

	function clsx(...str) {
		return str.filter(Boolean).join(' ');
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (!refAccountType.current) {
			isUserIdValidRef.current = true;
		} else {
			checkUserId();
		}
		if (!userNickName) {
			document.getElementById('userNickname-info').textContent =
				'請輸入你的顯示名稱';
		} else document.getElementById('userNickname-info').textContent = '';
		// console.log(isUserIdValidRef.current);
		if (!userNickName || isUserIdValidRef.current === false) return;
		//透過FormData拿值，並轉為Object
		const form = new FormData(e.target);
		// console.log('form', form);
		const data = Object.fromEntries(form.entries());

		// 每一列的 KEY 和 VALUE
		for (const [key, value] of form.entries()) {
			data[key] = value;
			// if (!value) {
			// 	delete data[key];
			// }
		}
		data['photo'] = avatarData;
		// console.log('data', data);
		switchFirstLearningYear(data, data.firstLearningYear);
		if (!checkBirthday() || !checkFullName(data.firstName, data.lastname)) {
			return invalidInputAlert();
		}

		const file = document.querySelector('#file').files[0];
		if (file) {
			const fileName = file.name.split('.');
			const photoType = fileName[fileName.length - 1].toUpperCase();
			// console.log(photoType);
			if (
				photoType !== 'JPEG' &&
				photoType !== 'PNG' &&
				photoType !== 'JPG'
			) {
				document.getElementById('avatar-info').innerText =
					'請上傳 JPEG, JPG 或 PNG 格式之档案';
				return invalidInputAlert();
			}
		}

		const formData = new FormData();
		Object.keys(data).forEach((key) => {
			// console.log(key, data[key]);
			if (!data[key]) data[key] = '';
			formData.append(key, data[key]);
		});
		let locale = navigator.language;
		switch (locale) {
			case 'zh-TW': {
				locale = 'ZF';
				break;
			}
			case 'en': {
				locale = 'EN';
				break;
			}
			default: {
				locale = 'ZH';
				break;
			}
		}
		formData.append('languageCode', locale);

		axiosUpdateUserData(formData);
	}

	function showModifyModal(type, id) {
		setModalType(type);
		setModalTitle(type);
		setModalId(id);
		setModalShow(true);

		// console.log(modalType);
	}

	// 修改email帳號成功，登出倒數視窗
	let timerInterval;
	const verifyEmailSendSuccess = () => {
		const swalFireSuccess = () => {
			Swal.fire({
				title: '验证信件已寄出',
				html: `<h4>请到您新的email信箱收取验证信<br/>以启用新账号。<br/><br/>您将在 <min></min> 分 <sec></sec> 秒后自动注销<br/>并回到登入画面</h4><br/><br/>`,
				showCancelButton: true,
				cancelButtonText: '取消更新',
				icon: 'success',
				timer: 300500,
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				timerProgressBar: true,
				didOpen: () => {
					const sec = Swal.getHtmlContainer().querySelector('sec');
					const min = Swal.getHtmlContainer().querySelector('min');
					timerInterval = setInterval(() => {
						sec.textContent = parseInt(
							(Swal.getTimerLeft() / 1000) % 60
						);
						min.textContent = parseInt(
							Swal.getTimerLeft() / 1000 / 60
						);
					}, 100);
				},
				willClose: () => {
					clearInterval(timerInterval);
				},
			}).then((result) => {
				if (result.dismiss === Swal.DismissReason.timer) {
					setCookie(
						'loginUser',
						{
							status: '1',
						},
						{
							path: '/',
						}
					);
					navigate('/login');
				} else if (result.dismiss === 'cancel') {
					clearInterval(timerInterval);
					cancelAlert();
					// setCookie(
					// 	'loginUser',
					// 	{
					// 		status: '1',
					// 	},
					// 	{
					// 		path: '/',
					// 	}
					// );
					// navigate('/login');
				}
			});
		};
		let timeInterval2;
		const updateAccountWaiting = () => {
			const API_URL = `/web/user/getupdateaccountstatus`;
			axios.get(API_URL).then((res) => {
				if (res.data.status === '0') {
					successAlert();
					clearInterval(timeInterval2);
				}
			});
		};

		const successAlert = () => {
			Swal.fire({
				title: '设置成功 ，请您重新登录',
				html: '<b></b>' + ' 秒后自动跳至登入画面',
				icon: 'success',
				timer: 530000,
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading();
					const b = Swal.getHtmlContainer().querySelector('b');
					timerInterval = setInterval(() => {
						b.textContent = parseInt(Swal.getTimerLeft() / 1000);
					}, 100);
				},
				willClose: () => {
					clearInterval(timerInterval);
				},
			}).then((result) => {
				setCookie(
					'loginUser',
					{
						status: '1',
					},
					{
						path: '/',
					}
				);
				navigate('/login');

				if (result.dismiss === Swal.DismissReason.timer) {
					setCookie(
						'loginUser',
						{
							status: '1',
						},
						{
							path: '/',
						}
					);
					// console.log(cookies);
					navigate('/login');
				}
			});
		};

		const cancelAlert = () => {
			Swal.fire({
				title: '已取消更新email账号，请您重新登入',
				html: '<b></b>' + ' 秒后自动跳至登入画面',
				icon: 'info',
				timer: 5300,
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading();
					const b = Swal.getHtmlContainer().querySelector('b');
					timerInterval = setInterval(() => {
						b.textContent = parseInt(Swal.getTimerLeft() / 1000);
					}, 100);
				},
				willClose: () => {
					clearInterval(timerInterval);
				},
			}).then((result) => {
				setCookie(
					'loginUser',
					{
						status: '1',
					},
					{
						path: '/',
					}
				);
				navigate('/login');

				if (result.dismiss === Swal.DismissReason.timer) {
					setCookie(
						'loginUser',
						{
							status: '1',
						},
						{
							path: '/',
						}
					);
					// console.log(cookies);
					navigate('/login');
				}
			});
		};

		if (refEmailValid.current === true) {
			setModalShow(false);
			const API_URL = '/web/user/sendverificationcode';
			// const updatePost = {
			// 	userGuid: guid,
			// 	accountType: refAccountType.current,
			// 	email: userEmailChangingInput,
			// 	countryCode: newCountryCode,
			// 	phoneCode: newPhone,
			// };
			// console.log(navigator.language);
			axios
				.post(API_URL, {})
				.then((res) => {
					swalFireSuccess();
					timeInterval2 = window.setInterval(() => {
						updateAccountWaiting();
					}, 5300);
				})
				.catch((err) => console.log(err));
		}
	};

	const updateSuccessAlert = () => {
		Swal.fire({
			title: '设置成功 ，请您重新登录',
			html: '<b></b>' + ' 秒后自动跳至登入画面',
			icon: 'success',
			timer: 5300,
			timerProgressBar: true,
			didOpen: () => {
				Swal.showLoading();
				const b = Swal.getHtmlContainer().querySelector('b');
				timerInterval = setInterval(() => {
					b.textContent = parseInt(Swal.getTimerLeft() / 1000);
				}, 100);
			},
			willClose: () => {
				clearInterval(timerInterval);
			},
		}).then((result) => {
			setCookie(
				'loginUser',
				{
					status: '1',
				},
				{
					path: '/',
				}
			);
			navigate('/login');

			if (result.dismiss === Swal.DismissReason.timer) {
				setCookie(
					'loginUser',
					{
						status: '1',
					},
					{
						path: '/',
					}
				);
				// console.log(cookies);
				navigate('/login');
			}
		});
	};

	// checking new phone info when updating phone account
	const checkNewPhone = (accountType, countryCode, phoneCode) => {
		// console.log('accountType', accountType);
		// console.log('countryCode', countryCode);
		// console.log('phoneCode', phoneCode);
		const phoneCodeReg = /^\d{0,20}$/;
		const API_URL = '/web/user/sendverificationcode';
		let uniqueCheckObj = {
			accountType,
			countryCode,
			phoneCode,
			locale: navigator.language,
		};
		// console.log(uniqueCheckObj);
		// Phone check
		if (refAccountType.current === '1') {
			if (!countryCode || !phoneCode) {
				return (document.getElementById('phone1-info').textContent =
					'请输入 20 位内数字之手机号');
			}

			if (countryCode && phoneCode) {
				if (!phoneCodeReg.test(phoneCode)) {
					document.getElementById('phone1-info').textContent =
						'请输入 20 位内数字之手机号';
				} else {
					axios.post(API_URL, uniqueCheckObj).then((res) => {
						if (res.data.status === '3') {
							document.getElementById('phone1-info').textContent =
								'不能使用原手機号';
						} else if (res.data.status === '2') {
							document.getElementById('phone1-info').textContent =
								'此手機号已被使用';
						} else {
							document.getElementById('phone1-info').textContent =
								'';
							setModalType('verification');
						}
					});
				}
			}
		}
	};

	// checking new email when updating email account
	const checkNewEmail = (accountType, email) => {
		// console.log('accountType', accountType);
		// console.log('email', email);
		const emailReg =
			/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
		const API_URL = '/web/user/sendverificationcode';
		let uniqueCheckObj = {
			accountType,
			email,
			locale: navigator.language,
		};
		// console.log(uniqueCheckObj);
		if (!email) {
			document.getElementById('email-info').textContent =
				'请输入电子邮箱';
		} else if (!emailReg.test(email)) {
			document.getElementById('email-info').textContent =
				'格式错误 请重新输入';
		} else if (email.length >= 40) {
			document.getElementById('email-info').textContent =
				'不得超過 40 個字';
			return;
		} else {
			axios.post(API_URL, uniqueCheckObj).then((res) => {
				// console.log(res.data);
				if (res.data.status === '3') {
					document.getElementById('email-info').textContent =
						'不能使用原电子邮箱';
				} else if (res.data.status === '2') {
					document.getElementById('email-info').textContent =
						'此电子邮箱已被使用';
				} else {
					document.getElementById('email-info').textContent = '';
					setModalType('verification');
				}
			});
		}
	};

	const handleResendVeriCode = () => {
		const obj = {
			accountType: refAccountType.current,
			countryCode: newCountryCode,
			phoneCode: newPhone,
			locale: navigator.language,
		};
		if (newEmail) obj.email = newEmail;

		axios.post('/web/user/resendverificationcode', obj).then((res) => {
			// console.log(res.data);
		});
	};

	const handleUpdateAccount = (veriCode) => {
		const obj = {
			accountType: refAccountType.current,
			countryCode: newCountryCode,
			phoneCode: newPhone,
			verificationCode: veriCode,
		};
		if (newEmail) obj.email = newEmail;
		if (!veriCode)
			return (document.querySelector('#code-info').innerText =
				'請輸入验证码');
		axios.put(`/web/user/account`, obj).then((res) => {
			// console.log(res);
			if (res.data.status === '1') {
				document.querySelector('#code-info').innerText = '验证码錯誤';
			} else if (res.data.status === '0') {
				updateSuccessAlert();
			}
		});
	};

	// Country
	const getCountryList = (userData) => {
		let locale = navigator.language;
		switch (locale) {
			case 'zh-TW': {
				locale = 'ZF';
				break;
			}
			case 'en': {
				locale = 'EN';
				break;
			}
			default: {
				locale = 'ZH';
				break;
			}
		}
		// console.log(locale);

		axios(`/web/sys/country/?languageCode=${locale}`).then((data) => {
			setCountryList(data.data.countryList);
		});
	};

	// Province
	let callAPItimes = 0;
	const [inputDisabled, setInputDisabled] = useState(false);
	const getProvinceList = async (input) => {
		let locale = navigator.language;
		switch (locale) {
			case 'zh-TW': {
				locale = 'ZF';
				break;
			}
			case 'en': {
				locale = 'EN';
				break;
			}
			default: {
				locale = 'ZH';
				break;
			}
		}

		const fetchData = async () => {
			try {
				const response = await axios.get(
					`/web/sys/provinceList/?countryCode=${input}`
				);
				const data = response.data;
				setProvinceList(data.provinceList);
				setInputDisabled(false);
			} catch (error) {
				console.log(error);
				// 如果是由于 JWT 错误导致的错误，等待两秒后重新执行请求
				if (
					error.response &&
					error.response.status === 550 &&
					callAPItimes < 2
				) {
					callAPItimes++;
					console.log(callAPItimes);
					setInputDisabled(true); //鎖定確定按鈕
					await new Promise((resolve) => setTimeout(resolve, 2000)); // 等待两秒
					await fetchData(); // 重新执行请求
				}
			}
		};

		await fetchData();
	};

	// City
	const getCityList = (input) => {
		let locale = navigator.language;
		switch (locale) {
			case 'zh-TW': {
				locale = 'ZF';
				break;
			}
			case 'en': {
				locale = 'EN';
				break;
			}
			default: {
				locale = 'ZH';
				break;
			}
		}

		// console.log(userAddrProvince);

		axios(
			`/web/sys/provincialCityList?countryCode=${userAddrCountry}&addrProvince=${input}`
		).then((data) => {
			// City
			setProvincialCityList(data.data.provincialCityList);
		});
	};

	const handleAvatar = (file) => {
		if (file) {
			// console.log(file);
			const blobURL = URL.createObjectURL(file);
			setAvatarImg(blobURL);
		}
	};

	const handleFileUpload = (e) => {
		const dataUrl = e.target.value;
		if (!dataUrl) return;
		const fileReader = new FileReader();
		const file = e.target.files.item(0);
		const fileType = file.type.split('/')[1].toLowerCase();

		const fileLoad = (e) => {
			// console.log(e.target.result);
			const imgSize = e.target.result.length * 0.75 - 2;
			if (imgSize > 2097152) {
				alert('档案大小 : 最大 2 MB');
			} else if (
				fileType !== 'jpg' &&
				fileType !== 'png' &&
				fileType !== 'jpeg'
			) {
				alert('档案限制 : JPG, PNG');
			} else {
				setAvatarData(file);
				handleAvatar(file);
			}
		};

		fileReader.addEventListener('load', fileLoad);
		fileReader.readAsDataURL(file);
	};

	// Phone unique info check
	useEffect(() => {
		const phoneCodeReg = /^\d{0,20}$/;

		if (!refAccountType.current) return;
		const API_URL = '/web/user/checkuseraccountunique';
		let uniqueCheckObj = {
			accountType: refAccountType.current,
			phoneCode: userPhone1,
			countryCode: userPhone1CountryCode,
		};

		// Phone check
		if (refAccountType.current === '1') {
			if (userPhone1 && userPhone1CountryCode) {
				if (!phoneCodeReg.test(userPhone1)) {
					document.getElementById('phone1-info').textContent =
						'请输入 20 位内数字之手机号';
				} else {
					axios.post(API_URL, uniqueCheckObj).then((res) => {
						if (res.data.status === '1') {
							document.getElementById('phone1-info').textContent =
								'此手機号已被使用';
						} else {
							document.getElementById('phone1-info').textContent =
								'';
						}
					});
				}
			}
		}
	}, [userPhone1, userPhone1CountryCode]);

	useEffect(() => {
		if (seconds > 0 && rerun) {
			setTimeout(() => setSeconds(seconds - 1), 1000);
		} else {
			setRerun(false);
			setSeconds('重新获取');
		}
	}, [rerun, seconds]);

	useEffect(() => {
		let locale = navigator.language;
		switch (locale) {
			case 'zh-TW': {
				locale = 'ZF';
				break;
			}
			case 'en': {
				locale = 'EN';
				break;
			}
			default: {
				locale = 'ZH';
				break;
			}
		}

		setAvatarImg(accountInfo.photoLink);
		setUserNickName(accountInfo.fullname);
		setUserUserId(accountInfo.userID);
		setUserFamilyName(accountInfo.lastname);
		setUserFirstName(accountInfo.firstname);
		setUserGender(accountInfo.gender);
		setUserBirthday(accountInfo.birthday);
		setUserFirstLearningYear(
			switchFirstLearningYear(accountInfo, accountInfo.firstLearningYear)
		);
		setUserPhone1CountryCode(accountInfo.phone1CountryCode);
		setUserPhone1(accountInfo.phone1);
		setUserEmail1(accountInfo.email1);
		setUserAddrCountryCode(accountInfo.addrCountryCode);
		setUserAddrCountry(accountInfo.addrCountry);
		setUserAddrProvince(accountInfo.addrProvince);
		setUserAddrCity(accountInfo.addrCity);
		setUserAddrStreet(accountInfo.addrStreet);
		setUserAddrZip(accountInfo.addrZip);
		setCountryList(accountInfo.countryList);
		accountInfo.provinceList && setProvinceList(accountInfo.provinceList);
		accountInfo.provincialCityList &&
			setProvincialCityList(accountInfo.provincialCityList);
	}, [accountInfo, navigator.language]);

	return (
		<article id="container-baseInfo">
			<section>
				<article>
					<form
						autoComplete="off"
						onSubmit={handleSubmit}
						className="row g-3"
						noValidate
					>
						<section
							id="upper-info-section"
							className="articleBoxWithSideBox"
						>
							<div className="containTitle">个人资料</div>
							<div className="row row-re">
								<div className="photo-wrapper col-md-5">
									{/*<!-- 圖片 -->*/}
									<div
										className="photoInfo"
										style={{
											padding: '2rem',
										}}
									>
										<div
											className="outer_container"
											style={{
												width: '180px',
												height: '180px',
												borderRadius: '50%',
												overflow: 'hidden',
												marginBottom: '2rem',
											}}
										>
											<div
												className="inner_container"
												style={{
													margin: '0 auto',
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<img
													style={{
														height: '100%',
														width: '100%',
														objectFit: 'cover',
													}}
													src={
														avatarImg ||
														'./images/user-default.png'
													}
													id="imagePho"
													alt=""
												/>
											</div>
										</div>
										<div>
											<label
												id="avatar-btn"
												htmlFor="file"
												className="btn btn-color-main rounded-pill p-0"
											>
												头像照片
											</label>
											<input
												type="file"
												id="file"
												name="photo"
												onChange={(e) => {
													handleFileUpload(e);
												}}
												accept=".PNG, .JPG"
											/>
											<p>
												档案大小 : 最大 2 MB
												<br />
												档案限制 : JPG, PNG
											</p>
											<div
												className="invalid-feedback"
												id="avatar-info"
											></div>
										</div>
									</div>
								</div>
								<div className="left-side-wrapper col-md-7">
									{/*<!-- 待改 -->*/}
									<input
										type="hidden"
										id="updateAble"
										value="0"
									/>
									{/*<!-- 顯示名稱 -->*/}
									<div className="form-group">
										<label
											className="form-label"
											htmlFor="fullname"
										>
											使用者 nickName
										</label>
										<input
											className="form-control form-control-lg "
											id="fullname"
											name="fullname"
											placeholder=""
											value={userNickName || ''}
											onChange={(e) => {
												setUserNickName(e.target.value);
											}}
										/>
										<div
											className="invalid-feedback"
											id="userNickname-info"
										></div>
									</div>
									{/*<!-- 使用者ID -->*/}
									<div className="form-group">
										<label
											htmlFor="userID"
											className="form-label"
										>
											使用者ID
										</label>
										<input
											className="form-control form-control-lg"
											id="userID"
											name="userID"
											placeholder="您可以指定一个会员名(ID)，做为您的登入帐号"
											value={userUserId || ''}
											onChange={(e) => {
												setUserUserId(e.target.value);
												refAccountType.current = '2';
											}}
											onBlur={checkUserId}
										/>
										<div className="id-info">
											请输入会员名(ID)，做为您的登入帐号
										</div>
										<div
											className="invalid-feedback"
											id="userID-info"
										></div>
									</div>
									{/*<!-- 姓 -->*/}
									<div className="form-group">
										<label
											htmlFor="lastname"
											className="form-label"
										>
											姓
										</label>
										<input
											className="form-control form-control-lg"
											id="lastname"
											name="lastname"
											placeholder=""
											value={userFamilyName || ''}
											onChange={(e) => {
												document.getElementById(
													'lastname-info'
												).textContent = '';
												setUserFamilyName(
													e.target.value
												);
											}}
										/>
										<div
											className="invalid-feedback"
											id="lastname-info"
										></div>
									</div>
									{/*<!-- 名 -->*/}
									<div className="form-group">
										<label
											htmlFor="firstName"
											className="form-label"
										>
											名
										</label>
										<input
											className="form-control form-control-lg"
											id="firstName"
											name="firstName"
											placeholder=""
											value={userFirstName || ''}
											onChange={(e) => {
												document.getElementById(
													'lastname-info'
												).textContent = '';
												setUserFirstName(
													e.target.value
												);
											}}
										/>
										<div
											className="invalid-feedback"
											id="firstname-info"
										></div>
									</div>
									{/*<!-- 性別 -->*/}
									<div className="form-group">
										<label
											htmlFor="gender"
											className="form-label"
										>
											性別
										</label>
										<select
											className="form-control form-control-lg"
											id="gender"
											name="gender"
											value={userGender || ''}
											onChange={(e) =>
												setUserGender(e.target.value)
											}
										>
											<option value="1"> 男 </option>
											<option value="2"> 女 </option>
											<option value="3">不公開</option>
										</select>
										<div
											className="invalid-feedback"
											id="gender-info"
										></div>
									</div>
									{/*<!-- 生日 -->*/}
									<div className="form-group">
										<label
											htmlFor="birthday"
											className="form-label"
										>
											生日
										</label>
										<input
											value={
												(userBirthday &&
													userBirthday.split(
														'T'
													)[0]) ||
												''
											}
											type="date"
											className="form-control form-control-lg"
											id="birthday"
											name="birthday"
											placeholder=""
											onChange={(e) => {
												document.getElementById(
													'birthday-info'
												).textContent = '';
												setUserBirthday(
													e.target.value || ''
												);
											}}
										/>
										<div
											className="invalid-feedback"
											id="birthday-info"
										></div>
									</div>
									{/*<!-- 球齡 -->*/}
									<div className="form-group">
										<label
											htmlFor="firstLearningYear"
											className="form-label"
										>
											球龄
										</label>
										<input
											value={userFirstLearningYear || ''}
											onChange={(e) =>
												setUserFirstLearningYear(
													e.target.value
												)
											}
											type="number"
											className="form-control form-control-lg"
											id="firstLearningYear"
											name="firstLearningYear"
											placeholder=""
											min="0"
											max="150"
											step="1"
										/>
										<div
											className="invalid-feedback"
											id="firstLearningYear-info"
										></div>
									</div>
								</div>
							</div>
						</section>
						<section className="articleBoxWithSideBox">
							<div className="row">
								<div className="col-md-7 left-side-wrapper">
									{/*<!-- 手機號 -->*/}
									<div className="row align-items-end form-group g-2 g-md-3">
										<div
											className="col-md-4"
											id="countryCodeBox"
										>
											<label
												htmlFor="phone1CountryCode"
												className="form-label"
											>
												國碼
											</label>
											<select
												className="form-control form-select-lg"
												id="phone1CountryCode"
												name="phone1CountryCode"
												value={
													userPhone1CountryCode || ''
												}
												onChange={(e) => {
													newCountryCode.current =
														e.target.value;
													refAccountType.current =
														'1';
												}}
												disabled
											>
												<option value="">請選擇</option>
												<option value="886">
													+886
												</option>
												<option value="86">+86</option>
												<option value="852">
													+852
												</option>
											</select>
										</div>
										<div className="col ">
											<label
												htmlFor="userPhone1"
												className="form-label"
											>
												手机号
											</label>

											<input
												value={userPhone1 || ''}
												type="text"
												className="form-control form-control-lg"
												id="userPhone1"
												name="userPhone1"
												placeholder=""
												disabled
											/>
										</div>
										<div className="btnSBox col-3 col-xs-3 col-sm-3 col-md-3">
											<button
												type="button"
												className="email-btn btn btn-size-s btn-color-auxiliary set-Modify form-control-lg"
												data-toggle="modal"
												data-target="#exampleModal"
												onClick={() => {
													refAccountType.current =
														'1';
													showModifyModal('phone', 1);
												}}
											>
												{userPhone1 ? '修改' : '新增'}
											</button>
										</div>
									</div>
									{/*<!-- 信箱 -->*/}
									<div className="row align-items-end form-group g-2 g-md-3">
										<div className="col" id="emailBox">
											<label
												htmlFor="userEmail1"
												className="form-label"
											>
												电子信箱
											</label>
											<input
												value={userEmail1 || ''}
												type="text"
												className="form-control form-control-lg"
												id="email1"
												name="email1"
												placeholder=""
												disabled
											/>
										</div>
										<div className="btnSBox col-3 col-xs-3 col-sm-3 col-md-3">
											<button
												type="button"
												className="email-btn btn btn-size-s btn-color-auxiliary set-Modify form-control-lg"
												data-toggle="modal"
												data-target="#exampleModal"
												onClick={() => {
													refAccountType.current =
														'0';
													showModifyModal('email', 0);
												}}
											>
												{userEmail1 ? '修改' : '新增'}
											</button>
										</div>
										<div
											className={`invalid-feedback ${
												modalShow === true && 'd-none'
											}`}
											id="userEmail1-info"
										></div>
									</div>
								</div>
								<div className="col-md-5"></div>
								{/*Modal*/}
								<Modal
									show={modalShow}
									onHide={() => setModalShow(false)}
									aria-labelledby="contained-modal-title-vcenter"
									centered
									className="modal__email"
								>
									<Modal.Header closeButton>
										<Modal.Title
											id="contained-modal-title-vcenter"
											className="form-label"
										>
											{modalTitle === 'phone'
												? '設置手机号码'
												: '設置电子邮箱'}
										</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<div className="limitBox-modal">
											<form
												autoComplete="off"
												id="modal-input-form"
											>
												{/*<!-- 資料類型 -->*/}
												<input
													type="hidden"
													id="accountType"
													value={modalType}
												/>
												{/*<!-- 資料編號 -->*/}
												<input
													type="hidden"
													id="accountId"
													value={modalId}
												/>
												{/*<!-- 信箱 -->*/}
												{modalType === 'email' && (
													<div
														className={clsx(
															'form-row formPage'
														)}
														id="emailForm"
													>
														<div className="email-input-wrapper">
															<label
																htmlFor="email"
																className="form-label"
															>
																电子邮箱
															</label>
															<input
																type="email"
																className="form-control form-control-lg"
																id="new-email1"
																value={
																	newEmail ||
																	''
																}
																placeholder="请输入电子邮箱"
																onChange={(
																	e
																) => {
																	setNewEmail(
																		e.target
																			.value
																	);
																}}
															/>
															<div
																className={`invalid-feedback ${
																	modalShow ===
																		true &&
																	'd-2none'
																}`}
																id="email-info"
															></div>
														</div>
													</div>
												)}
												{/*<!-- 電話 -->*/}
												{modalType === 'phone' && (
													<div
														className={`row gx-2 ${
															modalType ===
															'phone'
																? ''
																: 'd-none'
														} phone-input-wrapper`}
														id="phoneForm"
														style={{
															display:
																modalType ===
																	'phone' &&
																'flex',
														}}
													>
														<div className="col-4">
															<label
																htmlFor="phone1CountryCode"
																className="form-label"
															>
																手机号码
															</label>
															<select
																className="form-select"
																id="phone1CountryCode"
																value={
																	newCountryCode ||
																	''
																}
																onChange={(
																	e
																) => {
																	setNewCountryCode(
																		e.target
																			.value
																	);
																}}
															>
																<option value="">
																	请选择
																</option>
																<option value="886">
																	+886
																</option>
																<option value="86">
																	+86
																</option>
																<option value="852">
																	+852
																</option>
															</select>
														</div>
														<div className="col-8">
															{/* <label
																htmlFor="phone1"
																className="form-label"
															>
																手機號
															</label> */}

															<input
																type="text"
																className="form-control form-control-lg"
																id="phone1"
																placeholder="手机号码"
																value={
																	newPhone ||
																	''
																}
																onChange={(
																	e
																) => {
																	setNewPhone(
																		e.target
																			.value
																	);
																}}
															/>
														</div>
														<div
															className={`invalid-feedback `}
															id="phone1-info"
														></div>
													</div>
												)}
												{/*<!-- 帳號驗證碼 -->*/}
												{modalType ===
													'verification' && (
													<div
														className={`formPage`}
														id="codeForm"
													>
														<div className="form-group">
															<div className="row gx-2">
																<div className="col-7">
																	<label
																		htmlFor="code"
																		className="form-label"
																	>
																		验证码
																	</label>
																	<input
																		className="form-control form-control-lg"
																		type="text"
																		id="code"
																		placeholder="请输入验证码"
																		value={
																			veriCode
																		}
																		onChange={(
																			e
																		) => {
																			setVeriCode(
																				e
																					.target
																					.value
																			);
																		}}
																	/>
																</div>
																<div className="col-5 align-self-end">
																	<button
																		type="button"
																		disabled={
																			rerun
																		}
																		className="btn btn-size-lg btn-color-auxiliary"
																		id="resendVerifyBtn"
																		onClick={(
																			e
																		) => {
																			setRerun(
																				true
																			);
																			setSeconds(
																				10
																			);
																			handleResendVeriCode();
																		}}
																	>
																		{
																			seconds
																		}
																	</button>
																</div>
															</div>
															<div
																className={`invalid-feedback ${
																	modalShow ===
																		true &&
																	'd-n1one'
																}`}
																id="code-info"
															></div>

															{/*<!-- 提示訊息 -->*/}
															<div id="promptMessageBox">
																<div className="waiting__div">
																	{refAccountType.current ===
																		'0' &&
																		`验证码已发送到您的邮箱，有效时间为10分钟，
																		请耐心等候。`}
																	{refAccountType.current ===
																		'1' &&
																		`验证码已发送到您的手機號，有效时间为10分钟，
																		请耐心等候。`}
																</div>
															</div>
														</div>
													</div>
												)}
												{/*<!-- 修改成功畫面 -->*/}
												{modalType === 'finish' && (
													<div
														className={`formPage text-center`}
														id="successForm"
													>
														<div className="my-3">
															<i className="fas fa-check-circle"></i>
															<p className="mt-4">
																更新成功
															</p>
														</div>
													</div>
												)}
											</form>
										</div>
									</Modal.Body>
									<Modal.Footer>
										<Button
											className={`btn btn-light`}
											id="cancelBtn"
											onClick={() => {
												setNewEmail('');
												setNewCountryCode('');
												setNewPhone('');
												setVeriCode('');
												setModalShow(false);
											}}
										>
											取消
										</Button>
										{modalType === 'email' && (
											<Button
												className={`btn btn-dark `}
												id="verifyAccountBtn"
												onClick={() => {
													refAccountType.current =
														'0';

													refAccountType.current ===
														'0' &&
														checkNewEmail(
															refAccountType.current,
															newEmail
														);

													refAccountType.current ===
														'1' &&
														checkNewPhone(
															refAccountType.current,
															newCountryCode
														);

													verifyEmailSendSuccess();
												}}
											>
												确认
											</Button>
										)}

										{modalType === 'phone' && (
											<Button
												className={`btn btn-dark `}
												id="verifyAccountBtn"
												onClick={() => {
													refAccountType.current =
														'1';

													refAccountType.current ===
														'0' &&
														checkNewEmail(
															refAccountType.current,
															newEmail
														);

													refAccountType.current ===
														'1' &&
														checkNewPhone(
															refAccountType.current,
															newCountryCode,
															newPhone
														);

													verifyEmailSendSuccess();
												}}
											>
												确定
											</Button>
										)}
										{modalType === 'verification' && (
											<Button
												className={`btn btn-dark`}
												id="updateAccountBtn"
												onClick={() => {
													handleUpdateAccount(
														veriCode
													);
												}}
											>
												确认
											</Button>
										)}
									</Modal.Footer>
								</Modal>
							</div>
						</section>
						<section className="articleBoxWithSideBox">
							<div className="row">
								<div className="col-md-7 left-side-wrapper">
									{/*<!-- Country -->*/}
									<div className="form-group">
										<label
											className="form-label"
											htmlFor="addrCountry"
										>
											国家或地区
										</label>
										<select
											className="form-control form-control-lg"
											id="addrCountry"
											name="addrCountry"
											value={userAddrCountry || ''}
											onChange={(e) => {
												setUserAddrCountry(
													e.target.value
												);
												getProvinceList(e.target.value);
												setUserAddrProvince('');
												setUserAddrCity('');
											}}
										>
											<option value="">請選擇</option>
											{countryList &&
												countryList.map((country) => {
													return (
														<option
															key={country.code}
															value={country.code}
														>
															{country.name}
														</option>
													);
												})}
										</select>
										<div
											className="invalid-feedback"
											id="country-info"
										></div>
									</div>
									{/*<!-- Province -->*/}
									<div
										className={`form-group ${
											userAddrCountry === 'CN'
												? ''
												: 'd-none'
										}`}
										id="provinceBox"
									>
										<label
											htmlFor="province"
											className="form-label"
										>
											省
										</label>
										<input
											id="addrProvince"
											name="addrProvince"
											value={userAddrProvince || ''}
											list="province"
											className="form-control form-control-lg"
											onChange={(e) => {
												setUserAddrProvince(
													e.target.value
												);
												getCityList(e.target.value);
											}}
										/>
										<datalist
											id="province"
											name="addrProvince"
											disabled={userAddrCountry !== 'CN'}
										>
											<option value="">请选择</option>
											{provinceList &&
												provinceList.map((province) => {
													return (
														<option
															key={province}
															value={province}
														>
															{province}
														</option>
													);
												})}
										</datalist>
										<div
											className="invalid-feedback"
											id="province-info"
										></div>
									</div>
									{/*<!-- City -->*/}
									<div
										className={`form-group ${
											userAddrCountry === 'CN'
												? ''
												: 'd-none'
										}`}
										id="provincialCityBox"
									>
										<label
											htmlFor="provincialCity"
											className="form-label"
										>
											市
										</label>
										<input
											id="addrCity"
											name="addrCity"
											value={userAddrCity || ''}
											list="provincialCity"
											className="form-control form-control-lg"
											onChange={(e) =>
												setUserAddrCity(e.target.value)
											}
										/>
										<datalist
											id="provincialCity"
											name="addrCity"
											disabled={userAddrCountry !== 'CN'}
										>
											<option value="">请选择</option>
											{provincialCityList &&
												provincialCityList.map(
													(provincialCity) => {
														return (
															<option
																key={
																	provincialCity
																}
																value={
																	provincialCity
																}
															>
																{provincialCity}
															</option>
														);
													}
												)}
										</datalist>
										<div
											className="invalid-feedback"
											id="provincialCity-info"
										></div>
									</div>
									{/*<!-- 街道 -->*/}
									<div className="form-group">
										<label
											htmlFor="street"
											className="form-label"
										>
											地址
										</label>
										<input
											type="text"
											value={userAddrStreet || ''}
											onChange={(e) =>
												setUserAddrStreet(
													e.target.value
												)
											}
											className="form-control form-control-lg"
											id="addrStreet"
											name="addrStreet"
											placeholder=""
										/>
										<div
											className="invalid-feedback"
											id="addrStreet"
										></div>
									</div>
									{/*<!-- 郵遞區號 -->*/}
									<div
										className={`form-group ${
											userAddrCountry !== 'HK'
												? ''
												: 'd-none'
										}`}
									>
										<label
											htmlFor="zip"
											className="form-label"
										>
											邮编
										</label>
										<input
											type="number"
											className="form-control form-control-lg"
											id="zip"
											name="addrZip"
											placeholder=""
											value={userAddrZip || ''}
											onChange={(e) =>
												setUserAddrZip(e.target.value)
											}
										/>
										<div
											className="invalid-feedback"
											id="zip-info"
										></div>
									</div>
								</div>
								<div className="col-md-5"></div>
							</div>
							<section className="section-footer d-flex align-items-center justify-content-center mt-5">
								<button
									type="submit"
									className="btn btn-size-m btn-color-main rounded-pill"
									disabled={inputDisabled}
								>
									确定
								</button>
								{/*onClick={UpdateInfoEvent}*/}
							</section>
						</section>
					</form>
				</article>
			</section>
		</article>
	);
}

export default BaseInfo;
