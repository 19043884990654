import './productRegister.scss';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { axiosApi } from '../../../api';

function ProductRegister() {
	const navigate = useNavigate();

	const [modelId, setModelId] = useState('');
	const [serialNo, setSerialNo] = useState('');
	const [purchaseDate, setPurchaseDate] = useState('');
	const [store, setStore] = useState('');
	// const [checkAgreement, setCheckAgreement] = useState('');
	const [modalShow, setModalShow] = useState(false);

	// sweetAlert
	// --未同意註冊條款dialog --
	// const checkAgreementError = () => {
	// 	Swal.fire({
	// 		title: '尚未同意註冊條款',
	// 		icon: 'warning',
	// 	});
	// };

	// --查无此产品dialog --
	const notFoundError = () => {
		Swal.fire({
			title: '查无此产品',
			icon: 'warning',
			confirmButtonText: '确定',
		});
	};

	// --已被本人註冊過dialog --
	const regByOriginUserError = () => {
		Swal.fire({
			title: '此产品您已注册过',
			icon: 'warning',
			confirmButtonText: '确定',
		});
	};

	// --已被其他人註冊dialog --
	const regByOthersError = () => {
		Swal.fire({
			title: '此产品已被其他人注册',
			icon: 'warning',
			confirmButtonText: '确定',
		});
	};

	// --註冊成功dialog --
	const successAlert = () => {
		Swal.fire({
			title: '注册成功',
			html: `<h6>产品注册成功！<br/><br/>你的帐号已升级为【尊荣会员】。<br/><br/>此外，我们将提供给您5个免费的家族会员帐号，您可以授权给亲朋好友使用。具体使用方式，请进入家族会员帐号管理页面</h6>`,
			icon: 'success',
			confirmButtonText: '跳转至我的产品',
			allowOutsideClick: false,
		}).then((result) => {
			if (result.isConfirmed) {
				navigate('/product');
			}
		});
	};

	// 驗證欄位
	let modelIdReg = /^[0-9A-Z]{5}$/;
	let serialNoReg = /^[0-9]{7}$/;
	let Today = new Date();

	let serialNoValidated = false;
	let purchaseDateValidated = false;
	let storeValidated = false;

	const checkModelId = () => {
		if (!modelId) {
			document.querySelector('#serialNo-info').textContent = '不能为空白';
			document.querySelector('#modelId').style.borderColor = '#CC3300';
			serialNoValidated = false;
		} else if (!modelIdReg.test(modelId)) {
			document.querySelector('#serialNo-info').textContent =
				'格式错误 请重新输入';
			document.querySelector('#modelId').style.borderColor = '#CC3300';
			serialNoValidated = false;
		} else {
			document.querySelector('#serialNo-info').textContent = '';
			document.querySelector('#modelId').style.borderColor = '#ced4da';
			serialNoValidated = true;
		}
	};

	const checkSerialNo = () => {
		if (!serialNo) {
			document.querySelector('#serialNo-info').textContent = '不能为空白';
			document.querySelector('#serialNo').style.borderColor = '#CC3300';
			serialNoValidated = false;
		} else if (!serialNoReg.test(serialNo)) {
			document.querySelector('#serialNo-info').textContent =
				'格式错误 请重新输入';
			document.querySelector('#serialNo').style.borderColor = '#CC3300';
			serialNoValidated = false;
		} else {
			//驗證碼驗證
			const sixSerialNo = serialNo.slice(0, 6).split('');
			const verifyNo = parseInt(serialNo.slice(-1));
			// console.log(sixSerialNo, verifyNo);

			let num1 =
				sixSerialNo[0] * 1 +
				sixSerialNo[1] * 7 +
				sixSerialNo[2] * 1 +
				sixSerialNo[3] * 7 +
				sixSerialNo[4] * 1 +
				sixSerialNo[5] * 7;
			let num2 = parseInt(num1 % 10) * 7;
			let num3 = parseInt((num2 / 10) % 10) + parseInt(num2 % 10);
			let num4 = parseInt(num3 % 10);
			// console.log(num1, num2, num3, num4);

			if (num4 === verifyNo) {
				document.querySelector('#serialNo-info').textContent = '';
				document.querySelector('#serialNo').style.borderColor =
					'#ced4da';
				serialNoValidated = true;
			} else {
				document.querySelector('#serialNo-info').textContent =
					'格式错误 请重新输入';
				document.querySelector('#serialNo').style.borderColor =
					'#CC3300';
				serialNoValidated = false;
			}
		}
	};

	const checkPurchaseDate = () => {
		if (!purchaseDate) {
			document.querySelector('#purchaseDate-info').textContent =
				'不能为空白';
			document.querySelector('#purchaseDate').style.borderColor =
				'#CC3300';
		} else if (
			Date.parse(purchaseDate).valueOf() > Date.parse(Today).valueOf()
		) {
			document.querySelector('#purchaseDate-info').textContent =
				'格式错误 请重新输入';
			document.querySelector('#purchaseDate').style.borderColor =
				'#CC3300';
		} else {
			document.querySelector('#purchaseDate-info').textContent = '';
			document.querySelector('#purchaseDate').style.borderColor =
				'#ced4da';
			purchaseDateValidated = true;
		}
	};

	const checkStore = () => {
		if (!store) {
			document.querySelector('#store-info').textContent = '不能为空白';
			document.querySelector('#store').style.borderColor = '#CC3300';
		} else {
			document.querySelector('#store-info').textContent = '';
			document.querySelector('#store').style.borderColor = '#ced4da';
			storeValidated = true;
		}
	};

	// 註冊產品
	const handleRegisterProduct = async (e) => {
		e.preventDefault();

		checkModelId();
		checkSerialNo();
		checkPurchaseDate();
		checkStore();

		try {
			// 註冊產品API
			const data = {
				// 目前暫定產品一種
				modelID: modelId,
				SerialNo: serialNo,
				PurchaseDate: purchaseDate,
				StoreInfoGuid: store,
			};
			// console.log(data);

			if (serialNoValidated && purchaseDateValidated && storeValidated) {
				document.querySelector('#store-info').textContent = '';

				axiosApi('/web/product/register', 'put', data)
					.then((res) => {
						if (res.data.status === '1') {
							notFoundError();
						} else if (res.data.status === '2') {
							regByOriginUserError();
						} else if (res.data.status === '3') {
							regByOthersError();
						} else if (res.data.status === '0') {
							successAlert();
						}
					})
					.catch((err) => console.log(err));

				// const res = await axios.put('/web/product/register', data);
				// // console.log(res.data);

				// if (res.data.status === '1') {
				//     notFoundError();
				// } else if (res.data.status === '2') {
				//     regByOriginUserError();
				// } else if (res.data.status === '3') {
				//     regByOthersError();
				// } else if (res.data.status === '0') {
				//     successAlert();
				// }
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<article id="container-productRegister">
			<div className="articleBoxWithSideBox">
				<section className="row g-3 mb-3">
					<div className="col-12">
						<h1 className="registerProductTitle">注册产品</h1>
						<h2 className="mb-4">
							注册宾乐达产品的过程简单、快速且安全。
						</h2>
						<h2>产品注册的好处：</h2>
						<ol className="list-unstyled">
							<li>1. 获得原厂完整的服务支持。</li>
							<li>2. 免费升级帐号等级至【尊荣会员】。</li>
							<li>
								3.
								可以邀请5个宾乐达会员加入你的家族群组，他们的帐号将升等为【家族会员】。
							</li>
						</ol>
					</div>
				</section>
				<section className="row g-3 mb-3">
					<div className="col-12">
						<form
							className="row g-3"
							onSubmit={handleRegisterProduct}
						>
							{/* 设备序列号 */}
							<div className="col-lg-6">
								<label
									className="form-label"
									htmlFor="serialNo"
								>
									设备序列号
								</label>
								<div className="row">
									<div className="col-lg-4">
										<input
											type="text"
											className="form-control form-control-lg"
											id="modelId"
											name="modelId"
											value={modelId}
											onChange={(e) =>
												setModelId(
													e.target.value.toUpperCase()
												)
											}
											onBlur={checkModelId}
										/>
									</div>
									<div className="col-lg-1 d-none d-lg-block">
										<h6 className="h-100 m-0">-</h6>
									</div>
									<div className="col-lg-7">
										<input
											type="text"
											className="form-control form-control-lg"
											id="serialNo"
											name="serialNo"
											placeholder="请输入设备序列号"
											value={serialNo}
											onChange={(e) =>
												setSerialNo(
													e.target.value.toUpperCase()
												)
											}
											onBlur={checkSerialNo}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-6">
										<div
											className="col-sm-6 invalid-feedback"
											id="serialNo-info"
										></div>
									</div>
									<div className="col-sm-6 mt-1 text-sm-end">
										<Link
											to="#"
											data-toggle="modal"
											data-target="#dialog-howToCheck"
											onClick={() => setModalShow(true)}
										>
											如何查看设备序列号？
										</Link>
									</div>
								</div>
								<Modal
									className="serialNoModal"
									size="xl"
									show={modalShow}
									onHide={() => setModalShow(false)}
									centered
								>
									<Modal.Header closeButton></Modal.Header>
									<Modal.Body>
										<div className="row g-3">
											<div className="col-12 text-center">
												<h5 className="dialog-title">
													如何查看设备序列号？
												</h5>
												<div className="imgDiv">
													<img
														className="serialNoCheckImg"
														src="./images/machineCheck.png"
														alt="serialNoCheckImg"
													/>
												</div>
											</div>
										</div>
									</Modal.Body>
								</Modal>
							</div>
							<div className="w-100 d-none d-lg-block m-0"></div>

							{/* 购买日期 */}
							<div className="col-lg-6">
								<label
									className="form-label"
									htmlFor="purchaseDate"
								>
									购买日期
								</label>
								<input
									type="date"
									className="form-control form-control-lg"
									id="purchaseDate"
									name="purchaseDate"
									value={purchaseDate}
									onChange={(e) =>
										setPurchaseDate(e.target.value)
									}
									onBlur={checkPurchaseDate}
								/>
								<div
									className="invalid-feedback"
									id="purchaseDate-info"
								></div>
							</div>
							<div className="w-100 d-none d-lg-block m-0"></div>

							{/* 购买通路 */}
							<div className="col-lg-6">
								<label className="form-label" htmlFor="store">
									购买通路
								</label>
								<select
									className="form-select form-select-lg"
									id="store"
									name="store"
									value={store}
									onChange={(e) => setStore(e.target.value)}
									onBlur={checkStore}
								>
									<option value="">请选择通路</option>
									<option value="af28f9f8-3c1c-ec11-8c5b-00155d070f06">
										官网
									</option>
									<option value="bf28f9f8-3c1c-ec11-8c5b-00155d070f06">
										购物网站
									</option>
									<option value="cf28f9f8-3c1c-ec11-8c5b-00155d070f06">
										零售商店
									</option>
									<option value="df28f9f8-3c1c-ec11-8c5b-00155d070f06">
										训练中心
									</option>
								</select>
								<div
									className="invalid-feedback"
									id="store-info"
								></div>
							</div>
							<div className="w-100 d-none d-lg-block m-0"></div>

							{/* <div className="form-group">
								<input
									type="checkbox"
									id="agreement"
									className="mr-3"
									onChange={(e) =>
										setCheckAgreement(e.target.value)
									}
								/>
								<Link to="/product/productagreement">
									产品注册同意条款
								</Link>
							</div> */}

							{/*submit button */}
							<div
								className="col-lg-6 text-center mt-5 d-flex justify-content-center justify-content-lg-end"
								style={{ gap: '16px' }}
							>
								<button
									type="button"
									className="btn btn-size-m btn-color-outline-auxiliary rounded-pill"
									onClick={() => navigate('/product')}
								>
									取消
								</button>
								<button
									type="submit"
									className="btn btn-size-m btn-color-main rounded-pill"
								>
									注册
								</button>
							</div>
						</form>
					</div>
				</section>
			</div>
		</article>
	);
}

export default ProductRegister;
