import React from 'react';
import './presetDrill.scss';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const TR = styled.tr``;

function DraggableTR(props) {
	// console.log(props);
	const {
		data,
		index,
		dragStart,
		dragEnter,
		drop,
		categoryGuid,
		categoryItemGuid,
		activeClass,
	} = props;

	// console.log(props);
	const navigate = useNavigate();

	return (
		<TR
			className={activeClass}
			draggable
			onDragStart={() => dragStart(index)}
			onDragEnter={() => dragEnter(index)}
			onDragEnd={drop}
			key={data.presetDrillGuid}
			onClick={() =>
				navigate(
					`/presetDrill/PresetDrillUpdate/edit/${data.presetDrillGuid}`,
					{
						state: {
							categoryGuid,
							categoryItemGuid,
							presetDrillGuid: data.presetDrillGuid,
						},
					}
				)
			}
		>
			<td>{data.ui_order}</td>
			<td>
				{data.name_ZH || data.name_ZF || data.name_EN || data.name_JP}
			</td>
		</TR>
	);
}

export default DraggableTR;
