import './factoryRecordChange.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LoadingAnimate } from '../../loadingAnimate/LoadingAnimate';
import { useTranslation } from 'react-i18next';
import { generateEditLog } from '../../../../utils';
import Swal from 'sweetalert2';
import { ReactComponent as Icn_More } from '../../../../assets/images/icn_More.svg';
import { ReactComponent as Icn_Log } from '../../../../assets/images/icn_Log.svg';

const FactoryRecordChange = () => {
	const { t } = useTranslation(['common', 'factoryRecord']); // i18n

	//#region [States initializations]
	const [isLoading, setIsLoading] = useState(false);

	const [data, setData] = useState({
		modelID: '',
		qcTime: '',
		qcStaffID: '',
		qcStaffName: '',
		vipLevel: '',
		serialNo: '',
		storeGuid: '',
		storeGuidName: '',
		factoryDate: '',
		vipQuantity: '',
		rowStamp: '',
	});
	console.log('data', data);

	const [originalDataForLog, setOriginalDataForLog] = useState({
		// The parameters which were needed for logging page
		modelID: '',
		qcTime: '',
		qcStaffID: '',
		qcStaffName: '',
		vipLevel: '',
		vipLevelName: '',
		serialNo: '',
		storeGuid: '',
		storeGuidName: '',
		factoryDate: '',
		vipQuantity: '',
		rowStamp: '',
	});

	const [physicalStoreList, setPhysicalStoreList] = useState([]);
	const [userLevelList, setUserLevelList] = useState([]);

	const paramsGuid = useParams('factoryRecordGuid');
	const navigate = useNavigate();

	const [showMoreBtn, setShowMoreBtn] = useState(false);

	//#endregion

	//#region [function]

	// edit
	const handleSubmit = async (e) => {
		e.preventDefault();

		console.log(data);
		console.log(originalDataForLog);

		const newData = { ...data };

		// 只取想要更新的log資料
		const { vipLevel, storeGuid, ...newLogData } = newData;
		const {
			vipLevel: vipLevel2,
			storeGuid: storeGuid2,
			...newOriginalDataForLog
		} = originalDataForLog;

		console.log('newLogData', newLogData);
		console.log('newOriginalDataForLog', newOriginalDataForLog);

		const updateContent = generateEditLog(
			'factoryRecord',
			t,
			newLogData,
			newOriginalDataForLog
		);

		console.log('updateContent', updateContent);
		console.log('newData', newData);

		if (updateContent === '') {
			Swal.fire({
				icon: 'info',
				title: '未發現欄位改動',
				text: '請再次確認。',
			});
		} else if (Object.keys(paramsGuid).length) {
			//edit
			// setIsLoading(true);

			await axios
				.put(
					`/web/product/factoryRecords/${paramsGuid.factoryRecordGuid}`,
					{
						factoryDate: newData.factoryDate,
						storeGuid: newData.storeGuid,
						vipQuantity: newData.vipQuantity,
						vipLevel: newData.vipLevel,
						rowStamp: newData.rowStamp,
						updateContent: updateContent,
					}
				)
				.then((res) => {
					console.log(res.data);
					setIsLoading(false);
					navigate('/factoryRecord');
				})

				.catch((err) => {
					setIsLoading(false);
					Swal.fire({
						icon: 'error',
						title: '網路不穩，儲存失敗',
						text: '請再次儲存。',
					});
					console.log(err);
				});
		}
	};

	//#endregion

	//#region [useEffect]

	// getDetails
	useEffect(() => {
		const fetchOnlineVideoDetails = async () => {
			await axios
				.get(
					`/web/product/factoryRecords/${paramsGuid.factoryRecordGuid}`
				)
				.then((res) => {
					console.log(res.data);

					const data = res.data.result;

					setData({
						modelID: data.modelID,
						qcTime: data.qcTime?.split('+')[0],
						qcStaffID: data.qcStaffID,
						qcStaffName: data.qcStaffName,
						vipLevel: data.vipLevel,
						vipLevelName: res.data.userLevelList.filter((el) => {
							return el.userLevelCode === data.vipLevel;
						})[0].name_ZF,
						serialNo: data.serialNo,
						storeGuid: data.storeGuid,
						storeGuidName: res.data.physicalStoreList.filter(
							(el) => {
								return el.storeGuid === data.storeGuid;
							}
						)[0]?.name,
						factoryDate: data.factoryDate?.split('+')[0] || '',
						vipQuantity: data.vipQuantity,
						rowStamp: data.rowStamp,
					});

					setOriginalDataForLog({
						modelID: data.modelID,
						qcTime: data.qcTime?.split('+')[0],
						qcStaffID: data.qcStaffID,
						qcStaffName: data.qcStaffName,
						vipLevel: data.vipLevel,
						vipLevelName: res.data.userLevelList.filter((el) => {
							return el.userLevelCode === data.vipLevel;
						})[0].name_ZF,
						serialNo: data.serialNo,
						storeGuid: data.storeGuid,
						storeGuidName: res.data.physicalStoreList.filter(
							(el) => {
								return el.storeGuid === data.storeGuid;
							}
						)[0]?.name,
						factoryDate: data.factoryDate?.split('+')[0] || '',
						vipQuantity: data.vipQuantity,
						rowStamp: data.rowStamp,
					});

					setPhysicalStoreList(res.data.physicalStoreList);
					setUserLevelList(res.data.userLevelList);
				});
		};
		if (Object.keys(paramsGuid).length) {
			fetchOnlineVideoDetails();
		}
	}, [paramsGuid]);

	//#endregion

	console.log('physicalStoreList', physicalStoreList);
	console.log('userLevelList', userLevelList);

	return (
		<>
			<LoadingAnimate isLoading={isLoading} />

			<section id="section-main">
				<form
					id="container-factoryRecordChange"
					onSubmit={handleSubmit}
				>
					<div className="d-flex position-relative">
						<h5 className="fw-bold mb-4">发球机出厂记录</h5>
						<button
							className="btnCancel btn btn-outline-primary px-4 ms-auto me-2"
							onClick={(e) => {
								e.preventDefault();
								navigate('/factoryRecord');
							}}
						>
							取消
						</button>
						<button className="btn btn-primary text-white px-4 me-2">
							儲存
						</button>
						<button
							style={{ width: '32px' }}
							className="btn btn-outline-primary moreIconBtn"
							onClick={(e) => {
								e.preventDefault();
								setShowMoreBtn(!showMoreBtn);
							}}
						>
							<Icn_More />
						</button>

						{showMoreBtn ? (
							<div
								className="moreBtnBox position-absolute bg-white "
								style={{
									right: '0px',
									top: '40px',
									minWidth: '136px',
									boxShadow:
										'0px 4px 8px rgba(61, 61, 61, 0.51)',
									borderRadius: '0.25rem',
								}}
							>
								<div
									className="py-2 px-3"
									style={{ cursor: 'pointer' }}
								>
									<div
										className="d-flex justify-content-start align-items-center"
										onClick={() =>
											navigate(
												`/factoryRecord/log/${paramsGuid.factoryRecordGuid}`
											)
										}
									>
										<Icn_Log />
										<span className="fontSize875Rem">
											異動記錄
										</span>
									</div>
								</div>
							</div>
						) : null}
					</div>

					<div className="infoWrapper bg-white px-4 pt-4 pb-15 rounded-4">
						<div className="infoTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold mb-0">基本数据</h6>
						</div>
						<div className="infoBottom row">
							<div className="col-4">
								<label
									htmlFor="modelID"
									className="fontSize75Rem mb-2"
								>
									机型ID
								</label>
								<input
									type="text"
									id="modelID"
									name="modelID"
									className="form-control mb-3"
									value={data.modelID || ''}
									onChange={(e) =>
										setData({
											...data,
											modelID: e.target.value,
										})
									}
									disabled
								/>
								<label
									htmlFor="qcTime"
									className="fontSize75Rem mb-2"
								>
									制造时间
								</label>
								<input
									type="datetime-local"
									id="qcTime"
									name="qcTime"
									className="form-control mb-3"
									value={data.qcTime || ''}
									onChange={(e) =>
										setData({
											...data,
											qcTime: e.target.value,
										})
									}
									disabled
								/>
								<label
									htmlFor="qcStaffName"
									className="fontSize75Rem mb-2"
								>
									品管人员
								</label>
								<input
									type="text"
									id="qcStaffID"
									name="qcStaffID"
									className="form-control mb-3 d-none"
									value={data.qcStaffID || ''}
									onChange={(e) =>
										setData({
											...data,
											qcStaffID: e.target.value,
										})
									}
									disabled
								/>
								<input
									type="text"
									id="qcStaffName"
									name="qcStaffName"
									className="form-control mb-3"
									value={data.qcStaffName || ''}
									onChange={(e) =>
										setData({
											...data,
											qcStaffName: e.target.value,
										})
									}
									disabled
								/>

								<label
									htmlFor="vipLevel"
									className="fontSize75Rem mb-2"
								>
									䁬送的VIP等级
								</label>
								<select
									className="form-control mb-3"
									name="vipLevel"
									id="vipLevel"
									onChange={(e) =>
										setData({
											...data,
											vipLevel: e.target.value,
											vipLevelName:
												e.target.selectedOptions[0]
													.dataset.value2,
										})
									}
									value={data.vipLevel || ''}
									required
								>
									<option value="" disabled>
										-- 請選擇 --
									</option>
									{userLevelList?.map((el) => {
										return (
											<option
												key={el.userLevelCode}
												value={el.userLevelCode}
												data-value2={el.name_ZH}
											>
												{el.name_ZH}
											</option>
										);
									})}
								</select>
							</div>

							<div className="col-4">
								<label
									htmlFor="serialNo"
									className="fontSize75Rem mb-2"
								>
									主板型号/序号
								</label>
								<input
									type="text"
									id="serialNo"
									name="serialNo"
									className="form-control mb-3"
									value={data.serialNo}
									onChange={(e) =>
										setData({
											...data,
											serialNo: e.target.value,
										})
									}
									disabled
								/>

								<label
									htmlFor="storeGuid"
									className="fontSize75Rem mb-2"
								>
									销售网点
								</label>
								<select
									className="form-control mb-3"
									name="storeGuid"
									id="storeGuid"
									onChange={(e) =>
										setData({
											...data,
											storeGuid: e.target.value,
											storeGuidName:
												e.target.selectedOptions[0]
													.dataset.value2,
										})
									}
									value={data.storeGuid || ''}
									required
								>
									<option value="" disabled>
										-- 請選擇 --
									</option>
									{physicalStoreList?.map((el) => {
										return (
											<option
												key={el.storeGuid}
												value={el.storeGuid}
												data-value2={el.name}
											>
												{el.name}
											</option>
										);
									})}
								</select>

								<label
									htmlFor="factoryDate"
									className="fontSize75Rem mb-2"
								>
									出厂时间
								</label>
								<input
									type="datetime-local"
									id="factoryDate"
									name="factoryDate"
									className="form-control mb-3"
									value={data.factoryDate}
									onChange={(e) =>
										setData({
											...data,
											factoryDate: e.target.value,
										})
									}
									required
								/>

								<label
									htmlFor="vipQuantity"
									className="fontSize75Rem mb-2"
								>
									可授权的VIP数量
								</label>
								<input
									type="text"
									id="vipQuantity"
									name="vipQuantity"
									className="form-control"
									value={data.vipQuantity}
									onChange={(e) =>
										setData({
											...data,
											vipQuantity: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
				</form>
			</section>
		</>
	);
};

export default FactoryRecordChange;
