import './explore.scss';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
// import { IntlContext } from '../../components/I18nWrapper/I18nWrapper';
import { scrollToLocation } from '../../components/utils/utils';
import { axiosApi } from '../../api';

/// 网上展示厅
/// Author : YL
function Explore() {
	const [onlineVideoList, setOnlineVideoList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [categoryFilter, setCategoryFilter] = useState(null);
	const [searchKeyword, setSearchKeyword] = useState('');
	const userInputRef = useRef('');
	const categoryNameObj = {
		0: '发球机维护',
		1: '发球机打球影片维护',
		2: '宣传影片',
	};

	// 多國語言
	// const intlContext = useContext(IntlContext);

	// let language = intlContext.locale;
	// let languageCode = 'ZH';

	// if (language === 'zh-TW') {
	//     languageCode = 'ZF';
	// } else if (language === 'en') {
	//     languageCode = 'EN';
	// } else {
	//     languageCode = 'ZH';
	// }

	// 點擊切換分類
	const handleChangeFilter = (e, filter) => {
		[...e.currentTarget.parentNode.children].forEach((el) => {
			el.classList.remove('active');
		});
		e.currentTarget.classList.add('active');
		scrollToLocation('.section2');
		setCategoryFilter(filter);
	};

	// 取得影片 & 影片分類
	const getOnlineVideoList = useCallback(() => {
		axiosApi(`web/service/onlineVideo?languageCode=${navigator.language}`)
			.then((res) => {
				console.log(res.data);
				setOnlineVideoList(res.data.onlineVideoList);
				setCategoryList(res.data.categoryList);
			})
			.catch((err) => console.log(err));
	}, [navigator.language]);

	// 使用者輸入查詢
	const handleChangingUserInput = (input) => {
		if (!input) setSearchKeyword('');

		return (userInputRef.current = input);
	};

	const renderOnlineVideoList = (onlineVideoList) => {
		const result = onlineVideoList
			.filter((el) => {
				return (
					el.title.includes(searchKeyword) ||
					categoryNameObj[el.category].includes(searchKeyword)
				);
			})
			.filter((el) => {
				if (!categoryFilter) return el;
				return el.category === categoryFilter;
			})
			.map((el) => {
				return (
					<div className="card col-4" key={el.videoGuid}>
						<div className="cardImg">
							<img
								className="w-100 h-100"
								src={el.coverPath}
								onError={(e) => {
									e.target.onError = null;
									e.target.src =
										'./images/explore/defaultCardImage.png';
								}}
								alt="cardImg"
							></img>
						</div>
						<div className="cardWords bg-white row align-items-center justify-content-between">
							<div className="cardDesc col-7 border-right">
								<div className="mb-2 cardTitle">
									<span>{el.title}</span>
								</div>
								<div className="cardCategory">
									<span>{categoryNameObj[el.category]}</span>
								</div>
							</div>
							<div className="cardVideoLinkContainer col-5 text-center">
								<div className="cardVideoLink d-flex">
									<div
										onClick={() =>
											window.open(el.videoURL, '_blank')
										}
									>
										观看视频
									</div>
									<div className="arrowImg ms-2"></div>
								</div>
							</div>
						</div>
					</div>
				);
			});
		if (!result.length)
			return (
				<div className="card col-4" key={'defaultVideo'}>
					<div className="cardImg">
						<img
							className="w-100 h-100"
							src={'./images/explore/defaultCardImage.png'}
							alt="cardImg"
						></img>
					</div>
					<div className="cardWords bg-white row align-items-center justify-content-between">
						<div className="cardDesc col-7 border-right">
							<div className="mb-2 cardTitle">
								<span>宾乐达官方旗舰店</span>
							</div>
							<div className="cardCategory">
								<span>{'Recommendation'}</span>
							</div>
						</div>
						<div className="cardVideoLinkContainer col-5 text-center">
							<div className="cardVideoLink d-flex">
								<div
									onClick={() =>
										window.open(
											'https://world.taobao.com/',
											'_blank'
										)
									}
								>
									进入网店
								</div>
								<div className="arrowImg ms-2"></div>
							</div>
						</div>
					</div>
				</div>
			);

		return result;
	};

	const handleSearchByKeyword = (keyword) => {
		scrollToLocation('.section2');
		setSearchKeyword(keyword);
	};

	useEffect(() => {
		getOnlineVideoList();
	}, [getOnlineVideoList]);

	return (
		<div className="container-fluid p-0 bg-white">
			<article
				id="container-explore"
				className="d-flex flex-column align-items-center px-0 mx-auto"
			>
				{/* ExploreTop start */}

				<section className="exploreTop-section w-100 row">
					<div className="col position-relative p-0 text-center">
						<h1 className="text-center">鉴赏之旅</h1>
						<p className="text-center">
							来了解一下宾乐达乒乓球智能发球机吧！
						</p>

						<div className="searchWrapContainer w-100">
							{
								<div className="searchWrap position-relative mx-lg-auto">
									<input
										type="text"
										className="rounded-pill w-100 bg-white border-0"
										placeholder="请输入您想要了解的信息"
										// value={userInputRef.current}
										onChange={(e) => {
											handleChangingUserInput(
												e.target.value
											);
										}}
										onKeyPress={(e) =>
											e.key === 'Enter' &&
											handleSearchByKeyword(
												userInputRef.current.replaceAll(
													' ',
													''
												)
											)
										}
									/>
									<img
										className="position-absolute"
										src="./images/support/icnSearch.png"
										alt="icnSearch"
										onClick={() => {
											handleSearchByKeyword(
												userInputRef.current.replaceAll(
													' ',
													''
												)
											);
										}}
									/>
								</div>
							}
						</div>
						<div className="position-absolute pageBtnWrapper bg-white w-100">
							<div className="textWrap h-100 d-flex flex-wrap justify-content-center align-items-center mx-auto">
								<div className="classBtnInnerWrapper">
									<button
										type="button"
										className="active btn btn-primary btn-lg mx-2"
										key={'all'}
										onClick={(e) =>
											handleChangeFilter(e, null)
										}
									>
										全部内容
									</button>

									{categoryList.map((el) => {
										return (
											<button
												type="button"
												className="btn btn-primary btn-lg mx-2"
												key={el}
												onClick={(e) =>
													handleChangeFilter(e, el)
												}
											>
												{categoryNameObj[el]}
											</button>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* ExploreTop end */}

				<section className="explore-section section2 w-100 row">
					<div className="col-12 p-0 text-center">
						<h2 className="px-6 mx-auto mb-3 text-grey5">
							探索功能
						</h2>
						<h6 className="px-6 mx-auto mb-0 text-grey5">
							探索更多宾乐达乒乓球智能发球机使用指南
						</h6>
					</div>
				</section>

				<section className="explore-section section3 w-100 row mb-10 mb-lg-14">
					<div className="cardArea row justify-content-start">
						{renderOnlineVideoList(onlineVideoList)}
					</div>
				</section>
			</article>
		</div>
	);
}

export default Explore;
