import './OTAMainUpdateDetail.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as dayjs from 'dayjs';
import axios from 'axios';
import Swal from 'sweetalert2';

import { axiosApi } from '../../../api';

function DrillGradeUpdateDetail() {
    const paramsCode = useParams('otaGuid');
    const navigate = useNavigate();
    const [rowStamp, setRowStamp] = useState('');

    const [data, setData] = useState({
        otaGuid: '',
        otaCode: '',
        name: '',
        note: '',
        updateTime: '',
        appGuid: '',
    });
    const [appList, setAppList] = useState([]);

    //#region function
    const checkotaCode = async (otaCode) => {
        await axios
            .get(`web/sys/checkotaCodeValid?otaCode=${otaCode}`)
            .then((res) => {
                if (res.data === false) {
                    Swal.fire({
                        icon: 'error',
                        title: `OTA代码重複，請重新輸入`,
                    });
                    setData({ ...data, otaCode: '' });
                }
            });
    };

    // edit delete
    const handleDeleteItem = async () => {
        await axios
            .delete(`web/sys/ota/${paramsCode.otaGuid}`)
            .then((res) => {
                // console.log(res.data);
                res.status === 200 && navigate('/otaMainUpdate');
            })

            .catch((err) => console.log(err));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData(e.target);
        if (Object.keys(paramsCode).length) {
            //edit
            await axios
                .put(`web/sys/ota/${paramsCode.otaGuid}`, form)
                .then((res) => {
                    // console.log(res.data);
                    res.status === 200 && navigate('/otaMainUpdate');
                })
                .catch((err) => console.log(err));
        } else {
            //create
            await axios
                .post(`web/sys/ota`, form)
                .then((res) => {
                    // console.log(res.data);
                    res.status === 200 && navigate('/otaMainUpdate');
                })
                .catch((err) => console.log(err));
        }
    };

    const getOtaDetails = useCallback(() => {
        try {
            axiosApi(`web/sys/ota/${paramsCode.otaGuid}`).then((res) => {
                const data = res.data.result;
                // console.log(data);
                const {
                    appGuid,
                    otaCode,
                    otaGuid,
                    name,
                    note,
                    updateTime,
                    rowStamp,
                } = data;

                setRowStamp(rowStamp);
                setData({
                    ...data,
                    appGuid,
                    otaCode,
                    otaGuid,
                    name,
                    note,
                    updateTime:
                        updateTime &&
                        dayjs(updateTime).format('YYYY-MM-DD HH:mm:ss'),
                });
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    const fetchAppList = async () => {
        await axios.get(`web/sys/app`).then((res) => {
            const data = res.data.result;
            // console.log(data);
            setAppList(data);
        });
    };

    //#endregion

    //#region useEffect

    // getDetails

    useEffect(() => {
        if (Object.keys(paramsCode).length) {
            getOtaDetails();
        }
        fetchAppList();
    }, [getOtaDetails]);

    //#endregion

    return (
        <>
            <section id="section-main">
                <form
                    id="container-otaMainUpdateDetail"
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex">
                        <h5 className="fw-bold mb-4">OTA基本设定</h5>
                        <button
                            className="btnDelete btn btn-outline-danger px-4 ms-auto me-2"
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                        >
                            删除
                        </button>
                        <button
                            className="btnCancel btn btn-outline-primary px-4 me-2"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/otaMainUpdate');
                            }}
                        >
                            取消
                        </button>
                        <button className="btn btn-primary text-white px-4">
                            储存
                        </button>
                    </div>
                    {/* <div className="d-flex"></div> */}
                    <div className="manageFileWrotaer bg-white p-4 rounded-4 mb-3">
                        <input
                            id="rowStamp"
                            name="rowStamp"
                            className="d-none"
                            defaultValue={rowStamp}
                        />
                        <div className="d-flex">
                            <div className="col-6">
                                <h6 className="fw-bold mb-3">资料管理</h6>
                                <h6 className="fontSize75Rem mb-2">
                                    OTA代码（创建后不能修改）
                                </h6>
                                <input
                                    id="otaCode"
                                    name="otaCode"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.otaCode || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            otaCode:
                                                e.target.value.toUpperCase(),
                                        })
                                    }
                                    maxLength="2"
                                    disabled={
                                        Object.keys(paramsCode).length !== 0
                                    }
                                    onBlur={(e) => checkotaCode(e.target.value)}
                                />
                                <h6 className="fontSize75Rem mb-2">OTA名称</h6>
                                <input
                                    id="name"
                                    name="name"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.name || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name: e.target.value,
                                        })
                                    }
                                />
                                <h6 className="fontSize75Rem mb-2">注记</h6>
                                <input
                                    id="note"
                                    name="note"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.note || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            note: e.target.value,
                                        })
                                    }
                                />
                                <h6 className="fontSize75Rem mb-2">
                                    搭配的APP
                                </h6>
                                <select
                                    className="form-control mb-3"
                                    name="appGuid"
                                    id="appGuid"
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            appGuid: e.target.value,
                                        })
                                    }
                                    value={data.appGuid || ''}
                                    required
                                >
                                    {appList?.map((el) => (
                                        <option
                                            id={el.appGuid}
                                            key={el.appGuid}
                                            value={el.appGuid}
                                        >
                                            {el.name}
                                        </option>
                                    ))}
                                </select>
                                {/* <input
                                    id="note"
                                    name="note"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.note || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            note: e.target.value,
                                        })
                                    }
                                /> */}
                                {Object.keys(paramsCode).length !== 0 && (
                                    <React.Fragment>
                                        <h6 className="fontSize75Rem mb-2">
                                            创建/修改时间
                                        </h6>
                                        <input
                                            type="datetime-local"
                                            id="updateTime "
                                            name="updateTime"
                                            className="form-control mb-3 w-100"
                                            value={data.updateTime || ''}
                                            onChange={(e) =>
                                                setData({
                                                    ...data,
                                                    updateTime: e.target.value,
                                                })
                                            }
                                            disabled
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}

export default DrillGradeUpdateDetail;
