import './appDownload.scss';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';

function AppDownload() {
	const [isOnload, setOnload] = useState(false);
	const [apkUrl, setApkUrl] = useState('');

	const getApkUrl = async () => {
		await axios
			.get('web/sys/appdownloadurl?OSCode=1')
			.then((res) => {
				const url = res.data.result;
				// console.log(url);

				setApkUrl(url);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		setOnload(true);
		getApkUrl();
	}, []);

	//獲取最新版apk下載連結
	const getNewestAndroid = async () => {
		await axios
			.get('web/sys/appdownloadurl?OSCode=1')
			.then((res) => {
				//console.log(res);
				const url = res.data.result;

				// window.location.href = url;
				window.open(url, '_blank');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className="container-fluid p-0">
			<article
				id="container-appDownload"
				className="d-flex flex-column align-items-center px-0  mx-auto"
			>
				<section className="appDownload-section section1 row flex-column flex-lg-row px-lg-5 mx-0 mx-lg-auto">
					<div className="col-lg-5">
						<div className="h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start mt-5 mt-lg-0">
							<img
								className="section1AppIcon mb-3 mb-lg-5"
								src="./images/appDownload/section1AppIcon.png"
								alt="section1AppIcon"
							/>
							<h1 className="mb-2 mb-lg-4">宾乐达运动APP</h1>
							<p className="mb-3 mb-lg-8 px-5 px-lg-0">
								宾乐达提供发球机专用APP，化身您的练球伙伴，保持发球机最佳状态，让您的运动顺畅高效
								!
							</p>
							<div className="d-flex">
								<div
									className={`btnIcon ${
										isOnload ? 'd-flex' : 'd-none'
									} justify-content-center align-items-center btn btn-dark rounded-pill px-4 me-2`}
									onClick={() =>
										window.open(
											'https://apps.apple.com/cn/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id1611116746',
											'_blank'
										)
									}
								>
									<div
									// to={{
									//     pathname: '/app/download',
									//     state: {
									//         initialLocation: '.section1',
									//     },
									// }}
									>
										<img
											src="./images/appDownload/section1AppleLogo.png"
											alt="section1AppleLogo"
										/>
									</div>
								</div>
								<div
									className={`btnIcon ${
										isOnload ? 'd-flex' : 'd-none'
									}  justify-content-center align-items-center btn btn-dark rounded-pill px-4 me-2`}
									onClick={() => getNewestAndroid()}
								>
									<img
										src="./images/appDownload/section1AndroidLogo.png"
										alt="section1AndroidLogo"
									/>
								</div>
								<div
									className="d-flex justify-content-center align-items-center btn btn-primary rounded-pill px-3 px-lg-4"
									onClick={() => getNewestAndroid()}
								>
									立即下载安卓版本
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-7">
						<div className="h-100 d-flex align-items-center">
							<img
								className="section1Phone mx-auto ms-lg-14 mt-9 mt-lg-0"
								src="./images/appDownload/section1Phone.png"
								alt="section1Phone"
							/>
						</div>
					</div>
				</section>
				<section className="appDownload-section section2 w-100 row align-items-top">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h1 className="text-primary mt-8 mb-5 mt-lg-9 mb-lg-8">
							<span className="mb-3 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline">
								球谱球路
							</span>
							<span className="d-block d-lg-inline">
								有节奏的打球
							</span>
						</h1>
						<p className="px-6 mx-auto">
							打球是一个有节奏的运动，球与球之间的节奏有快有慢，宾乐达将多个球路的组成，称为「球谱」，您可以自定义球与球的发球间隔，模拟与真人打球的节奏，不再受限传统发球机发球频率固定、节奏呆板的运动模式，可依据球路特性，配合快慢节奏，享受打球乐趣。
						</p>
					</div>
				</section>
				<section className="appDownload-section section3 w-100 row flex-column-reverse flex-lg-row">
					<div className="col-lg-6">
						<div className="h-100 d-flex justify-content-center align-items-lg-end">
							<img
								className="section3Img mx-0 ms-lg-auto mt-12 mt-lg-0"
								src="./images/appDownload/section3.png"
								alt="section3Img"
							/>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start ps-0 ps-lg-9 mt-12 mt-lg-6">
							<h1 className="mb-4 mb-lg-4">无线蓝牙 两种联机</h1>
							<p className="mb-3 px-5 px-lg-0">
								【宾乐达运动APP】可以通过"蓝牙"或是"Wi-Fi无线网络"连接发球机，您可以依据打球环境，自行选择联机模式。使用宾乐达发球机，不需要额外遥控器，只要手机与【宾乐达运动APP】就能完全操控发球机，设定各种参数。
							</p>
							<h6 className="mb-3 mb-lg-8 px-5 px-lg-0 text-grey3">
								注：没有手机APP，也可直接使用发球机上的按键面板操作，不受网路限制。
							</h6>
						</div>
					</div>
				</section>
				<section className="appDownload-section section4 w-100 row">
					<div className="col-lg-6 mb-lg-0">
						<div className="h-100 d-flex flex-column justify-content-start align-lg-items-start mt-12">
							<div className="mx-auto ms-lg-auto">
								<h1 className="mb-4 mb-lg-4 text-center">
									云端保存 随时调用
								</h1>
								<p className="mb-3 mb-lg-8 px-5 px-lg-0">
									您通过【宾乐达运动APP】设置的球谱/球路，将自动保存在宾乐达云服务器中，无论您是在家中练习，还是外出或到俱乐部打球，只要带着手机就可以随时调用您设置好的球谱/球路。
									<br />
									<br />
									你的设置的球谱/球路，可以通用于任何一台宾乐达发球机，只要用您的宾乐达账号连接上发球机，点击"打球"，就能快速打球，提升练球效率。
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-6 px-0 px-lg-auto">
						<div className="h-100 ps-lg-15 ps-xxl-0 d-flex justify-content-start align-items-center">
							<img
								className="section4Phone mx-0 mt-12 mt-lg-0"
								srcSet={`${'./images/appDownload/section4PhoneSmall.png'} 992w, ${'./images/appDownload/section4Phone.png'} 993w`}
								alt="section4Phone"
							/>
						</div>
					</div>
				</section>
				<section className="appDownload-section section4_2 w-100 row flex-column-reverse flex-lg-row">
					<div className="col-lg-6">
						<div className="h-100 d-flex justify-content-center align-items-center">
							<img
								className="section4_2_Phone mx-0 ms-lg-auto mt-12 mt-lg-0"
								src="./images/appDownload/section4_2_Phone.png"
								alt="section4_2_Phone"
							/>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start ps-0 ps-lg-9 pb-lg-12 mt-12 mt-lg-0 ">
							<h1 className="mb-4 mb-lg-4">
								宾乐达球谱 专业陪练
							</h1>
							<p className="mb-3 px-5 px-lg-0">
								【宾乐达运动APP】內含數十套宾乐达球谱，它是专业乒乓球教练们与研发工程师团队，共同为广大的用户所设置，区分各种不同学习程度与考试需求，让喜爱轻松即用的您可以从我们设置的球谱中任意挑选，享受一键开打，挑战专业教练配置好的训练课程。
							</p>
						</div>
					</div>
				</section>
				<section className="appDownload-section section5 w-100 row flex-column flex-lg-row justify-content-center">
					<div className="col-lg-6">
						<div className="h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start ps-0 ps-lg-14 pb-lg-11 mt-12 mt-lg-0">
							<div className="mx-auto ms-lg-auto">
								<h1 className="mb-4 mb-lg-4 text-center">
									自定义球路/球谱
								</h1>
								<p className="mb-4 px-5 px-lg-0">
									为了达到更好的打球体验，我们推荐您使用【宾乐达运动APP】设置球路参数，并将这些球路组合成「球谱」，设置过程如同编辑自选歌单一样轻松简单，就能将各项仿真人的球路设定在您的口袋中。而且您可以自定球谱名称，例如：「左推右攻」、「反手搓球正手拉球」…，方便快速识别球谱特性，未来随时调用一键开打，满足各
									种练习要求。
								</p>
								<ul className="mb-5 mb-lg-6 px-5 px-lg-0 text-center text-lg-start">
									<li>✓ 出球频率、出球速度</li>
									<li>✓ 出球角度（左、右、俯、仰）</li>
									<li>✓ 出球延迟（与上一球的间距） </li>
									<li>✓ 旋球角度、旋球强度</li>
									<li>✓ 依序出球、随机出球</li>
									<li>✓ 打球时间、打球球数</li>
								</ul>
								<Link
									to={{
										pathname: '/explore',
									}}
								>
									<button className="d-flex justify-content-center align-items-center btn btn-outline-secondary rounded-pill px-5 mx-auto ms-lg-0">
										使用技巧
									</button>
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-6 px-5 px-lg-0">
						<div className="h-100 d-flex justify-content-center align-items-center">
							<img
								className="section5Phone ps-lg-8 mx-0 me-lg-auto mt-10 mt-lg-0"
								src="./images/appDownload/section5Phone.png"
								alt="section5Phone"
							/>
						</div>
					</div>
				</section>

				<section className="appDownload-section section5_2 w-100 row flex-column-reverse flex-lg-row">
					<div className="col-lg-6">
						<div className="h-100 d-flex justify-content-center align-items-center">
							<img
								className="section5_2_Phone ps-lg-15 mx-0 mx-lg-auto mt-12 mt-lg-0"
								src="./images/appDownload/section5_2_Phone.png"
								alt="section5_2_Phone"
							/>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="h-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start pb-lg-12 mt-12 mt-lg-0 ">
							<h1 className="mb-4 mb-lg-4">访客密码 尽享同乐</h1>
							<p className="mb-3 px-5 px-lg-0">
								通过【宾乐达运动APP】的管理，您可以为发球机设置访客密码，让访客亲友使用您的发球机。他们通过自己手机APP的宾乐达账号，调用自己设置的球谱/球路，用宾乐达发球机打球，如同拥有一台发球机一样。唯一的差别是，通过"访客密码"连接发球机，只能用于打球，无法更改设备的关键参数。宾乐达的保护机制，让您的设备不会被他人任意联机设置参数，且必要时，您可以通过宾乐达APP断开访客的联机，确保设备的使用权。
							</p>
						</div>
					</div>
				</section>

				<div className="sameBgWrap">
					<section className="appDownload-section section5_3 w-100 row justify-content-center align-items-center mx-0 mt-14 mt-lg-0">
						<div className="col-12 text-center">
							<h1 className="text-primary mb-4 mb-lg-11">
								<span className="mb-2 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline">
									随时更新
								</span>
								<span className="d-block d-lg-inline">
									自在训练
								</span>
							</h1>
							<p className="mb-3 mb-lg-4 px-5 px-lg-0">
								通过【宾乐达运动APP】的在线更新机制，你可以随时更新APP软件及固件（OTA），持续更新版本，满足各种训练要求。
							</p>
						</div>
					</section>
					<section className="appDownload-section section6 w-100 row flex-column flex-lg-row px-lg-5 mx-0">
						<div className="col-lg-7">
							<div className="h-100 qrCodeWrapper d-flex flex-column justify-content-start align-items-center align-items-lg-start pe-lg-12 mt-14 mt-lg-14">
								<div className="ms-auto">
									<h1 className="mb-3 mb-lg-4 text-center text-lg-start">
										宾乐达专用智能APP
									</h1>
									<h2 className="mb-4 mb-lg-6 px-5 px-lg-0 text-center text-lg-start">
										欢迎使用宾乐达专属控制软件，即刻安装 !
									</h2>
									<div className="row ">
										<div className="col-lg-5 d-lg-none">
											<div className="h-100 d-flex d-lg-none justify-content-start align-items-center px-5 px-lg-0">
												<img
													className="section6Phone mx-auto me-lg-auto mt-12 mb-9 mt-lg-0"
													src="./images/appDownload/section6Phone.png"
													alt="section6Phone"
												/>
											</div>
										</div>
										<div className="col-12 col-lg-6">
											<div className="d-flex flex-column align-items-center">
												<div
													// to={{
													//     pathname:
													//         '/support/downloadcenter',
													//     state: {
													//         initialLocation:
													//             '.section4',
													//     },
													// }}
													className="text-center"
													onClick={() =>
														window.open(
															'https://apps.apple.com/cn/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id1611116746',
															'_blank'
														)
													}
													style={{
														cursor: 'pointer',
													}}
												>
													<QRCodeSVG
														size="200"
														includeMargin={true}
														value="https://apps.apple.com/cn/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id1611116746"
													/>
													<div
														style={{
															cursor: 'pointer',
															borderRadius: '4px',
															overflow: 'hidden',
														}}
													>
														<img
															className="w-100"
															src="./images/download_IOS.svg"
															alt="iosQRcode"
														/>
													</div>
												</div>
												<p className="text-grey3 align-lg-self-start mb-0 mt-3">
													*最新版本以上
													<br />
													*目前支援版本iOS以上(含)
												</p>
											</div>
										</div>
										<div className="col-12 col-lg-6">
											<div className="d-flex flex-column align-items-center">
												<div
													// to={{
													//     pathname:
													//         '/support/downloadcenter',
													//     state: {
													//         initialLocation:
													//             '.section4',
													//     },
													// }}
													className="text-center"
													onClick={() =>
														window.open(apkUrl)
													}
													style={{
														cursor: 'pointer',
													}}
												>
													<QRCodeSVG
														size="200"
														includeMargin={true}
														value={apkUrl}
													/>
													<div
														style={{
															cursor: 'pointer',
															borderRadius: '4px',
															overflow: 'hidden',
														}}
													>
														<img
															className="w-100"
															src="./images/download_android.svg"
															alt="anQRcodeRcode"
														/>
													</div>
												</div>
												<p className="text-grey3 align-lg-self-start mb-0 mt-3">
													*最新版本以上
													<br />
													*目前支援版本Android以上(含)
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="h-100 d-none d-lg-flex justify-content-start align-items-center px-5 px-lg-0">
								<img
									className="section6Phone me-auto mt-9 mt-lg-0"
									src="./images/appDownload/section6Phone.png"
									alt="section6Phone"
								/>
							</div>
						</div>
					</section>
				</div>

				<section className="appDownload-section section7 w-100 row justify-content-center px-3 mt-12 mb-6 mt-lg-15 my-lg-13">
					<div className="col-12">
						<p className="text-grey3">说明：</p>
						<ul className="text-grey3 mb-5">
							<li>
								1、不同应用界面及游戏画面下，屏幕刷新率可能不同，请以实际体验为准。
							</li>
							<li>2、数据来源于宾乐达实验室。</li>
							<li>3、本产品非医疗器械，不具有治疗功能。</li>
						</ul>
						<p className="text-grey3">
							以上页面中的产品图片及屏幕内容仅作示意，实物产品效果（包括但不限于外观、颜色、尺寸）和屏幕显示内容（包括但不限于背景、UI、配图）可能略有差异，请以实物为准。
							<br />
							以上页面中的数据为理论值，均来自宾乐达内部实验室，于特定测试环境下所得（请见各项具体说明），实际使用中可能因产品个体差异、软件版本、使用条件和环境因素不同略有不同，请以实际使用的情况为准。
							<br />
							由于产品批次和生产供应因素实时变化，为尽可能提供准确的产品信息、规格参数、产品特性，宾乐达可能实时调整和修订以上页面中的文字表述、图片效果等内容，以求与实际产品性能、规格、指数、零部件等信息相匹配。
							<br />
							如遇确有进行上述修改和调整必要的情形，恕不专门通知。
						</p>
					</div>
				</section>
			</article>
		</div>
	);
}

export default AppDownload;
