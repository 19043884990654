import './supportTop.scss';
import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function SupportTop({ getNewFaq }) {
    const [cookies] = useCookies(['loginUser']);
    const navigate = useNavigate();
    const searchInput = useRef();

    // 跨頁維持input搜尋文字
    useEffect(() => {
        if (searchInput.current) {
            // 假使一開始localStorage無searchText
            if (localStorage.getItem('searchText') === null) {
                localStorage.setItem('searchText', '');
            } else {
                searchInput.current.value = localStorage.getItem('searchText');
            }
        }
    }, []);

    return (
        <section className="supportTop-section w-100 row">
            <div className="col position-relative p-0 text-center text-lg-start">
                <h1 className="text-center">服务支持</h1>
                <p className="text-center mb-lg-3">您需要什么帮助？</p>

                <div className="w-100 mb-11">
                    {window.location.pathname.includes('/support/question') ? (
                        // 常見問題頁面 以外 服務支持頁面
                        <div className="searchWrap position-relative mx-auto">
                            <input
                                type="text"
                                className="rounded-pill w-100 bg-white border-0"
                                placeholder="请输入您想要了解的信息"
                                ref={searchInput}
                                onChange={(e) => {
                                    localStorage.setItem(
                                        'searchText',
                                        e.target.value
                                    );

                                    // delete清空搜尋內容時
                                    if (e.target.value === '') {
                                        getNewFaq();
                                    }

                                    // 清除被標記文字的bg color
                                    document
                                        .querySelectorAll('span.textActive')
                                        .forEach((item) => {
                                            item.classList.remove('textActive');
                                        });
                                }}
                                onKeyPress={(e) =>
                                    e.key === 'Enter' && getNewFaq()
                                }
                            />
                            <img
                                className="position-absolute"
                                src="./images/support/icnSearch.png"
                                alt="icnSearch"
                                onClick={() => {
                                    getNewFaq();
                                }}
                            />
                        </div>
                    ) : (
                        // 常見問題頁面
                        <div className="searchWrap position-relative mx-auto">
                            <input
                                type="text"
                                className="rounded-pill w-100 bg-white border-0"
                                placeholder="请输入您想要了解的信息"
                                ref={searchInput}
                                onChange={(e) => {
                                    localStorage.setItem(
                                        'searchText',
                                        e.target.value
                                    );
                                }}
                                onKeyPress={(e) =>
                                    e.key === 'Enter' &&
                                    navigate('/support/question')
                                }
                            />
                            <img
                                className="position-absolute"
                                src="./images/support/icnSearch.png"
                                alt="icnSearch"
                                onClick={() => {
                                    navigate('/support/question');
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="position-absolute pageBtnWrapper bg-white w-100">
                    <div className="textWrap d-flex flex-wrap justify-content-around justify-content-lg-between mx-auto px-lg-12 py-lg-7">
                        <Link
                            to={{
                                pathname: '/support/question',
                                state: {
                                    initialLocation: '.section2',
                                },
                            }}
                        >
                            <h2 className="mb-2 text-primary text-center">
                                <img
                                    className="icnQuestion h-100 mx-0"
                                    src="./images/support/icnQuestion.png"
                                    alt="icnQuestion"
                                />
                            </h2>
                            <h2 className="mb-0">常见问题</h2>
                        </Link>
                        <div className="border-end border-grey1"></div>
                        <Link
                            to={{
                                pathname: '/support/downloadcenter',
                                state: {
                                    initialLocation: '.section2',
                                },
                            }}
                        >
                            <h2 className="mb-2 text-primary text-center">
                                <img
                                    className="icnDownloadCenter h-100 mx-0"
                                    src="./images/support/icnDownloadCenter.png"
                                    alt="icnDownloadCenter"
                                />
                            </h2>
                            <h2 className="mb-0">下载中心</h2>
                        </Link>
                        <div className="border-end border-grey1 wrapDiv"></div>
                        <div
                            className="productRegisterBtn"
                            onClick={() => {
                                if (
                                    cookies.loginUser &&
                                    cookies.loginUser.status === '0'
                                ) {
                                    navigate('/product');
                                } else {
                                    navigate('/login?search=product');
                                }
                            }}
                        >
                            <h2 className="mb-2 text-primary text-center">
                                <img
                                    className="icnProductRegister h-100 mx-0"
                                    src="./images/support/icnProductRegister.png"
                                    alt="icnProductRegister"
                                />
                            </h2>
                            <h2 className="mb-0">产品注册</h2>
                        </div>
                        <div className="border-end border-grey1"></div>
                        <Link
                            to={{
                                pathname: '/support/contactus',
                                state: {
                                    initialLocation: '.section2',
                                },
                            }}
                        >
                            <h2 className="mb-2 text-primary text-center">
                                <img
                                    className="icnContactUs h-100 mx-0"
                                    src="./images/support/icnContactUs.png"
                                    alt="icnContactUs"
                                />
                            </h2>
                            <h2 className="mb-0">联系我们</h2>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SupportTop;
