import axios from 'axios';

// baseURL是你API的主要Domain，之後發請求時只要填相對路徑就可以了
const instance = axios.create({
	baseURL: '/',
	headers: { 'Content-Type': 'application/json' },
	// timeout: 3000,
});

const sleepRequest = (milliseconds, originalRequest) => {
	console.log('sleepRequest');
	return new Promise((resolve, reject) => {
		setTimeout(() => resolve(instance(originalRequest)), milliseconds);
	});
};

// 此處的instance為我們create的實體
instance.interceptors.response.use(
	function (response) {
		// console.log('response', response);
		// Do something with response data
		const originalRequest = response.config;
		if (response.status === 202) return sleepRequest(100, originalRequest);
		return response;
	},
	function (error) {
		const sleepRequest = (milliseconds, originalRequest) => {
			console.log('sleepRequest');
			return new Promise((resolve, reject) => {
				setTimeout(
					() => resolve(instance(originalRequest)),
					milliseconds
				);
			});
		};

		if (error.response) {
			const { data, status, request } = error.response;
			const jwtStatus = data?.JwtStatus || data?.jwtStatus;
			const { config } = error;
			switch (status) {
				case 401:
					deleteCookies();
					redirectToLoginPage();
					break;
				case 550:
					console.log(error, jwtStatus);

					// jwt已過期且自動重新配發新的jwt -> 1
					// 這是舊版本的jwt（已被其他api更新）-> 2
					if ([1, 2, 3].includes(jwtStatus)) {
						sleepRequest(100, config);
					}

					// jwt驗證錯誤 -> 10
					// refresh token過期（jwt也過期） -> 11
					// refresh token或jwt無法通過資料庫比對 -> 12
					// 無法取得user id  -> 14
					// 無法取得權限資料 -> 15
					if ([10, 11, 12, 14, 15].includes(jwtStatus)) {
						deleteCookies();
						redirectToLoginPage();
					}

					// 錯誤的appsettings資料 -> 20
					if (jwtStatus === 20) {
						console.log(request.responseText);
						showErrorModal('連接資料庫失敗，無法登入');
					}

					// 無法連接資料庫 -> 21
					if (jwtStatus === 21) {
						console.log(request.responseText);
						showErrorModal('連服務器錯誤，無法登入');
					}

					// 其他系統內部錯誤 -> 22
					if (jwtStatus === 22) {
						console.log(request.responseText);
						showErrorModal('其他系統內部錯誤');
					}

					break;
				default:
					console.log(request.responseText);
					showErrorModal('其他系統內部錯誤');
					break;
			}
		}

		function deleteCookies() {
			const cookiesToDelete = [
				'AccessToken',
				'RefreshToken',
				'page',
				'loginUser',
			];
			cookiesToDelete.forEach((cookie) => {
				document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
			});
		}

		function redirectToLoginPage() {
			window.location.href = '/login';
		}

		function showErrorModal(errorMessage) {
			// display the error message in a modal or on the page
			alert(errorMessage);
		}
		if (!window.navigator.onLine) {
			alert('網路出了點問題，請重新連線後重整網頁');
			return;
		}
		return Promise.reject(error);
	}
);

// 此處的instance為我們create的實體
export function axiosApi(url, method, data = null, config) {
	method = method?.toLowerCase();
	switch (method) {
		case 'post':
			return instance.post(url, data, config);
		case 'delete':
			return instance.delete(url, data);
		case 'put':
			return instance.put(url, data);
		case 'patch':
			return instance.patch(url, data);
		default:
			return instance.get(url, { params: data });
	}
}
