import './exploreUpdate.scss';
import RenderIndexList from '../../../RenderIndexList';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

const ExploreUpdate = () => {
    const [isGetOnlineVideoList, setIsGetOnlineVideoList] = useState(false);
    const [onlineVideoList, setOnlineVideoList] = useState('');
    const [filterList, setFilterList] = useState('');
    const [filterItemsObjectList, setFilterItemsObjectList] = useState([]);
    const searchTextRef = useRef('');

    //#region [code to name]

    // TODO : put all temps into utils.js ?
    const pageTitle = '網上展示廳';
    const deleteApiURL = 'web/backstage/onlinevideo';
    const createURL = '/exploreupdate/create';

    const titleList = [
        '顯示順序',
        '型態',
        '語言代碼',
        '影片名稱',
        '發佈時間',
        '結束時間',
        '狀態',
        '',
    ];

    const categoryCodeToName = {
        0: '發球機維護',
        1: '發球機打球影片維護',
        2: '宣傳影片',
    };

    const languageCodeToName = {
        ZH: '簡体中文',
        ZF: '繁体中文',
        EN: '英文',
    };

    const statusCodeToName = {
        0: '停用',
        1: '啟用',
    };

    //#endregion

    //#region [function]

    const getOnlineVideoList = useCallback(() => {
        setIsGetOnlineVideoList(false);

        const gettingOnlineVideoList = async () => {
            await axios
                .get(`web/backstage/onlinevideo`)
                .then((res) => {
                    // console.log(res.data.result);
                    const data = res.data.result;
                    let list = [];

                    // TODO : app display function with undefined
                    data.forEach((el) => {
                        el.guid = el.videoGuid;
                        el.detailUrl = `/exploreupdate/edit/${el.guid}`;

                        el.dataList = [
                            el.displayOrder,
                            `${categoryCodeToName[el.category]}`,
                            `${languageCodeToName[el.languageCode]}`,
                            el.title,
                            el.releaseTime.split('T')[0],
                            el.endTime.split('T')[0],
                            `${statusCodeToName[el.status]}`,
                        ];

                        const filterItemsObject = new Object();
                        filterItemsObject.guid = el.videoGuid;
                        filterItemsObject.displayOrder =
                            el.displayOrder.toString();
                        filterItemsObject.categoryName = `${
                            categoryCodeToName[el.category]
                        }`;
                        filterItemsObject.languageName = `${
                            languageCodeToName[el.languageCode]
                        }`;
                        filterItemsObject.title = el.title;
                        filterItemsObject.releaseTime = el.releaseTime;
                        filterItemsObject.endTime = el.endTime;
                        filterItemsObject.status = `${
                            statusCodeToName[el.status]
                        }`;

                        list = [filterItemsObject, ...list];
                    });

                    setFilterItemsObjectList(list);
                    setOnlineVideoList(data);
                    setFilterList(data);
                    setIsGetOnlineVideoList(true);
                })
                .catch((err) => console.log(err));
        };

        gettingOnlineVideoList();
    }, []);

    //#endregion

    useEffect(() => {
        getOnlineVideoList();
    }, [getOnlineVideoList]);

    return (
        <section id="section-main">
            <div id="container-exploreUpdate">
                <RenderIndexList
                    titleList={titleList}
                    contentList={onlineVideoList}
                    setList={setOnlineVideoList}
                    searchTextRef={searchTextRef}
                    isGetList={isGetOnlineVideoList}
                    setIsGetList={setIsGetOnlineVideoList}
                    getList={getOnlineVideoList}
                    filterList={filterList}
                    setFilterList={setFilterList}
                    pageTitle={pageTitle}
                    filterItemsObjectList={filterItemsObjectList}
                    deleteApiURL={deleteApiURL}
                    createURL={createURL}
                />
            </div>
        </section>
    );
};

export default ExploreUpdate;
