import './authUpdateEdit.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
// import axios from 'axios';
import Swal from 'sweetalert2';

import { axiosApi } from '../../../api';

// 此權限修改頁面為 1.成員 與 2.權限 修改共用
function AuthUpdateEdit() {
    const navigate = useNavigate();
    const location = useLocation();

    const [userGuid, setUserGuid] = useState('');

    // console.log(location.pathname.split('/').includes('memberPage'));
    // console.log(
    // 	location.search.slice(1).split('&')[0].split('authorizationGuid=')[1]
    // );
    // console.log(
    // 	decodeURI(
    // 		location.search.slice(1).split('&')[1].split('authGroupName=')[1]
    // 	)
    // );

    /*	1.成員修改
		1-1 獲取authorizationGuid === memberPageEditAuthorizationGuid
		1-2 獲取此權限分組-成員資訊
	*/

    // 1-1 獲取authorizationGuid === memberPageEditAuthorizationGuid
    const memberPageEditAuthorizationGuid = location.search
        .slice(1)
        .split('&')[0]
        .split('authorizationGuid=')[1];

    // 1-2 獲取此權限分組-成員資訊
    const [pageAuthMemberList, setPageAuthMemberList] = useState('');

    const getPageAuthMemberList = useCallback(() => {
        memberPageEditAuthorizationGuid &&
            axiosApi(
                `web/SysAuthorization/authorizationUserList?guid=${memberPageEditAuthorizationGuid}`
            )
                .then((res) => {
                    // console.log(res.data.result);
                    setPageAuthMemberList(res.data.result);
                })
                .catch((err) => console.log(err));

        // const gettingPageAuthMemberList = async () => {
        //     await axios
        //         .get(
        //             `web/SysAuthorization/authorizationUserList?guid=${memberPageEditAuthorizationGuid}`
        //         )
        //         .then((res) => {
        //             // console.log(res.data.result);
        //             setPageAuthMemberList(res.data.result);
        //         })
        //         .catch((err) => console.log(err));
        // };

        // memberPageEditAuthorizationGuid && gettingPageAuthMemberList();
    }, [memberPageEditAuthorizationGuid]);

    /*	2.權限修改
		2-1 authorizationGuid === paramsGuid.authorizationGuid
		2-2 獲取所有"需要授權的頁面"資料表
		2-3 已選取的權限
	*/

    // 2-1 authorizationGuid
    const paramsGuid = useParams('authorizationGuid');
    // console.log(paramsGuid);

    // 2-2 獲取所有"需要授權的頁面"資料表
    const [pageAuthList, setPageAuthList] = useState('');

    const getPageAuthList = useCallback(() => {
        axiosApi(`web/SysAuthorization/pageAuthorization`)
            .then((res) => {
                console.log(res.data);
                setPageAuthList(res.data.result);
            })
            .catch((err) => console.log(err));

        // const gettingPageAuthList = async () => {
        //     await axios
        //         .get(`web/SysAuthorization/pageAuthorization`)
        //         .then((res) => {
        //             setPageAuthList(res.data.result);
        //         })
        //         .catch((err) => console.log(err));
        // };

        // gettingPageAuthList();
    }, []);

    //	2-3 已選取的權限
    const [authCodeCheckObj, setAuthCodeCheckObj] = useState('');
    const [authCodeCheckSubmit, setAuthCodeCheckSubmit] = useState([]);

    const getPageAuthPermissionList = useCallback(() => {
        paramsGuid.authorizationGuid &&
            axiosApi(
                `web/SysAuthorization/authorizationPermission/${paramsGuid.authorizationGuid}`
            )
                .then((res) => {
                    // 2-3-1 checkbox對應資料(obj)
                    const checkObj = {};
                    res.data.result.forEach((el) => {
                        checkObj[el.transactionCode] = true;
                    });
                    setAuthCodeCheckObj(checkObj);

                    // 2-3-2 是否全選判斷
                    // TODO: reRender跟不上，所以時好時壞
                    isCheckboxAll('authCheckbox');

                    // 2-3-3 submit回傳資料(transactionCodeList)
                    let transactionCodeList = [];
                    Object.keys(checkObj).forEach((key) => {
                        // console.log(key);
                        // console.log(checkObj[key]);
                        checkObj[key] && transactionCodeList.push(key);
                    });
                    setAuthCodeCheckSubmit(transactionCodeList);
                })
                .catch((err) => console.log(err));

        // const gettingPageAuthPermissionList = async () => {
        //     await axios
        //         .get(
        //             `web/SysAuthorization/authorizationPermission/${paramsGuid.authorizationGuid}`
        //         )
        //         .then((res) => {
        //             // 2-3-1 checkbox對應資料(obj)
        //             const checkObj = {};
        //             res.data.result.forEach((el) => {
        //                 checkObj[el.transactionCode] = true;
        //             });
        //             setAuthCodeCheckObj(checkObj);

        //             // 2-3-2 是否全選判斷
        //             // TODO: reRender跟不上，所以時好時壞
        //             isCheckboxAll('authCheckbox');

        //             // 2-3-3 submit回傳資料(transactionCodeList)
        //             let transactionCodeList = [];
        //             Object.keys(checkObj).forEach((key) => {
        //                 // console.log(key);
        //                 // console.log(checkObj[key]);
        //                 checkObj[key] && transactionCodeList.push(key);
        //             });
        //             setAuthCodeCheckSubmit(transactionCodeList);
        //         })
        //         .catch((err) => console.log(err));
        // };

        // paramsGuid.authorizationGuid && gettingPageAuthPermissionList();
    }, [paramsGuid.authorizationGuid]);

    useEffect(() => {
        getPageAuthMemberList(); // 成員 1-2
        getPageAuthList(); // 權限 2-2
        getPageAuthPermissionList(); //權限 2-3
    }, [getPageAuthMemberList, getPageAuthList, getPageAuthPermissionList]);

    // console.log('獲取所有 需要授權的頁面 資料表', pageAuthList);
    // console.log('checkbox對應資料', authCodeCheckObj);
    // console.log('submit回傳資料', authCodeCheckSubmit);

    // 3.判斷表格最左上角，全選checkbox是否要勾選?
    const isCheckboxAll = (checkboxName) => {
        let isNoneChecked = 0;
        let isAllChecked = 1;
        document.querySelectorAll(`.${checkboxName}Item`).forEach((el) => {
            // console.log(el.checked);
            isNoneChecked = el.checked ? isNoneChecked + 1 : isNoneChecked + 0;
            isAllChecked = el.checked ? isAllChecked * 1 : isAllChecked * 0;
        });
        // console.log(isAllChecked);
        if (isNoneChecked === 0) {
            document.querySelector(`.${checkboxName}All`).indeterminate = false;
            document.querySelector(`.${checkboxName}All`).checked = false;
        } else if (isAllChecked === 1) {
            document.querySelector(`.${checkboxName}All`).indeterminate = false;
            document.querySelector(`.${checkboxName}All`).checked = true;
        } else {
            document.querySelector(`.${checkboxName}All`).indeterminate = true;
            document.querySelector(`.${checkboxName}All`).checked = false;
        }
    };

    /*	4.編輯權限頁面-checkbox
		4-1 表格最左上角，全選checkbox onChange控制
		4-2 編輯權限頁面-checkbox onChange控制
	*/

    // 4-1 編輯權限頁面-表格最左上角，全選checkbox onChange控制
    let changeAuthCodeCheckAll = (e) => {
        let allCheckedCodeList = [];

        document.querySelectorAll('.authCheckboxItem').forEach((el) => {
            // console.log(el.checked);
            // console.log(el);
            // checkItem全選/全不選
            e.target.checked ? (el.checked = true) : (el.checked = false);

            allCheckedCodeList.push(el.value);
        });

        const checkObj = {};
        if (e.target.checked) {
            // checkbox對應資料
            allCheckedCodeList.forEach((el) => {
                checkObj[el] = true;
            });
            setAuthCodeCheckObj(checkObj);

            // submit回傳資料
            setAuthCodeCheckSubmit(allCheckedCodeList);
        } else {
            // checkbox對應資料
            allCheckedCodeList.forEach((el) => {
                checkObj[el] = false;
            });
            setAuthCodeCheckObj(checkObj);

            // submit回傳資料
            setAuthCodeCheckSubmit([]);
        }
    };

    // 4-2 編輯權限頁面-checkbox onChange控制
    const changeAuthCodeCheckObj = (e) => {
        const key = e.target.value;

        // checkbox對應資料
        let checkObj = {
            ...authCodeCheckObj,
            [key]: !authCodeCheckObj[key],
        };
        setAuthCodeCheckObj(checkObj);

        // 是否全選判斷
        isCheckboxAll('authCheckbox');

        // submit回傳資料
        let transactionCodeList = [];
        Object.keys(checkObj).forEach((key) => {
            // console.log(key);
            // console.log(checkObj[key]);
            checkObj[key] && transactionCodeList.push(key);
        });
        setAuthCodeCheckSubmit(transactionCodeList);
    };

    /*	5.编辑群组成员-checkbox
		5-1 表格最左上角，全選checkbox onChange控制
		5-2 編輯權限頁面-checkbox onChange控制
		5-3 刪除成員
	*/
    const [memberDeleteCheckObj, setMemberDeleteCheckObj] = useState({});
    const [memberDeleteCheckSubmit, setMemberDeleteCheckSubmit] = useState([]);

    // 5-1 编辑群组成员-表格最左上角，全選checkbox onChange控制
    let changeMemberDeleteCheckAll = (e) => {
        let allCheckedCodeList = [];

        document.querySelectorAll('.memberDeleteCheckboxItem').forEach((el) => {
            // console.log(el.checked);
            // console.log(el);
            // checkItem全選/全不選
            e.target.checked ? (el.checked = true) : (el.checked = false);

            allCheckedCodeList.push(el.value);
        });

        const checkObj = {};
        if (e.target.checked) {
            // checkbox對應資料
            allCheckedCodeList.forEach((el) => {
                checkObj[el] = true;
            });
            setMemberDeleteCheckObj(checkObj);

            // submit回傳資料
            setMemberDeleteCheckSubmit(allCheckedCodeList);
        } else {
            // checkbox對應資料
            allCheckedCodeList.forEach((el) => {
                checkObj[el] = false;
            });
            setMemberDeleteCheckObj(checkObj);

            // submit回傳資料
            setMemberDeleteCheckSubmit([]);
        }
    };

    // 5-2 编辑群组成员-checkbox onChange控制
    const changeMemberDeleteCheckObj = (e) => {
        const key = e.target.value;

        // checkbox對應資料
        let checkObj = {
            ...memberDeleteCheckObj,
            [key]: !memberDeleteCheckObj[key],
        };
        setMemberDeleteCheckObj(checkObj);

        // 是否全選判斷
        isCheckboxAll('memberDeleteCheckbox');

        // submit回傳資料
        let transactionCodeList = [];
        Object.keys(checkObj).forEach((key) => {
            // console.log(key);
            // console.log(checkObj[key]);
            checkObj[key] && transactionCodeList.push(key);
        });
        setMemberDeleteCheckSubmit(transactionCodeList);
    };

    console.log(memberDeleteCheckObj);
    console.log(memberDeleteCheckSubmit);

    // 5-3 刪除成員
    const handleDeleteMember = async () => {
        memberDeleteCheckSubmit.length &&
            (await Swal.fire({
                html: `<h4>確定要刪除?</h4>`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: '取消',
                showConfirmButton: true,
                confirmButtonText: '確定',
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((res) => {
                if (res.dismiss === 'cancel') {
                    console.log('cancelled');
                    return;
                } else {
                    console.log('confirmed delete');

                    // axios delete 如果要用body傳資料，需要外面多包一層data
                    // const request_config = {
                    //     data: {
                    //         authorizationUserGuid: memberDeleteCheckSubmit,
                    //     },
                    // };

                    const url = `web/SysAuthorization/authorizationUser?authorizationUserGuid=${memberDeleteCheckSubmit}`;

                    axiosApi(url, 'delete')
                        .then(() => {
                            getPageAuthMemberList();

                            // 清空checkbox
                            setMemberDeleteCheckObj({});
                            setMemberDeleteCheckSubmit([]);
                            isCheckboxAll('authCheckbox');
                        })
                        .catch((err) => console.log(err));
                }
            }));
    };

    // 6.儲存送出
    const handleSubmit = async () => {
        if (location.pathname.split('/').includes('memberPage')) {
            // memberPage
        } else {
            // authPage
            axiosApi(
                `web/SysAuthorization/authorizationPermission/${paramsGuid.authorizationGuid}`,
                'put',
                {
                    transactionCode: authCodeCheckSubmit,
                }
            )
                .then(() => {
                    navigate(`/authUpdate`);
                })
                .catch((err) => console.log(err));

            // await axios
            //     .put(
            //         `web/SysAuthorization/authorizationPermission/${paramsGuid.authorizationGuid}`,
            //         {
            //             transactionCode: authCodeCheckSubmit,
            //         }
            //     )
            //     .then(() => {
            //         navigate(`/authUpdate`);
            //     })
            //     .catch((err) => console.log(err));
        }
    };

    // 7.表格排序
    function sortTable(n, alwaysDesc) {
        //(必填)n:表格欄位(0,1,2...)
        //(選填)alwaysDesc:切換不同欄位排序時，永遠為desc
        let table,
            rows,
            switching,
            i,
            x,
            y,
            shouldSwitch,
            dir,
            switchCount = 0,
            isFirstTimeSort = false;

        //sort which table?
        table = document.querySelector('.table');

        switching = true;

        //若欄位在sort前就是asc排列，switchCount將為0，將造成類似double click的誤判
        //因此記錄isFirstTimeSort，透過該th span是否已經有排序符號來判別
        if (table.rows[0].querySelectorAll('th span')[n].innerHTML === '') {
            isFirstTimeSort = true;
        }

        //Set the sorting direction to ascending:(初始排序方向)
        dir = 'desc';
        //清空排序圖示
        table.rows[0]
            .querySelectorAll('th span')
            .forEach((i) => (i.innerHTML = ''));
        //加入desc排序圖示
        table.rows[0].querySelectorAll('th span')[n].innerHTML = `
					<svg className="align-bottom ml-1" viewBox="0 0 17 20" width="17" height="20">
                        <text id="_" fontFamily="NotoSans-Bold, Noto Sans" fontSize="14" fontWeight="700" fill="var(--grey4)" transform="matrix(1 0 0 -1 0 20)" data-name=" ▲"><tspan x="0" y="15"> </tspan><tspan fontFamily="SegoeUI-Bold, Segoe UI" y="15">▲</tspan></text>
                    </svg>
        `;

        /*Make a loop that will continue until no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the first, which contains table headers):*/
            for (i = 1; i < rows.length - 1; i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
            one from current row and one from the next:*/
                x = rows[i].querySelectorAll('td')[n];
                y = rows[i + 1].querySelectorAll('td')[n];
                /*check if the two rows should switch place,
            based on the direction, asc or desc:*/
                if (dir === 'asc') {
                    if (
                        x.innerHTML
                            .toLowerCase()
                            .localeCompare(y.innerHTML.toLowerCase()) === 1
                    ) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir === 'desc') {
                    if (
                        x.innerHTML
                            .toLowerCase()
                            .localeCompare(y.innerHTML.toLowerCase()) === -1
                    ) {
                        //if so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }

            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
            and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                //Each time a switch is done, increase this count by 1:
                switchCount++;
            } else {
                //在切換不同分組時，邏輯判斷永遠為desc排序
                if (alwaysDesc === 'desc') {
                    //console.log("desc");
                }
                //第一次點擊時，已經desc，完成排序
                else if (
                    switchCount === 0 &&
                    dir === 'desc' &&
                    isFirstTimeSort === true
                ) {
                    //console.log("FirstTimeSort");
                }
                //desc後，第二次點擊轉換為asc
                /*If no switching has been done AND the direction is "desc",
            set the direction to "asc" and run the while loop again.*/
                else if (switchCount === 0 && dir === 'desc') {
                    dir = 'asc';
                    switching = true;
                    table.rows[0].querySelectorAll('th span')[n].innerHTML = `
                       <svg className="align-bottom ml-1" viewBox="0 0 18 20" width="18" height="20">
                    <text id="_" fontFamily="NotoSans-Bold, Noto Sans" fontSize="14" fontWeight="700" fill="var(--grey4)" dataName=" ▲"><tspan x="0" y="15"> </tspan><tspan fontFamily="SegoeUI-Bold, Segoe UI" y="15">▲</tspan></text>
            		</svg>
                `;
                }
            }
        }
    }

    return (
        <section id="section-main">
            <div id="container-authUpdateEdit" className="container">
                <div className="d-flex align-items-center mb-3">
                    <h5 className="fw-bold mb-0">权限设定</h5>

                    {location.pathname.split('/').includes('memberPage') ? (
                        <div className="submitBtnWrapper ms-auto">
                            <button
                                className="btn btn-outline-primary me-2"
                                onClick={() => navigate(`/authUpdate`)}
                            >
                                返回
                            </button>
                        </div>
                    ) : (
                        <div className="submitBtnWrapper ms-auto">
                            <button
                                className="btn btn-outline-primary me-2"
                                onClick={() => navigate(`/authUpdate`)}
                            >
                                取消
                            </button>
                            <button
                                className="btn btn-primary text-white"
                                onClick={() => handleSubmit()}
                            >
                                儲存
                            </button>
                        </div>
                    )}
                </div>

                <div className="authUpdateWrapper row">
                    {location.pathname.split('/').includes('memberPage') ? (
                        <div className={`memberPageWrapper col-12 px-2`}>
                            <div className="bg-white px-4 py-3 rounded-4">
                                <div className="row pb-4 border-bottom">
                                    <div className="col-4">
                                        <h6 className="fw-bold">
                                            编辑群组成员
                                        </h6>
                                        <label
                                            htmlFor="name"
                                            className="text-grey4 fontSize75Rem mb-2"
                                        >
                                            群组名称
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={decodeURI(
                                                location.search
                                                    .slice(1)
                                                    .split('&')[1]
                                                    .split('authGroupName=')[1]
                                            )}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="row pb-4 mt-3">
                                    <div className="col-8">
                                        <div className="buttonWrapper mb-3">
                                            <button
                                                className="btn btn-primary text-white addMember p-0 me-2"
                                                onClick={() =>
                                                    navigate(
                                                        `/authUpdate/memberPage/create?authorizationGuid=${memberPageEditAuthorizationGuid}&authGroupName=${decodeURI(
                                                            location.search
                                                                .slice(1)
                                                                .split('&')[1]
                                                                .split(
                                                                    'authGroupName='
                                                                )[1]
                                                        )}`
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="me-2 mb-1"
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                >
                                                    <path
                                                        id="_Color"
                                                        data-name=" ↳Color"
                                                        d="M12,6.857H6.857V12H5.143V6.857H0V5.143H5.143V0H6.857V5.143H12Z"
                                                        fill="#fff"
                                                    />
                                                </svg>
                                                <span>新增成员</span>
                                            </button>
                                            <button
                                                className="btn btn-outline-danger removeMember"
                                                onClick={() =>
                                                    handleDeleteMember()
                                                }
                                            >
                                                刪除
                                            </button>
                                        </div>
                                        <div className="tableWrapper">
                                            <table
                                                className="table table-hover text-center"
                                                id="table-memberList"
                                            >
                                                <thead>
                                                    <tr className="bg-background">
                                                        <th
                                                            scope="col"
                                                            className="text-grey4 border-0 p-0"
                                                        >
                                                            <label
                                                                htmlFor="memberDeleteCheckboxAll"
                                                                className="w-100 h-100 px-2 py-3"
                                                            >
                                                                <input
                                                                    id="memberDeleteCheckboxAll"
                                                                    className="memberDeleteCheckboxAll"
                                                                    type="checkbox"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        changeMemberDeleteCheckAll(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </label>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-start text-grey4 border-0"
                                                            onClick={() =>
                                                                sortTable(0)
                                                            }
                                                        >
                                                            用户名称
                                                            <span></span>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-start text-grey4 border-0"
                                                            onClick={() =>
                                                                sortTable(1)
                                                            }
                                                        >
                                                            手机号码
                                                            <span></span>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-start text-grey4 border-0"
                                                            onClick={() =>
                                                                sortTable(2)
                                                            }
                                                        >
                                                            邮箱<span></span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="border-top-0">
                                                    {pageAuthMemberList ? (
                                                        pageAuthMemberList.map(
                                                            (list, index) => {
                                                                return (
                                                                    <tr
                                                                        key={
                                                                            list.authorizationUserGuid
                                                                        }
                                                                        className={`${
                                                                            list.userGuid ===
                                                                            userGuid
                                                                                ? 'active'
                                                                                : ''
                                                                        }`}
                                                                        onClick={() =>
                                                                            setUserGuid(
                                                                                list.userGuid
                                                                            )
                                                                        }
                                                                    >
                                                                        <td className="text-center memberDeleteCheckboxWrapper p-0">
                                                                            <label
                                                                                htmlFor={
                                                                                    list.authorizationUserGuid
                                                                                }
                                                                                className="w-100 h-100 px-2 py-3"
                                                                            >
                                                                                <input
                                                                                    id={
                                                                                        list.authorizationUserGuid
                                                                                    }
                                                                                    className="memberDeleteCheckboxItem"
                                                                                    type="checkbox"
                                                                                    // checked={
                                                                                    // 	memberDeleteCheckObj[
                                                                                    // 		list
                                                                                    // 			.authorizationUserGuid
                                                                                    // 	]
                                                                                    // }
                                                                                    value={
                                                                                        list.authorizationUserGuid
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        changeMemberDeleteCheckObj(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </label>
                                                                        </td>
                                                                        <td className="text-start">
                                                                            {
                                                                                list.fullname
                                                                            }
                                                                        </td>
                                                                        <td className="text-start">
                                                                            {
                                                                                list.phone1
                                                                            }
                                                                        </td>
                                                                        <td className="text-start">
                                                                            {
                                                                                list.email1
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )
                                                    ) : (
                                                        <tr></tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="authPageWrapper col-12 px-2">
                            <div className="bg-white px-4 py-3 rounded-4">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bold mb-4">
                                            编辑权限
                                        </h6>
                                        <table className="table table-hover text-center">
                                            <thead>
                                                <tr className="bg-background">
                                                    <th
                                                        scope="col"
                                                        className="text-grey4 border-0 p-0"
                                                    >
                                                        <label
                                                            htmlFor="authCheckboxAll"
                                                            className="w-100 h-100 px-2 py-3"
                                                        >
                                                            <input
                                                                id="authCheckboxAll"
                                                                className="authCheckboxAll"
                                                                type="checkbox"
                                                                onClick={(e) =>
                                                                    changeAuthCodeCheckAll(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </label>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="text-start text-grey4 border-0"
                                                        onClick={() =>
                                                            sortTable(0)
                                                        }
                                                    >
                                                        网站功能权限
                                                        <span></span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="border-top-0">
                                                {pageAuthList ? (
                                                    pageAuthList.map((list) => {
                                                        return (
                                                            <tr
                                                                key={
                                                                    list.pageGuid
                                                                }
                                                            >
                                                                <td className="p-0">
                                                                    <label
                                                                        htmlFor={
                                                                            list.transactionCode
                                                                        }
                                                                        className="w-100 h-100 px-2 py-3"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            id={
                                                                                list.transactionCode
                                                                            }
                                                                            className="authCheckboxItem"
                                                                            checked={
                                                                                authCodeCheckObj[
                                                                                    list
                                                                                        .transactionCode
                                                                                ]
                                                                            }
                                                                            value={
                                                                                list.transactionCode
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                changeAuthCodeCheckObj(
                                                                                    e
                                                                                )
                                                                            }
                                                                        />
                                                                    </label>
                                                                </td>
                                                                <td className="text-start">
                                                                    {list.name}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr></tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default AuthUpdateEdit;
