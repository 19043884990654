import './questionCategoryUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';

const QuestionCategoryUpdateDetail = () => {
    const navigate = useNavigate();

    const [categoryCode, setCategoryCode] = useState('');
    const [displayOrder, setDisplayOrder] = useState('');
    const [nameZH, setNameZH] = useState('');
    const [nameZF, setNameZF] = useState('');
    const [nameEN, setNameEN] = useState('');
    const [nameJA, setNameJA] = useState('');
    const [status, setStatus] = useState('');
    const [rowStamp, setRowStamp] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const paramsGuid = useParams('categoryCode');
    const isEditPage = Object.keys(paramsGuid).length === 0 ? false : true;
    // console.log(paramsGuid);
    // console.log(isEditPage);

    const checkCategoryCode = async (code) => {
        code &&
            (await axios
                .get(`web/backstage/verifyqacategorycode/${code}`)
                .then((res) => {
                    if (res.data === false && code !== '') {
                        Swal.fire({
                            icon: 'error',
                            title: `型態代碼${code}重複，請重新輸入`,
                        });
                        setCategoryCode('');
                    }
                })
                .catch((err) => console.log(err)));
    };

    //edit getInfo
    useEffect(() => {
        if (isEditPage)
            (async () => {
                await axios
                    .get(`web/backstage/qacategory/${paramsGuid.categoryCode}`)
                    .then((res) => {
                        const QACategoryUpdateObj = res.data.result;
                        console.log(QACategoryUpdateObj);

                        setCategoryCode(QACategoryUpdateObj.categoryCode);
                        setDisplayOrder(QACategoryUpdateObj.displayOrder);
                        setStatus(QACategoryUpdateObj.status);
                        setNameZH(QACategoryUpdateObj.name_ZH);
                        setNameZF(QACategoryUpdateObj.name_ZF);
                        setNameEN(QACategoryUpdateObj.name_EN);
                        setNameJA(QACategoryUpdateObj.name_JA);
                        setRowStamp(QACategoryUpdateObj.rowStamp);
                    })
                    .catch((err) => console.log(err));
            })();
    }, [paramsGuid]);

    // edit delete
    const handleDeleteItem = async () => {
        await Swal.fire({
            html: `<h4>確定要刪除?</h4>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: '取消',
            showConfirmButton: true,
            confirmButtonText: '確定',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((res) => {
            if (res.dismiss === 'cancel') {
                console.log('cancelled');
                return;
            } else {
                console.log('confirmed');
                axios
                    .delete(
                        `web/backstage/qacategory/${paramsGuid.categoryCode}`
                    )
                    .then(() => {
                        navigate('/questioncategoryupdate');
                    })
                    .catch((err) => console.log(err));
            }
        });
    };

    // edit submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);

        if (isEditPage) {
            //edit
            setIsLoading(true);

            await axios
                .put(
                    `web/backstage/qacategory/${paramsGuid.categoryCode}`,
                    form
                )
                .then((res) => {
                    console.log(res.data);
                    setIsLoading(false);
                    navigate('/questioncategoryupdate');
                })
                .catch((err) => {
                    setIsLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: '網路不穩，儲存失敗',
                        text: '請再次儲存。',
                    });
                    console.log(err);
                });
        } else {
            //create
            setIsLoading(true);

            await axios
                .post(`web/backstage/qacategory`, form)
                .then((res) => {
                    console.log(res.data);
                    setIsLoading(false);
                    navigate('/questioncategoryupdate');
                })
                .catch((err) => {
                    setIsLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: '網路不穩，儲存失敗',
                        text: '請再次儲存。',
                    });
                    console.log(err);
                });
        }
    };

    return (
        <>
            <LoadingAnimate isLoading={isLoading} />

            <section id="section-main">
                <form
                    id="container-questionUpdateDetail"
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex">
                        <h5 className="text-grey4 fw-bold mb-4">
                            常見問題型態
                        </h5>
                        <button
                            className={`btnDelete btn btn-outline-danger px-4 ms-auto me-2 ${
                                !isEditPage && 'd-none'
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                        >
                            刪除
                        </button>
                        <button
                            className={`btnCancel btn btn-outline-primary px-4 me-2 ${
                                !isEditPage && 'ms-auto'
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/questioncategoryupdate');
                            }}
                        >
                            取消
                        </button>
                        <button className="btn btn-primary text-white px-4">
                            儲存
                        </button>
                    </div>

                    <div className="manageFileWrapper bg-white p-4 rounded-4 mb-3">
                        <input
                            id="rowStamp"
                            name="rowStamp"
                            className="d-none"
                            defaultValue={rowStamp}
                        />
                        <h6 className="text-grey4 fw-bold mb-3">檔案管理</h6>
                        <div className="row mb-3">
                            <div className="col-6">
                                <label
                                    htmlFor="name_ZH"
                                    className="fontSize75Rem mb-2"
                                >
                                    中文名稱(繁)
                                </label>
                                <input
                                    type="text"
                                    id="name_ZH"
                                    name="name_ZH"
                                    className="form-control"
                                    value={nameZH || ''}
                                    onChange={(e) => setNameZH(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label
                                    htmlFor="name_ZF"
                                    className="fontSize75Rem mb-2"
                                >
                                    中文名稱(簡)
                                </label>
                                <input
                                    type="text"
                                    id="name_ZF"
                                    name="name_ZF"
                                    className="form-control mb-3"
                                    value={nameZF || ''}
                                    onChange={(e) => setNameZF(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label
                                    htmlFor="name_EN"
                                    className="fontSize75Rem mb-2"
                                >
                                    英文名稱
                                </label>
                                <input
                                    type="text"
                                    id="name_EN"
                                    name="name_EN"
                                    className="form-control mb-3"
                                    value={nameEN || ''}
                                    onChange={(e) => setNameEN(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label
                                    htmlFor="name_JA"
                                    className="fontSize75Rem mb-2"
                                >
                                    日文名稱
                                </label>
                                <input
                                    type="text"
                                    id="name_JA"
                                    name="name_JA"
                                    className="form-control mb-3"
                                    value={nameJA || ''}
                                    onChange={(e) => setNameJA(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="infoWrapper bg-white p-4 rounded-4">
                        <div className="infoTop d-flex align-items-center mb-3 ">
                            <h6 className="text-grey4 fw-bold mb-0">
                                檔案資訊
                            </h6>
                        </div>
                        <div className="infoBottom row">
                            <div className="col-4">
                                <label
                                    htmlFor="displayOrder"
                                    className="fontSize75Rem mb-2"
                                >
                                    顯示順序
                                </label>
                                <input
                                    type="number"
                                    id="displayOrder"
                                    name="displayOrder"
                                    className="form-control mb-3"
                                    value={displayOrder || ''}
                                    onChange={(e) =>
                                        setDisplayOrder(e.target.value)
                                    }
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="categoryCode"
                                    className="fontSize75Rem mb-2"
                                >
                                    型態代碼
                                </label>
                                <input
                                    type="text"
                                    id="categoryCode"
                                    name="categoryCode"
                                    className={`form-control mb-3 ${
                                        isEditPage && 'bg-grey1'
                                    }`}
                                    value={categoryCode || ''}
                                    onChange={(e) =>
                                        setCategoryCode(e.target.value)
                                    }
                                    onBlur={(e) =>
                                        checkCategoryCode(e.target.value)
                                    }
                                    disabled={isEditPage}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="status"
                                    className="fontSize75Rem mb-2"
                                >
                                    狀態
                                </label>
                                <select
                                    className="form-control mb-3"
                                    name="status"
                                    id="status"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status || ''}
                                    required
                                >
                                    <option value="" disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value="0">停用</option>
                                    <option value="1">啟用</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default QuestionCategoryUpdateDetail;
