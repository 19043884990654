import NavMenu from '../navMenu/NavMenu';
import FooterApp from '../footerApp/FooterApp';
import React from 'react';

function Layout({ children }) {
	return (
		<>
			<NavMenu />
			<main id="main-app">{children}</main>
			<FooterApp />
		</>
	);
}

export default Layout;
