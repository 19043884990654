import './login.scss';
import React, {
	useRef,
	useState,
	useEffect,
	useCallback,
	useContext,
} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Visibility, VisibilityOff, Sync } from '@material-ui/icons';
import Captcha from 'react-captcha-code';
import { randomNum, originalCharacter } from '../../../components/utils/utils';
import { AccountContext } from '../../../App';
import { axiosApi } from '../../../api';

function Login() {
	const [cookies, setCookie] = useCookies(['loginAuto']);

	const [captchaUserInput, setCaptchaUserInput] = useState('');
	const [captcha, setCaptcha] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [loginStyle, setLoginStyle] = useState('emailID');

	const emailID = useRef();
	const countryCode = useRef();
	const phoneCode = useRef();
	const password = useRef();

	const navigate = useNavigate();
	const location = useLocation();

	const accountContext = useContext(AccountContext);
	const { accountInfo, getAccountInfo, homePageIndex, setHomePageIndex } =
		accountContext;

	// 即時檢查帳號
	const emailReg =
		/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
	const IDReg = /^[A-Za-z0-9]{6,15}$/;
	const phoneCodeReg = /^[0-9]{6,15}$/;

	const checkUserAccount = () => {
		if (emailID.current.value) {
			if (emailID.current.value.includes('@')) {
				if (!emailReg.test(emailID.current.value)) {
					document.querySelector('#emailID-info').textContent =
						'格式错误 请重新输入';
					document.querySelector('#emailID').style.borderColor =
						'#CC3300';
					return 1;
				} else {
					document.querySelector('#emailID-info').textContent = '';
					document.querySelector('#emailID').style.borderColor =
						'#ced4da';
					return 0;
				}
			} else {
				if (!IDReg.test(emailID.current.value)) {
					document.querySelector('#emailID-info').textContent =
						'格式错误 请重新输入';
					document.querySelector('#emailID').style.borderColor =
						'#CC3300';
					return 1;
				} else {
					document.querySelector('#emailID-info').textContent = '';
					document.querySelector('#emailID').style.borderColor =
						'#ced4da';
					return 0;
				}
			}
		} else if (phoneCode.current.value) {
			if (!phoneCodeReg.test(phoneCode.current.value)) {
				document.querySelector('#phoneCode-info').textContent =
					'格式错误 请重新输入';
				document.querySelector('#phoneCode').style.borderColor =
					'#CC3300';
				return 1;
			} else {
				document.querySelector('#phoneCode-info').textContent = '';
				document.querySelector('#phoneCode').style.borderColor =
					'#ced4da';
				return 0;
			}
		}
	};

	// 圖形驗證碼-刷新驗證碼
	//console.log("captchaUserInput: ", captchaUserInput);
	//console.log("captcha: ", captcha);

	const handleCaptchaRefresh = useCallback(() => {
		let str = '';
		for (let i = 0; i < 4; i++) {
			const temp =
				originalCharacter[randomNum(0, originalCharacter.length - 1)];
			str = `${str}${temp}`;
		}
		setCaptcha(str);
		setCaptchaUserInput('');
	}, []);

	useEffect(() => {
		handleCaptchaRefresh();
	}, [handleCaptchaRefresh]);

	// 圖形驗證碼-驗證圖形驗證碼
	const checkCaptcha = () => {
		if (captchaUserInput !== captcha) {
			document.querySelector('#captcha-info').textContent = '验证码错误';
			document.querySelector('#captcha-info').style.borderColor =
				'#CC3300';
			handleCaptchaRefresh();
			return 1;
		} else {
			document.querySelector('#captcha-info').textContent = '';
			document.querySelector('#captcha').style.borderColor = '#ced4da';
			return 0;
		}
	};

	// 密碼顯示切換
	function switchPassword() {
		setShowPassword(!showPassword);
	}

	// 自動登入radio設定
	let isLoginAuto = cookies.loginAuto === 'true' ? true : false;

	const handleLoginAuto = () => {
		isLoginAuto = !isLoginAuto;
		setCookie('loginAuto', isLoginAuto, { path: '/' });
	};
	// console.log('isLoginAuto', isLoginAuto);
	// console.log('cookies.loginAuto', cookies.loginAuto);

	// 切換登入帳號類型
	function loginChange(e) {
		e.preventDefault();

		if (loginStyle === 'emailID') {
			setLoginStyle('phone');
			emailID.current.value = '';
			document.querySelector('#phoneCode-info').textContent = '';
			document.querySelector('#phoneCode').style.borderColor = '#ced4da';
		} else if (loginStyle === 'phone') {
			setLoginStyle('emailID');
			phoneCode.current.value = '';
			document.querySelector('#emailID-info').textContent = '';
			document.querySelector('#emailID').style.borderColor = '#ced4da';
		}
	}

	// 登入事件
	const handleSubmit = async (e) => {
		e.preventDefault();

		const emailIDPost = {
			accountType: '0',
			emailOrID: emailID.current.value,
			password: password.current.value,
		};

		const phonePost = {
			accountType: '1',
			countryCode: countryCode.current.value,
			phoneCode: phoneCode.current.value,
			password: password.current.value,
		};

		try {
			//空欄位 錯誤訊息顯示
			if (emailID.current.value === '') {
				document.querySelector('#emailID-info').textContent =
					'不能为空白';
				document.querySelector('#emailID').style.borderColor =
					'#CC3300';
			} else {
				checkUserAccount();
			}

			if (phoneCode.current.value === '') {
				document.querySelector('#phoneCode-info').textContent =
					'不能为空白';
				document.querySelector('#phoneCode').style.borderColor =
					'#CC3300';
			} else {
				checkUserAccount();
			}

			if (password.current.value === '') {
				document.querySelector('#password-info').textContent =
					'不能为空白';
				document
					.querySelectorAll('#password, #passwordSwitch')
					.forEach((e) => (e.style.borderColor = '#CC3300'));

				handleCaptchaRefresh();
			} else {
				document.querySelector('#password-info').textContent = '';
				document
					.querySelectorAll('#password, #passwordSwitch')
					.forEach((e) => (e.style.borderColor = '#ced4da'));
			}

			if (captchaUserInput === '') {
				document.querySelector('#captcha-info').textContent =
					'不能为空白';
				document.querySelector('#captcha').style.borderColor =
					'#CC3300';
			} else {
				document.querySelector('#captcha-info').textContent = '';
				document.querySelector('#phoneCode').style.borderColor =
					'#ced4da';
			}

			// 登入API
			if (
				(emailID.current.value !== '' ||
					phoneCode.current.value !== '') &&
				password.current.value !== '' &&
				captchaUserInput !== ''
			) {
				if (checkUserAccount() === 0 && checkCaptcha() === 0) {
					const res = await axios.post(
						'/web/user/ValidateLogin',
						loginStyle === 'emailID' ? emailIDPost : phonePost
					);
					//帳號或密碼錯誤
					if (res.data.status === '1' || res.data.status === '4') {
						if (loginStyle === 'emailID') {
							document.querySelector(
								'#emailID-info'
							).textContent = '账号或密码错误';
							document.querySelector(
								'#emailID'
							).style.borderColor = '#CC3300';
						} else if (loginStyle === 'phone') {
							document.querySelector(
								'#phoneCode-info'
							).textContent = '账号或密码错误';

							document.querySelector(
								'#phoneCode'
							).style.borderColor = '#CC3300';
						}

						handleCaptchaRefresh();
						//帳號尚未啟用
					} else if (res.data.status === '2') {
						if (loginStyle === 'emailID') {
							document.querySelector(
								'#emailID-info'
							).textContent =
								'账号尚未启用，请先到您注册的email或手机简讯，启用账号';
							document.querySelector(
								'#emailID'
							).style.borderColor = '#CC3300';
						} else if (loginStyle === 'phone') {
							document.querySelector(
								'#phoneCode-info'
							).textContent =
								'账号尚未启用，请先到您注册的email或手机简讯，启用账号';
							document.querySelector(
								'#phoneCode'
							).style.borderColor = '#CC3300';
							handleCaptchaRefresh();
						}
						//登入成功
					} else if (res.data.status === '0') {
						setCookie('loginUser', res.data, { path: '/' });

						console.log(res.data);
						getAccountInfo();
						console.log(res.data.userGuid);

						// 儲存權限
						axiosApi('web/SysAuthorization/userPermission').then(
							(res) => {
								// 取得TransactionCode List
								console.log(res.data.result);

								setCookie('page', res.data.result, {
									path: '/',
								});
								console.log(cookies.page);
							}
						);
						// const gettingUserPermission = async () => {
						//     await axios
						//         .get(`web/SysAuthorization/userPermission`)
						//         .then((res) => {
						//             // 取得TransactionCode List
						//             console.log(res.data.result);

						//             setCookie('page', res.data.result, {
						//                 path: '/',
						//             });
						//             console.log(cookies.page);
						//         })
						//         .catch((err) => {
						//             // console.log(err.response.data.JwtStatus);
						//             if (err.response.data.JwtStatus === 551) {
						//                 if (apiRecallNumber < 10) {
						//                     setTimeout(
						//                         gettingUserPermission,
						//                         200
						//                     );
						//                     apiRecallNumber++;
						//                 } else {
						//                     window.location.href = '/login';
						//                 }
						//             } else {
						//                 window.location.href = '/login';
						//             }
						//         });
						// };

						// 有要轉的地址
						console.log(location.search);
						if (location.search) {
							const to = location.search.split('=')[1];
							navigate(`/${to}`);
						} else {
							navigate(`/baseInfo`);
						}
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		// TODO: 之後要把他 del, 因為在 api.js & logout function 裡已經做過了
		document.cookie =
			'RefreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		document.cookie =
			'AccessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		document.cookie =
			'page=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	}, []);

	return (
		<div className="container-fluid p-0">
			<article
				id="container-login"
				className="py-lg-3 d-flex flex-column align-items-center justify-content-lg-center bg-background "
			>
				<div className="articleBox d-flex flex-column justify-content-start">
					<h2 className="pageTitle">帐号登录</h2>
					<section id="article-login">
						<form
							className="row g-3"
							autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
						>
							{/*<!-- email/ID -->*/}
							<div
								className={`col-12 ${
									loginStyle !== 'emailID' && 'd-none'
								}`}
							>
								{/* <label className="form-label" htmlFor="emailID">
									<FormattedMessage
										id="auth.emailID"
										defaultMessage="电子邮箱/ID"
									/>
								</label> */}
								<input
									ref={emailID}
									type="text"
									className="form-control form-control-lg"
									id="emailID"
									name="emailID"
									placeholder="邮箱/宾乐达用户名"
									onBlur={checkUserAccount}
									required
								/>
								<div
									className="invalid-feedback"
									id="emailID-info"
								></div>
							</div>

							{/*  電話  */}
							<div
								className={`col-12 ${
									loginStyle !== 'phone' && 'd-none'
								}`}
							>
								{/* <label
									className="form-label"
									htmlFor="phoneCode"
								>
									<FormattedMessage
										id="auth.phoneNumber"
										defaultMessage="手机号"
									/>
								</label> */}
								<div className="row">
									<div className="col-4 col-lg-3">
										<select
											ref={countryCode}
											className="form-select form-select-lg"
											id="countryCode"
											name="countryCode"
										>
											<option value="86">+86</option>
											<option value="886">+886</option>
											<option value="852">+852</option>
										</select>
									</div>
									<div className="col-8 col-lg-9">
										<input
											ref={phoneCode}
											type="text"
											className="form-control form-control-lg"
											id="phoneCode"
											name="phoneCode"
											placeholder="手机号码"
											pattern="^[0-9]*$"
											disabled={loginStyle === 'email'}
											onBlur={checkUserAccount}
										/>
										<div
											className="invalid-feedback"
											id="phoneCode-info"
										></div>
									</div>
								</div>
							</div>

							{/*<!-- 密碼 -->*/}
							<div className="col-12">
								{/* <label
									className="form-label"
									htmlFor="password"
								>
									<FormattedMessage
										id="auth.password"
										defaultMessage="密码"
									/>
								</label> */}
								<div className="input-group">
									<input
										ref={password}
										type={
											showPassword ? 'text' : 'password'
										}
										className="form-control form-control-lg"
										id="password"
										name="password"
										placeholder="密码"
										pattern="^[a-zA-Z0-9]*$"
										minLength="6"
										maxLength="16"
										required
										aria-describedby="passwordSwitch"
										autoComplete="off"
									/>

									<button
										type="button"
										className="btn btn-color-outline-auxiliary"
										id="passwordSwitch"
										tabIndex="-1"
										onClick={switchPassword}
									>
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</button>
								</div>
								<div
									className="invalid-feedback"
									id="password-info"
								></div>
							</div>

							{/*<!-- 圖形驗證碼 -->*/}
							<div className="col-12">
								{/* <label className="form-label" htmlFor="captcha">
									<FormattedMessage
										id="auth.verificationCode"
										defaultMessage="验证码"
									/>
								</label> */}
								<div className="d-flex align-items-center">
									<input
										type="text"
										className="form-control form-control-lg "
										id="captcha"
										name="captcha"
										placeholder="验证码"
										required
										value={captchaUserInput}
										onChange={(e) =>
											setCaptchaUserInput(e.target.value)
										}
									/>

									<div className="px-3 captchaDiv">
										<Captcha
											code={captcha}
											bgColor="#ffe"
											height="48"
											onClick={handleCaptchaRefresh}
										/>
									</div>

									<button
										type="button"
										className="refreshBtn btn  btn-color-auxiliary d-flex justify-content-center align-items-center"
										onClick={handleCaptchaRefresh}
									>
										<Sync />
									</button>
								</div>
								<div
									className="invalid-feedback"
									id="captcha-info"
								></div>
							</div>

							{/*<!-- 自動登入 -->*/}
							<div className="col-6">
								<div className="form-check ">
									<input
										type="checkbox"
										className="form-check-input"
										id="loginAuto"
										checked={
											cookies.loginAuto === 'true'
												? true
												: false
										}
										onChange={handleLoginAuto}
									/>
									<label
										className="form-check-label"
										htmlFor="loginAuto"
									>
										自动登入
									</label>
								</div>
							</div>

							{/*<!-- 切換登入 -->*/}
							<div className="col-6 loginToggleDiv">
								<div className="text-end">
									<Link
										to=""
										className={`text-decoration-none ${
											loginStyle !== 'emailID' && 'd-none'
										}`}
										onClick={loginChange}
									>
										手机号登入
									</Link>
									<Link
										to=""
										className={`text-decoration-none ${
											loginStyle !== 'phone' && 'd-none'
										}`}
										onClick={loginChange}
									>
										电子邮箱/ID登入
									</Link>
								</div>
							</div>

							{/*<!-- 登入 -->*/}
							<div className="col-12 text-center mb-0 mb-lg-3">
								<button
									type="submit"
									className="w-100 btn btn-lg btn-color-main rounded-pill"
									id="btn-login"
								>
									登入
								</button>
							</div>

							{/*<!-- 切換登入 / 註冊 -->*/}
							<div className="col-12">
								<div className="changeBox d-flex justify-content-center align-items-center">
									<Link
										className="text-decoration-none pe-3 border-end"
										to="/register"
									>
										注册
									</Link>
									<Link
										className="text-decoration-none ps-3"
										to="/forgotPwd"
									>
										忘记密码
									</Link>
								</div>
							</div>
						</form>
					</section>
				</div>
			</article>
		</div>
	);
}

export default Login;
