import './about.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/// 关于宾乐达
/// Author : YL
function About() {
	const { t } = useTranslation(['common', 'about']); // i18n

	return (
		<div className="container-fluid p-0">
			<article
				id="container-about"
				className="d-flex flex-column align-items-center px-0 mx-auto"
			>
				<section className="about-section section1 row justify-content-center align-items-center position-relative">
					<div id="section1-1_img" className="text-center">
						<h1 className="banner_title">关于宾乐达</h1>
						<section className="switchingPostionBar bg-white row align-items-center justify-content-center">
							<div className="col wrapper">
								<Link className="active me-lg-7" to="#">
									公司简介
								</Link>
							</div>
						</section>
					</div>
				</section>

				<div className="smallWrapper">
					<section className="about-section section2 w-100 row align-items-center justify-content-center px-0 bg-white">
						<div className="text-center">
							宾乐达是整合科技与运动的专业技术团队所创造的智能运动科技品牌，品牌致力以人工智能为基础，专注于科技创新发展对乒乓球市场的整合效应，搭配大数据与平台建置的设计方向，能提供使用者体验人机整合的科技感官快乐，并提供拟制真人出球的最佳击球感受。
						</div>
					</section>
				</div>

				<div className="wrapper fullWrapperRWD">
					<section className="about-section section3 w-100 row align-items-center justify-content-center px-0 bg-white">
						<div
							id="section3-1_img"
							className="text-left position-relative"
						>
							<div className="banner_title">
								<h1>公司简介</h1>
								<p>快乐运动，健康生活。</p>
							</div>
						</div>
					</section>
				</div>

				<div className="smallWrapper">
					<section className="about-section section4 w-100 row align-items-center justify-content-center px-0 bg-white">
						<div className="text-center">
							宾乐达品牌由海南翔睿德科技有限公司历经数年研发，结合乒乓球专业教练、科技信息整合顾问、与数十位工程师团队，以创新有趣的思维注入，发扬并颠覆传统乒乓球发球机的单调与限制，让乒乓球发球机的研发再生，能够辅助所有喜爱乒乓球运动的教练与选手们，拥有最忠实最经济的练球伙伴。
						</div>
						<div className="text-center mt-lg-5">
							我们的企业秉承“快乐运动，健康生活”的服务理念，深信“创新引领方向、服务决定未来”的宗旨，坚持以人为本，打造对人类社会有益之产品。
						</div>
					</section>
				</div>

				{/* <div className="wrapper">
                    <section className="about-section section5 w-100 row align-items-center justify-content-center px-0 bg-white">
                        <button
                            type="button"
                            className="btn btn-size-customised btn-color-main rounded-pill text-white"
                        >
                            了解翔睿德集团
                        </button>
                    </section>
                </div> */}

				<div className="wrapper">
					<section className="about-section section6 w-100 d-flex align-items-center justify-content-center px-0 bg-white position-relative">
						<div className="left">
							<div className="backWord">TECHNOLOGY</div>
							<div className="frontWord">高新科技</div>
						</div>
						<div className="right">
							<div className="wordArea">
								<div className="word">
									宾乐达装载有高端芯片与智能固件，运用互联网、APP应用、云计算、大数据、深度学习等智能整合技术工艺，自主研发并生产制造智能体育产品。
								</div>
							</div>
						</div>
					</section>
				</div>

				<div className="wrapper">
					<section className="about-section section7 w-100 d-flex align-items-center justify-content-center px-0 bg-white position-relative">
						<div className="left">
							<div className="backWord">INNOVATION</div>
							<div className="frontWord">创新引领</div>
						</div>
						<div className="right">
							<div className="wordArea">
								<div className="word">
									宾乐达可以搭载适合不同人士的专属训练模式，并能让用户透过智能手机与后台数据库的连动与分享，触动各种潮流可能，引领乒乓球的科技世界将有重大变革。
								</div>
							</div>
						</div>
					</section>
				</div>

				<div className="wrapper">
					<section className="about-section section8 w-100 d-flex align-items-center justify-content-center px-0 bg-white position-relative">
						<div className="left">
							<div className="backWord">PING-PONG</div>
							<div className="frontWord">快乐运动</div>
						</div>
						<div className="right">
							<div className="wordArea">
								<div className="word">
									宾乐达提供人机互动、快乐有趣、简便易用、让用户自主设计训练球谱，定义专属的球路不同的落球点与球速变化，实时储存检验自己的运动进展，并轻松享受科技辅助的快乐。
								</div>
							</div>
						</div>
					</section>
				</div>

				<div className="wrapper">
					<section className="about-section section9 w-100 d-flex align-items-center justify-content-center px-0 bg-white position-relative">
						<div className="left">
							<div className="backWord">SERVICE</div>
							<div className="frontWord">服务大众</div>
						</div>
						<div className="right">
							<div className="wordArea">
								<div className="word">
									宾乐达长期为广大爱好体育人士服务，用户的深度体验与您的爱不释手，是对我们产品的最大肯定，也是我们继续研发前行的最大动力，我们感谢每一位用户的热情反馈。
								</div>
							</div>
						</div>
					</section>
				</div>

				<div className="wrapper fullWrapperRWD">
					<section className="about-section section10 w-100 row align-items-center justify-content-center px-0 bg-white">
						<div
							id="section3-1_img"
							className="text-left position-relative"
						>
							<div className="banner_title">
								<h1>知识产权</h1>
								<p>探索宾乐达自主创新</p>
							</div>
						</div>
					</section>
				</div>

				<div className="smallWrapper">
					<section className="about-section section11 w-100 row align-items-center justify-content-center px-0 bg-white">
						<div className="text-center">
							我们以自主知识产权创新，生产制造智能体育产品，追求创新技术与卓越质量，以成为备受推崇的体育科技领导品牌为企业愿景，并已获得超过十项以上国家专利的肯定。
						</div>
					</section>
				</div>
			</article>
		</div>
	);
}

export default About;
