import './productFeature.scss';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SupportBottom from '../../support/SupportBottom';

function ProductFeature() {
	const [sectionSlider, setSectionSlider] = useState(1);
	const [section14Slider, setSection14Slider] = useState(1);

	return (
		<div className="container-fluid p-0">
			<article
				id="container-productFeature"
				className="d-flex flex-column align-items-center px-0 mx-auto bg-black"
			>
				<section className="productFeature-section productCenterNavPhone section0 position-fixed w-100 row justify-content-center bg-white">
					<div className="col-12 section0Wrapper p-0 text-center">
						<div className="h-100 d-flex justify-content-between align-items-center px-4">
							<h6 className="text-grey5 mb-0">
								宾乐达乒乓球智能发球机
							</h6>
							<Dropdown>
								<Dropdown.Toggle
									variant="transparent"
									id="dropdown-basic"
								>
									功能亮点
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item>
										<Link
											className="text-primary"
											to="/productcenter/productfeature"
										>
											功能亮点
										</Link>
									</Dropdown.Item>
									<Dropdown.Item>
										<Link
											className="text-grey5"
											to="/productcenter/productstat"
										>
											规格参数
										</Link>
									</Dropdown.Item>
									<Dropdown.Item>
										<Link
											className="text-grey5"
											to="/app/download"
										>
											宾乐达APP
										</Link>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</section>
				<section className="productFeature-section section1 w-100 row align-items-top">
					<div className="offset-lg-3 col p-0 text-center text-lg-start">
						<h1 className="text-white mb-4 mb-lg-7">
							<span className="mb-3 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline">
								崭新升级 硬实力派
							</span>
						</h1>
						<Link to="/physicalstore">
							<button
								type="button"
								className="btn btn-size-m btn-hover-white  rounded-pill mb-9 mb-lg-0"
							>
								立即购买
							</button>
						</Link>
					</div>
				</section>
				<section className="productFeature-section section2 w-100 row mt-lg-13">
					<div
						className="col-12 p-0 text-center
					"
					>
						<p className="px-6 mx-auto mb-lg-7 text-white">
							宾乐达将强大的功能蕴藏在大小刚好的机体中，采用经久耐用的高精密直流无刷调速电机、360度全方位三轴发球头、自动回收循环供球设计，忠实还原实战球性。核心系统采用高科技人工智能算法，支持蓝牙与Wi-Fi无线网络双重联机模式，搭配专属APP自定编程，摸拟真实打球场景，球性接近真人实战，创造极致流畅的打球体验，扎实训练不中断。
						</p>
						<p className="px-6 mx-auto mb-lg-13 text-white">
							宾乐达，您的练球最佳陪伴。
						</p>
						<img
							className="d-block d-lg-none section2Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0"
							src="./images/productCenter/productFeature/section2_1_2x.png"
							alt="section2Img"
						/>
						<h1 className="text-primary mt-12 mt-lg-4 mb-5 mb-lg-12">
							<span className="mb-3 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline">
								颜值出众
							</span>
							<span className="d-block d-lg-inline">
								性能服众
							</span>
						</h1>
						<p className="px-6 mx-auto mb-7 mb-lg-11 text-white">
							现代化的精简线条为宾乐达赋予了独特的视觉亮点，高质感雾光咬花的沉静设计，圆滑角度的简练机身，定义了崭新宾乐达的外观与风格。充满气势的外观并未减损核心性能表现，每一处的精心打造，为您带来更舒适的极致体验，练习气氛也可以很时尚。
						</p>
					</div>
					<div className="col-12 p-0 text-center d-none d-lg-block">
						<picture>
							<source
								className="section2Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0"
								media="(min-width: 992px)"
								srcSet="./images/productCenter/productFeature/section2_1_2x.png"
								alt="section2Img"
							/>
							<img
								className="section2Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0"
								src="./images/productCenter/productFeature/section2_1_1x.png"
								alt="section2Img"
							/>
						</picture>
					</div>
				</section>
				<section className="productFeature-section section3 w-100 row mt-lg-13 mb-lg-14">
					<div
						className="col-12 p-0 text-center
					"
					>
						<img
							className="d-block d-lg-none section3_1Img w-100 mx-0 ms-lg-auto mt-0"
							src="./images/productCenter/productFeature/section3_1_1x.png"
							alt="section3_1Img"
						/>
						<h2 className="px-6 mx-auto mt-6 mt-lg-0 mb-4 mb-lg-6 text-white">
							变化灵活 真实陪练
						</h2>
						<p className="px-6 mx-auto mb-6 mb-lg-13 text-white">
							三轴可同步转动的机构设计，藉由步进电机精准控制出球角度，全方位全球台变化落点，精选参数全面涵盖。俯仰角度可达80度，水平角度可达60度，旋球角度可达360度。球路变化更灵活，轻松实现跳球转换。
						</p>
						<div className="textWrap d-flex justify-content-between mx-auto px-8 px-lg-0">
							<div>
								<h2 className="mb-1 text-primary">-40~40度</h2>
								<h6 className="text-white">俯仰角度</h6>
							</div>
							<div className="me-lg-4">
								<h2 className="mb-1 text-primary">-30~30度</h2>
								<h6 className="text-white">水平角度</h6>
							</div>
							<div className="me-lg-4">
								<h2 className="mb-1 text-primary">360度</h2>
								<h6 className="text-white">旋球角度</h6>
							</div>
						</div>
						<img
							className="d-block d-lg-none section3_2Img w-100 mx-0 ms-lg-auto mt-0"
							src="./images/productCenter/productFeature/section3_2_1x.png"
							alt="section3_2Img"
						/>
					</div>
				</section>
				<section className="productFeature-section section4 w-100 row align-items-top">
					<div className="col-12 p-0 text-center">
						<h2 className="text-white mt-6 mb-4">
							桌立两用，出行便捷
						</h2>
						<p className="px-6 mx-auto mb-9 mb-lg-0 text-white">
							不拘空间与时间，移地训练不受限，桌上直立兼顾，依照标示线快速对齐，搭配专属脚架，自由调节刻度，无须工具即可拆装收纳，适合任何场地任意移动，不论家用或是大型、小型球馆，也适用体育高考训练，如同24小时教练在身边。
						</p>
						<Link to="/explore">
							<button
								type="button"
								className="btn btn-outline-primary text-white rounded-pill py-2 mt-lg-8"
							>
								网上展示厅
							</button>
						</Link>
					</div>
				</section>

				<section className="productFeature-section section5 w-100 row mt-lg-13">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h1 className="text-primary mb-4 mb-lg-12">
							<span className="mb-2 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline">
								流畅体验
							</span>
							<span className="d-block d-lg-inline">
								真实陪练
							</span>
						</h1>
						<p className="px-6 mx-auto mb-lg-7 text-white">
							宾乐达发球机的全功能主板同时搭载2个高端智能芯片，内核频率可达396MHz的工业级处理器，采同步平行处理技术与机器人等级的高效智能运算，让您尽情感受其优异的反应速度，忠实还原多种实战球性。核心系统具备OTA远程升级能力，一键即可完成固件更新。将练球体验推向崭新境界！
						</p>
						<p className="px-6 mx-auto mb-lg-15 text-white">
							宾乐达，为您提供最佳优势，展现最大潜力，成就高光时刻。
						</p>
					</div>
					<div className="col-12 p-0 text-center">
						<picture>
							<source
								className="section5Img w-100 mx-0 ms-lg-auto mt-9 mt-lg-0"
								media="(min-width: 992px)"
								srcSet="./images/productCenter/productFeature/section5_1_2x.png"
								alt="section5Img"
							/>
							<img
								className="section5Img w-100 mx-0 ms-lg-auto"
								src="./images/productCenter/productFeature/section5_1_1x.png"
								alt="section5Img"
							/>
						</picture>
					</div>
				</section>
				<section className="productFeature-section section6 w-100 row mt-lg-7 mb-lg-15">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h2 className="px-6 mx-auto mt-4 mt-lg-0 mb-4 mb-lg-5 text-white">
							全面高配 练球无阻
						</h2>
						<p className="px-6 mx-auto mb-6 mb-lg-12 text-white">
							使用高精密直流无刷调速电机，出球精确度高，减少耗时维修，能耗更低更耐用，寿命是一般发球机采用的碳刷直流电机10倍以上。搭载高质量耐磨橡胶发球轮，加上大范围发球角度，可创造多重旋球弧度，球路变化更灵活，轻松实现跳球转换。
						</p>
						<div className="textWrap d-flex justify-content-between mx-auto px-5 px-lg-0">
							<div>
								<h2 className="mb-1 text-primary">20000+时</h2>
								<h6 className="text-white">高寿命稳定输出</h6>
							</div>
							<div className="">
								<h2 className="mb-1 text-primary">500000+次</h2>
								<h6 className="text-white">高质耐磨发球轮</h6>
							</div>
							<div className="me-4">
								<h2 className="mb-1 text-primary">60000+球</h2>
								<h6 className="text-white">连续出球不卡球</h6>
							</div>
						</div>
					</div>
				</section>
				<section className="productFeature-section section7 w-100 row align-items-top">
					<div className="col-12 position-relative p-0 text-center">
						<img
							className="d-block d-lg-none section7Img w-100 mx-0 ms-lg-auto"
							src="./images/productCenter/productFeature/section7_1_1x.png"
							alt="section7Img"
						/>
						<h2 className="text-white mt-7 mt-lg-0 mb-5 mb-lg-0">
							摆头轻松调 旋球轻松练
						</h2>
						<img
							className="d-none d-lg-block section7Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0"
							src="./images/productCenter/productFeature/section7_1_2x.png"
							alt="section7Img"
						/>
						<div className="d-none d-lg-block section7InfoBox position-absolute">
							<div className="leftWrap position-absolute">
								<h3 className="title text-primary text-end mb-5">
									360°全方位转动 旋球变化灵活
								</h3>
								<h6 className="text text-white text-start">
									双轮驱动发球头三轴同步转动设计，反应灵活敏捷，搭配步进电机精准控制出球，通过专属APP轻松调弧度，仿真所有旋球角度，模拟实战球路，增加赛事经验。
								</h6>
							</div>
							<div className="rightTopWrap position-absolute">
								<h6 className="text text-white text-start mb-4">
									可以调整亮度的旋球指示灯，根据不同的旋球方向与角度，显示对应的提示灯，快速辨识旋球种类，练球更直观。
								</h6>
								<h3 className="title text-primary text-start">
									旋球灯号 直观辨识
								</h3>
							</div>
							<div className="rightBottomWrap position-absolute">
								<h6 className="text text-white text-start mb-4">
									提供语音辅助辨识设备状态 :
									开机、联机、开始发球、发球结束等等，实时掌握打球动态，维持最佳练球状态。
								</h6>
								<h3 className="title text-primary text-start">
									亲切语音，温馨提示
								</h3>
							</div>
						</div>

						{/* mobile */}
						<div className="d-block d-lg-none section7mobileBox">
							<div
								className={`box1 ${
									sectionSlider === 1 ? 'd-block' : 'd-none'
								}`}
							>
								<h3 className="title text-primary text-center mb-2">
									360°全方位转动 旋球变化灵活
								</h3>
								<h6 className="text text-white text-start px-7">
									双轮驱动发球头三轴同步转动设计，反应灵活敏捷，搭配步进电机精准控制出球，通过专属APP轻松调弧度，仿真所有旋球角度，模拟实战球路，增加赛事经验。
								</h6>
							</div>
							<div
								className={`box2 ${
									sectionSlider === 2 ? 'd-block' : 'd-none'
								}`}
							>
								<h3 className="title text-primary text-center mb-2">
									旋球灯号 直观辨识
								</h3>
								<h6 className="text-white text-start px-7">
									可以调整亮度的旋球指示灯，根据不同的旋球方向与角度，显示对应的提示灯，快速辨识旋球种类，练球更直观。
								</h6>
							</div>
							<div
								className={`box3 ${
									sectionSlider === 3 ? 'd-block' : 'd-none'
								}`}
							>
								<h3 className="title text-primary text-center mb-2">
									亲切语音，温馨提示
								</h3>
								<h6 className="text-white text-start px-7">
									提供语音辅助辨识设备状态 :
									开机、联机、开始发球、发球结束等等，实时掌握打球动态，维持最佳练球状态。
								</h6>
							</div>

							<div className="sliderBtnWrapper d-flex justify-content-between p-2 border border-grey2 rounded-pill mt-9 mx-auto">
								<div
									className={`btn-1 p-1 rounded-circle ${
										sectionSlider === 1
											? 'bg-white'
											: 'bg-grey3'
									}`}
									onClick={() => setSectionSlider(1)}
								></div>
								<div
									className={`btn-2 p-1 rounded-circle ${
										sectionSlider === 2
											? 'bg-white'
											: 'bg-grey3'
									}`}
									onClick={() => setSectionSlider(2)}
								></div>
								<div
									className={`btn-3 p-1 rounded-circle ${
										sectionSlider === 3
											? 'bg-white'
											: 'bg-grey3'
									}`}
									onClick={() => setSectionSlider(3)}
								></div>
							</div>
						</div>
					</div>
				</section>
				<section className="productFeature-section section8 w-100 row mt-lg-11 mb-lg-15">
					<div
						className="col-12 p-0 text-center
					"
					>
						<div className="col-12 p-0 text-center">
							<picture>
								<source
									className="section8Img w-75 mx-0 ms-lg-auto mt-12 mt-lg-0"
									media="(min-width: 992px)"
									srcSet="./images/productCenter/productFeature/section8_1_2x.png"
									alt="section8Img"
								/>
								<img
									className="section8Img w-75 mx-0 ms-lg-auto mt-2"
									src="./images/productCenter/productFeature/section8_1_1x.png"
									alt="section8Img"
								/>
							</picture>
						</div>
						<h2 className="text-white text-center text-lg-start px-4 px-lg-6 mx-auto mb-4 mb-lg-3">
							高速无刷电机 高效智能运算
						</h2>
						<p className="text-center text-lg-start px-6 mx-auto mb-6 mb-lg-12 text-white">
							宾乐达发球机采用高端AI智能芯片，引进最新人工算法技术，搭载最快转速达12000转/分钟的高速无刷电机，可精准调控电机转速，旋转更强，球性稳定，球速最快可达16米/秒。发球频率也能自由调控，每分钟最快可发120球，规律发球也能忽快忽慢，自己定义球与球之间的时间差，调整发球节奏，整体表现优于市售同类产品。
						</p>
						<div className="textWrap d-flex justify-content-between mx-auto px-5 px-lg-0">
							<div>
								<h2 className="mb-1 text-primary">
									12000 转/分钟
								</h2>
								<h6 className="text-white">电机最高转速</h6>
							</div>
							<div className="">
								<h2 className="mb-1 text-primary">
									120 球/分钟
								</h2>
								<h6 className="text-white">最高出球频率</h6>
							</div>
							<div className="me-4">
								<h2 className="mb-1 text-primary">
									2~16 米/秒
								</h2>
								<h6 className="text-white">出球速度</h6>
							</div>
						</div>
					</div>
				</section>
				<section className="productFeature-section section9 w-100 row mt-lg-7 mb-lg-16">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h2 className="px-6 mx-auto mb-4 mb-lg-5 text-white">
							轻巧之下 实力爆表
						</h2>
						<p className="px-6 mx-auto text-white">
							宾乐达以仅5.5公斤、高度62厘米的轻巧体积提供惊人的性能表现，内外构件由高强度、高质量的塑料组成，结构强度高，刚毅坚韧使用寿命长。集球桶可容纳100球自动循环回收，单人即可独立装卸收纳，让您无须担心出行不便，随时能尽情体验打球乐趣。
						</p>
					</div>
				</section>
				<section className="productFeature-section section10 w-100 row align-items-top mb-lg-10">
					<div className="d-none d-lg-block col-12 position-relative p-0 text-center">
						<h2 className="text-white mb-5 mb-lg-12">
							技术设备更高端 安装调控更便捷
						</h2>
						<div className="section10CardBox row flex-wrap justify-content-lg-around mx-auto mb-lg-13">
							<div className="col6 cardLeftTop position-relative mb-4">
								<div className="cardInfo position-absolute">
									<h6 className="text-white text-start mt-3 mb-5">
										全方位发球头，俯仰、水平、旋球三轴同时转动，所有角度皆可发，落点区域全覆盖，自定义专属编程。
									</h6>
									<h6 className="text-white text-start mt-lg-5">
										✓ 俯仰80度，可调檔位达160档
									</h6>
									<h6 className="text-white text-start">
										✓ 水平60度，可调檔位达60档
									</h6>
									<h6 className="text-white text-start mb-4">
										✓ 一跳球、两跳球
									</h6>
									<h3 className="title text-primary text-start">
										多种旋球 随心编程
									</h3>
								</div>
							</div>
							<div className="col6 cardRightTop position-relative mb-4">
								<div className="cardInfo position-absolute">
									<h3 className="title text-primary text-end mb-5">
										自动循环 供球顺畅
									</h3>
									<h6 className="text text-white text-start mb-5">
										曲线力学转盘自动导球，连续六万球不卡球纪录，进球超顺畅。循环回收50球即可开打，集球桶容量充足，专注练习不中断。
									</h6>
								</div>
							</div>
							<div className="col6 cardLeftBottom position-relative">
								<div className="cardInfo position-absolute ms-9">
									<h3 className="title text-primary text-start mb-5">
										球道顺畅 专注练习
									</h3>
									<h6 className="text text-white text-start mb-4">
										进球通道具备多重侦测机制，运行过程混入异物或者变形球以致无法进球时，能实时发出语言提示，暂停发球。
									</h6>
								</div>
							</div>
							<div className="col6 cardRightBottom position-relative">
								<div className="cardInfo position-absolute">
									<h3 className="title text-primary text-start mt-8 mb-5">
										一键退球 无须工具
									</h3>
									<h6 className="text text-white text-start">
										通过APP或按键面板一键自动退球，免拆螺丝即可手动从卸球门取球，简单清理球道灰尘或异物，轻松杜绝卡球。
									</h6>
								</div>
							</div>
						</div>
						<Link
							to={{
								pathname: '/support/downloadcenter',
								state: {
									initialLocation: '.section2',
								},
							}}
						>
							<button
								type="button"
								className="btn btn-outline-primary text-white rounded-pill py-2"
							>
								安装指南
							</button>
						</Link>
					</div>

					{/* mobile */}
					<div className="d-block d-lg-none col-12 p-0 text-center">
						<h2 className="text-white mb-5 mb-lg-12">
							技术设备更高端
						</h2>
						<img
							className="section10LeftTop_1_1x w-100 mx-0 ms-lg-auto mt-2 mb-4"
							src="./images/productCenter/productFeature/section10LeftTop_1_1x.png"
							alt="section10LeftTop_1_1x"
						/>
						<div className="cardInfo px-6">
							<h3 className="title text-primary text-start mb-2">
								多种旋球 随心编程
							</h3>

							<h6 className="text-white text-start">
								全方位发球头，俯仰、水平、旋球三轴同时转动，所有角度皆可发，落点区域全覆盖，自定义专属编程。
							</h6>
							<h6 className="text-white text-start">
								✓ 俯仰80度，可调檔位达160档
							</h6>
							<h6 className="text-white text-start">
								✓ 水平60度，可调檔位达60档
							</h6>
							<h6 className="text-white text-start mb-6">
								✓ 一跳球、两跳球
							</h6>
						</div>
						<img
							className="section10RightTop_1_1x w-100 mx-0 ms-lg-auto mt-2 mb-4"
							src="./images/productCenter/productFeature/section10RightTop_1_1x.png"
							alt="section10RightTop_1_1x"
						/>
						<div className="cardInfo px-6">
							<h3 className="title text-primary text-start mb-2">
								自动循环 供球顺畅
							</h3>
							<h6 className="text text-white text-start mb-6">
								曲线力学转盘自动导球，连续六万球不卡球纪录，进球超顺畅。循环回收50球即可开打，集球桶容量充足，专注练习不中断。
							</h6>
						</div>
						<h2 className="text-white mt-14 mb-6">
							安装调控更便捷
						</h2>
						<img
							className="section10LeftBottom_1_1x w-100 mx-0 ms-lg-auto mb-4"
							src="./images/productCenter/productFeature/section10LeftBottom_1_1x.png"
							alt="section10LeftBottom_1_1x"
						/>
						<div className="cardInfo px-6">
							<h3 className="title text-primary text-start mb-2">
								球道顺畅 专注练习
							</h3>
							<h6 className="text text-white text-start mb-6">
								进球通道具备多重侦测机制，运行过程混入异物或者变形球以致无法进球时，能实时发出语言提示，暂停发球。
							</h6>
						</div>
						<img
							className="section10RightBottom_1_1x w-100 mx-0 ms-lg-auto mb-4"
							src="./images/productCenter/productFeature/section10RightBottom_1_1x.png"
							alt="section10RightBottom_1_1x"
						/>
						<div className="cardInfo px-6">
							<h3 className="title text-primary text-start mb-2">
								一键退球 无须工具
							</h3>
							<h6 className="text text-white text-start mb-12">
								通过APP或按键面板一键自动退球，免拆螺丝即可手动从卸球门取球，简单清理球道灰尘或异物，轻松杜绝卡球。
							</h6>
						</div>

						<Link to="/support/downloadcenter">
							<button
								type="button"
								className="btn btn-outline-primary text-white rounded-pill py-2 mb-12"
							>
								安装指南
							</button>
						</Link>
					</div>
				</section>
				<section className="productFeature-section section11 w-100 row bg-white">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h1 className="text-primary mb-5 mb-lg-12">
							<span className="mb-1 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline">
								一开即用
							</span>
							<span className="d-block d-lg-inline">
								一键开打
							</span>
						</h1>
						<p className="px-5 px-lg-6 mx-auto mb-5 mb-lg-12 text-grey5">
							宾乐达提供便捷出色的智能互动和编程系统，将多种球性模式优化和个性化，可根据应用需求灵活调整，为您打开专属训练。专属APP软件直观调控，快速连接机体，轻松操作指令，可让您个人化专属编程及自定义偏好选项，符合您的各种需求，有效提高练球效率，打造高效训练快捷方式，考试娱乐两不误。
						</p>
						<div className="textWrap d-flex justify-content-between px-5 px-lg-0 mx-auto">
							<div>
								<h2 className="mb-1 text-primary">直观智能</h2>
								<h6 className="text-grey5">专用App</h6>
							</div>
							<div className="">
								<h2 className="mb-1 text-primary">个人化</h2>
								<h6 className="text-grey5">自定义编程</h6>
							</div>
							<div className="">
								<h2 className="mb-1 text-primary">便捷稳定</h2>
								<h6 className="text-grey5">无线连接</h6>
							</div>
						</div>
					</div>
				</section>
				<section className="productFeature-section section12 w-100 row bg-white">
					<div
						className="col-12 p-0 text-center
					"
					>
						<picture>
							<source
								className="section12Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0"
								media="(min-width: 992px)"
								srcSet="./images/productCenter/productFeature/section12_1_2x.png"
								alt="section12Img"
							/>
							<img
								className="section12Img w-100 mx-0 ms-lg-auto mt-2"
								src="./images/productCenter/productFeature/section12_1_1x.png"
								alt="section12Img"
							/>
						</picture>
					</div>
				</section>
				<section className="productFeature-section section13 w-100 row bg-white">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h2 className="px-6 mx-auto mb-4 mb-lg-5 text-grey5">
							高效调控 持续更新
						</h2>
						<p className="px-6 mx-auto mb-0 mb-lg-3 text-grey5">
							下载专用APP，随心调用程序，一键线上自动更新软韧体，自定义专属球谱，在无APP状态下，可直接使用按键面板操作，不受网路限制，有效提升练球效率。额外提供管理者模式，可限制访客权限，带来更便捷安心地训练方式。
						</p>
					</div>
				</section>
				<section className="productFeature-section section14 w-100 row align-items-top bg-white">
					<div className="col-12 position-relative p-0 text-center mb-lg-15">
						<picture>
							<source
								className="section14Img w-100 mx-0 ms-lg-auto mt-12 mt-lg-0"
								media="(min-width: 992px)"
								srcSet="./images/productCenter/productFeature/section14_1_2x.png"
								alt="section14Img"
							/>
							<img
								className="section14Img w-100 px-4 mx-0 ms-lg-auto mt-lg-2"
								src="./images/productCenter/productFeature/section14_1_1x.png"
								alt="section14Img"
							/>
						</picture>

						<div className="d-none d-lg-block section14InfoBox position-absolute">
							<div className="leftTopWrap position-absolute">
								<h3 className="title text-black text-start mb-4">
									快速响应 无缝连接
								</h3>
								<h6 className="text text-grey5 text-start">
									支持蓝牙与Wi-Fi无线网络双重连机模式，瞬间自动匹配，任意方向调节，无须对着发球机，无须額外遙控器，摆脱指向性遥控，故障率低，无线不缠绕。
								</h6>
							</div>
							<div className="rightTopWrap position-absolute">
								<h3 className="title text-black text-start mb-4">
									全面参数 随心定义
								</h3>
								<h6 className="text text-grey5 text-start">
									<ul className="ps-0">
										<li className="mb-1">
											✓ 出球頻率、出球速度、出球角度
										</li>
										<li className="mb-1">
											✓ 旋球角度、旋球強度
										</li>
										<li className="mb-1">
											✓ 隨機出球、出球延遲
										</li>
										<li className="mb-1">
											✓ 打球時間、打球球數
										</li>
									</ul>
								</h6>
							</div>
							<div className="leftBottomWrap position-absolute">
								<h3 className="title text-black text-start mb-3">
									介面可视化 直观易使用
								</h3>
								<h6 className="text text-grey5 text-start">
									宾乐达优良介面设计让您专注编程球路组合，依据需求任意设置球谱参数，直观提供触手可及的多样内容和强大功能，持续更新版本，满足各种训练要求。
								</h6>
							</div>
						</div>

						{/* mobile */}
						<div className="d-block d-lg-none section14mobileBox mt-6">
							<div
								className={`box1 ${
									section14Slider === 1 ? 'd-block' : 'd-none'
								}`}
							>
								<h3 className="title text-black text-start px-7 mb-2">
									快速响应 无缝连接
								</h3>
								<h6 className="text text-grey5 text-start px-7 mb-0">
									支持蓝牙与Wi-Fi无线网络双重连机模式，瞬间自动匹配，任意方向调节，无须对着发球机，无须額外遙控器，摆脱指向性遥控，故障率低，无线不缠绕。
								</h6>
							</div>
							<div
								className={`box2 ${
									section14Slider === 2 ? 'd-block' : 'd-none'
								}`}
							>
								<h3 className="title text-black text-start px-7 mb-2">
									全面参数 随心定义
								</h3>
								<h6 className="text-grey5 text-start px-7 mb-0">
									<ul className="ps-0">
										<li className="mb-1">
											✓ 出球頻率、出球速度、出球角度
										</li>
										<li className="mb-1">
											✓ 旋球角度、旋球強度
										</li>
										<li className="mb-1">
											✓ 隨機出球、出球延遲
										</li>
										<li className="mb-1">
											✓ 打球時間、打球球數
										</li>
									</ul>
								</h6>
							</div>
							<div
								className={`box3 ${
									section14Slider === 3 ? 'd-block' : 'd-none'
								}`}
							>
								<h3 className="title text-black text-start px-7 mb-2">
									介面可视化 直观易使用
								</h3>
								<h6 className="text-grey5 text-start px-7 mb-0">
									宾乐达优良介面设计让您专注编程球路组合，依据需求任意设置球谱参数，直观提供触手可及的多样内容和强大功能，持续更新版本，满足各种训练要求。
								</h6>
							</div>

							<div className="sliderBtnWrapper d-flex justify-content-between p-2 border border-grey2 rounded-pill mt-6 mb-5 mx-auto">
								<div
									className={`btn-1 p-1 rounded-circle ${
										section14Slider === 1
											? 'bg-grey4'
											: 'bg-grey3'
									}`}
									onClick={() => setSection14Slider(1)}
								></div>
								<div
									className={`btn-2 p-1 rounded-circle ${
										section14Slider === 2
											? 'bg-grey4'
											: 'bg-grey3'
									}`}
									onClick={() => setSection14Slider(2)}
								></div>
								<div
									className={`btn-3 p-1 rounded-circle ${
										section14Slider === 3
											? 'bg-grey4'
											: 'bg-grey3'
									}`}
									onClick={() => setSection14Slider(3)}
								></div>
							</div>
						</div>
					</div>
					<div className="col-12 text-center">
						<Link to="/explore">
							<button
								type="button"
								className="btn btn-outline-black rounded-pill py-2"
							>
								使用技巧
							</button>
						</Link>
					</div>
				</section>
				<section className="productFeature-section section15 w-100 row bg-white">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h1 className="text-primary mb-4 mb-lg-11">
							<span className="mb-1 mb-lg-0 me-0 me-lg-3 d-block d-lg-inline">
								售后有保证
							</span>
							<span className="d-block d-lg-inline">
								乒乓放心练
							</span>
						</h1>
						<p className="px-4 mx-auto mb-6 mb-lg-12 text-grey5">
							让您体验贴心完善的售后保固服务!
							请放心购买宾乐达智能发球机，同步宾乐达配件，让您便捷装卸机体和周边装置。
							我们的帮助热线会在需要时帮您解决问题，提供一年质保耐用又省心，练球更安心。
						</p>
						<div className="textWrap d-flex justify-content-between mx-auto px-11 px-lg-0">
							<div>
								<h2 className="mb-2 text-primary">
									<img
										className="section15_1Img h-100 mx-0"
										src="./images/productCenter/productFeature/section15_1_2x.png"
										alt="section15_1Img"
									/>
								</h2>
								<h2 className="text-primary mb-0 mb-lg-2">
									正品保证
								</h2>
							</div>
							<div className="">
								<h2 className="mb-2 text-primary">
									<img
										className="section15_2Img h-100 mx-0"
										src="./images/productCenter/productFeature/section15_2_2x.png"
										alt="section15_2Img"
									/>
								</h2>
								<h2 className="text-primary mb-0 mb-lg-2">
									优质客服
								</h2>
							</div>
							<div className="">
								<h2 className="mb-2 text-primary">
									<img
										className="section15_3Img h-100 mx-0"
										src="./images/productCenter/productFeature/section15_3_2x.png"
										alt="section15_3Img"
									/>
								</h2>
								<h2 className="text-primary mb-0 mb-lg-2">
									一年质保
								</h2>
							</div>
						</div>
					</div>
				</section>
				<section className="productFeature-section section16 w-100 row justify-content-center px-0 bg-white">
					<div className="col-12 desc px-3 px-lg-0">
						<p className="text-grey3 mb-0 mb-lg-3">说明：</p>
						<ul className="text-grey3 ps-0 ps-lg-5 mb-4 mb-lg-5">
							<li>
								1、不同应用界面及游戏画面下，屏幕刷新率可能不同，请以实际体验为准。
							</li>
							<li>2、数据来源于宾乐达实验室。</li>
							<li>3、本产品非医疗器械，不具有治疗功能。</li>
						</ul>
						<p className="text-grey3 mb-0 mb-lg-3">
							以上页面中的产品图片及屏幕内容仅作示意，实物产品效果（包括但不限于外观、颜色、尺寸）和屏幕显示内容（包括但不限于背景、UI、配图）可能略有差异，请以实物为准。
							<br />
							以上页面中的数据为理论值，均来自宾乐达内部实验室，于特定测试环境下所得（请见各项具体说明），实际使用中可能因产品个体差异、软件版本、使用条件和环境因素不同略有不同，请以实际使用的情况为准。
							<br />
							由于产品批次和生产供应因素实时变化，为尽可能提供准确的产品信息、规格参数、产品特性，宾乐达可能实时调整和修订以上页面中的文字表述、图片效果等内容，以求与实际产品性能、规格、指数、零部件等信息相匹配。
							<br />
							如遇确有进行上述修改和调整必要的情形，恕不专门通知。
						</p>
					</div>

					<SupportBottom
						leftCol={{
							title: `产品说明书`,
							text: `查看更多内容`,
							link: `/support/downloadcenter`,
						}}
						rightCol={{
							title: `APP软件下载`,
							text: `查看更多内容`,
							link: `/app/download`,
							section: '.section1',
						}}
					/>

					{/* <div className="col-12 p-0 w-100 afterSalesService border-grey2 border-top bg-white text-center">
                        <h2 className="mt-6 mt-lg-7 mb-2 mb-lg-3 text-grey5">
                            售后服务
                        </h2>
                        <h6 className="px-8 px-lg-0 mb-5 text-grey5">
                            欢迎使用宾乐达售后服务，期待为您提供帮助。
                        </h6>
                        <div className="btn btn-primary rounded-pill text-white mb-6 mb-lg-14">
                            跳转至服务功能
                        </div>
                    </div> */}
				</section>
			</article>
		</div>
	);
}

export default ProductFeature;
