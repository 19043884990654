import './questionUpdateDetail.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';

const QuestionUpdateDetail = () => {
    const navigate = useNavigate();

    const [categoryCode, setCategoryCode] = useState('');
    const [displayOrder, setDisplayOrder] = useState('');
    const [languageCode, setLanguageCode] = useState('');
    const [status, setStatus] = useState('');
    const [questionText, setQuestionText] = useState('');
    const [answerText, setAnswerText] = useState('');

    const [rowStamp, setRowStamp] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [categoryCodeList, setCategoryCodeList] = useState([]);

    const paramsGuid = useParams('qaGuid');
    const isEditPage = Object.keys(paramsGuid).length === 0 ? false : true;
    // console.log(paramsGuid);
    // console.log(isEditPage);

    // get qacategory
    useEffect(() => {
        (async () => {
            await axios
                .get(`web/backstage/qacategory`)
                .then((res) => {
                    console.log(res.data.result);
                    let list = [];
                    res.data.result.forEach((el) => {
                        list.push(el.categoryCode);
                    });
                    setCategoryCodeList(list);
                })
                .catch((err) => console.log(err));
        })();
    }, []);
    // console.log(categoryCodeList);

    //edit getInfo
    useEffect(() => {
        if (isEditPage)
            (async () => {
                await axios
                    .get(`web/backstage/qa/${paramsGuid.qaGuid}`)
                    .then((res) => {
                        const QAUpdateObj = res.data.result;
                        console.log(QAUpdateObj);

                        setCategoryCode(QAUpdateObj.categoryCode);
                        setDisplayOrder(QAUpdateObj.displayOrder);
                        setLanguageCode(QAUpdateObj.languageCode);
                        setQuestionText(QAUpdateObj.question);
                        setAnswerText(QAUpdateObj.answer);
                        setStatus(QAUpdateObj.status);
                        setRowStamp(QAUpdateObj.rowStamp);
                    })
                    .catch((err) => console.log(err));
            })();
    }, [paramsGuid]);

    // edit delete
    const handleDeleteItem = async () => {
        await Swal.fire({
            html: `<h4>確定要刪除?</h4>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: '取消',
            showConfirmButton: true,
            confirmButtonText: '確定',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((res) => {
            if (res.dismiss === 'cancel') {
                console.log('cancelled');
                return;
            } else {
                console.log('confirmed');
                axios
                    .delete(`web/backstage/qa/${paramsGuid.qaGuid}`)
                    .then(() => {
                        navigate('/questionupdate');
                    })
                    .catch((err) => console.log(err));
            }
        });
    };

    // edit submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);

        if (isEditPage) {
            //edit
            setIsLoading(true);

            await axios
                .put(`web/backstage/qa/${paramsGuid.qaGuid}`, form)
                .then((res) => {
                    console.log(res.data);
                    setIsLoading(false);
                    navigate('/questionupdate');
                })
                .catch((err) => {
                    setIsLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: '網路不穩，儲存失敗',
                        text: '請再次儲存。',
                    });
                    console.log(err);
                });
        } else {
            //create
            setIsLoading(true);

            await axios
                .post(`web/backstage/qa`, form)
                .then((res) => {
                    console.log(res.data);
                    setIsLoading(false);
                    navigate('/questionupdate');
                })
                .catch((err) => {
                    setIsLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: '網路不穩，儲存失敗',
                        text: '請再次儲存。',
                    });
                    console.log(err);
                });
        }
    };

    return (
        <>
            <LoadingAnimate isLoading={isLoading} />

            <section id="section-main">
                <form
                    id="container-questionUpdateDetail"
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex">
                        <h5 className="text-grey4 fw-bold mb-4">常見問題</h5>
                        <button
                            className={`btnDelete btn btn-outline-danger px-4 ms-auto me-2 ${
                                !isEditPage && 'd-none'
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                        >
                            刪除
                        </button>
                        <button
                            className={`btnCancel btn btn-outline-primary px-4 me-2 ${
                                !isEditPage && 'ms-auto'
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/questionupdate');
                            }}
                        >
                            取消
                        </button>
                        <button className="btn btn-primary text-white px-4">
                            儲存
                        </button>
                    </div>

                    <div className="manageFileWrapper bg-white p-4 rounded-4 mb-3">
                        <input
                            id="rowStamp"
                            name="rowStamp"
                            className="d-none"
                            defaultValue={rowStamp}
                        />
                        <h6 className="text-grey4 fw-bold mb-3">檔案管理</h6>
                        <div className="row mb-3">
                            <div className="col-6">
                                <label
                                    htmlFor="questionText"
                                    className="fontSize75Rem mb-2"
                                >
                                    問題
                                </label>
                                <textarea
                                    id="questionText"
                                    name="question"
                                    className="form-control"
                                    rows="5"
                                    value={questionText || ''}
                                    onChange={(e) =>
                                        setQuestionText(e.target.value)
                                    }
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label
                                    htmlFor="answerText"
                                    className="fontSize75Rem mb-2"
                                >
                                    回答
                                </label>
                                <textarea
                                    id="answerText"
                                    name="answer"
                                    className="form-control"
                                    rows="5"
                                    value={answerText || ''}
                                    onChange={(e) =>
                                        setAnswerText(e.target.value)
                                    }
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="infoWrapper bg-white p-4 rounded-4">
                        <div className="infoTop d-flex align-items-center mb-3 ">
                            <h6 className="text-grey4 fw-bold mb-0">
                                檔案資訊
                            </h6>
                        </div>
                        <div className="infoBottom row">
                            <div className="col-4">
                                <label
                                    htmlFor="displayOrder"
                                    className="fontSize75Rem mb-2"
                                >
                                    顯示順序
                                </label>
                                <input
                                    type="number"
                                    id="displayOrder"
                                    name="displayOrder"
                                    className="form-control mb-3"
                                    value={displayOrder || ''}
                                    onChange={(e) =>
                                        setDisplayOrder(e.target.value)
                                    }
                                    required
                                />

                                <label
                                    htmlFor="languageCode"
                                    className="fontSize75Rem mb-2"
                                >
                                    語言代碼
                                </label>
                                <select
                                    className="form-control"
                                    name="languageCode"
                                    id="languageCode"
                                    onChange={(e) =>
                                        setLanguageCode(e.target.value)
                                    }
                                    value={languageCode || ''}
                                    required
                                >
                                    <option value="" disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value="ZF">繁體中文</option>
                                    <option value="ZH">簡體中文</option>
                                    <option value="EN">英文</option>
                                    <option value="JA">日文</option>
                                </select>
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="categoryCode"
                                    className="fontSize75Rem mb-2"
                                >
                                    型態代碼
                                </label>
                                <select
                                    className="form-control mb-3"
                                    name="categoryCode"
                                    id="categoryCode"
                                    onChange={(e) =>
                                        setCategoryCode(e.target.value)
                                    }
                                    value={categoryCode || ''}
                                    required
                                >
                                    <option value="" disabled>
                                        -- 請選擇 --
                                    </option>
                                    {categoryCodeList.map((item) => {
                                        return (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="status"
                                    className="fontSize75Rem mb-2"
                                >
                                    狀態
                                </label>
                                <select
                                    className="form-control"
                                    name="status"
                                    id="status"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status || ''}
                                    required
                                >
                                    <option value="" disabled>
                                        -- 請選擇 --
                                    </option>
                                    <option value="0">停用</option>
                                    <option value="1">啟用</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default QuestionUpdateDetail;
