import './productVipSearch.scss';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { SearchRounded } from '@material-ui/icons';
import axios from 'axios';

function ProductVipSearch() {
	const navigate = useNavigate();
	const [searchedMember, setSearchedtMember] = useState(null);
	const [searchType, setSearchType] = useState('0');
	const [countryCode, setCountryCode] = useState('');
	const [phoneCode, setPhoneCode] = useState('');
	const [emailOrID, setEmailOrID] = useState('');
	const [searchResult, setSearchResult] = useState('');

	// Check email input
	const handleEmailInput = () => {
		const emailReg =
			/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;

		if (!emailOrID) {
			document.getElementById('searched-info').innerText =
				'请输入电子邮箱';
		} else if (!emailReg.test(emailOrID)) {
			document.getElementById('searched-info').innerText =
				'請填寫正確电子邮箱';
		} else {
			document.getElementById('searched-info').innerText = '';
			handleSearchFamilyMember();
		}
	};

	// Check phone input
	const handlePhoneInput = () => {
		const phoneCodeReg = /^\d{0,20}$/;
		if (!phoneCode || !countryCode) {
			document.getElementById('searched-info').innerText = '请输入手机号';
		} else if (!phoneCodeReg.test(phoneCode)) {
			document.getElementById('searched-info').innerText =
				'請填寫正確手机号';
		} else {
			document.getElementById('searched-info').innerText = '';
			handleSearchFamilyMember();
		}
	};

	// search family member
	const handleSearchFamilyMember = async () => {
		let dataUrl;
		if (emailOrID) {
			dataUrl = `?emailOrID=${emailOrID}&accountType=${searchType}`;
		} else if (phoneCode && countryCode) {
			dataUrl = `?countryCode=${countryCode}&phoneCode=${phoneCode}&accountType=${searchType}`;
		} else return;

		await axios(`/web/user/searchfamilymember${dataUrl}`).then((res) => {
			document.getElementById('searched-info').innerText = '';
			console.log(res.data);
			const status = res.data.status;
			const familyStatus = res.data.familyStatus;
			if (status === '1') {
				setSearchedtMember(null);
				document.getElementById('searched-info').innerText =
					'查無此人, 請重新搜索';

				return;
			}

			if (familyStatus === '1') {
				setSearchResult('该帐号已属于您的家族成员！无法重复加入！');
				setSearchedtMember(['1', res.data]);
			} else if (familyStatus === '2') {
				setSearchResult('该帐号已属于其他的家族成员！无法加入！');
				setSearchedtMember(['2', res.data]);
			} else {
				setSearchResult('确定要将此帐号加入你的家族成员？');
				setSearchedtMember(['0', res.data]);
			}
		});
		setEmailOrID('');
		setCountryCode('');
		setPhoneCode('');
	};

	const handleSearchTypeChange = () => {
		if (searchType === '0') {
			setSearchType('1');
			setEmailOrID('');
		} else if (searchType === '1') {
			setSearchType('0');
			setPhoneCode('');
			setCountryCode('');
		}
	};

	// send confirmation email
	const handleSendConfirmFamilyMail = async () => {
		console.log(searchedMember);
		const postObj = {
			memberGuid: searchedMember[1]['userGuid'],
		};
		try {
			const res = await axios.post(`/web/user/addfamilymember`, postObj);
			console.log(res.data);
			setSearchedtMember(null);
			navigate('/member/productvip');
		} catch (err) {
			console.log(err);
		}
	};

	//// 取消
	function cancel() {
		navigate('/member/productVip');

		//    var url = location.href.split('vipgive');
		//    location.href = (url[0] + "manage" + url[1]);
	}

	searchedMember && console.log(searchedMember);
	return (
		<article id="container-productVipSearch">
			<div className="articleBoxWithSideBox">
				<p className="containTitle">用户家族</p>
				<article>
					<input type="hidden" id="groupGuid" value="" />
					{/*<!-- 搜尋 -->*/}
					{/*@include('Community._friendSearch')*/}
					<section className="section-search">
						<form className="form-search" id="f-form">
							{/*<!-- 搜尋 -->*/}
							<div className="row justify-content-center">
								{searchType === '0' ? (
									<div className="col-12 emailID-group position-relative">
										<input
											className="form-control form-control-lg"
											type="text"
											value={emailOrID}
											placeholder="搜索帐号"
											onChange={(e) =>
												setEmailOrID(e.target.value)
											}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													e.preventDefault();
													handleEmailInput();
												}
											}}
										/>

										<SearchRounded
											className="search__btn"
											onClick={handleEmailInput}
										/>
										{/* <div className="input-group-append">
											<div
												className="btn btn-color-auxiliary"
												onClick={
													handleSearchFamilyMember
												}
											></div>
										</div> */}
									</div>
								) : (
									<div className="col-12 phone-group position-relative">
										<div className="row">
											<div className="col-4">
												<select
													className="form-select form-select-lg"
													id="phone1CountryCode"
													name="phone1CountryCode"
													value={countryCode || ''}
													onChange={(e) => {
														setCountryCode(
															e.target.value
														);
													}}
												>
													<option value="86">
														+86
													</option>
													<option value="886">
														+886
													</option>
													<option value="852">
														+852
													</option>
												</select>
											</div>
											<div className="col-8">
												<input
													type="text"
													className="form-control form-control-lg"
													name="phone1"
													id="phone1"
													placeholder="搜索帐号"
													value={phoneCode || ''}
													onChange={(e) => {
														setPhoneCode(
															e.target.value
														);
													}}
												/>
												<SearchRounded
													className="search__btn"
													onClick={handlePhoneInput}
												/>
											</div>
										</div>
									</div>
								)}
								<div
									className="col-12 change__search"
									onClick={(e) => handleSearchTypeChange()}
								>
									{searchType === '0'
										? '用手机号码搜索'
										: '用邮箱/用户名搜索'}
								</div>
								<div
									className="col-12 invalid-feedback"
									id="searched-info"
								></div>
							</div>
						</form>
					</section>
					<section>
						<div className="cardBox">
							{searchedMember && (
								<div className="card-body d-flex flex-column align-items-center">
									<div className="card-avatar-outer">
										<div className="card-avatar-inner">
											<img
												className="card-avatar"
												src={
													searchedMember[1]
														.photoLink ||
													'./images/user-default.png'
												}
												alt=""
											/>
										</div>
									</div>
									<div className="card-title">
										用户：
										{searchedMember[1].fullName}
									</div>
									<p className="card-text">{searchResult}</p>
									{searchedMember[0] === '0' && (
										<section className="section-footer d-flex">
											<button
												type="button"
												className="btn btn-color-outline-white btn-size-s btn__cancel me-3"
												onClick={cancel}
											>
												取消
											</button>
											<button
												type="button"
												className="btn btn-color-auxiliary btn-size-s btn__confirm"
												onClick={
													handleSendConfirmFamilyMail
												}
											>
												确定
											</button>
										</section>
									)}
								</div>
							)}
						</div>
					</section>
				</article>
			</div>
		</article>
	);
}

export default ProductVipSearch;
