import './supportDownloadCenter.scss';
import React, { useEffect, useState } from 'react';
import SupportTop from '../SupportTop';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SupportBottom from '../SupportBottom';

function SupportDownloadCenter() {
    const [manual, setManual] = useState([]);

    //獲取產品說明書
    const getManual = async () => {
        await axios
            .get('web/service/manual')
            .then((res) => {
                console.log(res.data.result);
                setManual(res.data.result);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getManual();
    }, []);

    //獲取最新版apk下載連結
    const getNewestAndroid = async () => {
        await axios
            .get('web/sys/appdownloadurl?OSCode=1')
            .then((res) => {
                //console.log(res);
                const url = res.data.result;

                // window.location.href = url;
                window.open(url, '_blank');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="container-fluid p-0 bg-white">
            <article
                id="container-supportDownloadCenter"
                className="d-flex flex-column align-items-center px-0 mx-auto"
            >
                <SupportTop />

                <section className="supportDownloadCenter-section section2 w-100 row bg-white">
                    <div
                        className="col-12 p-0 text-center
					"
                    >
                        <h2 className="px-6 mx-auto mb-3 text-grey5">
                            下载中心
                        </h2>
                        <h6 className="px-6 mx-auto mb-0 text-grey5">
                            宾乐达产品说明、使用规范等总结并罗列出详细内容，供您尽情查阅！
                        </h6>
                    </div>
                </section>

                <section className="supportDownloadCenter-section section3 w-100 row bg-white">
                    {manual.map((item) => {
                        return (
                            <div
                                key={item.manualPath}
                                className="manualWrapper col-12 col-lg-4 px-3 py-3 mx-auto mx-lg-auto"
                            >
                                <div
                                    className="manualItem p-4 bg-background
					"
                                >
                                    <h2 className="mb-3 text-grey5">
                                        {item.title}
                                    </h2>
                                    <h6 className="mb-3 text-grey3">
                                        {item.title}.pdf
                                    </h6>
                                    <h6 className="mb-4 text-grey3">
                                        {item.createTime.split('T')[0]} |{' '}
                                        {item.fileSize}M
                                    </h6>
                                    <Link
                                        to={'/' + item.manualPath}
                                        target="_blank"
                                    >
                                        <div className="btn btn-dark rounded-pill text-white">
                                            下载
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </section>

                <section className="supportDownloadCenter-section section4 w-100 row bg-white pb-9 pb-lg-7">
                    <div
                        className="col-12 p-0 text-center
					"
                    >
                        <h2 className="px-6 mx-auto mb-3 text-grey5">
                            APP软件下载
                        </h2>
                        <h6 className="px-6 mx-auto text-grey5">
                            欢迎使用宾乐达专属控制软件，即刻安装 !
                        </h6>
                    </div>
                </section>

                <section className="supportDownloadCenter-section section5 w-100 row py-7 px-lg-13 py-lg-7 mb-lg-5 bg-background">
                    <div
                        className="col-12 col-lg-6 mb-5 mb-lg-0
					"
                    >
                        <div className="d-flex flex-column flex-lg-row align-items-center">
                            <div className="leftCol mb-3 mb-lg-0 me-lg-4">
                                <img
                                    className="section5_1_2x h-100 mx-0"
                                    src="./images/support/supportDownloadCenter/section5_1_2x.png"
                                    alt="section5_1_2x"
                                />
                            </div>
                            <div className="rightCol d-flex flex-column justify-content-center">
                                <p className="mb-2">宾乐达专用智能APP</p>
                                <h5 className="text-grey3 text-center text-lg-start mb-0">
                                    一开即用 一键开打
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="h-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                            <div
                                className="mb-2 mb-lg-0"
                                // to="/support/downloadcenter"\
                                onClick={() =>
                                    window.open(
                                        'https://apps.apple.com/cn/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id1611116746',
                                        '_blank'
                                    )
                                }
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    className="section5_2_2x me-lg-4"
                                    src="./images/support/supportDownloadCenter/section5_2_2x.png"
                                    alt="section5_2_2x"
                                />
                            </div>
                            <div
                                // to={{
                                //     pathname: '/support/downloadcenter',
                                //     state: {
                                //         initialLocation: '.section4',
                                //     },
                                // }}
                                onClick={getNewestAndroid}
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    className="section5_3_2x"
                                    src="./images/support/supportDownloadCenter/section5_3_2x.png"
                                    alt="section5_3_2x"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="supportDownloadCenter-section section6 w-100 bg-white text-center pt-5 pt-lg-0 pb-10 pb-lg-0">
                    <div
                    // to={{
                    //     pathname: '/support/downloadcenter',
                    //     state: {
                    //         initialLocation: '.section4',
                    //     },
                    // }}
                    >
                        <div
                            className="btn btn-primary rounded-pill text-white"
                            onClick={getNewestAndroid}
                            style={{ cursor: 'pointer' }}
                        >
                            立即下载安卓版本
                        </div>
                    </div>
                </section>

                <SupportBottom
                    leftCol={{
                        title: `常见问题`,
                        text: `查看更多内容`,
                        link: `/support/question`,
                    }}
                    rightCol={{
                        title: `联系我们`,
                        text: `开始咨询`,
                        link: `/support/contactus`,
                    }}
                />
            </article>
        </div>
    );
}

export default SupportDownloadCenter;
