import './userDrill.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const UserDrill = () => {
	const [isGetUserDrillList, setIsGetUserDrillList] = useState(false);
	const [userDrillList, setUserDrillList] = useState('');
	const [searchText, setSearchText] = useState('');

	const [modalShow, setModalShow] = useState(false);
	const [forUserModalShow, setForUserModalShow] = useState(false);

	// 复制到宾乐达球谱 dialog
	const [drillCategoryGuid, setDrillCategoryGuid] = useState(null);
	const [drillCategoryItemGuid, setDrillCategoryItemGuid] = useState(null);
	const [drillCategoryList, setDrillCategoryList] = useState([]);
	const [drillCategoryItemList, setDrillCategoryItemList] = useState([]);
	const [drillClassList, setDrillClassList] = useState([]);
	const [languageCode, setLanguageCode] = useState('ZH');
	const [languageList, setLanguageList] = useState([
		{ languageCode: 'ZH', name: '简体中文' },
		{ languageCode: 'ZF', name: '繁體中文' },
		{ languageCode: 'EN', name: 'English' },
		{ languageCode: 'JA', name: '日本語' },
	]);

	// 复制到用户 dialog
	const [copyUserInfo, setCopyUserInfo] = useState(null);
	const [accountType, setAccountType] = useState('1');
	const [countryCode, setCountryCode] = useState('86');
	const [searchInputValue, setSearchInputValue] = useState(null);
	const [noOnFound, setNoOnFound] = useState(false);
	const [userDrillClassList, setUserDrillClassList] = useState([]);
	const [userDrillClassGuid, setUserDrillClassGuid] = useState(null);

	const navigate = useNavigate();
	const location = useLocation();
	const fullname = location.state.fullname;
	const paramsGuid = useParams('userGuid');

	const statusList = {
		0: '"0"：未启用',
		1: '"1"：启用中',
		2: '"2"：停用中',
		4: '"4"：删除',
	};

	const clickSvg = (
		<svg
			id="icn_Check-r"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<path
				id="_Color"
				data-name=" ↳Color"
				d="M14.222,0H1.778A1.777,1.777,0,0,0,0,1.778V14.222A1.777,1.777,0,0,0,1.778,16H14.222A1.777,1.777,0,0,0,16,14.222V1.778A1.777,1.777,0,0,0,14.222,0Z"
				transform="translate(0 0)"
				fill="#e3e8ee"
				opacity="0"
			/>
			<path
				id="Path"
				d="M4.444,9.262,0,4.818,1.253,3.564,4.444,6.747,11.191,0l1.253,1.262Z"
				transform="translate(1.778 3.182)"
				fill="#1278e8"
			/>
		</svg>
	);

	// --複製成功dialog --
	const successAlert = () => {
		Swal.fire({
			title: '複製成功',
			html: `<h6>宾乐达球谱复制成功!</h6>`,
			icon: 'success',
			confirmButtonText: '返回',
			allowOutsideClick: false,
		}).then((result) => {
			if (result.isConfirmed) {
				setModalShow(false);
			}
		});
	};

	const handleCopyUserDrill = async () => {
		await axios
			.post(`web/SysDrill/copyUserDrillClass/${userDrillClassGuid}`, {
				drillCategoryGuid,
				drillCategoryItemGuid,
				languageCode,
			})
			.then((res) => {
				res.status === 200 && successAlert();
			})
			.catch((err) => console.log(err));
	};

	const handleCopyUserDrillToUser = async () => {
		console.log(copyUserInfo);

		await axios
			.post(
				`web/sysDrill/copyUserDrillClassToUser/${userDrillClassGuid}`,
				{
					userGuid: copyUserInfo.userGuid,
					drillClassGuid: userDrillClassGuid,
				}
			)
			.then((res) => {
				res.status === 200 && successAlert();
				setForUserModalShow(false);
			})
			.catch((err) => console.log(err));
	};

	let callAPItimes = 0;
	const [inputDisabled, setInputDisabled] = useState(false);
	const getDrillCategoryItemList = async (categoryGuid) => {
		setDrillCategoryItemGuid('');

		const fetchData = async () => {
			try {
				const response = await axios.get(
					`web/sysDrill/drillCategoryItem?guid=${categoryGuid}`
				);
				const data = response.data.result;

				setDrillCategoryItemList(data);
				setDrillCategoryItemGuid(data[0].categoryItemGuid);
				setInputDisabled(false);
			} catch (error) {
				console.log(error);
				// 如果是由于 JWT 错误导致的错误，等待两秒后重新执行请求
				if (
					error.response &&
					error.response.status === 550 &&
					callAPItimes < 2
				) {
					callAPItimes++;
					console.log(callAPItimes);
					setInputDisabled(true); //鎖定確定按鈕
					await new Promise((resolve) => setTimeout(resolve, 2000)); // 等待两秒
					await fetchData(); // 重新执行请求
				}
			}
		};

		await fetchData();
	};

	// const [isGetUserDrillClassList, setIsGetUserDrillClassList] = useState([]);
	// const getUserDrillClassList = useCallback(() => {
	// 	setIsGetUserDrillClassList(false);

	// 	const gettingUserDrillClassList = async () => {
	// 		await axios
	// 			.get(`web/sysDrill/userDrillClass?guid=${paramsGuid.userGuid}`)
	// 			.then((res) => {
	// 				const data = res.data.result;
	// 				console.log(data);

	// 				data.sort(function (a, b) {
	// 					return a.displayOrder - b.displayOrder;
	// 				});

	// 				setUserDrillClassList(data);
	// 				setIsGetUserDrillClassList(true);
	// 			})
	// 			.catch((err) => console.log(err));
	// 	};

	// 	gettingUserDrillClassList();
	// }, []);

	const handleModalOpen = () => {
		setDrillCategoryGuid('');
		setDrillCategoryItemGuid('');

		const getDrillCategoryList = async () => {
			await axios
				.get(`web/sysDrill/drillCategory`)
				.then((res) => {
					const data = res.data.result;
					// console.log(data);

					setDrillCategoryList(data);
					setDrillCategoryGuid(data[0].categoryGuid);

					getDrillCategoryItemList(data[0].categoryGuid);
				})
				.catch((err) => console.log(err));
		};
		getDrillCategoryList();
		setModalShow(true);
	};

	const handleForUserModalShow = () => {
		setDrillCategoryGuid('');
		setDrillCategoryItemGuid('');

		const getDrillCategoryList = async () => {
			await axios
				.get(`web/sysDrill/drillCategory`)
				.then((res) => {
					const data = res.data.result;
					// console.log(data);

					setDrillCategoryList(data);
					setDrillCategoryGuid(data[0].categoryGuid);

					getDrillCategoryItemList(data[0].categoryGuid);
				})
				.catch((err) => console.log(err));
		};
		getDrillCategoryList();
		setForUserModalShow(true);
	};

	const handleGetUniqueUser = async () => {
		// uniqueUserInfo
		if (accountType && searchInputValue) {
			setCopyUserInfo(null);
			setNoOnFound(false);
			await axios
				.post(`web/sysDrill/uniqueUserInfo`, {
					accountType,
					email: accountType === '0' ? searchInputValue : null,
					phoneCode: accountType === '0' ? null : searchInputValue,
					countryCode,
				})
				.then(({ data }) => {
					console.log(data);
					const { fullname, photoLink, userGuid } = data;

					if (data) {
						setCopyUserInfo({
							fullname,
							photoLink,
							userGuid,
						});
					} else {
						setNoOnFound(true);
					}
				})
				.catch((err) => console.log(err));
		}
	};

	const getUserDrillList = useCallback(() => {
		setIsGetUserDrillList(false);

		const gettingUserDrillList = async () => {
			await axios
				.get(`web/sysDrill/userDrill/${paramsGuid.userGuid}`)
				.then((res) => {
					const data = res.data.result;
					const userDrillClassList = res.data.userDrillClassList;
					// console.log(data);

					setUserDrillList(data);
					setIsGetUserDrillList(true);
					setUserDrillClassList(userDrillClassList);
				})
				.catch((err) => console.log(err));
		};

		gettingUserDrillList();
	}, []);

	useEffect(() => {
		getUserDrillList();
	}, [getUserDrillList]);

	//過濾搜尋
	const filterUserDrillList = async () => {
		const filterList = userDrillList.filter((item) => {
			const searchItems = { ...item };
			delete searchItems.userDrillGuid;

			let isInclude = false;
			let drillCategoryInfoArray = Object.values(searchItems);
			for (let i = 0; i < drillCategoryInfoArray.length; i++) {
				// console.log(drillCategoryInfoArray[i]);
				// if (i === 6)
				//     drillCategoryInfoArray[i] &&
				//         statusList[drillCategoryInfoArray[i]]
				//             .toString()
				//             .includes(searchText.toLowerCase());

				if (
					drillCategoryInfoArray[i] &&
					drillCategoryInfoArray[i]
						.toString()
						.toLowerCase()
						.includes(searchText.toLowerCase())
				) {
					isInclude = true;
				}
			}

			return isInclude;
		});
		// console.log(filterList);

		setUserDrillList(filterList);
	};

	//過濾搜尋
	const resetFilterUserDrillList = () => {
		setSearchText('');
		getUserDrillList();
	};

	//表格排序
	function sortTable(n, alwaysDesc) {
		let table = document.querySelector('.table'); // 取得表格元素
		let rows = Array.from(table.tBodies[0].rows); // 取得表格主體的所有列
		let dir = 'asc'; // 預設排序方式為升序
		let thSpans = table.rows[0].querySelectorAll('th span'); // 取得所有表頭的圖示元素

		// 檢查當前欄位是否已經按升序排序
		if (thSpans[n].innerHTML === '▲') {
			dir = 'desc'; // 若是，將排序方式設為降序
		}

		// 清除所有欄位的排序圖示
		thSpans.forEach((span) => (span.innerHTML = ''));

		// 為當前欄位設置排序圖示
		thSpans[n].innerHTML = dir === 'asc' ? '▲' : '▼';

		// 對列進行排序
		rows.sort((a, b) => {
			const aValue = a.cells[n].textContent; // 取得列的當前欄位值
			const bValue = b.cells[n].textContent; // 取得列的下一列對應欄位值

			const aIsNumber = !isNaN(aValue);
			const bIsNumber = !isNaN(bValue);

			if (aIsNumber && bIsNumber && dir === 'asc') {
				// 如果都是數字，則按數字大小排序
				return parseInt(aValue, 10) - parseInt(bValue, 10);
			} else if (aIsNumber && bIsNumber && dir !== 'asc') {
				// 如果都是數字，則按數字大小排序
				return parseInt(bValue, 10) - parseInt(aValue, 10);
			} else if (aIsNumber) {
				// 如果只有 a 是數字，b 較大
				return -1;
			} else if (bIsNumber) {
				// 如果只有 b 是數字，a 較大
				return 1;
			} else {
				// 否則按字典順序排序
				if (dir === 'asc') {
					return aValue.localeCompare(bValue);
				} else {
					return bValue.localeCompare(aValue);
				}
			}
		});

		// 將排序後的列重新附加到表格主體
		rows.forEach((row) => table.tBodies[0].appendChild(row));
	}

	return (
		<section id="section-main">
			<div id="container-userDrill">
				{/* 复制到宾乐达球谱 */}
				<Modal
					className="copyDrillModal modal"
					show={modalShow}
					onHide={() => setModalShow(false)}
					centered
				>
					<Modal.Header closeButton className="border-bottom">
						<Modal.Title>
							复制此球谱分类所有球谱到宾乐达球谱
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row g-3">
							<div className="col-12">
								<label
									htmlFor="fullname"
									className="fontSize75Rem mb-2"
								>
									复制此球谱分类所有球谱
								</label>
								<select
									className="form-control mb-3 w-100"
									name="copyUserDrillClass"
									id="copyUserDrillClass"
									value={userDrillClassGuid}
									onChange={(e) => {
										setUserDrillClassGuid(e.target.value);
									}}
								>
									{userDrillClassList.map((el) => (
										<option
											value={el.classGuid}
											key={el.classGuid}
											id={el.classGuid}
										>
											{`${el.name}`}
										</option>
									))}
								</select>
								<label
									htmlFor="fullname"
									className="fontSize75Rem mb-2"
								>
									球谱类型
								</label>
								<select
									className="form-control mb-3 w-100"
									name="phone1CountryCode"
									id="phone1CountryCode"
									value="0"
									disabled
								>
									<option value="">0 宾乐达预设球谱</option>
								</select>
								<label
									htmlFor="fullname"
									className="fontSize75Rem mb-2"
								>
									球谱型态
								</label>
								<select
									className="form-control mb-3 w-100"
									name="phone1CountryCode"
									id="phone1CountryCode"
									value={drillCategoryGuid}
									onChange={(e) => {
										setDrillCategoryGuid(e.target.value);
										getDrillCategoryItemList(
											e.target.value
										);
									}}
								>
									{drillCategoryList.map((el) => (
										<option
											value={el.categoryGuid}
											key={el.categoryGuid}
											id={el.categoryGuid}
										>
											{`${el.name_ZH}`}
										</option>
									))}
								</select>
								<label
									htmlFor="fullname"
									className="fontSize75Rem mb-2"
								>
									球谱子型态
								</label>
								<select
									className="form-control mb-3 w-100"
									name="phone1CountryCode"
									id="phone1CountryCode"
									value={drillCategoryItemGuid}
									onChange={(e) => {
										setDrillCategoryItemGuid(
											e.target.value
										);
									}}
								>
									{drillCategoryItemList.map((el) => (
										<option
											value={el.categoryItemGuid}
											key={el.categoryItemGuid}
											id={el.categoryItemGuid}
										>
											{el.name_ZH}
										</option>
									))}
								</select>
								<label
									htmlFor="fullname"
									className="fontSize75Rem mb-2"
								>
									语言
								</label>
								<select
									className="form-control mb-3 w-100"
									name="languageCode"
									id="languageCode"
									value={languageCode}
									onChange={(e) => {
										setLanguageCode(e.target.value);
									}}
								>
									{languageList.map((el) => (
										<option
											value={el.languageCode}
											key={el.languageCode}
											id={el.languageCode}
										>
											{el.name}
										</option>
									))}
								</select>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className="justify-content-center mx-4">
						<button
							type="button"
							className="btn btn-primary text-white px-4 me-2"
							onClick={handleCopyUserDrill}
							disabled={inputDisabled}
						>
							確定
						</button>
						<button
							type="button"
							className="btnCancel btn btn-outline-primary px-4"
							onClick={() => setModalShow(false)}
						>
							取消
						</button>
					</Modal.Footer>
				</Modal>

				{/* 复制到用户 */}
				<Modal
					className="copyDrillModal modal"
					show={forUserModalShow}
					onHide={() => setForUserModalShow(false)}
					centered
				>
					<Modal.Header closeButton className="border-bottom">
						<Modal.Title>复制此球谱分类所有球谱到用户</Modal.Title>
					</Modal.Header>
					<Modal.Body className="pb-0">
						<div className="searchBox row mb-3">
							<div className="col-2 mx-1 px-0">
								<select
									className="form-control me-2"
									name="searchColumn"
									id="searchColumn"
									onChange={(e) => {
										setAccountType(e.target.value);
										setSearchInputValue('');
									}}
									value={accountType}
									required
								>
									{/* 可以用 電話、郵件、姓名、用戶名 查詢 */}
									<option value="1" id="phone1Search">
										手机号
									</option>
									<option value="0" id="email1Search">
										邮箱
									</option>
								</select>
							</div>
							{accountType === '1' && (
								<div className="col-2 mx-1 px-0">
									<select
										className="form-control me-2"
										name="searchColumn"
										id="searchColumn"
										onChange={(e) => {
											setCountryCode(e.target.value);
										}}
										value={countryCode}
										required
										disabled
									>
										<option value="86" id="86">
											+86
										</option>
										<option value="886" id="886">
											+886
										</option>
										<option value="852" id="852">
											+852
										</option>
									</select>
								</div>
							)}
							<div className="col mx-1 px-0">
								<input
									type={
										accountType === '1' ? 'number' : 'text'
									}
									className="searchInput form-control me-2"
									placeholder="搜寻"
									value={searchInputValue}
									// style={{ width: '120px' }}
									onChange={(e) =>
										setSearchInputValue(
											e.target.value.toLowerCase()
										)
									}
									// onKeyDown={(e) =>
									//     e.key === 'Enter' && filterUserList()
									// }
								/>
							</div>
							<div className="col-2 mx-1 px-0">
								<button
									className="btn btn-primary btn-size-s text-white"
									onClick={handleGetUniqueUser}
								>
									查询
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								{copyUserInfo ? (
									<div className="card-body d-flex flex-column align-items-center">
										<div className="card-avatar-outer">
											<div className="card-avatar-inner">
												<img
													className="card-avatar"
													src={
														copyUserInfo.photoLink ||
														'./images/user-default.png'
													}
													alt=""
												/>
											</div>
										</div>
										<div className="card-title">
											用户：{copyUserInfo.fullname}
										</div>

										<label
											htmlFor="userDrillClass"
											className="mb-2　d-inline"
										>
											{`复制此球谱分类所有球谱到 ${copyUserInfo.fullname} 帐号`}
										</label>
										<select
											className="form-control mb-5 w-50 d-inline"
											name="userDrillClass"
											id="userDrillClass"
											value={userDrillClassGuid}
											onChange={(e) => {
												setUserDrillClassGuid(
													e.target.value
												);
											}}
											disabled={
												userDrillClassList.length === 0
													? true
													: false
											}
										>
											{userDrillClassList.length !== 0 ? (
												userDrillClassList.map((el) => (
													<option
														value={el?.classGuid}
														key={el?.classGuid}
														id={el?.classGuid}
													>
														{el?.name}
													</option>
												))
											) : (
												<option value="noData">
													无资料，如需选择请先前往新增
												</option>
											)}
										</select>
									</div>
								) : (
									<div className="row text-center justify-content-center">
										<div className="col">
											{noOnFound ? '查無此人' : ''}
										</div>
									</div>
								)}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className="justify-content-center mx-4">
						<button
							type="button"
							className="btn btn-primary text-white px-4 me-2"
							onClick={copyUserInfo && handleCopyUserDrillToUser}
						>
							確定
						</button>
						<button
							type="button"
							className="btnCancel btn btn-outline-primary px-4"
							onClick={() => setForUserModalShow(false)}
						>
							取消
						</button>
					</Modal.Footer>
				</Modal>
				<h5 className="fw-bold mb-4">{fullname}的自编球谱</h5>
				<div className="searchWrapper bg-white p-4 rounded-4 mb-3">
					<div className="searchBox d-flex">
						<input
							className="searchInput form-control me-2"
							placeholder="搜寻"
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							onKeyDown={(e) =>
								e.key === 'Enter' && filterUserDrillList()
							}
						/>
						<button
							className="btn btn-outline-primary btn-size-s me-2"
							onClick={resetFilterUserDrillList}
						>
							重置
						</button>
						<button
							className="btn btn-primary btn-size-s text-white"
							onClick={filterUserDrillList}
						>
							查询
						</button>
					</div>
				</div>

				<div className="searchTableWrapper bg-white p-4 rounded-4">
					<div className="searchTableTop d-flex align-items-center mb-3 ">
						<h6 className="fw-bold">球谱列表</h6>
						<button
							className="btn btn-primary text-white ms-auto me-2"
							onClick={handleForUserModalShow}
						>
							<svg
								id="icn_Create"
								className="me-2 mb-1"
								viewBox="0 0 12 12"
								width="12"
								height="12"
								fill="#fff"
							>
								<defs> </defs>
								<path
									className="cls-1"
									id="_Color"
									d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
									data-name=" ↳Color"
								></path>
							</svg>
							<span>依分类复制給用戶</span>
						</button>
						<button
							className="btn btn-primary text-white me-2"
							onClick={handleModalOpen}
						>
							<svg
								id="icn_Create"
								className="me-2 mb-1"
								viewBox="0 0 12 12"
								width="12"
								height="12"
								fill="#fff"
							>
								<defs> </defs>
								<path
									className="cls-1"
									id="_Color"
									d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
									data-name=" ↳Color"
								></path>
							</svg>
							<span>依分类复制到宾乐达球谱</span>
						</button>
						<button
							className="btn btn-primary text-white ms-0 me-2"
							onClick={() =>
								navigate('/userDrillItem/create', {
									state: {
										fullname: location.state.fullname,
										action: 'create',
										userDrillClassList,
										userGuid: paramsGuid.userGuid,
									},
								})
							}
						>
							<svg
								id="icn_Create"
								className="me-2 mb-1"
								viewBox="0 0 12 12"
								width="12"
								height="12"
								fill="#fff"
							>
								<defs> </defs>
								<path
									className="cls-1"
									id="_Color"
									d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
									data-name=" ↳Color"
								></path>
							</svg>
							<span>新增球谱</span>
						</button>
						<span
							className="reloadUserDrillList me-2"
							onClick={getUserDrillList}
						>
							<svg width="32" height="32" viewBox="0 0 32 32">
								<defs>
									<clipPath id="clip-path-reload">
										<rect
											id="Rectangle_3588"
											data-name="Rectangle 3588"
											width="14.149"
											height="16"
											fill="none"
										/>
									</clipPath>
								</defs>
								<g
									id="Group_8495"
									data-name="Group 8495"
									transform="translate(-1232 -296)"
								>
									<rect
										id="Rectangle_3587"
										data-name="Rectangle 3587"
										width="32"
										height="32"
										transform="translate(1232 296)"
										fill="none"
									/>
									<g
										id="Group_8494"
										data-name="Group 8494"
										transform="translate(1241 304)"
									>
										<g
											id="Group_8493"
											data-name="Group 8493"
											clipPath="url(#clip-path-reload)"
										>
											<path
												id="Path_1555"
												data-name="Path 1555"
												d="M0,0H2.126V2.716a7.434,7.434,0,1,1-1.1,10.632l1.719-1.259a5.311,5.311,0,1,0,.877-7.842H6.372V6.371H0Z"
												fill="#686868"
											/>
										</g>
									</g>
								</g>
							</svg>
						</span>
						<span>
							<svg width="32" height="32" viewBox="0 0 32 32">
								<defs>
									<clipPath id="clip-path-gear">
										<rect
											id="Rectangle_3562"
											data-name="Rectangle 3562"
											width="16"
											height="15.081"
											fill="#686868"
										/>
									</clipPath>
								</defs>
								<g
									id="Group_8492"
									data-name="Group 8492"
									transform="translate(-1264 -296)"
								>
									<rect
										id="Rectangle_3586"
										data-name="Rectangle 3586"
										width="32"
										height="32"
										transform="translate(1264 296)"
										fill="none"
									/>
									<g
										id="Group_8491"
										data-name="Group 8491"
										transform="translate(1272 304)"
									>
										<g
											id="Group_8450"
											data-name="Group 8450"
											transform="translate(0 0)"
											clipPath="url(#clip-path-gear)"
										>
											<path
												id="Path_1516"
												data-name="Path 1516"
												d="M2.622,12.655c.048-.139.144-.4.232-.66a4.859,4.859,0,0,0,.27-.867,1.063,1.063,0,0,0-.7-1.179,2.892,2.892,0,0,0-.871-.182A1.527,1.527,0,0,1,0,8.139C0,7.721,0,7.3,0,6.884a1.518,1.518,0,0,1,1.52-1.566,2.949,2.949,0,0,0,.788-.136A1.152,1.152,0,0,0,3.1,3.747a2.87,2.87,0,0,0-.255-.659A1.534,1.534,0,0,1,3.472.923C3.9.678,4.326.432,4.756.191A1.322,1.322,0,0,1,6.591.672a3.619,3.619,0,0,0,.582.747,1.152,1.152,0,0,0,1.665-.036A3.124,3.124,0,0,0,9.327.75,1.352,1.352,0,0,1,11.3.231c.417.239.835.475,1.25.717a1.525,1.525,0,0,1,.606,2.1,2.964,2.964,0,0,0-.289.84,1.112,1.112,0,0,0,.768,1.28,2.855,2.855,0,0,0,.8.149A1.541,1.541,0,0,1,16,6.91c.005.418,0,.837,0,1.255a1.522,1.522,0,0,1-1.547,1.6,3.014,3.014,0,0,0-.593.077,1.216,1.216,0,0,0-.929,1.645,3.244,3.244,0,0,0,.243.564,1.519,1.519,0,0,1-.588,2.075q-.662.388-1.332.762a1.346,1.346,0,0,1-1.879-.5,3.5,3.5,0,0,0-.561-.717,1.154,1.154,0,0,0-1.653.015,3.064,3.064,0,0,0-.5.644,1.353,1.353,0,0,1-1.968.528c-.423-.241-.846-.48-1.267-.724a1.591,1.591,0,0,1-.8-1.475m5.37-2.521A2.593,2.593,0,1,0,5.4,7.54a2.59,2.59,0,0,0,2.593,2.6"
												transform="translate(0 0)"
												fill="#686868"
											/>
										</g>
									</g>
								</g>
							</svg>
						</span>
					</div>
					<div className="searchTableBottom">
						<section>
							<table
								className="UserDrillListTable table table-hover text-center"
								id="table-drill"
							>
								<thead>
									<tr className="bg-background">
										<th
											scope="col"
											className="text-grey4 border-0"
											onClick={() => sortTable(0)}
										>
											顺序<span></span>
										</th>
										<th
											scope="col"
											className="text-grey4 border-0 text-start"
											onClick={() => sortTable(1)}
											style={{ width: '40%' }}
										>
											球谱名称<span></span>
										</th>
										<th
											scope="col"
											className="text-grey4 border-0 text-start"
											onClick={() => sortTable(2)}
											// style={{ width: '40%' }}
										>
											球谱分类<span></span>
										</th>
										<th
											scope="col"
											className="text-grey4 border-0"
											onClick={() => sortTable(3)}
										>
											出球频率<span></span>
										</th>
										<th
											scope="col"
											className="text-grey4 border-0"
											onClick={() => sortTable(4)}
										>
											依序出球<span></span>
										</th>
										<th
											scope="col"
											className="text-grey4 border-0"
											onClick={() => sortTable(5)}
										>
											计时<span></span>
										</th>
										<th
											scope="col"
											className="text-grey4 border-0"
											onClick={() => sortTable(6)}
										>
											时间(分)<span></span>
										</th>
										<th
											scope="col"
											className="text-grey4 border-0"
											onClick={() => sortTable(7)}
										>
											总球数<span></span>
										</th>
									</tr>
								</thead>
								<tbody className="border-top-0">
									{isGetUserDrillList &&
										(userDrillList.length !== 0 ? (
											userDrillList.map((list) => {
												return (
													<tr
														id={list.userDrillGuid}
														key={list.userDrillGuid}
														onClick={() =>
															navigate(
																`/userDrillItem/${list.userDrillGuid}`,
																{
																	state: {
																		fullname,
																		userGuid:
																			paramsGuid.userGuid,
																		userDrillClassList,
																	},
																}
															)
														}
													>
														<td>
															{list.displayOrder}
														</td>
														<td className="text-start">
															{list.name}
														</td>
														<td>
															{
																list.drillClassName
															}
														</td>
														<td>
															{list.frequency}
														</td>
														<td>
															{list.serveOrder}
														</td>
														<td>
															{list.serveAmount
																? clickSvg
																: ''}
														</td>
														<td>{list.timer}</td>
														<td>{list.quantity}</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan="8">無球谱紀錄</td>
											</tr>
										))}
								</tbody>
							</table>

							{/*讀取畫面*/}
							<div
								className={`text-center loadingBox ${
									isGetUserDrillList && 'd-none'
								}`}
								id="loadingBox"
							>
								<h6 className="sr-only">Loading...</h6>

								<div
									className="spinner-border text-primary"
									role="status"
								></div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</section>
	);
};

export default UserDrill;
