import './supportContactUs.scss';
import React from 'react';
import SupportTop from '../SupportTop';
import SupportBottom from '../SupportBottom';
import { Link } from 'react-router-dom';

function SupportContactUs() {
	return (
		<div className="container-fluid p-0">
			<article
				id="container-supportContactUs"
				className="d-flex flex-column align-items-center px-0 mx-auto"
			>
				<SupportTop />

				<section className="supportContactUs-section section2 w-100 row bg-white">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h2 className="px-6 mx-auto mb-3 text-grey5">
							联系我们
						</h2>
						<h6 className="px-6 mx-auto mb-0 text-grey5">
							如果您有任何疑问，欢迎通过以下方式与我们联系。
						</h6>
					</div>
				</section>

				<section className="supportContactUs-section section3 w-100 px-5 px-lg-12 py-5 py-lg-7 row bg-background">
					<div
						className="col-12 p-0
					"
					>
						<h5 className="mb-lg-3 text-grey5">
							咨询/售后服务电话
						</h5>
						<p className="mb-0 mb-lg-1 text-grey5">
							<span className="me-2">
								<img
									className="icnPhone h-100 mx-0"
									src="./images/support/supportContactUs/icnPhone.png"
									alt="icnPhone"
								/>
							</span>
							<Link
								to="#"
								onClick={(e) => {
									window.location.href = 'tel:089866160260';
									e.preventDefault();
								}}
							>
								<span className="text-primary">
									0898-66160260
								</span>
							</Link>
						</p>
						<p className="mb-0 mb-lg-1 text-grey5">
							<span className="me-2">
								<img
									className="icnMail h-100 mx-0"
									src="./images/support/supportContactUs/icnMail.png"
									alt="icnMail"
								/>
							</span>
							<Link
								to="#"
								onClick={(e) => {
									window.location.href =
										'mailto:service@synerter.com.cn';
									e.preventDefault();
								}}
							>
								<span className="text-primary">
									service@synerter.com.cn
								</span>
							</Link>
						</p>
						<p className="mb-0 mb-1 text-grey5">
							<span className="me-2">
								<img
									className="icnLocation h-100 mx-0"
									src="./images/support/supportContactUs/icnLocation.png"
									alt="icnLocation"
								/>
							</span>
							<span className="locationText">
								海南省海口市秀英区秀英街道南海大道266号
								海口国家高新区创业孵化中心A楼5层A26-72号
							</span>
						</p>
						<h6 className="mt-4">普通话</h6>
						<h6>
							为了让您及时享受完善的服务，请在工作时间段与我们联系!
						</h6>
					</div>
				</section>

				<section className="supportContactUs-section section4 w-100 row bg-white">
					<div
						className="col-12 p-0 text-center
					"
					>
						<h2 className="px-6 mx-auto mb-3 text-grey5">
							售后服务
						</h2>
						<h6 className="px-6 mx-auto text-grey5">
							欢迎使用宾乐达售后服务，期待为您提供帮助。
						</h6>
					</div>
				</section>

				<section className="supportContactUs-section section5 w-100 row bg-white">
					<div
						className="col-12 p-0 text-center
					"
					>
						<div className="textWrap d-flex justify-content-between mx-auto mb-4 mb-lg-11 px-11 px-lg-0">
							<div>
								<h2 className="mb-2 mb-lg-3 text-primary">
									<img
										className="section5_1Img h-100 mx-0"
										src="./images/support/supportContactUs/section5_1_2x.png"
										alt="section5_1Img"
									/>
								</h2>
								<p className="text-primary mb-0 mb-lg-2">
									专业维修
								</p>
							</div>
							<div className="">
								<h2 className="mb-2 mb-lg-3 text-primary">
									<img
										className="section5_2Img h-100 mx-0"
										src="./images/support/supportContactUs/section5_2_2x.png"
										alt="section5_2Img"
									/>
								</h2>
								<p className="text-primary mb-0 mb-lg-2">
									无忧服务
								</p>
							</div>
							<div className="">
								<h2 className="mb-2 mb-lg-3 text-primary">
									<img
										className="section5_3Img h-100 mx-0"
										src="./images/support/supportContactUs/section5_3_2x.png"
										alt="section5_3Img"
									/>
								</h2>
								<p className="text-primary mb-0 mb-lg-2">
									官方质保
								</p>
							</div>
						</div>

						<h6 className="mx-auto text-center text-grey5">
							凡是购买本公司发球机的系列产品，
							<span className="d-block d-lg-inline mt-2 mt-lg-0">
								出现质量问题，免费保修一年。
							</span>
						</h6>
						<h6 className="mx-auto text-center text-grey5">
							具体服务项目以服务网点提供为准，建议您咨询后前往。
						</h6>
					</div>
				</section>

				<section className="supportContactUs-section section6 w-100 mb-10 row justify-content-center bg-white">
					<div
						className="col-12 col-lg-auto p-0 text-center
					"
					>
						<img
							className="section6_1Img h-100 mx-0 d-none d-lg-block"
							src="./images/support/supportContactUs/section6_1_2x.png"
							alt="section6_1Img"
						/>
						<img
							className="section6_1Img h-100 mx-auto d-block d-lg-none"
							src="./images/support/supportContactUs/section6_1_1x.png"
							alt="section6_1Img"
						/>
					</div>
					<div
						className="col-12 col-lg-auto p-4 px-lg-9 pt-lg-10 pb-lg-9 bg-background
					"
					>
						<p className="mb-2 mb-lg-1">
							查询全国服务网点，
							<br />
							一站式解决您的问题
						</p>
						<h6 className="mb-3 mb-lg-5">
							*具体服务项目以服务网点提供为准。
						</h6>
						<Link
							to={{
								pathname: '/physicalstore',
								state: {
									initialLocation: '.section5',
								},
							}}
						>
							<div className="btn btn-primary rounded-pill text-white">
								了解更多
							</div>
						</Link>
					</div>
				</section>

				<SupportBottom
					leftCol={{
						title: `常见问题`,
						text: `查看更多内容`,
						link: `/support/question`,
					}}
					rightCol={{
						title: `下载中心`,
						text: `查看更多内容`,
						link: `/support/downloadcenter`,
					}}
				/>
			</article>
		</div>
	);
}

export default SupportContactUs;
