import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { sortTable } from './components/utils/utils';
import Swal from 'sweetalert2';
import { axiosApi } from './api';

const RenderIndexList = ({
	titleList,
	contentList,
	searchTextRef,
	isGetList,
	getList,
	filterList,
	setFilterList,
	pageTitle,
	filterItemsObjectList,
	deleteApiURL,
	createURL,
}) => {
	const navigate = useNavigate();

	// console.log('list', filterItemsObjectList);
	// console.log('data', contentList);

	useEffect(() => {
		getList();
	}, [getList]);

	//#region [function]

	//過濾搜尋
	const filter = async () => {
		let guidList = [];
		filterItemsObjectList.forEach((item) => {
			let isInclude = false;
			let appInfoArray = Object.values(item);
			console.log(appInfoArray, searchTextRef.current);

			//  Start from i=1 because the appInfoArray[0] is guid, which is not going to be scanned.
			for (let i = 1; i < appInfoArray.length; i++) {
				if (
					appInfoArray[i]
						?.toLowerCase()
						.includes(searchTextRef.current.toLowerCase())
				) {
					isInclude = true;
				}
			}

			if (isInclude) return guidList.push(appInfoArray[0]);
		});

		let result = [];
		contentList.forEach((el) => {
			guidList.forEach((guid) => {
				if (el.guid === guid) result.push(el);
			});
		});

		setFilterList(result);
	};

	//過濾搜尋
	const resetFilter = () => {
		document.querySelector('.searchInput').value = '';
		searchTextRef.current = '';
		getList();
	};

	//刪除
	const handleDelete = async (el) => {
		await Swal.fire({
			html: `<h4>確定要刪除?</h4>`,
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: '取消',
			showConfirmButton: true,
			confirmButtonText: '確定',
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((res) => {
			if (res.dismiss === 'cancel') {
				console.log('cancelled');
				return;
			} else {
				console.log('confirmed');

				axiosApi(`${deleteApiURL}/${el.guid}`, 'delete')
					.then(getList)
					.catch((err) => console.log(err));
			}
		});
	};

	//#endregion
	return (
		<>
			<h5 className="fw-bold mb-4">{pageTitle}</h5>
			<div className="searchWrapper bg-white p-4 rounded-4 mb-3">
				<div className="searchBox d-flex">
					<input
						className="searchInput form-control me-2 w-50"
						placeholder="搜尋條件"
						onChange={(e) =>
							(searchTextRef.current = e.target.value)
						}
						onKeyDown={(e) => e.key === 'Enter' && filter()}
					/>
					<button
						className="btn btn-outline-primary btn-size-s me-2"
						onClick={resetFilter}
					>
						重置
					</button>
					<button
						className="btn btn-primary btn-size-s text-white"
						onClick={filter}
					>
						查詢
					</button>
				</div>
			</div>

			<div className="searchTableWrapper bg-white p-4 rounded-4">
				<div className="searchTableTop d-flex align-items-center mb-3 ">
					<h6 className="fw-bold">資料查詢</h6>
					<Link className="ms-auto" to={createURL}>
						<button className="btn btn-primary text-white">
							<svg
								id="icn_Create"
								className="me-2 mb-1"
								viewBox="0 0 12 12"
								width="12"
								height="12"
								fill="#fff"
							>
								<defs> </defs>
								<path
									className="cls-1"
									id="_Color"
									d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
									data-name=" ↳Color"
								></path>
							</svg>
							<span>新增資料</span>
						</button>
					</Link>

					<span className="reloadAppList ms-3 me-2" onClick={getList}>
						<svg width="32" height="32" viewBox="0 0 32 32">
							<defs>
								<clipPath id="clip-path-reload">
									<rect
										id="Rectangle_3588"
										data-name="Rectangle 3588"
										width="14.149"
										height="16"
										fill="none"
									/>
								</clipPath>
							</defs>
							<g
								id="Group_8495"
								data-name="Group 8495"
								transform="translate(-1232 -296)"
							>
								<rect
									id="Rectangle_3587"
									data-name="Rectangle 3587"
									width="32"
									height="32"
									transform="translate(1232 296)"
									fill="none"
								/>
								<g
									id="Group_8494"
									data-name="Group 8494"
									transform="translate(1241 304)"
								>
									<g
										id="Group_8493"
										data-name="Group 8493"
										clipPath="url(#clip-path-reload)"
									>
										<path
											id="Path_1555"
											data-name="Path 1555"
											d="M0,0H2.126V2.716a7.434,7.434,0,1,1-1.1,10.632l1.719-1.259a5.311,5.311,0,1,0,.877-7.842H6.372V6.371H0Z"
											fill="#686868"
										/>
									</g>
								</g>
							</g>
						</svg>
					</span>
					<span>
						<svg width="32" height="32" viewBox="0 0 32 32">
							<defs>
								<clipPath id="clip-path-gear">
									<rect
										id="Rectangle_3562"
										data-name="Rectangle 3562"
										width="16"
										height="15.081"
										fill="#686868"
									/>
								</clipPath>
							</defs>
							<g
								id="Group_8492"
								data-name="Group 8492"
								transform="translate(-1264 -296)"
							>
								<rect
									id="Rectangle_3586"
									data-name="Rectangle 3586"
									width="32"
									height="32"
									transform="translate(1264 296)"
									fill="none"
								/>
								<g
									id="Group_8491"
									data-name="Group 8491"
									transform="translate(1272 304)"
								>
									<g
										id="Group_8450"
										data-name="Group 8450"
										transform="translate(0 0)"
										clipPath="url(#clip-path-gear)"
									>
										<path
											id="Path_1516"
											data-name="Path 1516"
											d="M2.622,12.655c.048-.139.144-.4.232-.66a4.859,4.859,0,0,0,.27-.867,1.063,1.063,0,0,0-.7-1.179,2.892,2.892,0,0,0-.871-.182A1.527,1.527,0,0,1,0,8.139C0,7.721,0,7.3,0,6.884a1.518,1.518,0,0,1,1.52-1.566,2.949,2.949,0,0,0,.788-.136A1.152,1.152,0,0,0,3.1,3.747a2.87,2.87,0,0,0-.255-.659A1.534,1.534,0,0,1,3.472.923C3.9.678,4.326.432,4.756.191A1.322,1.322,0,0,1,6.591.672a3.619,3.619,0,0,0,.582.747,1.152,1.152,0,0,0,1.665-.036A3.124,3.124,0,0,0,9.327.75,1.352,1.352,0,0,1,11.3.231c.417.239.835.475,1.25.717a1.525,1.525,0,0,1,.606,2.1,2.964,2.964,0,0,0-.289.84,1.112,1.112,0,0,0,.768,1.28,2.855,2.855,0,0,0,.8.149A1.541,1.541,0,0,1,16,6.91c.005.418,0,.837,0,1.255a1.522,1.522,0,0,1-1.547,1.6,3.014,3.014,0,0,0-.593.077,1.216,1.216,0,0,0-.929,1.645,3.244,3.244,0,0,0,.243.564,1.519,1.519,0,0,1-.588,2.075q-.662.388-1.332.762a1.346,1.346,0,0,1-1.879-.5,3.5,3.5,0,0,0-.561-.717,1.154,1.154,0,0,0-1.653.015,3.064,3.064,0,0,0-.5.644,1.353,1.353,0,0,1-1.968.528c-.423-.241-.846-.48-1.267-.724a1.591,1.591,0,0,1-.8-1.475m5.37-2.521A2.593,2.593,0,1,0,5.4,7.54a2.59,2.59,0,0,0,2.593,2.6"
											transform="translate(0 0)"
											fill="#686868"
										/>
									</g>
								</g>
							</g>
						</svg>
					</span>
				</div>
				<div className="renderIndexListSearchTableBottom">
					<section>
						<table
							className="appListTable table table-hover text-center"
							id="table-drill"
						>
							<thead>
								<tr className="bg-background">
									{titleList.map((el) => (
										<th
											key={titleList.indexOf(el)}
											scope="col"
											className="text-grey4 border-0"
											onClick={() =>
												sortTable(titleList.indexOf(el))
											}
										>
											{el}
											<span></span>
										</th>
									))}
								</tr>
							</thead>
							<tbody className="border-top-0">
								{isGetList &&
									(filterList.length !== 0 ? (
										filterList.map((el) => {
											return (
												<tr
													key={el.guid}
													onClick={(e) => {
														if (
															e.target.closest(
																'.renderIndexListDeleteBtn'
															)
														) {
															handleDelete(el);
														} else {
															navigate(
																el.detailUrl
															);
														}
													}}
												>
													{el.dataList.map((el) => (
														<td key={Math.random()}>
															{el}
														</td>
													))}

													<td
														// renderIndexListDeleteBtn style is inside common.scss
														className="renderIndexListDeleteBtn"
														key={'deleteBtn'}
													>
														<svg
															id="icn_Trash"
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															viewBox="0 0 16 16"
														>
															<g
																id="Group_9521"
																data-name="Group 9521"
																transform="translate(1.369 1)"
															>
																<path
																	id="Path_1388"
																	data-name="Path 1388"
																	d="M10.316,2.21V1.473A1.474,1.474,0,0,0,8.842,0H4.421A1.473,1.473,0,0,0,2.948,1.473V2.21H.737a.737.737,0,1,0,0,1.474h.737v8.1A2.211,2.211,0,0,0,3.684,14h5.9a2.211,2.211,0,0,0,2.211-2.211v-8.1h.737a.737.737,0,0,0,0-1.474ZM8.842,1.473H4.421V2.21H8.842Zm1.474,2.211H2.948v8.1a.736.736,0,0,0,.736.737h5.9a.737.737,0,0,0,.737-.737Z"
																	fill="#ea4132"
																	fillRule="evenodd"
																/>
																<rect
																	id="Rectangle_3039"
																	width="1.474"
																	height="5.895"
																	transform="translate(4.421 5.158)"
																	fill="#ea4132"
																/>
																<rect
																	id="Rectangle_3040"
																	width="1.474"
																	height="5.895"
																	transform="translate(7.368 5.158)"
																	fill="#ea4132"
																/>
															</g>
															<rect
																id="Rectangle_3042"
																width="16"
																height="16"
																fill="none"
															/>
														</svg>
													</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td colSpan="7">無版本紀錄</td>
										</tr>
									))}
							</tbody>
						</table>

						{/*讀取畫面*/}
						<div
							className={`text-center loadingBox ${
								isGetList && 'd-none'
							}`}
							id="loadingBox"
						>
							<h6 className="sr-only">Loading...</h6>

							<div
								className="spinner-border text-primary"
								role="status"
							></div>
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default RenderIndexList;
