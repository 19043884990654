import React, { useEffect, useState, useCallback } from 'react';
import {
	Routes,
	Route,
	useNavigate,
	useLocation,
	Navigate,
} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useInitialLocation from './useInitialLocation';

import Layout from './components/layout/Layout';
import LayoutAccount from './components/layoutAccount/LayoutAccount';
import Homepage from './pages/home/homepage/Homepage';
import Login from './pages/userManage/login/Login';
import Register from './pages/userManage/register/Register';
import ForgotPwd from './pages/userManage/forgotPwd/ForgotPwd';
import BaseInfo from './pages/userManage/baseInfo/BaseInfo';
import PwdReset from './pages/userManage/pwdReset/PwdReset';
import AccountDelete from './pages/userManage/accountDelete/AccountDelete';
// import ProductHint from './pages/productManage/productHint/ProductHint';
import MyProduct from './pages/productManage/myProduct/MyProduct';
import ProductRegister from './pages/productManage/productRegister/ProductRegister';
import ProductVip from './pages/productManage/productVip/ProductVip';
import ProductVipSearch from './pages/productManage/productVipSearch/ProductVipSearch';
import ProductFeature from './pages/productCenter/productFeature/ProductFeature';
import ProductStat from './pages/productCenter/productStat/ProductStat';
import AppDownload from './pages/home/appDownload/AppDownload';

import Bldc from './pages/sysManage/bldc/Bldc';
import Stp from './pages/sysManage/stp/Stp';
import Fan from './pages/sysManage/fan/Fan';
import Feedback from './pages/sysManage/feedback/feedback';
import FileUpdateLayout from './pages/sysManage/fileUpdateLayout/FileUpdateLayout';
import AppUpdate from './pages/sysManage/appUpdate/AppUpdate';
import AppUpdateDetail from './pages/sysManage/appUpdateDetail/AppUpdateDetail';
import AppFactoryUpdate from './pages/sysManage/appFactoryUpdate/AppFactoryUpdate';
import AppFactoryUpdateDetail from './pages/sysManage/appFactoryUpdateDetail/AppFactoryUpdateDetail';
import OTAFirmwareUpdate from './pages/sysManage/OTAFirmwareUpdate/OTAFirmwareUpdate';
import OTAFirmwareUpdateDetail from './pages/sysManage/OTAFirmwareUpdateDetail/OTAFirmwareUpdateDetail';
import SupportContactUs from './pages/support/supportContactUs/SupportContactUs';
import SupportDownloadCenter from './pages/support/supportDownloadCenter/SupportDownloadCenter';
import PhysicalStore from './pages/physicalStore/PhysicalStore';
import About from './pages/about/About';
import SupportQuestion from './pages/support/supportQuestion/SupportQuestion';
import Agreement from './pages/agreement/Agreement';
import Privacy from './pages/privacy/Privacy';
import Explore from './pages/explore/Explore';
import ExploreUpdate from './pages/sysManage/exploreUpdate/ExploreUpdate';
import ExploreUpdateChange from './pages/sysManage/exploreUpdateChange/ExploreUpdateChange';
import DownloadCenterUpdate from './pages/sysManage/downloadCenterUpdate/DownloadCenterUpdate';
import DownloadCenterUpdateDetail from './pages/sysManage/downloadCenterUpdateDetail/DownloadCenterUpdateDetail';
import QuestionUpdate from './pages/sysManage/questionUpdate/QuestionUpdate';
import QuestionUpdateDetail from './pages/sysManage/questionUpdateDetail/QuestionUpdateDetail';
import QuestionCategoryUpdate from './pages/sysManage/questionCategoryUpdate/QuestionCategoryUpdate';
import QuestionCategoryUpdateDetail from './pages/sysManage/questionCategoryUpdateDetail/QuestionCategoryUpdateDetail';
import PhysicalStoreUpdate from './pages/sysManage/physicalStoreUpdate/PhysicalStoreUpdate';
import PhysicalStoreUpdateChange from './pages/sysManage/physicalStoreUpdateChange/PhysicalStoreUpdateChange';
import NotFoundPage from './components/notFoundPage/NotFoundPage';
import ErrorPage from './components/errorPage/ErrorPage';
import DrillCategoryUpdate from './pages/sysManage/drillCategoryUpdate/DrillCategoryUpdate';
import DrillCategoryUpdateDetail from './pages/sysManage/drillCategoryUpdateDetail/DrillCategoryUpdateDetail';
import UsersUpdateDetail from './pages/sysManage/usersUpdateDetail/UsersUpdateDetail';
import UsersUpdate from './pages/sysManage/usersUpdate/UsersUpdate';
import AppMainUpdate from './pages/sysManage/appMainUpdate/AppMainUpdate';
import AppMainUpdateDetail from './pages/sysManage/appMainUpdateDetail/AppMainUpdateDetail';
import OTAMainUpdate from './pages/sysManage/OTAMainUpdate/OTAMainUpdate';
import OTAMainUpdateDetail from './pages/sysManage/OTAMainUpdateDetail/OTAMainUpdateDetail';
import UserDrillAll from './pages/sysManage/userDrillAll/UserDrillAll';
import UserDrill from './pages/sysManage/userDrill/UserDrill';
import UserDrillItem from './pages/sysManage/userDrillItem/UserDrillItem';
import PresetDrillUpdate from './pages/sysManage/presetDrillUpdate/PresetDrillUpdate';
import OTAAudioUpdate from './pages/sysManage/OTAAudioUpdate/OTAAudioUpdate';
import OTAAudioUpdateDetail from './pages/sysManage/OTAAudioUpdateDetail/OTAAudioUpdateDetail';
import AuthUpdate from './pages/sysManage/authUpdate/AuthUpdate';
import AuthUpdateGroupName from './pages/sysManage/authUpdateGroupName/AuthUpdateGroupName';
import AuthUpdateEdit from './pages/sysManage/authUpdateEdit/AuthUpdateEdit';
import PresetDrill from './pages/sysManage/presetDrill/PresetDrill';
import PresetDrillCategoryUpdateDetail from './pages/sysManage/presetDrillCategoryUpdateDetail/PresetDrillCategoryUpdateDetail';
import PresetDrillCategoryItemUpdateDetail from './pages/sysManage/presetDrillCategoryItemUpdateDetail/PresetDrillCategoryItemUpdateDetail';
import Cms from './pages/sysManage/cms/Cms';
import AuthUpdateMemberCreate from './pages/sysManage/authUpdateMemberCreate/AuthUpdateMemberCreate';

import ProductionRecord from './pages/sysManage/prdManage/productionRecord/ProductionRecord';
import ProductionRecordChange from './pages/sysManage/prdManage/productionRecordChange/ProductionRecordChange';
import ProductionRecordLog from './pages/sysManage/prdManage/productionRecordLog/ProductionRecordLog';
import FactoryRecord from './pages/sysManage/prdManage/factoryRecord/FactoryRecord';
import FactoryRecordChange from './pages/sysManage/prdManage/factoryRecordChange/FactoryRecordChange';
import FactoryRecordLog from './pages/sysManage/prdManage/factoryRecordLog/FactoryRecordLog';

import { axiosApi } from './api';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import UserDrillClass from './pages/sysManage/userDrillClass/UserDrillClass';
import UserDrillClassItem from './pages/sysManage/userDrillClassItem/UserDrillClassItem';

// 建立登入共用資料context
export const AccountContext = React.createContext();

function App() {
	const [cookies, setCookie, removeCookie] = useCookies([
		'loginUser',
		'AccessToken',
		'RefreshToken',
		'page',
	]);
	const [accountInfo, setAccountInfo] = useState('');
	const [homePageIndex, setHomePageIndex] = useState(0);
	const [appUrInfo, setAppUInfo] = useState({
		androidUrl: '',
		iosUrl: 'https://apps.apple.com/cn/app/%E8%B3%93%E6%A8%82%E9%81%94%E9%81%8B%E5%8B%95/id1611116746',
	});
	const navigate = useNavigate();
	const location = useLocation();
	const initialLocation = useInitialLocation();

	// const { JwtTokenGuid, UserGuid, exp, iss } = jwt_decode(
	//     cookies?.AccessToken
	// );
	// console.log(JwtTokenGuid, UserGuid, exp, iss);
	// exp && console.log(new Date().getTime() > exp * 1000);

	// get prevUrl
	const prevUrl = localStorage.getItem('prevUrl');

	// set new prevUrl
	localStorage.setItem('prevUrl', window.location.href);
	// console.log(prevUrl, window.location.href);
	if (window.location.pathname === '/') {
		if (prevUrl !== window.location.href) {
			window.scrollTo(0, initialLocation);
		}
	} else {
		window.scrollTo(0, initialLocation);
	}

	// cookies loginUser
	useEffect(() => {
		if (!cookies.loginUser) {
			setCookie('loginUser', { status: null }, { path: '/' });
			setCookie('loginAuto', 'false', { path: '/' });
			removeCookie('AccessToken');
		}
	}, [cookies.loginUser, setCookie, removeCookie]);

	const guid =
		cookies.AccessToken && jwt_decode(cookies.AccessToken).UserGuid
			? jwt_decode(cookies.AccessToken).UserGuid
			: '';

	// 共用資料context
	const getAccountInfo = useCallback(() => {
		try {
			axiosApi(`web/user?languageCode=ZH`).then((res) => {
				setAccountInfo(res.data);
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	// 取得 android apk -> 以生成 QR code
	const getAndroidApkUrl = useCallback(() => {
		try {
			axiosApi('web/sys/appdownloadurl?OSCode=1').then((res) => {
				const url = res.data.result;
				setAppUInfo({ ...appUrInfo, androidUrl: url });
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	// TODO: 放 login / app ?
	const getUserPermission = useCallback(() => {
		try {
			axiosApi('web/sysAuthorization/userPermission').then((res) => {
				console.log(res);
				// const url = res.data.result;
				// setAppUInfo({ ...appUrInfo, androidUrl: url });
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		guid && getAccountInfo();
		guid && getUserPermission();
		getAndroidApkUrl();
	}, [guid, getAndroidApkUrl, getUserPermission, getAccountInfo]);

	// 多國語言

	let localStorageLocale = // cookies 儲存的先前設定語言
		localStorage.getItem('i18n') ||
		navigator.language ||
		navigator.browserLanguage;
	// console.log('localStorageLocale', localStorageLocale);

	localStorageLocale = 'zh-CN'; // 暫時強制為簡體中文
	const [lang, setLang] = useState(localStorageLocale);
	const { i18n } = useTranslation(['common']);

	// 在事件方法中使用i18n.changeLanguage()方法
	const toggleI18n = () => {
		let locale = 'en';

		if (lang === 'zh-CN') {
			locale = 'zh-CN';
		} else if (lang === 'zh-TW') {
			locale = 'zh';
		} else if (lang === 'en') {
			locale = 'en';
		}

		console.log('locale', locale);
		i18n.changeLanguage(locale);
	};

	useEffect(() => {
		toggleI18n();
	}, [lang]);

	return (
		<AccountContext.Provider
			value={{
				accountInfo,
				getAccountInfo,
				homePageIndex,
				setHomePageIndex,
				appUrInfo,
				lang,
				setLang,
			}}
		>
			{cookies.loginUser && cookies.loginUser.status === '0' ? (
				<Routes>
					{/* 首頁 */}
					<Route
						path="/"
						element={
							<Layout>
								<Homepage />
							</Layout>
						}
					/>
					{/* 產品中心 */}
					<Route
						path="/productcenter/productfeature"
						element={
							<Layout>
								<ProductFeature />
							</Layout>
						}
					/>
					<Route
						path="/productcenter/productstat"
						element={
							<Layout>
								<ProductStat />
							</Layout>
						}
					/>
					{/* 購買渠道 */}
					<Route
						path="/physicalstore"
						element={
							<Layout>
								<PhysicalStore />
							</Layout>
						}
					/>
					{/* 服務支持 */}
					<Route
						path="/support/question"
						element={
							<Layout>
								<SupportQuestion />
							</Layout>
						}
					/>
					<Route
						path="/support/downloadcenter"
						element={
							<Layout>
								<SupportDownloadCenter />
							</Layout>
						}
					/>
					<Route
						path="/support/contactus"
						element={
							<Layout>
								<SupportContactUs />
							</Layout>
						}
					/>
					{/* 關於賓樂達 */}
					<Route
						path="/about"
						element={
							<Layout>
								<About />
							</Layout>
						}
					/>
					{/* 賓樂達APP下載 */}
					<Route
						path="/app/download"
						element={
							<Layout>
								<AppDownload />
							</Layout>
						}
					/>
					{/* 用戶協議 */}
					<Route
						path="/agreement"
						element={
							<Layout>
								<Agreement />
							</Layout>
						}
					/>
					{/* 隱私政策 */}
					<Route
						path="/privacy"
						element={
							<Layout>
								<Privacy />
							</Layout>
						}
					/>
					{/* 网上展示厅 */}
					<Route
						path="/explore"
						element={
							<Layout>
								<Explore />
							</Layout>
						}
					/>
					{/* 登入註冊 */}
					<Route path="/login" element={<Navigate to="/" />} />
					<Route path="/register" element={<Navigate to="/" />} />
					<Route path="/forgotpwd" element={<Navigate to="/" />} />
					{/* ------登入後頁面------ */}
					{/* 個人頁面 */}
					<Route
						path="/baseInfo"
						element={
							<LayoutAccount>
								<BaseInfo />
							</LayoutAccount>
						}
					/>
					{/* 修改密碼 */}
					<Route
						path="/pwdreset"
						element={
							<LayoutAccount>
								<PwdReset />
							</LayoutAccount>
						}
					/>
					{/* 刪除帳號 */}
					<Route
						path="/accountDelete"
						element={
							<LayoutAccount>
								<AccountDelete />
							</LayoutAccount>
						}
					/>
					{/* 我的產品 */}
					<Route
						path="/product"
						element={
							<LayoutAccount>
								<MyProduct />
							</LayoutAccount>
						}
					/>
					<Route
						path="/product/productregister"
						element={
							<LayoutAccount>
								<ProductRegister />
							</LayoutAccount>
						}
					/>
					<Route
						path="/member/productvip"
						element={
							<LayoutAccount>
								<ProductVip />
							</LayoutAccount>
						}
					/>
					<Route
						path="/member/productvipsearch"
						element={
							<LayoutAccount>
								<ProductVipSearch />
							</LayoutAccount>
						}
					/>
					{/* 工廠與韌體 */}
					<Route path="/bldc" element={<Bldc />} />
					<Route path="/stp" element={<Stp />} />
					<Route path="/fan" element={<Fan />} />
					<Route path="/feedback" element={<Feedback />} />

					{/* 後台入口 */}
					<Route
						path="/cms"
						element={
							<Layout>
								<FileUpdateLayout>
									<Cms />
								</FileUpdateLayout>
							</Layout>
						}
					/>

					{/* 系統管理 */}
					{/* APP基本设定 */}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/appMainUpdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<AppMainUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/appMainUpdate/edit/:appGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<AppMainUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/appMainUpdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<AppMainUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* OTA基本设定 */}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/OTAMainUpdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAMainUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/OTAMainUpdate/edit/:otaGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAMainUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/OTAMainUpdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAMainUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* 權限設定 */}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/authUpdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<AuthUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/authUpdate/authUpdateGroupName/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<AuthUpdateGroupName />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/authUpdate/authUpdateGroupName/edit/:authorizationGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<AuthUpdateGroupName />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/authUpdate/memberPage/edit"
							element={
								<Layout>
									<FileUpdateLayout>
										<AuthUpdateEdit />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/authUpdate/authPage/edit/:authorizationGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<AuthUpdateEdit />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authSystem') && (
						<Route
							path="/authUpdate/memberPage/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<AuthUpdateMemberCreate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* APP管理 */}
					{cookies.page?.includes('authApp') && (
						/* APP管理 */
						<Route
							path="/appupdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<AppUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authApp') && (
						<Route
							path="/appupdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<AppUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authApp') && (
						<Route
							path="/appupdate/edit"
							element={
								<Layout>
									<FileUpdateLayout>
										<AppUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* OTA管理 */}
					{cookies.page?.includes('authOta') && (
						/* OTA管理 - Firmware */
						<Route
							path="/otafirmwareupdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAFirmwareUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authOta') && (
						<Route
							path="/otafirmwareupdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAFirmwareUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authOta') && (
						<Route
							path="/otafirmwareupdate/edit"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAFirmwareUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* OTA管理 - Audio */}
					{cookies.page?.includes('authOta') && (
						<Route
							path="/otaaudioupdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAAudioUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authOta') && (
						<Route
							path="/otaaudioupdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAAudioUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authOta') && (
						<Route
							path="/otaaudioupdate/edit"
							element={
								<Layout>
									<FileUpdateLayout>
										<OTAAudioUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* 用户管理 */}
					{cookies.page?.includes('authUser') && (
						<Route
							path="/usersUpdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<UsersUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authUser') && (
						<Route
							path="/usersUpdate/edit/:userGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<UsersUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* 賓樂達球譜管理 */}
					{/* 用戶球譜 */}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/userDrill"
							element={
								<Layout>
									<FileUpdateLayout>
										<UserDrillAll />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/userDrill/:userGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<UserDrill />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/userDrillItem/:userDrillGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<UserDrillItem />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/userDrillItem/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<UserDrillItem />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* 用戶球譜分類 */}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/userDrillClass"
							element={
								<Layout>
									<FileUpdateLayout>
										<UserDrillAll />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/userDrillClass/:userGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<UserDrillClass />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/userDrillClassItem/:classGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<UserDrillClassItem />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/userDrillClassItem/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<UserDrillClassItem />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* 賓樂達球譜 */}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/presetDrill"
							element={
								<Layout>
									<FileUpdateLayout>
										<PresetDrill />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/presetDrill/drillCategory/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<PresetDrillCategoryUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/presetDrill/drillCategory/edit/:categoryGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<PresetDrillCategoryUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/presetDrill/drillCategoryItem/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<PresetDrillCategoryItemUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/presetDrill/drillCategoryItem/edit/:categoryItemGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<PresetDrillCategoryItemUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/presetDrill/PresetDrillUpdate/edit/:presetDrillGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<PresetDrillUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* 球譜管理-宾乐达球谱型态 */}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/drillCategoryUpdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<DrillCategoryUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/drillCategoryUpdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<DrillCategoryUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authPresetDrill') && (
						<Route
							path="/drillCategoryUpdate/edit/:categoryCode"
							element={
								<Layout>
									<FileUpdateLayout>
										<DrillCategoryUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{/* authWeb */}
					{/* 後台管理-网上展示厅 */}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/exploreupdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<ExploreUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/exploreupdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<ExploreUpdateChange />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{cookies.page?.includes('authWeb') && (
						<Route
							path="/exploreupdate/edit/:videoGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<ExploreUpdateChange />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{/* 後台管理-销售网点 */}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/physicalstoreupdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<PhysicalStoreUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/physicalstoreupdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<PhysicalStoreUpdateChange />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/physicalstoreupdate/edit/:storeGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<PhysicalStoreUpdateChange />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{/* 後台管理-下载中心 */}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/downloadcenterupdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<DownloadCenterUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/downloadcenterupdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<DownloadCenterUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/downloadcenterupdate/edit/:manualGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<DownloadCenterUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{/* 後台管理-常见问题 */}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/questionupdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<QuestionUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/questionupdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<QuestionUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/questionupdate/edit/:qaGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<QuestionUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{/* 後台管理-常见问题型态 */}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/questioncategoryupdate"
							element={
								<Layout>
									<FileUpdateLayout>
										<QuestionCategoryUpdate />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/questioncategoryupdate/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<QuestionCategoryUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/questioncategoryupdate/edit/:categoryCode"
							element={
								<Layout>
									<FileUpdateLayout>
										<QuestionCategoryUpdateDetail />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{/* authWeb */}
					{/* 發球機生管 */}
					{/* TODO:authWeb */}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/productionRecord"
							element={
								<Layout>
									<FileUpdateLayout>
										<ProductionRecord />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{cookies.page?.includes('authWeb') && (
						<Route
							path="/productionRecord/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<ProductionRecordChange />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{cookies.page?.includes('authWeb') && (
						<Route
							path="/productionRecord/edit/:productionRecordGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<ProductionRecordChange />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{cookies.page?.includes('authWeb') && (
						<Route
							path="/productionRecord/log/:productionRecordGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<ProductionRecordLog />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{/* 發球機生管 */}
					{/* 出廠記錄 */}
					{cookies.page?.includes('authWeb') && (
						<Route
							path="/factoryRecord"
							element={
								<Layout>
									<FileUpdateLayout>
										<FactoryRecord />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{cookies.page?.includes('authWeb') && (
						<Route
							path="/factoryRecord/create"
							element={
								<Layout>
									<FileUpdateLayout>
										<FactoryRecordChange />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{cookies.page?.includes('authWeb') && (
						<Route
							path="/factoryRecord/edit/:factoryRecordGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<FactoryRecordChange />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{cookies.page?.includes('authWeb') && (
						<Route
							path="/factoryRecord/log/:factoryRecordGuid"
							element={
								<Layout>
									<FileUpdateLayout>
										<FactoryRecordLog />
									</FileUpdateLayout>
								</Layout>
							}
						/>
					)}

					{/* 錯誤提示網頁  */}
					<Route
						path="/error"
						element={
							<Layout>
								<ErrorPage />
							</Layout>
						}
					/>
					{/* 找不到網頁  */}
					<Route
						path="*"
						element={
							<Layout>
								<NotFoundPage />
							</Layout>
						}
					/>
				</Routes>
			) : (
				<Routes>
					{/* 刪除帳號 */}
					<Route
						path="/accountDelete"
						element={
							<LayoutAccount>
								<AccountDelete />
							</LayoutAccount>
						}
					/>
					{/* 首頁 */}
					<Route
						path="/"
						element={
							<Layout>
								<Homepage />
							</Layout>
						}
					/>
					{/* 產品中心 */}
					<Route
						path="/productcenter/productfeature"
						element={
							<Layout>
								<ProductFeature />
							</Layout>
						}
					/>
					<Route
						path="/productcenter/productstat"
						element={
							<Layout>
								<ProductStat />
							</Layout>
						}
					/>
					{/* 購買渠道 */}
					<Route
						path="/physicalstore"
						element={
							<Layout>
								<PhysicalStore />
							</Layout>
						}
					/>
					{/* 服務支持 */}
					<Route
						path="/support/question"
						element={
							<Layout>
								<SupportQuestion />
							</Layout>
						}
					/>
					<Route
						path="/support/downloadcenter"
						element={
							<Layout>
								<SupportDownloadCenter />
							</Layout>
						}
					/>
					<Route
						path="/support/contactus"
						element={
							<Layout>
								<SupportContactUs />
							</Layout>
						}
					/>
					{/* 關於賓樂達 */}
					<Route
						path="/about"
						element={
							<Layout>
								<About />
							</Layout>
						}
					/>
					{/* 賓樂達APP下載 */}
					<Route
						path="/app/download"
						element={
							<Layout>
								<AppDownload />
							</Layout>
						}
					/>
					{/* 登入註冊 */}
					<Route
						path="/login"
						element={
							<Layout>
								<Login />
							</Layout>
						}
					/>
					<Route
						path="/register"
						element={
							<Layout>
								<Register />
							</Layout>
						}
					/>
					<Route
						path="/forgotpwd"
						element={
							<Layout>
								<ForgotPwd />
							</Layout>
						}
					/>
					{/* 工廠與韌體 */}
					<Route path="/bldc" element={<Bldc />} />
					<Route path="/stp" element={<Stp />} />
					<Route path="/fan" element={<Fan />} />
					<Route path="/feedback" element={<Feedback />} />
					{/* 用戶協議 */}
					<Route
						path="/agreement"
						element={
							<Layout>
								<Agreement />
							</Layout>
						}
					/>
					{/* 隱私政策 */}
					<Route
						path="/privacy"
						element={
							<Layout>
								<Privacy />
							</Layout>
						}
					/>
					{/* 网上展示厅 */}
					<Route
						path="/explore"
						element={
							<Layout>
								<Explore />
							</Layout>
						}
					/>

					{/* 錯誤提示網頁  */}
					<Route
						path="/error"
						element={
							<Layout>
								<ErrorPage />
							</Layout>
						}
					/>
					{/* 找不到網頁  */}
					<Route
						path="*"
						element={
							<Layout>
								<NotFoundPage />
							</Layout>
						}
					/>
				</Routes>
			)}
		</AccountContext.Provider>
	);
}

export default App;
