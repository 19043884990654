import './drillCategoryUpdateDetail.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';

function DrillCategoryUpdateDetail() {
    //#region [States initializations]
    const paramsCode = useParams('categoryCode');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [rowStamp, setRowStamp] = useState('');

    const [data, setData] = useState({
        displayOrder: '',
        categoryCode: '',
        name_ZH: '',
        name_ZF: '',
        name_EN: '',
        name_JA: '',
        status: '',
    });

    //#endregion

    //#region [function]

    const checkCategoryCode = async (categoryCode) => {
        await axios
            .get(
                `web/sysDrill/checkCategoryCodeValid?categoryCode=${categoryCode}`
            )
            .then((res) => {
                if (res.data === false) {
                    Swal.fire({
                        icon: 'error',
                        title: `球谱型态代码重複，請重新輸入`,
                    });
                    setData({ ...data, categoryCode: '' });
                }
            });
    };

    // delete
    const handleDeleteItem = async () => {
        await axios
            .delete(`web/sysDrill/drillCategory/${paramsCode.categoryCode}`)
            .then((res) => {
                console.log(res.data);
                navigate('/drillCategoryUpdate');
            })

            .catch((err) => console.log(err));
    };

    // edit & create
    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData(e.target);
        if (Object.keys(paramsCode).length) {
            //edit
            await axios
                .put(
                    `web/sysDrill/drillCategory/${paramsCode.categoryCode}`,
                    form
                )
                .then((res) => {
                    // console.log(res.data);
                    navigate('/drillCategoryUpdate');
                })
                .catch((err) => console.log(err));
        } else {
            //create
            await axios
                .post(`web/sysDrill/drillCategory`, form)
                .then((res) => {
                    console.log(res.data);
                    navigate('/drillCategoryUpdate');
                })
                .catch((err) => console.log(err));
        }
    };

    //#endregion

    //#region [useEffect]

    // getDetails
    useEffect(() => {
        const fetchDrillCategoryDetails = async () => {
            await axios
                .get(`web/sysDrill/drillCategory/${paramsCode.categoryCode}`)
                .then((res) => {
                    const data = res.data.result;
                    // console.log(data);

                    const {
                        displayOrder,
                        categoryCode,
                        name_ZH,
                        name_ZF,
                        name_EN,
                        name_JA,
                        status,
                        rowStamp,
                    } = data;

                    setRowStamp(rowStamp);
                    setData({
                        ...data,
                        displayOrder,
                        categoryCode,
                        name_ZF,
                        name_ZH,
                        name_EN,
                        name_JA,
                        status,
                    });
                });
        };
        if (Object.keys(paramsCode).length) {
            fetchDrillCategoryDetails();
        }
    }, [paramsCode]);

    //#endregion

    return (
        <>
            <LoadingAnimate isLoading={isLoading} />

            <section id="section-main">
                <form
                    id="container-exploreUpdateChange"
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex">
                        <h5 className="fw-bold mb-4">宾乐达球谱型态</h5>
                        <button
                            className="btnDelete btn btn-outline-danger px-4 ms-auto me-2"
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteItem();
                            }}
                        >
                            删除
                        </button>
                        <button
                            className="btnCancel btn btn-outline-primary px-4 me-2"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/drillCategoryUpdate');
                            }}
                        >
                            取消
                        </button>
                        <button className="btn btn-primary text-white px-4">
                            储存
                        </button>
                    </div>
                    {/* <div className="d-flex"></div> */}
                    <div className="manageFileWrapper bg-white p-4 rounded-4 mb-3">
                        <input
                            id="rowStamp"
                            name="rowStamp"
                            className="d-none"
                            defaultValue={rowStamp}
                        />
                        <div className="d-flex">
                            <div className="col-6">
                                <h6 className="fw-bold mb-3">档案管理</h6>
                                <h6 className="fontSize75Rem mb-2">显示顺序</h6>
                                <input
                                    id="displayOrder"
                                    name="displayOrder"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.displayOrder}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            displayOrder: e.target.value,
                                        })
                                    }
                                    min="0"
                                />
                                {/* <h6 className="fontSize75Rem mb-2">
                                    球谱型态代码
                                </h6>
                                <input
                                    id="categoryCode"
                                    name="categoryCode"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.categoryCode || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            categoryCode: e.target.value,
                                        })
                                    }
                                    onBlur={(e) =>
                                        checkCategoryCode(e.target.value)
                                    }
                                    disabled={
                                        Object.keys(paramsCode).length !== 0
                                    }
                                /> */}
                                <h6 className="fontSize75Rem mb-2">中文名称</h6>
                                <input
                                    id="nameZh"
                                    name="name_ZH"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.name_ZH || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_ZH: e.target.value,
                                        })
                                    }
                                />
                                <h6 className="fontSize75Rem mb-2">
                                    中文名称(繁)
                                </h6>
                                <input
                                    id="nameZf"
                                    name="name_ZF"
                                    className="form-control mb-3 w-100"
                                    required
                                    value={data.name_ZF || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_ZF: e.target.value,
                                        })
                                    }
                                />
                                <h6 className="fontSize75Rem mb-2">英文名称</h6>
                                <input
                                    id="nameEn"
                                    name="name_EN"
                                    className="form-control mb-3 w-100"
                                    value={data.name_EN || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_EN: e.target.value,
                                        })
                                    }
                                />
                                <h6 className="fontSize75Rem mb-2">日文名称</h6>
                                <input
                                    id="nameJa"
                                    name="name_JA"
                                    className="form-control mb-3 w-100"
                                    value={data.name_JA || ''}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name_JA: e.target.value,
                                        })
                                    }
                                />
                                <label
                                    htmlFor="status"
                                    className="fontSize75Rem mb-2"
                                >
                                    状态
                                </label>
                                <select
                                    className="form-control mb-3 "
                                    name="status"
                                    id="status"
                                    value={data.status}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            status: e.target.value,
                                        })
                                    }
                                    required
                                >
                                    <option value="0">"0"：未启用</option>
                                    <option value="1">"1"：启用中</option>
                                    <option value="2">"2"：停用中</option>
                                    <option value="4">"4"：删除</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}

export default DrillCategoryUpdateDetail;
