import './feedback.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

function Feedback() {
	//ALL DATA
	const [feedbackList, setFeedbackList] = useState([]);
	//filtered Data
	const [appListFiltered, setAppListFiltered] = useState([]);

	//篩選選單
	const [phoneOSList, setPhoneOSList] = useState([]);
	const [appVersionList, setAppVersionList] = useState([]);
	const [phoneOSVersionList, setPhoneOSVersionList] = useState([]);
	const [appNameList, setAppNameList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [progressList, setProgressList] = useState([]);

	//目前篩選條件
	const [filterObj, setFilterObj] = useState({
		phoneOS: 'ALL',
		appVersion: 'ALL',
		phoneOSVersion: 'ALL',
		appName: 'ALL',
		progress: '0',
		category: 'ALL',
	});

	//是否資料修改成功
	const [isUpdate, setIsUpdate] = useState({});

	//modal
	const [isModalOpen, setModalIsOpen] = useState(false);
	const [modalList, setModalList] = useState('');
	const [modalColDisabled, setModalColDisabled] = useState(true);

	//獲取篩選選單資料
	useEffect(() => {
		const getFeedbackList = async () => {
			await axios
				.get('web/sys/appfeedback')
				.then((res) => setFeedbackList(res.data.appFeedbackList))
				.catch((err) => console.log(err));
		};

		getFeedbackList();
	}, [isUpdate]);

	//set篩選選單(去除重複)
	useEffect(() => {
		feedbackList.forEach((item) => {
			setPhoneOSList((arr) => [...arr, item.phoneOS]);
		});

		setPhoneOSList((arr) =>
			arr.filter((element, index, self) => {
				// console.log(self);
				return self.indexOf(element) === index;
			})
		);
	}, [feedbackList]);

	useEffect(() => {
		feedbackList.forEach((item) => {
			setAppVersionList((arr) => [...arr, item.appVersion]);
		});

		setAppVersionList((arr) =>
			arr.filter((element, index, self) => {
				return self.indexOf(element) === index;
			})
		);
	}, [feedbackList]);

	useEffect(() => {
		feedbackList.forEach((item) => {
			setPhoneOSVersionList((arr) => [...arr, item.phoneOSVersion]);
		});

		setPhoneOSVersionList((arr) =>
			arr.filter((element, index, self) => {
				return self.indexOf(element) === index;
			})
		);
	}, [feedbackList]);

	useEffect(() => {
		feedbackList.forEach((item) => {
			setAppNameList((arr) => [...arr, item.appName]);
		});

		setAppNameList((arr) =>
			arr.filter((element, index, self) => {
				return self.indexOf(element) === index;
			})
		);
	}, [feedbackList]);

	useEffect(() => {
		feedbackList.forEach((item) => {
			setProgressList((arr) => [...arr, item.progress]);
		});

		setProgressList((arr) =>
			arr.filter((element, index, self) => {
				return self.indexOf(element) === index;
			})
		);
	}, [feedbackList]);

	useEffect(() => {
		feedbackList.forEach((item) => {
			setCategoryList((arr) => [...arr, item.category]);
		});

		setCategoryList((arr) =>
			arr.filter((element, index, self) => {
				return self.indexOf(element) === index;
			})
		);
	}, [feedbackList]);

	//日期換算
	const DateDiff = (time) => {
		const dateNow = new Date();
		const dateFeedback = new Date(time);
		let timeList = [];

		const days = Math.floor(
			Math.abs((dateFeedback - dateNow) / 1000 / 60 / 60 / 24)
		);
		const hours = Math.floor(
			Math.abs((dateFeedback - dateNow) / 1000 / 60 / 60)
		);

		const minutes = Math.floor(
			Math.abs((dateFeedback - dateNow) / 1000 / 60)
		);

		if (days === 0 && hours === 0) {
			timeList.push(minutes);
			timeList.push('分鐘前');
			return timeList;
		} else if (days === 0) {
			timeList.push(hours);
			timeList.push('時');
			timeList.push(minutes - hours * 60);
			timeList.push('分前');
			return timeList;
		} else {
			timeList.push(days);
			timeList.push('天');
			timeList.push(hours - days * 24);
			timeList.push('時前');
			return timeList;
		}
	};

	//目前篩選控制
	const handleCheckbox = (item) => {
		// console.log(item);
		setFilterObj((obj) => ({
			...obj,
			...item,
		}));
	};
	// console.log(filterObj);
	// console.log(appListFiltered);

	//篩選後card資料ajax
	useEffect(() => {
		const asArray = Object.entries(filterObj);
		const filtered = asArray.filter(([key, value]) => value !== 'ALL');
		const exceptAll = Object.fromEntries(filtered);
		// console.log(exceptAll);

		const filterKey = Object.keys(exceptAll);
		let filterVal = Object.values(exceptAll);
		// console.log(filterKey,filterVal);

		//湊網址字串
		let temp = `web/sys/appfeedback?`;
		for (let i = 0; i < filterKey.length; i++) {
			temp += filterKey[i] + '=' + filterVal[i] + '&';
		}
		// console.log(temp);

		//ajax
		const getFeedbackFiltered = async () => {
			await axios
				.get(temp)
				.then((res) => setAppListFiltered(res.data.appFeedbackList))
				.catch((err) => console.log(err));
		};

		getFeedbackFiltered();
	}, [filterObj, isUpdate]);
	// console.log(appListFiltered);

	//篩選後顯示項目
	// const handleFilter = (feedbackList) => {
	// 	const filter = feedbackList
	// 		.filter((item) => {
	// 			if (filterObj.phoneOS === 'ALL') {
	// 				return true;
	// 			} else {
	// 				return item.phoneOS === filterObj.phoneOS;
	// 			}
	// 		})
	// 		.filter((item) => {
	// 			if (filterObj.appVersion === 'ALL') {
	// 				return true;
	// 			} else {
	// 				return item.appVersion === filterObj.appVersion;
	// 			}
	// 		})
	// 		.filter((item) => {
	// 			if (filterObj.phoneOSVersion === 'ALL') {
	// 				return true;
	// 			} else {
	// 				return item.phoneOSVersion === filterObj.phoneOSVersion;
	// 			}
	// 		})
	// 		.filter((item) => {
	// 			if (filterObj.progress === 'ALL') {
	// 				return true;
	// 			} else {
	// 				return item.progress === filterObj.progress;
	// 			}
	// 		})
	// 		.filter((item) => {
	// 			if (filterObj.appName === 'ALL') {
	// 				return true;
	// 			} else {
	// 				return item.appName === filterObj.appName;
	// 			}
	// 		});

	// 	// console.log(filter);
	// 	return filter;
	// };

	//篩選選單-開關
	function handleFilterModal(contentClass) {
		document.querySelector(contentClass).classList.toggle('d-none');
	}

	//視窗外click-關閉視窗
	window.onclick = function (e) {
		let filterNameArr = Object.keys(filterObj);
		// console.log(filterNameArr);

		//篩選選單modal
		filterNameArr.forEach((item) => {
			if (
				document.querySelector(`.${item}Content`) &&
				e.target.classList[0] !== `${item}Btn`
			) {
				document
					.querySelector(`.${item}Content`)
					.classList.add('d-none');
			}
		});

		//FEEDBACK modal
		if (isModalOpen && modalColDisabled) {
			if (e.target.classList[0] === 'modal__backdrop') {
				setModalIsOpen(false);
			}
		}
	};

	//獲取並打開Modal
	const handleModal = (guid) => {
		const getFeedback = async () => {
			await axios
				.get(`web/sys/appfeedback/${guid}`)
				.then((res) => setModalList(res.data.feedback[0]))
				.catch((err) => console.log(err));

			// console.log(res.data.appFeedback[0]);
		};
		getFeedback();

		setModalIsOpen(true);
		setModalColDisabled(true);
	};

	//關閉Modal
	const toggleModal = () => {
		setModalIsOpen(!isModalOpen);
		setModalColDisabled(true);
	};

	//Modal編輯頁面 Go to bottom
	useEffect(() => {
		const scrollToBottom = () => {
			var element = document.querySelector('.modal__backdrop');
			if (element) {
				element.scrollTop = element.scrollHeight;
				// console.log(element.scrollTop, element.scrollHeight);
			}
		};

		scrollToBottom();
	}, [modalColDisabled]);

	//if img is error
	// const errorImg =
	// 	'https://images2.gamme.com.tw/news2/2018/03/20/q5qWnaadl6GbqA.jpg';

	// const errorImg =
	// 	'https://fakeimg.pl/440x300/282828/EAE0D0/?text=imageError';
	// const handleListImgErr = (id) => {
	// 	document.querySelector(`#${id} img`).src = errorImg;
	// };

	// const handleModalImgErr = () => {
	// 	setModalList({
	// 		...modalList,
	// 		attachment: errorImg,
	// 	});
	// };

	//FEEDBACK MODAL
	const Modal = () => {
		console.log(modalList);
		const [categoryVal, setCategoryVal] = useState(modalList.category);
		const [progressVal, setProgressVal] = useState(modalList.progress);
		const [priorityVal, setPriorityVal] = useState(modalList.priority);
		const [handlerVal, setHandlerVal] = useState(modalList.handler);
		const [processingLogVal, setProcessingLogVal] = useState(
			modalList.processingLog
		);
		const [rowStampVal, setRowStampVal] = useState(modalList.rowStamp);

		//creatable
		const [issueVal, setIssueVal] = useState(modalList.issue);

		const [feedbackTimeVal, setFeedbackTimeVal] = useState(
			new Date(modalList.feedbackTime).toLocaleString()
		);
		const [userTimeZoneVal, setUserTimeZoneVal] = useState(
			modalList.userTimeZone
		);
		const [appNameVal, setAppNameVal] = useState(modalList.appName);
		const [appVersionVal, setAppVersionVal] = useState(
			modalList.appVersion
		);
		const [phoneInfoVal, setPhoneInfoVal] = useState(modalList.phoneInfo);
		const [phoneResolutionVal, setPhoneResolutionVal] = useState(
			modalList.phoneResolution
		);
		const [phoneOSVal, setPhoneOSVal] = useState(modalList.phoneOS);
		const [phoneOSVersionVal, setPhoneOSVersionVal] = useState(
			modalList.phoneOSVersion
		);

		const textareaRef = React.useRef(null);

		React.useLayoutEffect(() => {
			// Reset height - important to shrink on delete
			textareaRef.current.style.height = 'inherit';
			// Set height
			textareaRef.current.style.height = `${Math.max(
				textareaRef.current.scrollHeight,
				32
			)}px`;
		}, [issueVal]);

		// const issue = document.querySelector('.issue');
		// if (issue) {
		// 	issue.style.height = 'auto';
		// 	issue.style.height = issue.scrollHeight + 'px';
		// 	console.log(issue.scrollHeight);
		// }
		// console.log(issue.scrollHeight);
		// console.log(issue);

		//Edit Btn
		const handleEdit = () => {
			setModalColDisabled(false);
		};

		//Save Btn
		const handleSave = () => {
			let updateData = {
				category: categoryVal,
				progress: progressVal,
				priority: priorityVal,
				handler: handlerVal,
				processingLog: processingLogVal,
				rowStamp: rowStampVal,
			};

			const saveData = async () => {
				console.log(updateData);
				await axios
					.put(
						`/web/sys/appfeedback/${modalList.appFeebackGuid}`,
						updateData
					)
					.then((res) => {
						if (res.data.status === '0') {
							editSuccessAlert();
							setModalColDisabled(true);

							setIsUpdate(updateData);
						}
					})
					.catch((err) => editErrorAlert(err));
			};
			saveData();
		};

		//success alert
		const editSuccessAlert = () => {
			Swal.fire({
				title: '更新成功',
				icon: 'success',
			}).then(() => {
				handleModal(modalList.appFeebackGuid);
			});
		};

		//error alert
		const editErrorAlert = (err) => {
			Swal.fire({
				title: '更新失敗',
				icon: 'error',
				text: err,
			});
		};

		//ESC關閉Modal
		useEffect(() => {
			function onKeyDown(event) {
				if (event.keyCode === 27) {
					// Close the modal when the Escape key is pressed
					toggleModal();
				}
			}
			document.addEventListener('keydown', onKeyDown);
		});

		return (
			<div className="modal__backdrop">
				<div className="modal__container">
					<div className="modalTitleBox d-flex align-items-center mb-3">
						<h3 className="modal__title m-0">
							{modalList.userName}
						</h3>
					</div>
					<div className="modalFeedbackImgBox mb-3">
						<img
							className="w-100"
							src={modalList.attachment}
							// onError={handleModalImgErr}
							alt="feedbackImg"
						/>
					</div>
					<div className="modalIssueBox mb-3 pb-4 border-bottom border-gray">
						{/* <p className="issueText fw-lighter p-3 border border-gray rounded-3"> */}
						<div className="d-flex issueBox justify-content-center mb-3">
							<textarea
								className="issue fw-lighter p-3 border border-gray rounded-3"
								ref={textareaRef}
								name="issue"
								id="issue"
								cols="92"
								rows="7"
								value={issueVal}
								onChange={(e) => setIssueVal(e.target.value)}
								disabled
							></textarea>
						</div>
						{/* </p> */}
						<div className="modalIssueBoxBottom row px-3">
							<div className="col-12 col-sm-6 col-md-4 rowStamp d-none">
								<h6 className="fw-bold">rowStamp</h6>
								<h6>
									<input
										className="form-control"
										type="text"
										value={rowStampVal}
										onChange={(e) =>
											setRowStampVal(e.target.value)
										}
										disabled
									/>
								</h6>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<h6 className="fw-bold">回饋類型</h6>
								<select
									className="editable form-control"
									id="category"
									name="category"
									value={categoryVal}
									onChange={(e) =>
										setCategoryVal(e.target.value)
									}
									disabled={modalColDisabled}
								>
									<option value="0"> 一般回饋 </option>
									<option value="1"> APP問題回報 </option>
									<option value="2"> 機構問題回報 </option>
								</select>
							</div>

							<div className="col-12 col-sm-6 col-md-4 feedbackTime">
								<h6 className="fw-bold">提交時間</h6>
								<h6>
									<input
										className="form-control"
										type="text"
										value={feedbackTimeVal}
										onChange={(e) =>
											setFeedbackTimeVal(e.target.value)
										}
										disabled
									/>
								</h6>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<h6 className="fw-bold">時區</h6>
								<input
									className="form-control"
									type="text"
									value={userTimeZoneVal}
									onChange={(e) =>
										setUserTimeZoneVal(e.target.value)
									}
									disabled
								/>
							</div>
						</div>
					</div>

					<div className="modalAppBox mb-3 pb-4 border-bottom border-gray">
						<h4 className="fw-bold px-3 mb-4">App</h4>
						<div className="row px-3">
							<div className="col-12 col-sm-6 col-md-4">
								<h6 className="fw-bold">App名稱</h6>
								<select
									className="form-control"
									id="appName"
									name="appName"
									value={appNameVal}
									onChange={(e) =>
										setAppNameVal(e.target.value)
									}
									disabled
								>
									<option value="0"> Pingtex </option>
									<option value="1">
										Swift-QA(零件檢測）
									</option>
								</select>
							</div>
							<div className="col-12 col-sm-6 col-md-4 appVersion">
								<h6 className="fw-bold">App版本號</h6>
								<input
									className="form-control"
									type="text"
									value={appVersionVal}
									onChange={(e) =>
										setAppVersionVal(e.target.value)
									}
									disabled
								/>
							</div>
						</div>
					</div>

					<div className="modalDeviceBox mb-3 pb-4 border-bottom border-gray">
						<h4 className="fw-bold px-3 mb-4">裝置</h4>
						<div className="row px-3">
							<div className="col-12 col-sm-6 col-md-4">
								<div className="row">
									<div className="col-12 mb-2">
										<h6 className="fw-bold">手機資訊</h6>
										<input
											className="form-control"
											type="text"
											value={phoneInfoVal}
											onChange={(e) =>
												setPhoneInfoVal(e.target.value)
											}
											disabled
										/>
									</div>
									<div className="col-12 mb-2">
										<h6 className="fw-bold">螢幕解析度</h6>
										<input
											className="form-control"
											type="text"
											value={phoneResolutionVal}
											onChange={(e) =>
												setPhoneResolutionVal(
													e.target.value
												)
											}
											disabled
										/>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<div className="row">
									<div className="col-12 mb-2">
										<h6 className="fw-bold">手機OS</h6>
										<select
											className="form-control"
											id="phonOS"
											name="phonOS"
											value={phoneOSVal}
											onChange={(e) =>
												setPhoneOSVal(e.target.value)
											}
											disabled
										>
											<option value="0"> iOS </option>
											<option value="1"> Android </option>
											<option value="2">鴻蒙</option>
										</select>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<div className="row">
									<div className="col-12 mb-2">
										<h6 className="fw-bold">
											手機OS版本號
										</h6>
										<input
											className="form-control"
											type="text"
											value={phoneOSVersionVal}
											onChange={(e) =>
												setPhoneOSVersionVal(
													e.target.value
												)
											}
											disabled
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="modalAppBox mb-3 pb-4 border-bottom border-gray">
						<h4 className="fw-bold px-3 mb-4">處置</h4>
						<div className="row px-3 mb-4">
							<div className="col-12 col-sm-6 col-md-4 appType">
								<h6 className="fw-bold">處理進度</h6>
								<select
									className="editable form-control"
									id="progress"
									name="progress"
									value={progressVal}
									onChange={(e) =>
										setProgressVal(e.target.value)
									}
									disabled={modalColDisabled}
								>
									<option value="0">未讀 </option>
									<option value="1">已讀未處理 </option>
									<option value="2">處理中</option>
									<option value="3">關閉：已完成</option>
									<option value="4">關閉：無法解決</option>
								</select>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<h6 className="fw-bold">優先順序</h6>
								<select
									className="editable form-control"
									id="priority"
									name="priority"
									value={priorityVal}
									onChange={(e) =>
										setPriorityVal(e.target.value)
									}
									disabled={modalColDisabled}
								>
									<option value="1">最緊急</option>
									<option value="2">緊急</option>
									<option value="3">一般</option>
									<option value="4">不急</option>
									<option value="5">最不急</option>
								</select>
							</div>
							<div className="col-12 col-sm-6 col-md-4">
								<h6 className="fw-bold">處理人員</h6>
								<input
									className="editable form-control"
									type="text"
									value={handlerVal || ''}
									onChange={(e) =>
										setHandlerVal(e.target.value)
									}
									disabled={modalColDisabled}
								/>
							</div>
						</div>
						<h6 className="fw-bold px-3">
							處理記錄（工程人員註記）
						</h6>
						<div className="d-flex processingLogBox justify-content-center">
							<textarea
								className="processingLog editable fw-lighter p-3 border border-gray rounded-3"
								name="processingLog"
								id="processingLog"
								cols="92"
								rows="7"
								value={processingLogVal || ''}
								onChange={(e) =>
									setProcessingLogVal(e.target.value)
								}
								disabled={modalColDisabled}
							></textarea>
						</div>
					</div>

					<div className="modalFooter d-flex justify-content-between">
						<button
							className={`btn modalEditBtn ${
								modalColDisabled ? '' : 'd-none'
							}`}
							type="button"
							onClick={handleEdit}
						>
							修改
						</button>
						<button
							className={`btn modalSaveBtn w-75 ${
								modalColDisabled ? 'd-none' : ''
							}`}
							type="button"
							onClick={handleSave}
						>
							儲存
						</button>
						<button
							className="btn modalCloseBtn"
							type="button"
							onClick={toggleModal}
						>
							關閉
						</button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="container-fluid p-0 position-relative">
			<article
				id="container-feedback"
				className="py-5 d-flex flex-column align-items-center justify-content-lg-center bg-lg-background "
			>
				<div className="container-custom mt-0 w-100">
					<div className="row-custom pt-0">
						<div className="col">
							{/* Title */}
							<div className="d-flex align-items-center">
								<h4 className="text-start fw-bold mb-0">
									FEEDBACK
								</h4>
								<span
									style={{ cursor: 'pointer' }}
									className="ms-auto text-success"
									onClick={() =>
										handleCheckbox({
											phoneOS: 'ALL',
											appVersion: 'ALL',
											phoneOSVersion: 'ALL',
											appName: 'ALL',
											progress: 'ALL',
											category: 'ALL',
										})
									}
								>
									GET ALL DATA
								</span>
							</div>

							<hr />
							{/* Filter */}
							<div className="row filterBox mb-4">
								<div className="col-12 col-sm-3 col-md-2">
									<div
										className="phoneOSBtn position-relative"
										onClick={() =>
											handleFilterModal('.phoneOSContent')
										}
									>
										<span className="phoneOSBtn text-primary me-1">
											{`OS平台( ${
												filterObj.phoneOS === '0'
													? 'iOS'
													: filterObj.phoneOS === '1'
													? 'Android'
													: filterObj.phoneOS === '2'
													? '鴻蒙'
													: 'ALL'
											} )`}
										</span>
										<svg
											className="align-bottom"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 17 20"
											width="17"
											height="20"
										>
											<text
												id="_"
												fontFamily="NotoSans-Bold, Noto Sans"
												fontSize="14"
												fontWeight="700"
												fill="#1278e8"
												transform="matrix(1 0 0 -1 0 20)"
												data-name=" ▲"
											>
												<tspan x="0" y="15"></tspan>
												<tspan
													className="phoneOSBtn"
													fontFamily="SegoeUI-Bold, Segoe UI"
													y="15"
												>
													▲
												</tspan>
											</text>
										</svg>
										<div className="phoneOSContent  bg-white rounded-3 d-none">
											<ul className="h-100 d-flex flex-column align-items-start justify-content-evenly list-unstyled m-0">
												<li
													className={
														'w-100 d-flex align-items-center '
													}
												>
													<label
														className="w-100 px-3 py-2"
														htmlFor={`phoneOS`}
													>
														<input
															type="radio"
															name="phoneOSRadio"
															id={`phoneOS`}
															className="me-2"
															value="ALL"
															checked={
																filterObj.phoneOS ===
																'ALL'
															}
															onChange={() =>
																handleCheckbox({
																	phoneOS:
																		'ALL',
																})
															}
														/>
														ALL
													</label>
												</li>
												{phoneOSList
													.sort()
													.map((list, index) => {
														return (
															<li
																key={list}
																className={
																	'w-100 d-flex align-items-center '
																}
															>
																<label
																	className="w-100 px-3 py-2"
																	htmlFor={`phoneOS${index}`}
																>
																	<input
																		type="radio"
																		name="phoneOSRadio"
																		id={`phoneOS${index}`}
																		className="me-2"
																		value={
																			list
																		}
																		checked={
																			filterObj.phoneOS ===
																			list
																		}
																		onChange={() =>
																			handleCheckbox(
																				{
																					phoneOS:
																						list,
																				}
																			)
																		}
																	/>

																	{list ===
																	'0'
																		? 'iOS'
																		: list ===
																		  '1'
																		? 'Android'
																		: list ===
																		  '2'
																		? '鴻蒙'
																		: ''}
																</label>
															</li>
														);
													})}
											</ul>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-3 col-md-2">
									<div
										className="phoneOSVersionBtn position-relative"
										onClick={() =>
											handleFilterModal(
												'.phoneOSVersionContent'
											)
										}
									>
										<span className="phoneOSVersionBtn text-primary me-1">
											{`OS版本( ${filterObj.phoneOSVersion} )`}
										</span>
										<svg
											className="align-bottom"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 17 20"
											width="17"
											height="20"
										>
											<text
												id="_"
												fontFamily="NotoSans-Bold, Noto Sans"
												fontSize="14"
												fontWeight="700"
												fill="#1278e8"
												transform="matrix(1 0 0 -1 0 20)"
												data-name=" ▲"
											>
												<tspan x="0" y="15"></tspan>
												<tspan
													className="phoneOSVersionBtn"
													fontFamily="SegoeUI-Bold, Segoe UI"
													y="15"
												>
													▲
												</tspan>
											</text>
										</svg>
										<div className="phoneOSVersionContent  bg-white rounded-3 d-none">
											<ul className="h-100 d-flex flex-column align-items-start justify-content-evenly list-unstyled m-0">
												<li
													className={
														'w-100 d-flex align-items-center '
													}
												>
													<label
														className="w-100 px-3 py-2"
														htmlFor={`phoneOSVersion`}
													>
														<input
															type="radio"
															name="phoneOSVersionRadio"
															id={`phoneOSVersion`}
															className="me-2"
															value="ALL"
															checked={
																filterObj.phoneOSVersion ===
																'ALL'
															}
															onChange={() =>
																handleCheckbox({
																	phoneOSVersion:
																		'ALL',
																})
															}
														/>
														ALL
													</label>
												</li>
												{phoneOSVersionList
													.sort()
													.map((list, index) => {
														return (
															<li
																key={list}
																className={
																	'w-100 d-flex align-items-center '
																}
															>
																{' '}
																<label
																	className="w-100 px-3 py-2"
																	htmlFor={`phoneOSVersion${index}`}
																>
																	<input
																		type="radio"
																		name="phoneOSVersionRadio"
																		id={`phoneOSVersion${index}`}
																		className="me-2"
																		value={
																			list
																		}
																		checked={
																			filterObj.phoneOSVersion ===
																			list
																		}
																		onChange={() =>
																			handleCheckbox(
																				{
																					phoneOSVersion:
																						list,
																				}
																			)
																		}
																	/>

																	{list}
																</label>
															</li>
														);
													})}
											</ul>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-3 col-md-2">
									<div
										className="appNameBtn position-relative"
										onClick={() =>
											handleFilterModal('.appNameContent')
										}
									>
										<span className="appNameBtn text-primary me-1">
											{`App名稱( ${
												filterObj.appName === '0'
													? 'Pingtex'
													: filterObj.appName === '1'
													? 'Swift-QA(零件檢測）'
													: 'ALL'
											} )`}
										</span>
										<svg
											className="align-bottom"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 17 20"
											width="17"
											height="20"
										>
											<text
												id="_"
												fontFamily="NotoSans-Bold, Noto Sans"
												fontSize="14"
												fontWeight="700"
												fill="#1278e8"
												transform="matrix(1 0 0 -1 0 20)"
												data-name=" ▲"
											>
												<tspan x="0" y="15"></tspan>
												<tspan
													className="appNameBtn"
													fontFamily="SegoeUI-Bold, Segoe UI"
													y="15"
												>
													▲
												</tspan>
											</text>
										</svg>
										<div className="appNameContent  bg-white rounded-3 d-none">
											<ul className="h-100 d-flex flex-column align-items-start justify-content-evenly list-unstyled m-0">
												<li
													className={
														'w-100 d-flex align-items-center '
													}
												>
													<label
														className="w-100 px-3 py-2"
														htmlFor={`appName`}
													>
														<input
															type="radio"
															name="appNameRadio"
															id={`appName`}
															className="me-2"
															value="ALL"
															checked={
																filterObj.appName ===
																'ALL'
															}
															onChange={() =>
																handleCheckbox({
																	appName:
																		'ALL',
																})
															}
														/>
														ALL
													</label>
												</li>
												{appNameList
													.sort()
													.map((list, index) => {
														return (
															<li
																key={list}
																className={
																	'w-100 d-flex align-items-center '
																}
															>
																<label
																	className="w-100 px-3 py-2"
																	htmlFor={`appName${index}`}
																>
																	<input
																		type="radio"
																		name="appNameRadio"
																		id={`appName${index}`}
																		className="me-2"
																		value={
																			list
																		}
																		checked={
																			filterObj.appName ===
																			list
																		}
																		onChange={() =>
																			handleCheckbox(
																				{
																					appName:
																						list,
																				}
																			)
																		}
																	/>

																	{list ===
																	'0'
																		? 'Pingtex'
																		: list ===
																		  '1'
																		? 'Swift-QA(零件檢測）'
																		: 'ALL'}
																</label>
															</li>
														);
													})}
											</ul>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-3 col-md-2 appVersionBtnBox">
									<div
										className="appVersionBtn position-relative"
										onClick={() =>
											handleFilterModal(
												'.appVersionContent'
											)
										}
									>
										<span className="appVersionBtn text-primary me-1">
											{`App版本( ${filterObj.appVersion} )`}
										</span>
										<svg
											className="align-bottom"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 17 20"
											width="17"
											height="20"
										>
											<text
												id="_"
												fontFamily="NotoSans-Bold, Noto Sans"
												fontSize="14"
												fontWeight="700"
												fill="#1278e8"
												transform="matrix(1 0 0 -1 0 20)"
												data-name=" ▲"
											>
												<tspan x="0" y="15"></tspan>
												<tspan
													className="appVersionBtn"
													fontFamily="SegoeUI-Bold, Segoe UI"
													y="15"
												>
													▲
												</tspan>
											</text>
										</svg>
										<div className="appVersionContent  bg-white rounded-3 d-none">
											<ul className="h-100 d-flex flex-column align-items-start justify-content-evenly list-unstyled m-0">
												<li
													className={
														'w-100  d-flex align-items-center'
													}
												>
													<label
														className="w-100 px-3  py-2"
														htmlFor={`appVersion`}
													>
														<input
															type="radio"
															name="appVersionRadio"
															id={`appVersion`}
															className="me-2"
															value="ALL"
															checked={
																filterObj.appVersion ===
																'ALL'
															}
															onChange={() =>
																handleCheckbox({
																	appVersion:
																		'ALL',
																})
															}
														/>
														ALL
													</label>
												</li>
												{appVersionList
													.sort()
													.map((list, index) => {
														return (
															<li
																key={list}
																className={
																	'w-100 d-flex align-items-center '
																}
															>
																<label
																	className="w-100  px-3 py-2"
																	htmlFor={`appVersion${index}`}
																>
																	<input
																		type="radio"
																		name="appVersionRadio"
																		id={`appVersion${index}`}
																		className="me-2"
																		value={
																			list
																		}
																		checked={
																			filterObj.appVersion ===
																			list
																		}
																		onChange={() =>
																			handleCheckbox(
																				{
																					appVersion:
																						list,
																				}
																			)
																		}
																	/>

																	{list}
																</label>
															</li>
														);
													})}
											</ul>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-3 col-md-2">
									<div
										className="categoryBtn position-relative"
										onClick={() =>
											handleFilterModal(
												'.categoryContent'
											)
										}
									>
										<span className="categoryBtn text-primary me-1">
											{`回饋類型( ${
												filterObj.category === '0'
													? '一般回饋'
													: filterObj.category === '1'
													? 'APP問題回報'
													: filterObj.category === '2'
													? '機構問題回報'
													: 'ALL'
											} )`}
										</span>
										<svg
											className="align-bottom"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 17 20"
											width="17"
											height="20"
										>
											<text
												id="_"
												fontFamily="NotoSans-Bold, Noto Sans"
												fontSize="14"
												fontWeight="700"
												fill="#1278e8"
												transform="matrix(1 0 0 -1 0 20)"
												data-name=" ▲"
											>
												<tspan x="0" y="15"></tspan>
												<tspan
													className="categoryBtn"
													fontFamily="SegoeUI-Bold, Segoe UI"
													y="15"
												>
													▲
												</tspan>
											</text>
										</svg>
										<div className="categoryContent  bg-white rounded-3 d-none">
											<ul className="h-100 d-flex flex-column align-items-start justify-content-evenly list-unstyled m-0">
												<li
													className={
														'w-100 d-flex align-items-center'
													}
												>
													<label
														className="w-100 px-3 py-2"
														htmlFor={`category`}
													>
														<input
															type="radio"
															name="categoryRadio"
															id={`category`}
															className="me-2"
															value="ALL"
															checked={
																filterObj.category ===
																'ALL'
															}
															onChange={() =>
																handleCheckbox({
																	category:
																		'ALL',
																})
															}
														/>
														ALL
													</label>
												</li>
												{categoryList
													.sort()
													.map((list, index) => {
														return (
															<li
																key={list}
																className={
																	'w-100 d-flex align-items-center '
																}
															>
																<label
																	className="w-100 px-3 py-2"
																	htmlFor={`category${index}`}
																>
																	<input
																		type="radio"
																		name="categoryRadio"
																		id={`category${index}`}
																		className="me-2"
																		value={
																			list
																		}
																		checked={
																			filterObj.category ===
																			list
																		}
																		onChange={() =>
																			handleCheckbox(
																				{
																					category:
																						list,
																				}
																			)
																		}
																	/>
																	{list ===
																	'0'
																		? '一般回饋'
																		: list ===
																		  '1'
																		? 'APP問題回報'
																		: list ===
																		  '2'
																		? '機構問題回報'
																		: 'ALL'}
																</label>
															</li>
														);
													})}
											</ul>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-3 col-md-2">
									<div
										className="progressBtn position-relative"
										onClick={() =>
											handleFilterModal(
												'.progressContent'
											)
										}
									>
										<span className="progressBtn text-primary me-1">
											{`處理進度( ${
												filterObj.progress === '0'
													? '未讀'
													: filterObj.progress === '1'
													? '已讀未處理'
													: filterObj.progress === '2'
													? '處理中'
													: filterObj.progress === '3'
													? '關閉：已完成'
													: filterObj.progress === '4'
													? '關閉：無法解決'
													: 'ALL'
											} )`}
										</span>
										<svg
											className="align-bottom"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 17 20"
											width="17"
											height="20"
										>
											<text
												id="_"
												fontFamily="NotoSans-Bold, Noto Sans"
												fontSize="14"
												fontWeight="700"
												fill="#1278e8"
												transform="matrix(1 0 0 -1 0 20)"
												data-name=" ▲"
											>
												<tspan x="0" y="15"></tspan>
												<tspan
													className="progressBtn"
													fontFamily="SegoeUI-Bold, Segoe UI"
													y="15"
												>
													▲
												</tspan>
											</text>
										</svg>
										<div className="progressContent  bg-white rounded-3 d-none">
											<ul className="h-100 d-flex flex-column align-items-start justify-content-evenly list-unstyled m-0">
												<li
													className={
														'w-100 d-flex align-items-center'
													}
												>
													<label
														className="w-100 px-3 py-2"
														htmlFor={`progress`}
													>
														<input
															type="radio"
															name="progressRadio"
															id={`progress`}
															className="me-2"
															value="ALL"
															checked={
																filterObj.progress ===
																'ALL'
															}
															onChange={() =>
																handleCheckbox({
																	progress:
																		'ALL',
																})
															}
														/>
														ALL
													</label>
												</li>
												{progressList
													.sort()
													.map((list, index) => {
														return (
															<li
																key={list}
																className={
																	'w-100 d-flex align-items-center '
																}
															>
																<label
																	className="w-100 px-3 py-2"
																	htmlFor={`progress${index}`}
																>
																	<input
																		type="radio"
																		name="progressRadio"
																		id={`progress${index}`}
																		className="me-2"
																		value={
																			list
																		}
																		checked={
																			filterObj.progress ===
																			list
																		}
																		onChange={() =>
																			handleCheckbox(
																				{
																					progress:
																						list,
																				}
																			)
																		}
																	/>
																	{list ===
																	'0'
																		? '未讀'
																		: list ===
																		  '1'
																		? '已讀未處理'
																		: list ===
																		  '2'
																		? '處理中'
																		: list ===
																		  '3'
																		? '關閉：已完成'
																		: list ===
																		  '4'
																		? '關閉：無法解決'
																		: 'ALL'}
																</label>
															</li>
														);
													})}
											</ul>
										</div>
									</div>
								</div>
							</div>

							{/* Card */}
							<section id="article-feedback" className="row">
								{appListFiltered &&
								appListFiltered.length !== 0 ? (
									appListFiltered.map((list) => {
										return (
											<div
												id={list.appFeebackGuid}
												key={list.appFeebackGuid}
												className="cardBox col-12 col-sm-6 col-md-4 py-2"
											>
												<div className="cardContent border border-gray rounded-3">
													<div
														className="imgBox px-4 pt-4 bg-secondary rounded-3"
														onClick={() =>
															handleModal(
																list.appFeebackGuid
															)
														}
													>
														<img
															className="w-100"
															alt=""
															src={
																list.attachment
															}
															// onError={() =>
															// 	handleListImgErr(
															// 		list.appFeebackGuid
															// 	)
															// }
														/>
													</div>
													<div className="infoBox px-4 pb-4">
														<div className="infoHeader row my-2">
															<div className="col-md-8">
																<span className="userName text-center text-md-start fw-bold">
																	{
																		list.userName
																	}
																</span>
																<span className="text-center text-md-start fw-bold text-danger">
																	{list.category ===
																	'0'
																		? '一般回饋'
																		: list.category ===
																		  '1'
																		? 'APP問題回報'
																		: list.category ===
																		  '2'
																		? '機構問題回報'
																		: ''}
																</span>
															</div>
															<div className="col-md-4">
																<div className="feedbackTime text-center text-md-end text-gray">
																	<span>
																		{
																			DateDiff(
																				list.feedbackTime
																			)[0]
																		}
																	</span>
																	<span className="ms-1">
																		{
																			DateDiff(
																				list.feedbackTime
																			)[1]
																		}
																	</span>
																	<span className="ms-1">
																		{
																			DateDiff(
																				list.feedbackTime
																			)[2]
																		}
																	</span>
																	<span className="ms-1">
																		{
																			DateDiff(
																				list.feedbackTime
																			)[3]
																		}
																	</span>
																</div>
															</div>
														</div>
														<div className="infoVersion row my-2">
															<div className="col text-center text-md-start">
																<span className="appVersion">
																	{
																		list.appVersion
																	}
																</span>
																<span className="mx-1">
																	◆
																</span>
																<span className="phoneInfo">
																	{
																		list.phoneInfo
																	}
																</span>
																<span className="mx-1">
																	◆
																</span>
																<span className="phoneOSVersion">
																	{
																		list.phoneOSVersion
																	}
																</span>
															</div>
														</div>
														<div className="infoIssue mt-2">
															<p className="form-control m-0">
																{list.issue}
															</p>
														</div>
													</div>
												</div>
											</div>
										);
									})
								) : (
									<div>無回饋資訊</div>
								)}
							</section>

							{/* Modal */}
							{isModalOpen && <Modal />}
						</div>
					</div>
				</div>
			</article>
		</div>
	);
}

export default Feedback;
