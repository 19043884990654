import './myProduct.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { axiosApi } from '../../../api';

function MyProduct() {
    const navigate = useNavigate();

    const [productList, setProductList] = useState('');
    const [isGetUserProduct, setIsGetUserProduct] = useState(false);

    useEffect(() => {
        const getUserProduct = async () => {
            axiosApi('/web/product')
                .then((res) => {
                    setProductList(res.data.productList);
                    setIsGetUserProduct(true);
                })
                .catch((err) => console.log(err));
            // const res = await axios.get(`/web/product`);
            // setProductList(res.data.productList);
            // setIsGetUserProduct(true);
        };

        getUserProduct();
    }, []);

    //console.log("productList", productList);

    return (
        <article id="container-myProduct">
            <div className="articleBoxWithSideBox">
                <h1 className="containTitle">我的产品</h1>
                <section className="row g-3 mb-3">
                    <div className="col-12">
                        <h2 className="mb-4">
                            注册宾乐达产品的过程简单、快速且安全。
                        </h2>
                        <h2>产品注册的好处：</h2>
                        <ol className="list-unstyled">
                            <li>1. 获得原厂完整的服务支持。</li>
                            <li>2. 免费升级帐号等级至【尊荣会员】。</li>
                            <li>
                                3.
                                可以邀请5个宾乐达会员加入你的家族群组，他们的帐号将升等为【家族会员】。
                            </li>
                        </ol>
                    </div>
                </section>
                <section className="d-flex justify-content-between align-items-center mb-3">
                    <span>已注册的产品：</span>
                    <button
                        type="button"
                        className="registerProductBtn btn btn-color-main rounded-pill px-4"
                        onClick={() => navigate('/product/productRegister')}
                    >
                        注册产品
                    </button>
                </section>
                <section>
                    {/*<!-- 資料表格 -->*/}
                    <table
                        className="table table-hover text-center"
                        id="table-drill"
                    >
                        <thead>
                            <tr className="bg-grey1">
                                {/*<!-- 產品型號 -->*/}
                                <th scope="col">产品型号</th>
                                {/*<!-- 设备序列号 -->*/}
                                <th scope="col">设备序列号</th>
                                {/*<!-- 購買日期 -->*/}
                                <th scope="col">购买日期</th>
                                {/*<!-- 保修期限 -->*/}
                                <th scope="col">保修期限</th>
                                {/*<!-- 可授权的家族成员数量 -->*/}
                                <th scope="col">可授权的家族成员数量</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isGetUserProduct &&
                                (productList.length !== 0 ? (
                                    productList.map((list) => {
                                        return (
                                            <tr key={list.productGuid}>
                                                <td>{list.modelID}</td>
                                                <td>{list.serialNo}</td>
                                                <td>
                                                    {list.purchaseDate
                                                        ? list.purchaseDate.split(
                                                              'T'
                                                          )[0]
                                                        : ''}
                                                </td>
                                                <td>
                                                    {list.warrantyDate
                                                        ? list.warrantyDate.split(
                                                              'T'
                                                          )[0]
                                                        : ''}
                                                </td>
                                                <td>{list.vipQuantity}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="5">无产品注册纪录</td>
                                    </tr>
                                ))}
                            {/*{console.log(productList)}*/}
                        </tbody>
                    </table>

                    {/*讀取畫面*/}
                    <div
                        className={`text-center loadingBox ${
                            isGetUserProduct && 'd-none'
                        }`}
                        id="loadingBox"
                    >
                        <h6 className="sr-only">Loading...</h6>

                        <div
                            className="spinner-border text-primary"
                            role="status"
                        ></div>
                    </div>
                </section>
            </div>
        </article>
    );
}

export default MyProduct;
