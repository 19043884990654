import './notFoundPage.scss';
import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
	return (
		<>
			<div className="notFoundPageWrapper bg-white">
				<div className="d-flex flex-column align-items-center">
					<div className="section1 w-100 position-relative mt-11 mt-lg-10">
						<div className="imgWrapper d-flex justify-content-center align-items-center rounded-circle bg-background mx-auto">
							<img
								src="./images/notFoundPage/section1_1_2x.png"
								alt="notFoundPage"
							/>
						</div>

						<div className="section2 position-absolute text-center w-100">
							<h1 className="text-grey5 fw-bold mb-3">
								很抱歉，您访问的页面不可用。
							</h1>
							<h6 className="text-grey4 mb-1">
								您可能输错了网址，或该网页已删除或不存在。
							</h6>
							<h6 className="text-grey4">
								请点击下方按钮返回首页。
							</h6>
						</div>
					</div>
					<div className="section3 mt-12 mt-lg-14 mb-15 mb-lg-14">
						<Link to="/">
							<div className="btn btn-primary text-white rounded-pill">
								返回首页
							</div>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default NotFoundPage;
