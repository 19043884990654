import './register.scss';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Sync, CheckCircleOutline } from '@material-ui/icons';
import Captcha from 'react-captcha-code';
import { randomNum, originalCharacter } from '../../../components/utils/utils';
import axios from 'axios';

function Register() {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);

	//隱私權modal
	const [modalShow, setModalShow] = useState(false);
	const [accountType, setAccountType] = useState('0');

	const [captcha, setCaptcha] = useState('');
	const [emailInput, setEmailInput] = useState('');
	const [countryCodeInput, setCountryCodeInput] = useState('86');
	const [phoneCodeInput, setPhoneCodeInput] = useState('');
	const [captchaInput, setCaptchaInput] = useState('');
	const [phoneInputError, setPhoneInputError] = useState('');
	const [emailInputError, setEmailInputError] = useState('');
	const [fullnameInputError, setFullnameInputError] = useState('');
	const [passwordInputError, setPasswordInputError] = useState('');
	const [captchaInputError, setCaptchaInputError] = useState('');
	const [verificationCodeError, setVerificationCodeError] = useState('');
	const [countdownBtnDisabled, setCountdownBtnDisabled] = useState();

	const [fullnameInput, setFullnameInput] = useState('');

	const [passwordInput, setPasswordInput] = useState('');
	const [confirmPasswordInput, setConfirmPasswordInput] = useState('');

	const [verificationCode, setVerificationCode] = useState('');

	// const [isEmailValid, setIsEmailValid] = useState(false);
	// const [isPhoneValid, setIsPhoneValid] = useState(false);
	// const [isFullnameValid, setIsFullnameValid] = useState(false);
	// const [isPasswordValid, setIsPasswordValid] = useState(false);
	// const [isCaptchaValid, setIsCaptchaValid] = useState(false);

	// 切換註冊帳號類型
	const handleRegisterTypeChange = () => {
		accountType === '0' ? setAccountType('1') : setAccountType('0');
		setEmailInput('');
		setPhoneInputError('');
		setPhoneCodeInput('');
		setFullnameInput('');
		setPasswordInput('');
		setConfirmPasswordInput('');
		setCaptchaInput('');

		setEmailInputError('');
		setPhoneInputError('');
		setFullnameInputError('');
		setPasswordInputError('');
		setCaptchaInputError('');
		handleRefreshCaptcha();
	};
	let isEmailValid = useRef();
	let isPhoneValid = useRef();
	let isFullnameValid = useRef();
	let isPasswordValid = useRef();
	let isCaptchaValid = useRef();

	// Check email input
	const handleEmailInput = () => {
		console.log('loading');
		const emailReg =
			/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
		let accObj = { accountType, email: emailInput };
		if (!emailInput) {
			isEmailValid.current = false;
			setEmailInputError('请输入电子邮箱');
		} else if (!emailReg.test(emailInput)) {
			isEmailValid.current = false;
			setEmailInputError('請填寫正確电子邮箱');
		} else {
			axios.post('/web/user/checkaccountunique', accObj).then((res) => {
				isEmailValid.current = false;
				if (res.data.status === '1') {
					setEmailInputError('账号已经存在了');
				} else if (res.data.status === '0') {
					setEmailInputError('');
					isEmailValid.current = true;
				}
			});
		}
	};

	// Check phone input
	const handlePhoneInput = () => {
		const phoneCodeReg = /^\d{0,20}$/;
		let accObj = {
			accountType,
			countryCode: countryCodeInput,
			phoneCode: phoneCodeInput,
		};
		if (!phoneCodeInput) {
			isPhoneValid.current = false;
			setPhoneInputError('请输入手机号');
		} else if (!phoneCodeReg.test(phoneCodeInput)) {
			isPhoneValid.current = false;
			setPhoneInputError('請填寫正確手机号');
		} else {
			axios.post('/web/user/checkaccountunique', accObj).then((res) => {
				if (res.data.status === '1') {
					isPhoneValid.current = false;
					setPhoneInputError('账号已经存在了');
				} else if (res.data.status === '0') {
					setPhoneInputError('');
					isPhoneValid.current = true;
				}
			});
		}
	};

	// Check fullname input
	const handleFullnameInput = () => {
		console.log(document.getElementById('fullname').value);
		if (!fullnameInput) {
			isFullnameValid.current = false;
			setFullnameInputError('输入姓名');
		} else {
			setFullnameInputError('');
			isFullnameValid.current = true;
		}
	};

	// Check password input
	const handlePasswordInput = () => {
		// Minimum eight characters, at least one letter and one number
		const passwordRegex = /^(?=.*\d)[A-Za-z\d]{6,16}$/;
		if (!passwordRegex.test(passwordInput)) {
			isPasswordValid.current = false;
			setPasswordInputError('輸入6-16位密碼，英文區分大小寫');
		} else if (passwordInput !== confirmPasswordInput) {
			isPasswordValid.current = false;
			setPasswordInputError('请确定密码与确认密码相同');
		} else {
			setPasswordInputError('');
			isPasswordValid.current = true;
		}
	};

	// Check captcha input
	const handleCaptchaInput = () => {
		if (!captchaInput) {
			isCaptchaValid.current = false;
			setCaptchaInputError('验证码不能空白');
		} else if (captchaInput !== captcha) {
			isCaptchaValid.current = false;
			setCaptchaInputError('验证码错误');
		} else {
			setCaptchaInputError('');
			isCaptchaValid.current = true;
		}
	};

	// Refresh captcha
	const handleRefreshCaptcha = useCallback(() => {
		let str = '';
		for (let i = 0; i < 4; i++) {
			const temp =
				originalCharacter[randomNum(0, originalCharacter.length - 1)];
			str = `${str}${temp}`;
		}
		setCaptcha(str);
		setCaptchaInput('');
	}, []);

	// Register
	const handleRegister = () => {
		handleEmailInput();
		handlePhoneInput();
		handleFullnameInput();
		handlePasswordInput();
		handleCaptchaInput();
		//console.log(isEmailValid.current);
		if (
			(isEmailValid.current || isPhoneValid.current) &&
			isFullnameValid.current &&
			isPasswordValid.current &&
			isCaptchaValid.current
		) {
			axiosRegister(
				accountType,
				emailInput,
				countryCodeInput,
				phoneCodeInput,
				fullnameInput,
				passwordInput,
				navigator.language
			);
		}
	};

	const axiosRegister = (
		accountType,
		email,
		countryCode,
		phoneCode,
		fullname,
		password,
		locale
	) => {
		if (email === '') email = null;
		axios
			.post('/web/user/register', {
				accountType,
				email,
				countryCode,
				phoneCode,
				fullname,
				password,
				locale,
			})

			.then((res) => {});
		setPage(2);
		setCountdownBtnDisabled(10);
	};

	const handleActivateUser = (e) => {
		e.preventDefault();
		const post = {
			accountType,
			verificationCode,
			email: emailInput,
			countryCode: countryCodeInput,
			phoneCode: phoneCodeInput,
		};

		if (!post.verificationCode)
			return setVerificationCodeError('請輸入驗證碼');
		if (post.email === '') post.email = null;
		axios.put('/web/user/activate', post).then((res) => {
			//console.log(res.data);
			if (res.data.status === '0') {
				setPage(3);
				setSecondsBackToLogin(10);
			} else if (res.data.status === '1') {
				setVerificationCodeError('驗證碼錯誤');
			}
		});
	};

	const handleResendVerificationCode = () => {
		setCountdownBtnDisabled(10);
		const data = {
			accountType,
			email: emailInput,
			countryCode: countryCodeInput,
			phoneCode: phoneCodeInput,
			locale: navigator.language,
		};
		!data.email && (data.email = null);
		axios.post('/web/user/resendverificationcode', data).then((res) => {
			//console.log(res);
		});
	};

	//倒數計時 (回到首頁)
	const [secondsBackToLogin, setSecondsBackToLogin] = useState();
	useEffect(() => {
		const interval = setInterval(() => {
			if (secondsBackToLogin >= 0) {
				//console.log('h1h1', secondsBackToLogin);
				setSecondsBackToLogin(
					(secondsBackToLogin) => secondsBackToLogin - 1
				);
			}
		}, 1000);
		// console.log(secondsBackToLogin);
		if (secondsBackToLogin === 0) {
			navigate('/login');
		}

		return () => clearInterval(interval);
	}, [secondsBackToLogin, navigate]);

	useEffect(() => {
		handleRefreshCaptcha();
	}, [handleRefreshCaptcha]);

	//驗證碼重新发送 限制點擊
	useEffect(() => {
		// 解決useEffect慢一秒的問題
		document.querySelector('#resendVerifyBtn').textContent =
			countdownBtnDisabled;
		document.querySelector('#resendVerifyBtn').setAttribute('disabled', '');

		const timeout = setTimeout(() => {
			if (countdownBtnDisabled > 0) {
				document
					.querySelector('#resendVerifyBtn')
					.setAttribute('disabled', '');
				document.querySelector('#resendVerifyBtn').textContent =
					countdownBtnDisabled;
				//console.log('b', countdownBtnDisabled);

				setCountdownBtnDisabled(
					(countdownBtnDisabled) => countdownBtnDisabled - 1
				);
			} else if (countdownBtnDisabled === 0) {
				document
					.querySelector('#resendVerifyBtn')
					.removeAttribute('disabled');
				document.querySelector('#resendVerifyBtn').textContent =
					'重新获取';
			}
		}, 1000);

		return () => clearTimeout(timeout);
	}, [countdownBtnDisabled]);

	return (
		<div className="container-fluid p-0">
			<article
				id="container-register"
				className="bg-background flex-column align-items-center justify-content-lg-start px-2 px-lg-0"
			>
				<h1
					className={`pageTitle-mobile d-block d-lg-none ${
						page === 2 || page === 3 ? 'd-none' : ''
					}`}
				>
					创建账号
				</h1>
				<h1
					className={`pageTitle-mobile ${
						page === 2 ? 'd-block d-lg-none' : 'd-none'
					}`}
				>
					账号验证
				</h1>
				<section
					id="section-step"
					className={`${
						page === 3 ? 'd-none d-lg-block' : 'd-block'
					}`}
				>
					<div className="row text-center mx-0">
						<div
							className={`col-4 stepTitle ${
								page === 1 ? 'currentStep' : ''
							}`}
							id="liPage_1"
						>
							<span className="circleIcon">1</span>
							账号注册
						</div>
						<div
							className={`col-4 stepTitle ${
								page === 2 ? 'currentStep' : ''
							}`}
							id="liPage_3"
						>
							<span className="circleIcon">2</span>
							账号验证
						</div>
						<div
							className={`col-4 stepTitle ${
								page === 3 ? 'currentStep' : ''
							}`}
							id="liPage_2"
						>
							<span className="circleIcon">3</span>
							注册成功
						</div>
					</div>
				</section>
				<article className="w-100 articleBox mx-0">
					<section
						id="registerPage_1"
						className={`row g-3
							${page === 1 ? 'registerPageShow' : 'registerPageHidden'}`}
					>
						<h1 className="pageTitle d-none d-lg-block">
							创建账号
						</h1>
						{/*<!-- 帳號類型 -->*/}
						{accountType === '1' ? (
							// accountType: phone
							<div className="col-12">
								<div className="row g-4">
									<div className="col-12">
										<div className="row">
											<div className="col-4 col-lg-4">
												<select
													onChange={(e) => {
														setCountryCodeInput(
															e.target.value
														);
													}}
													placeholder="+886"
													className="form-select form-select-lg"
													id="countryCode"
													name="countryCode"
												>
													<option value="86">
														+86
													</option>
													<option value="886">
														+886
													</option>
													<option value="852">
														+852
													</option>
												</select>
											</div>
											<div className="col-8 col-lg-8">
												<input
													onChange={(e) => {
														setPhoneCodeInput(
															e.target.value
														);
													}}
													onBlur={() => {
														accountType === '1' &&
															handlePhoneInput();
													}}
													type="text"
													className="form-control form-control-lg"
													id="phoneCode"
													name="phoneCode"
													pattern="^[0-9]*$"
													placeholder="手機號"
													autoComplete="off"
													required
												/>
											</div>
										</div>
										<div
											className="invalid-feedback"
											id="phoneCode-info"
										>
											{phoneInputError}
										</div>
									</div>
									{/*<!-- 姓名 -->*/}
									<div className="col-12">
										<input
											type="text"
											value={fullnameInput}
											className="form-control form-control-lg"
											id="fullname"
											name="fullname"
											placeholder="姓名"
											autoComplete="off"
											onChange={(e) =>
												setFullnameInput(e.target.value)
											}
										/>
										<div
											className="invalid-feedback"
											id="fullname-info"
										>
											{fullnameInputError}
										</div>
									</div>
									{/*<!-- 密碼 -->*/}
									<div className="col-12">
										<input
											type="password"
											value={passwordInput}
											className="form-control form-control-lg"
											id="password"
											name="password"
											autoComplete="off"
											placeholder="密碼"
											onChange={(e) =>
												setPasswordInput(e.target.value)
											}
										/>
										<div
											className="invalid-feedback"
											id="password-info"
										>
											{passwordInputError}
										</div>
									</div>
									{/*<!-- 確定密碼 -->*/}
									<div className="col-12">
										<input
											type="password"
											value={confirmPasswordInput}
											className="form-control form-control-lg"
											id="confirmPWD"
											name="confirmPWD"
											placeholder="確認密碼"
											autoComplete="off"
											onChange={(e) =>
												setConfirmPasswordInput(
													e.target.value
												)
											}
										/>
										<div
											className="invalid-feedback"
											id="confirmPWD-info"
										></div>
									</div>
								</div>
							</div>
						) : (
							// accountType: email
							<div className="col-12">
								<div className="row g-4">
									<div className="col-12">
										<input
											onChange={(e) => {
												setEmailInput(e.target.value);
											}}
											onBlur={() => {
												accountType === '0' &&
													handleEmailInput();
											}}
											value={emailInput}
											type="email"
											className="form-control form-control-lg"
											id="email"
											name="email"
											pattern="^[a-zA-Z0-9@.]*$"
											placeholder="電子信箱"
											autoComplete="off"
											required
										/>
										<div
											className="invalid-feedback"
											id="email-info"
										>
											{emailInputError}
										</div>
									</div>
									{/*<!-- 姓名 -->*/}
									<div className="col-12">
										<input
											type="text"
											value={fullnameInput}
											className="form-control form-control-lg"
											id="fullname"
											name="fullname"
											placeholder="姓名"
											autoComplete="off"
											onChange={(e) =>
												setFullnameInput(e.target.value)
											}
										/>
										<div
											className="invalid-feedback"
											id="fullname-info"
										>
											{fullnameInputError}
										</div>
									</div>
									{/*<!-- 密碼 -->*/}
									<div className="col-12">
										<input
											type="password"
											value={passwordInput}
											className="form-control form-control-lg"
											id="password"
											name="password"
											autoComplete="off"
											placeholder="輸入6-16位密碼，英文區分大小寫"
											onChange={(e) =>
												setPasswordInput(e.target.value)
											}
										/>
										<div
											className="invalid-feedback"
											id="password-info"
										>
											{passwordInputError}
										</div>
									</div>
									{/*<!-- 確定密碼 -->*/}
									<div className="col-12">
										<input
											type="password"
											value={confirmPasswordInput}
											className="form-control form-control-lg"
											id="confirmPWD"
											name="confirmPWD"
											placeholder="確認密碼"
											autoComplete="off"
											onChange={(e) =>
												setConfirmPasswordInput(
													e.target.value
												)
											}
										/>
										<div
											className="invalid-feedback"
											id="confirmPWD-info"
										></div>
									</div>
								</div>
							</div>
						)}

						{/*<!-- 圖形驗證碼 -->*/}
						<div className="col-12 mt-4">
							<div className="d-flex justify-content-between">
								<input
									type="text"
									value={captchaInput}
									className="form-control form-control-lg"
									id="captchaInput-info"
									name="captchaInput-info"
									placeholder="验证码"
									required
									onChange={(e) => {
										setCaptchaInput(e.target.value);
									}}
								/>
								<div className="px-3 captchaDiv">
									<Captcha
										code={captcha}
										bgColor="#ffe"
										height="48"
										onClick={() => {
											handleRefreshCaptcha();
										}}
									/>
								</div>

								<button
									type="button"
									className="refreshBtn btn  btn-color-auxiliary d-flex justify-content-center align-items-center"
									onClick={handleRefreshCaptcha}
								>
									<Sync />
								</button>
							</div>
							<div className="invalid-feedback" id="captcha-info">
								{captchaInputError}
							</div>
						</div>

						{/* 隱私權政策  */}
						<div className="col-12 mt-5">
							已閱讀並同意以下協議 ：
							<Button
								variant="link"
								onClick={() => setModalShow(true)}
							>
								隱私權政策
							</Button>
						</div>
						{/*<!-- 同意協議並註冊 -->*/}
						<div className="col-12 text-center mb-3">
							<div id="agreeBtnBox">
								<button
									type="button"
									id="registerAgreeBtn"
									className="btn btn-lg btn-color-main rounded-pill row fs-16"
									onClick={handleRegister}
								>
									同意協議並註冊
								</button>
								{/* 切換註冊  */}
								<div
									id="registerChangeBox"
									className="mt-3 text-start"
								>
									{accountType === '1' ? (
										<Link
											to="/register"
											onClick={handleRegisterTypeChange}
										>
											电子邮箱注册
										</Link>
									) : (
										<Link
											to="/register"
											onClick={handleRegisterTypeChange}
										>
											手机号注册
										</Link>
									)}
								</div>
							</div>
						</div>

						<Modal
							size="lg"
							show={modalShow}
							onHide={() => setModalShow(false)}
							centered
						>
							<Modal.Header closeButton>
								<Modal.Title>隱私權政策</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div>
									在您使用我們的產品及/或服務時，我們需要/可能需要收集和使用的您的個人信息包括如下兩種：
									<br />
									1，為實現向您提供我們產品或/或服務的基本功能，您應授權我們收集，使用的必要的信息。如您拒絕提供相應的信息，您將無法正常使用我們的產品及/或服務；
									<br />
									2，為實現向您提供我們產品及/或服務的附加功能，您替代授權我們收集，使用的信息。如您拒絕提供，您將無法正常使用相關的附加功能或無法達到我們擬達到的功能效果，但並不會影響您正常使用我們產品及/或服務的基本功能。
									<br />
									<br />
									您理解並同意：
									<br />
									1，我們致力於打造多樣化的產品和服務並滿足您的需求。因我們向您提供的產品和服務種類繁多，且不同用戶選擇使用的具體產品/服務範圍存在差異，相應的，基本/附加功能及收集使用的個人信息類型，範圍等會有所區別，請以具體的產品/服務功能為準；
									<br />
									2，為給您帶來更好的產品和服務體驗，我們在持續努力改進我們的技術，隨之我們可能會不時推出新的或優化後的功能，可能需要收集，使用新的個人信息或變更個人信息使用目的或方式。這樣做，我們將通過更新本政策，彈窗，頁面提示等方式向向您說明對應的信息收集目的，範圍和使用方式，並為您提供自主選擇同意的方式，並且在徵得您明示同意同意後收集，使用。在此過程中，如果您有任何疑問，意見或建議的，您可通過淘寶網提供的各種聯繫方式與我們聯繫，我們會盡快為您做出解答。
									<br />
									<br />
									我們會為實現本政策預期的大部分功能，收集和使用您的個人信息：
									<br />
									（一）幫助您成為我們的會員
									<br />
									1，基礎會員服務
									<br />
									我們通過淘寶平台賬戶為您提供基礎會員服務。為註冊成為會員並使用我們的會員服務，您需要提供手機號碼，擬使用的會員名和密碼用於創建淘寶平台賬戶。如果您僅需使用瀏覽，搜索服務，您不需要註冊成為我們的會員及提供上述信息。
									<br />
									對於需要通過淘寶平台賬戶才能使用的服務，我們可能會根據您提供的上述信息重置您的會員身份，確保我們是在為您本人提供服務。若存在依法需確定您的會員身份的場景（包括依法）為您扣繳稅費，行政執法或司法訴訟中相關主體認定等）時，您授權我們可自付款寶公司處所獲取您對應的支付寶帳戶的相關認證信息用於上述目的。
									<br />
									我們會根據您的會員賬戶使用情況和平台設置的會員等級計算規則確定您當前的會員等級，並為您提供相應會員等級所對應的基本權益。
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="secondary"
									onClick={() => setModalShow(false)}
								>
									取消
								</Button>
							</Modal.Footer>
						</Modal>
					</section>
					{/* <section
								id="registerPage_2"
								className={
									page === 2
										? 'registerPageShow'
										: 'registerPageHidden'
								}
							>
								<div className="contentBox"></div> */}
					{/*<!-- 按鈕列 -->*/}
					{/* 
								<div
									className="btnBox text-center "
									style={{
										maxWidth: '100px',
										margin: '0 auto',
										marginTop: '100px',
									}}
								>
									<button className="btn btn-size-m btn-color-white">
										<FormattedMessage
											id="startup.previous"
											defaultMessage="上一步"
										/>
									</button>
									<button className="btn btn-size-m btn-color-white">
										<FormattedMessage
											id="startup.next"
											defaultMessage="下一步"
										/>
									</button>
								</div>
							</section>  */}
					<section
						className={`row g-3 ${page !== 2 && 'd-none'}`}
						id="registerPage_2"
					>
						<h1 className="pageTitle d-none d-lg-block">
							账号验证
						</h1>
						{/*<!-- 帳號驗證碼 -->*/}
						<div className="col-12">
							{/* <label htmlFor="verificationCode">
										<FormattedMessage
											id="auth.verificationCode"
											defaultMessage="验证码"
										/>
									</label> */}
							<div className="row">
								<div className="col-8">
									<input
										className="form-control form-control-lg"
										type="text"
										id="verificationCode"
										name="verificationCode"
										placeholder="请输入验证码"
										value={verificationCode}
										onChange={(e) =>
											setVerificationCode(e.target.value)
										}
									/>
								</div>
								<div className="col-4">
									<button
										type="button"
										className="btn  btn-color-auxiliary"
										id="resendVerifyBtn"
										onClick={handleResendVerificationCode}
									>
										'重新获取'
									</button>
								</div>
							</div>
							<div
								className="invalid-feedback"
								id="verificationCode-info"
							>
								{verificationCodeError}
							</div>
						</div>
						<div className="col-12" id="promptMessageBox">
							<p>
								验证码已发送到您的邮箱，有效时间为10分钟，请耐心等候。
							</p>
						</div>
						<div className="col-12 mb-4">
							<div className="btnBox row text-center w-75 mx-auto ">
								<button
									className="col btn btn-size-l btn-color-white rounded-pill me-2"
									onClick={(e) => {
										setPage(1);
										setVerificationCode('');
										setCaptchaInput('');
										handleRefreshCaptcha();
									}}
								>
									上一步
								</button>
								<button
									className="col btn btn-size-l btn-color-main rounded-pill ms-2"
									onClick={(e) => {
										handleActivateUser(e);
									}}
								>
									下一步
								</button>
							</div>
						</div>
					</section>
					<section
						id="registerPage_3"
						className={`row ${
							page === 3
								? 'registerPageShow'
								: 'registerPageHidden'
						}`}
					>
						<div className="col-12 text-center mb-3">
							<CheckCircleOutline
								style={{
									fill: '#1278E8',
									width: '100px',
									height: '100px',
								}}
							/>
						</div>
						<h1 className="col-12 text-center">註冊成功</h1>
						<div className="col-12 text-center">
							<span id="countdowntimer">
								{secondsBackToLogin}
							</span>
							秒后自动跳转至登录画面
						</div>
						{/*<!-- 按鈕列 -->*/}
						<div className="col-12 btnBox text-center">
							<button
								className="btn btn-size-m btn-color-main rounded-pill"
								onClick={() => {
									// clearInterval(timer);
									navigate('/login');
								}}
							>
								回登入畫面
							</button>
						</div>
					</section>
				</article>
			</article>
		</div>
	);
}

export default Register;
