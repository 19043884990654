import './physicalStore.scss';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { scrollToLocation } from '../../components/utils/utils';

/// 购买渠道
/// Author : YL
function PhysicalStore() {
    const [physicalStoreList, setPhysicalStoreList] = useState([]); // 宾乐达官方旗舰店
    const [flagshipStore, setFlagshipStore] = useState(null); // 销售网点
    const getPhysicalStoreList = () => {
        const url = '/web/Service/physicalstore';
        axios
            .get(url)
            .then((res) => {
                setFlagshipStore(res.data.flagshipStore);
                setPhysicalStoreList(res.data.physicalStoreList);
            })
            .catch((err) => console.log(err));
    };

    // 如果「销售网点」圖片失效,改用 fallbackSrc 灰底為圖片
    const handleImgOnError = (el) => {
        // el.target.onError = null;
        el.currentTarget.src = './images/physicalStore/fallbackSrc.png';
    };

    useEffect(() => {
        getPhysicalStoreList();

        return () => {};
    }, []);

    return (
        <div className="container-fluid p-0">
            <article
                id="container-physicalStore"
                className="d-flex flex-column align-items-center px-0 mx-auto"
            >
                <section className="physicalStore-section section1 row justify-content-center align-content-center text-center">
                    <h1 className="col-12 word">购买渠道</h1>
                    <h2 className="col-12 word">我们随时提供服务</h2>
                </section>

                <div className="wrapper">
                    <section className="physicalStore-section section2 bg-white row align-items-center">
                        <div className="col buttonArea">
                            <div
                                className="ms-3 me-lg-7 onlineShopTitleSectionLink"
                                onClick={() => scrollToLocation('.section3')}
                            >
                                线上商城
                            </div>
                            <div
                                className="ms-3 physicalStoreTitleSectionLink"
                                onClick={() => scrollToLocation('.section5')}
                            >
                                销售网点
                            </div>
                        </div>
                    </section>
                </div>

                <hr className="w-100 m-0" />

                <div className="wrapper">
                    <section
                        id="onlineShopTitleSection"
                        className="physicalStore-section section3 bg-white row align-items-center text-center"
                    >
                        <h2 className="col-12 ">线上商城</h2>
                        <div className="col-12">
                            线上商城购买渠道，多款产品供您选择！
                        </div>
                    </section>
                </div>

                <div className="wrapper">
                    <section
                        id="onlineShopSection"
                        className="physicalStore-section section4 row align-items-center justify-content-center text-center"
                    >
                        <div className="col-12 onlineShopImage onlineShopImage1"></div>
                        <div className="col-12 onlineShopTitle onlineShopTitle1 flagshipStoreTitle">
                            宾乐达官方旗舰店
                        </div>
                        <button
                            type="button"
                            className="btn btn-size-m btn-primary rounded-pill text-white mt-3 mb-4 mb-lg-7"
                            onClick={() => window.open(flagshipStore, '_blank')}
                        >
                            淘宝旗舰店
                        </button>
                    </section>
                </div>

                <div className="wrapper">
                    <section
                        id="physicalStoreTitleSection"
                        className="physicalStore-section section5 bg-white row align-items-center text-center justify-content-center"
                    >
                        <h2 className="col-12">销售网点</h2>
                        <div className="col-12">
                            建议体验前先联系店家，确认营业时间及展示型号。
                        </div>
                        <div className="col-12">
                            后续各地网点将陆续上线，敬请期待!
                        </div>
                    </section>
                </div>

                <div className="wrapper mb-11">
                    <section
                        id="physicalStoreSection"
                        className="physicalStore-section section6 bg-white row align-items-center text-center"
                    >
                        {physicalStoreList
                            ? physicalStoreList.map((el) => (
                                  <div
                                      id={el.storeGuid}
                                      key={el.storeGuid}
                                      className="physicalStoreCard bg-white w-100"
                                  >
                                      <div className="mx-4 mx-lg-0 d-flex">
                                          <div className="physicalStore-name">
                                              <div className="cardInner text-grey5 pb-4 mb-0 mb-lg-3">
                                                  <span>
                                                      <img
                                                          className="section16Img h-100 mx-0"
                                                          src="./images/physicalStore/section6_1.png"
                                                          alt="section6_1Img"
                                                      />
                                                  </span>
                                                  <span className="me-2">
                                                      {el.name}
                                                  </span>
                                              </div>
                                          </div>
                                          <div className="physicalStore-location">
                                              <div className="cardInner">
                                                  <div>{el.address}</div>
                                                  <div>
                                                      <span>
                                                          <img
                                                              className="section16Img h-100 mx-0"
                                                              src="./images/physicalStore/section6_2.png"
                                                              alt="section6_1Img"
                                                          />
                                                      </span>
                                                      <span className="ms-2">
                                                          {el.telephone}
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="physicalStore-image">
                                              {el.picture && (
                                                  <img
                                                      src={el.picture}
                                                      onError={(e) => {
                                                          handleImgOnError(e);
                                                      }}
                                                      alt=""
                                                  />
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </section>
                </div>

                <div className="wrapper mb-10 mb-lg-14">
                    <section className="physicalStore-section section7 w-100 row align-items-center justify-content-center px-0 bg-white">
                        <div className="smallWrapper">
                            <div className="col-12 desc px-3 px-lg-0">
                                <div className="text-grey3 mb-0">说明：</div>
                                <ul className="text-grey3 ps-0 mb-4 mb-lg-5">
                                    <li>
                                        1、宾乐达官方服务中心：为用户提供专业、便捷、贴心服务的窗口，主要业务有产品维修、检测、软件升级和咨询等服务；
                                    </li>
                                    <li>
                                        2、服务站：宾乐达官方认证并授权开展软件升级、快速送修和咨询服务的体验中心或专卖店；
                                    </li>
                                    <li>
                                        3、授权代送修点：代送修点不提供直接维修服务，会将机器送往就近服务中心进行维修。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="wrapper">
                    <section className="physicalStore-section section8 w-100 row justify-content-center">
                        <div className="qaSection col-12 col-lg-6 text-center px-0">
                            <div className="border-grey5 border-end">
                                <div className="text-grey5 pt-0 pt-lg-4 mt-4 mb-2 mb-lg-5">
                                    常见问题
                                </div>
                                <h6 className="text-grey5 pb-0 pb-lg-4 mb-3">
                                    <Link className="" to="/support/question">
                                        <span className="me-2">
                                            查看更多内容
                                        </span>
                                        <span>
                                            <img
                                                className="section16Img h-100 m-0"
                                                src="./images/physicalStore/section8_1.png"
                                                alt="section8Img"
                                            />
                                        </span>
                                    </Link>
                                </h6>
                            </div>
                        </div>
                        <div className="contactUs col-12 col-lg-6 text-center px-0">
                            <div className="text-grey5 pt-lg-4 mt-3 mt-lg-4 mb-2 mb-lg-5">
                                联系我们
                            </div>
                            <h6 className="text-grey5 pb-4 mb-0 mb-lg-3">
                                <Link className="" to="/support/contactus">
                                    <span className="me-2">开始咨询</span>
                                    <span>
                                        <img
                                            className="section16Img h-100 mx-0"
                                            src="./images/physicalStore/section8_1.png"
                                            alt="section8Img"
                                        />
                                    </span>
                                </Link>
                            </h6>
                        </div>
                    </section>
                </div>

                <hr className="w-100 m-0" />

                {/* <div className="wrapper">
                    <section className="physicalStore-section section9 w-100 align-items-center justify-content-center px-0 bg-white text-center">
                        <h2 className="mt-6 mt-lg-7 mb-2 mb-lg-3 text-grey5">
                            售后服务
                        </h2>
                        <div className="px-8 px-lg-0 mb-5 text-grey5">
                            欢迎使用宾乐达售后服务，期待为您提供帮助。
                        </div>
                        <div className="btn btn-primary rounded-pill text-white mb-6 mb-lg-14">
                            跳转至服务功能
                        </div>
                    </section>
                </div> */}
            </article>
        </div>
    );
}

export default PhysicalStore;
