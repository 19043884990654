import React from 'react';
import { Modal } from 'react-bootstrap';

function EditDrillItemModal({
    editDrillModalOpen,
    setEditDrillModalOpen,
    editDrillItemData,
    setEditDrillItemData,
    drillCategoryItemList,
    spinNameTable,
    handleEditDrillItemSubmit,
    handleDeleteDrillItem,
    // handleSpinPowerChange,
    // handleHighSpeedChange,
    // handleSpinChange,
}) {
    const checkVerticalValue = (val) => {
        if ((val / 10) % 0.5 !== 0 || val > 400 || val < -400) {
            alert('请输入0.5的倍数,數值介於-40至40之間');
            setEditDrillItemData({ ...editDrillItemData, vertical: 0 });

            return false;
        }

        return true;
    };

    const checkHorizontalValue = (val) => {
        if ((val / 10) % 0.5 !== 0 || val > 300 || val < -300) {
            alert('请输入0.5的倍数,數值介於-30至30之間');
            setEditDrillItemData({ ...editDrillItemData, horizontal: 0 });

            return false;
        }

        return true;
    };

    const checkHighSpeedValue = (val) => {
        const parsedValue = parseInt(val, 10);
        console.log(parsedValue);

        if (parsedValue != val || val > 80 || val < 10 || !val) {
            alert('请输入整數,數值介於10至80之間');
            setEditDrillItemData({ ...editDrillItemData, highSpeed: 0 });

            return false;
        }

        return true;
    };

    const checkDelayValue = (val) => {
        // const parsedValue = parseInt(val, 10);

        if (val > 3 || val < 0 || !val) {
            alert('请输入數值介於0至3.0之間');
            setEditDrillItemData({ ...editDrillItemData, delay: 0 });

            return false;
        }

        return true;
    };

    const calculateLowSpeed = (
        highSpeedWheel = editDrillItemData.highSpeedWheel,
        highSpeed = editDrillItemData.highSpeed,
        powerBLDC1 = editDrillItemData.powerBLDC1,
        powerBLDC2 = editDrillItemData.powerBLDC2,
        spinPower = editDrillItemData.spinPower,
        spin = editDrillItemData.spin
    ) => {
        console.log(editDrillItemData);

        // 計算最高旋球強度
        const maxSpinPower = Math.floor((highSpeed - 10) / 2 + 1);

        console.log(maxSpinPower);
        console.log(spinPower);
        // 計算低速輪

        let correctSpin = 0;

        // 如果本來是無旋球
        if (spinPower === 0) {
            correctSpin = maxSpinPower;
            spinPower = maxSpinPower;

            // 其他
        } else {
            correctSpin = spinPower > maxSpinPower ? maxSpinPower : spinPower;
        }

        console.log(correctSpin);

        const lowSpeed = highSpeed - (2 * (correctSpin - 1) + 10);

        setEditDrillItemData({
            ...editDrillItemData,
            highSpeedWheel: highSpeedWheel,
            highSpeed: highSpeed,
            lowSpeed: lowSpeed,
            powerBLDC1: powerBLDC1,
            powerBLDC2: powerBLDC2,
            spinPower: spinPower,
            maxSpinPower: maxSpinPower,
            spin: spin,
        });
    };

    // 改變 spin power, 帶動 低速輪改變
    const handleSpinPowerChange = (val) => {
        calculateLowSpeed(
            undefined,
            undefined,
            undefined,
            undefined,
            val,
            undefined
        );
    };

    const handleSpinChange = (val) => {
        calculateLowSpeed(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            val
        );
    };

    const handleHighSpeedChange = (val) => {
        console.log(val);
        calculateLowSpeed(
            undefined,
            val,
            undefined,
            undefined,
            undefined,
            undefined
        );
    };

    return (
        <Modal
            className="editDrillItemModal modal"
            show={editDrillModalOpen}
            onHide={() => setEditDrillModalOpen(false)}
            centered
        >
            <Modal.Header closeButton className="border-bottom">
                <Modal.Title>編輯球路</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row g-3 align-items-end">
                    <div className="col-6">
                        <label
                            htmlFor="fullname"
                            className="fontSize75Rem mb-2"
                        >
                            俯仰角度
                        </label>
                        <select
                            className="form-control mb-3 w-100"
                            name="verticalDirection"
                            id="verticalDirection"
                            value={editDrillItemData.verticalDirection || 0}
                            onChange={(e) =>
                                setEditDrillItemData({
                                    ...editDrillItemData,
                                    verticalDirection: e.target.value,
                                })
                            }
                        >
                            <option value={1}>上</option>
                            <option value={-1}>下</option>
                        </select>
                    </div>
                    <div className="col-6">
                        <input
                            id="vertical"
                            name="vertical"
                            className="form-control mb-3"
                            type="number"
                            value={editDrillItemData.vertical / 10}
                            onChange={(e) =>
                                setEditDrillItemData({
                                    ...editDrillItemData,
                                    vertical: e.target.value * 10,
                                })
                            }
                            onBlur={(e) =>
                                checkVerticalValue(e.target.value * 10)
                            }
                        />
                    </div>
                </div>
                <div className="row g-3 align-items-end">
                    <div className="col-6">
                        <label
                            htmlFor="fullname"
                            className="fontSize75Rem mb-2"
                        >
                            水平角度
                        </label>
                        <select
                            className="form-control mb-3 w-100"
                            name="horizontalDirection"
                            id="horizontalDirection"
                            value={editDrillItemData.horizontalDirection}
                            onChange={(e) =>
                                setEditDrillItemData({
                                    ...editDrillItemData,
                                    horizontalDirection: e.target.value,
                                })
                            }
                        >
                            <option value={-1}>左</option>
                            <option value={1}>右</option>
                        </select>
                    </div>
                    <div className="col-6">
                        <input
                            id="vertical"
                            name="vertical"
                            className="form-control mb-3"
                            type="number"
                            value={editDrillItemData.horizontal / 10 || 0}
                            onChange={(e) =>
                                setEditDrillItemData({
                                    ...editDrillItemData,
                                    horizontal: e.target.value * 10,
                                })
                            }
                            onBlur={(e) =>
                                checkHorizontalValue(e.target.value * 10)
                            }
                        />
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-12">
                        <label
                            htmlFor="spinPower"
                            className="fontSize75Rem mb-2"
                        >
                            出球速度
                        </label>
                        <input
                            id="highSpeed"
                            name="highSpeed"
                            className="form-control mb-3"
                            type="number"
                            pattern="[0-9]*"
                            value={editDrillItemData.highSpeed}
                            onChange={(e) => {
                                setEditDrillItemData({
                                    ...editDrillItemData,
                                    highSpeed: e.target.value,
                                });
                            }}
                            onBlur={(e) => {
                                handleHighSpeedChange(e.target.value);
                                checkHighSpeedValue(e.target.value);
                            }}
                        />
                        <label
                            htmlFor="spinPower"
                            className="fontSize75Rem mb-2"
                        >
                            旋球強度
                        </label>
                        <select
                            className="form-control mb-3 w-100"
                            name="spinPower"
                            id="spinPower"
                            value={
                                editDrillItemData.spin === 'noSpin'
                                    ? 'noSpin'
                                    : editDrillItemData.spinPower >
                                      editDrillItemData.maxSpinPower
                                    ? editDrillItemData.maxSpinPower
                                    : editDrillItemData.spinPower
                            }
                            disabled={editDrillItemData.spin === 'noSpin'}
                            onChange={(e) => {
                                handleSpinPowerChange(e.target.value);
                            }}
                        >
                            {Array.from(
                                {
                                    length: editDrillItemData.maxSpinPower,
                                },
                                (_, index) => (
                                    <option
                                        key={`spinPower${index + 1}`}
                                        value={index + 1}
                                    >
                                        {index + 1}
                                    </option>
                                )
                            ).reverse()}
                            {editDrillItemData.spin === 'noSpin' && (
                                <option key="spinPower0" value="noSpin">
                                    不旋球
                                </option>
                            )}
                        </select>
                        <label
                            htmlFor="fullname"
                            className="fontSize75Rem mb-2"
                        >
                            旋球角度
                        </label>
                        <select
                            className="form-control mb-3 w-100"
                            name="spin"
                            id="spin"
                            value={editDrillItemData.spin || 0}
                            onChange={(e) => {
                                handleSpinChange(e.target.value);
                            }}
                        >
                            {Array.from({ length: 16 }, (_, index) => (
                                <option
                                    key={`newSpin${index}`}
                                    value={index * 225}
                                >
                                    {spinNameTable[index]}
                                </option>
                            ))}
                            {/* {drillCategoryItemList.map((el) => (
                                <option
                                    value={el.categoryItemGuid}
                                    key={el.categoryItemGuid}
                                    id={el.categoryItemGuid}
                                >
                                    {`${el.name_ZH}`}
                                </option>
                            ))} */}
                            <option value="noSpin">不旋球</option>
                        </select>
                        <label
                            htmlFor="fullname"
                            className="fontSize75Rem mb-2"
                        >
                            出球延遲(秒)
                        </label>
                        <input
                            id="newDelay"
                            name="newDelay"
                            className="form-control mb-3"
                            type="number"
                            pattern="[0-9]*"
                            value={editDrillItemData.delayUI || 0}
                            onChange={(e) =>
                                setEditDrillItemData({
                                    ...editDrillItemData,
                                    delayUI: e.target.value,
                                })
                            }
                            onBlur={(e) => checkDelayValue(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row g-3 align-items-end">
                    <div className="col-6">
                        <label
                            htmlFor="fullname"
                            className="fontSize75Rem mb-2"
                        >
                            高速輪
                        </label>
                        <input
                            id="horizontal"
                            name="horizontal"
                            className="form-control mb-3"
                            type="number"
                            value={editDrillItemData.highSpeed}
                            onChange={(e) =>
                                setEditDrillItemData({
                                    ...editDrillItemData,
                                    horizontal: e.target.value,
                                })
                            }
                            disabled
                        />
                    </div>
                    <div className="col-6">
                        <label
                            htmlFor="fullname"
                            className="fontSize75Rem mb-2"
                        >
                            低速輪
                        </label>
                        <input
                            id="horizontal"
                            name="horizontal"
                            className="form-control mb-3"
                            type="number"
                            value={
                                editDrillItemData.spin === 'noSpin'
                                    ? editDrillItemData.highSpeed
                                    : editDrillItemData.lowSpeed
                            }
                            onChange={(e) =>
                                setEditDrillItemData({
                                    ...editDrillItemData,
                                    horizontal: e.target.value,
                                })
                            }
                            disabled
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center mx-4">
                <button
                    type="button"
                    className="btn btn-primary text-white px-4 me-2"
                    onClick={() => {
                        checkVerticalValue(editDrillItemData.vertical) &&
                            checkHorizontalValue(
                                editDrillItemData.horizontal
                            ) &&
                            checkHighSpeedValue(editDrillItemData.highSpeed) &&
                            handleEditDrillItemSubmit();
                    }}
                >
                    確定
                </button>
                <button
                    type="button"
                    className="btnCancel btn btn-outline-primary px-4"
                    onClick={() => setEditDrillModalOpen(false)}
                >
                    取消
                </button>
                <button
                    type="button"
                    className="btnCancel btn btn-outline-danger px-4"
                    onClick={() => {
                        handleDeleteDrillItem(
                            editDrillItemData.presetDrillItemGuid
                        );
                    }}
                >
                    刪除球路
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditDrillItemModal;
