import './homepage.scss';
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Card } from 'react-bootstrap';
import { AccountContext } from '../../../App';

function Homepage() {
    const accountContext = useContext(AccountContext);
    const homePageIndex = accountContext.homePageIndex;
    const setHomePageIndex = accountContext.setHomePageIndex;
    const handleSelect = (selectedIndex, e) => {
        setHomePageIndex(selectedIndex);
        //// Navbar text color end
    };

    const isLoaded = document.querySelectorAll('.visually-hidden')[1];
    useEffect(() => {
        document.querySelectorAll('.visually-hidden')[0].textContent = '';
        document.querySelectorAll('.visually-hidden')[1].textContent = '';
        document.querySelectorAll('.head-menu-item1').forEach((el) => {
            el.classList.add('homepageNavColor');
        });

        return () => {
            document.querySelectorAll('.head-menu-item1').forEach((el) => {
                el.classList.remove('homepageNavColor');
            });
        };
    }, [isLoaded]);

    return (
        <div className="container-fluid p-0">
            <article id="container-homepage">
                <section className="carousel-section">
                    <Carousel
                        activeIndex={homePageIndex}
                        onSelect={handleSelect}
                        variant={'dark'}
                    >
                        <Carousel.Item interval={99999999}>
                            <picture>
                                <source
                                    className="d-block w-100"
                                    media="(min-width: 992px)"
                                    srcSet="./images/home/homepage_slide_1_2x.png"
                                    alt="First slide"
                                />
                                <img
                                    className="d-block w-100"
                                    src="./images/home/homepage_slide_1_1x.png"
                                    alt="First slide"
                                />
                            </picture>

                            <Carousel.Caption>
                                <h1 className="text-white mb-2 mb-lg-5">
                                    宾乐达乒乓球智能发球机
                                </h1>
                                <h2 className="text-white mb-3 mb-lg-4">
                                    性能服众 硬实力派
                                </h2>
                                <p className="text-white mb-3 mb-lg-6">
                                    经久耐用的核心部件 ｜ 专业质量的电路板 ｜
                                    <br className=" d-lg-none" />
                                    搭载APP调控
                                </p>
                                <div
                                    className="carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start"
                                    style={{ gap: '16px' }}
                                >
                                    <Link
                                        className=" btn-size-m"
                                        to="/productcenter/productfeature"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-size-m btn-hover-white  rounded-pill "
                                        >
                                            了解更多
                                        </button>
                                    </Link>
                                    <Link
                                        className=" btn-size-m"
                                        to="/physicalstore"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-size-m btn-color-main rounded-pill text-white"
                                        >
                                            立即购买
                                        </button>
                                    </Link>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={99999999}>
                            <picture>
                                <source
                                    className="d-block w-100"
                                    media="(min-width: 992px)"
                                    srcSet="./images/home/homepage_slide_2_2x.png"
                                    alt="Second slide"
                                />
                                <img
                                    className="d-block w-100"
                                    src="./images/home/homepage_slide_2_1x.png"
                                    alt="Second slide"
                                />
                            </picture>

                            <Carousel.Caption>
                                <h1 className="text-white mb-2 mb-lg-5">
                                    宾乐达乒乓球智能发球机
                                </h1>
                                <h2 className="text-white mb-3 mb-lg-4">
                                    循环出球 畅⁠练⁠起⁠来
                                </h2>
                                <p className="text-white mb-3 mb-lg-6">
                                    经久耐用的核心部件 ｜ 专业质量的电路板 ｜
                                    <br className=" d-lg-none" />
                                    搭载APP调控
                                </p>
                                <div
                                    className="carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start"
                                    style={{ gap: '16px' }}
                                >
                                    <Link className=" btn-size-m" to="/explore">
                                        <button
                                            type="button"
                                            className="btn btn-size-m btn-hover-white  rounded-pill "
                                        >
                                            了解更多
                                        </button>
                                    </Link>
                                    <Link
                                        className=" btn-size-m"
                                        to="/physicalstore"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-size-m btn-color-main rounded-pill text-white"
                                        >
                                            立即购买
                                        </button>
                                    </Link>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <source
                                    className="d-block w-100"
                                    media="(min-width: 992px)"
                                    srcSet="./images/home/homepage_slide_3_2x.png"
                                    alt="Third slide"
                                />
                                <img
                                    className="d-block w-100"
                                    src="./images/home/homepage_slide_3_1x.png"
                                    alt="Third slide"
                                />
                            </picture>

                            <Carousel.Caption>
                                <h1 className="mb-2 mb-lg-5">
                                    宾乐达乒乓球智能发球机
                                </h1>
                                <h2 className="mb-3 mb-lg-4">
                                    APP调控 高效非凡
                                </h2>
                                <p className="mb-3 mb-lg-6">
                                    经久耐用的核心部件 ｜ 专业质量的电路板 ｜
                                    <br className=" d-lg-none" />
                                    搭载APP调控
                                </p>
                                <div
                                    className="carouselBtnWrap d-flex flex-column-reverse flex-md-row  align-items-center justify-content-md-center justify-content-lg-start"
                                    style={{ gap: '16px' }}
                                >
                                    <Link
                                        className=" btn-size-m"
                                        to="/app/download"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-size-m btn-color-outline-transparent-auxiliary  rounded-pill"
                                        >
                                            了解更多
                                        </button>
                                    </Link>
                                    <Link
                                        className=" btn-size-m"
                                        to="/physicalstore"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-size-m btn-color-main rounded-pill text-white"
                                        >
                                            立即购买
                                        </button>
                                    </Link>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </section>
                <section className="articleCard-section row flex-column flex-lg-row g-0">
                    <Card className="col-lg-4 px-3 py-3 py-lg-0 px-xxl-4 border-0">
                        <Link
                            to="/productcenter/productfeature"
                            alc="productfeature"
                        >
                            <div className="imgBox">
                                <Card.Img
                                    variant="top"
                                    src="./images/home/homepage_1.png"
                                />
                            </div>
                            <Card.Body className="bg-background">
                                <Card.Title>流畅体验, 真实陪练</Card.Title>
                                <Card.Text>
                                    经久耐用的核心部件，您的最佳陪伴。
                                </Card.Text>
                            </Card.Body>
                        </Link>
                    </Card>
                    <Card className="col-lg-4 px-3 py-3 py-lg-0 px-xxl-4 border-0">
                        <Link to="/explore" alc="explore">
                            <div className="imgBox">
                                <Card.Img
                                    variant="top"
                                    src="./images/home/homepage_2.png"
                                />
                            </div>
                            <Card.Body className="bg-background">
                                <Card.Title>鉴赏之旅, 仔细欣赏</Card.Title>
                                <Card.Text>探索更多功能指南</Card.Text>
                            </Card.Body>
                        </Link>
                    </Card>
                    <Card className="col-lg-4 px-3 py-3 py-lg-0 px-xxl-4 border-0">
                        <Link to="/app/download" alc="appDownload">
                            <div className="imgBox">
                                <Card.Img
                                    variant="top"
                                    src="./images/home/homepage_3.png"
                                />
                            </div>
                            <Card.Body className="bg-background">
                                <Card.Title>一开即用, 一键开打</Card.Title>
                                <Card.Text>
                                    即刻安装宾乐达专属控制软件 !
                                </Card.Text>
                            </Card.Body>
                        </Link>
                    </Card>
                </section>
            </article>
        </div>
    );
}

export default Homepage;
