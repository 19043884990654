import './navMenu.scss';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
// import { IntlContext } from '../I18nWrapper/I18nWrapper';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
	PersonOutline,
	Menu,
	Close,
	TrendingUpTwoTone,
	MenuBookSharp,
} from '@material-ui/icons';
import { Accordion } from 'react-bootstrap';
import { AccountContext } from '../../App';
import { useTranslation } from 'react-i18next';

function NavMenu() {
	const { t } = useTranslation(['common', 'navMenu']); // i18n

	const [secondNavIndex, setSecondNavIndex] = useState(null);
	const [listShow, setListShow] = useState(false);
	const [rwdNavShow, setRwdNavShow] = useState(false);
	const [userFullname, setUserFullname] = useState('使用者');
	const [cookies, setCookie, removeCookie] = useCookies([
		'loginUser',
		'page',
		'RefreshToken',
		'AccessToken',
	]);

	// fullname
	const accountContext = useContext(AccountContext);
	const homePageIndex = accountContext.homePageIndex;

	// 多國語言
	let language = accountContext.lang;
	let setLang = accountContext.setLang;

	const navigate = useNavigate();
	const location = useLocation();
	const params = location.pathname;
	const guid =
		cookies.loginUser && cookies.loginUser.userGuid
			? cookies.loginUser.userGuid
			: '';

	const handleCheckTransitionOpening = () => {
		if (document.querySelector('.rwd-navbar-transition')) {
			console.log('opening');
			document.getElementById(
				'personIconContainer'
			).children[0].style.color = '#282828';
			document.getElementById(
				'personIconContainer'
			).children[0].children[0].style.color = '#282828';

			document.querySelector('.control-nav').children[0].style.color =
				'#282828';
		} else {
			handleCheckListShow();
			// console.log('closing');
		}

		window.innerWidth > 992 && setRwdNavShow(false);
	};

	const handleCheckListShow = () => {
		// 1.電腦版-產品中心secondNav .productCenterNav 顯示/不顯示
		let productCenterNav = document.querySelector('.productCenterNav');
		let firstImgSection = document.querySelector('.section1');

		if (productCenterNav) {
			if (scrollTop === 0 && scrolling === false) {
				// 1.卷軸位於Top == 0
				productCenterNav.style = 'top: 79px'; // .productCenterNav下移
				if (firstImgSection) firstImgSection.style = 'margin-top:90px';
				// .section1下移
			} else if (scrolling === false) {
				// 2.卷軸往上
				document
					.querySelector('#header-app')
					.classList.remove('d-lg-none'); // navbar顯示
				productCenterNav.style = 'top: 79px'; // .productCenterNav下移
			} else if (scrollTop > 0) {
				// 3.卷軸離開Top == 0
				document
					.querySelector('#header-app')
					.classList.add('d-lg-none'); // navbar隱藏
				productCenterNav.style = 'top: 0; border-top: none'; // .productCenterNav上移
				if (firstImgSection) firstImgSection.style = 'margin-top:50px';
				// .section1上移
			}
		}

		// 2.手機板-產品中心secondNav .productCenterNavPhone 顯示/不顯示
		let productCenterNavPhone = document.querySelector(
			'.productCenterNavPhone'
		);

		if (productCenterNavPhone) {
			if (scrollTop === 0 && scrolling === false) {
				// 1.卷軸位於Top == 0
				productCenterNavPhone.style = 'top: 47px'; // .productCenterNavPhone下移
				if (firstImgSection) firstImgSection.style = 'margin-top:90px';
				// .section1下移
			} else if (scrolling === false) {
				// 2.卷軸往上
				document
					.querySelector('#header-app')
					.classList.remove('d-none'); // navbar顯示
				productCenterNavPhone.style = 'top: 47px'; // .productCenterNavPhone下移
			} else if (scrollTop > 0) {
				// 3.卷軸離開Top == 0
				document.querySelector('#header-app').classList.add('d-none'); // navbar隱藏
				productCenterNavPhone.style = 'top: 0; border-top: none'; // .productCenterNavPhone上移
				if (firstImgSection) firstImgSection.style = 'margin-top:75px';
				// .section1上移
			}
		}

		// console.log(params);

		if (params === '/') {
			document.querySelectorAll('.head-menu-item1').forEach((el) => {
				if (scrollTop) {
					el.children[0].style.color = '#282828';
					document.getElementById(
						'personIconContainer'
					).children[0].style.color = '#282828';
					document.querySelector(
						'.control-nav'
					).children[0].style.color = '#282828';
					document.getElementById(
						'personIconContainer'
					).children[0].children[0].style.color = '#282828';
				} else {
					el.children[0].style.color = '#fff';
					document.getElementById(
						'personIconContainer'
					).children[0].style.color = '#fff';
					document.querySelector(
						'.control-nav'
					).children[0].style.color = '#fff';
					document.getElementById(
						'personIconContainer'
					).children[0].children[0].style.color = '#fff';
					if (homePageIndex === 2) {
						el.children[0].style.color = '#282828';
						document.getElementById(
							'personIconContainer'
						).children[0].style.color = '#282828';

						document.querySelector(
							'.control-nav'
						).children[0].style.color = '#282828';
						document.getElementById(
							'personIconContainer'
						).children[0].children[0].style.color = '#282828';
					}
				}
			});
		} else {
			document.querySelectorAll('.head-menu-item1').forEach((el) => {
				el.children[0].style.color = '#282828';
			});
			document.querySelector('.control-nav').children[0].style.color =
				'#282828';
			document.getElementById(
				'personIconContainer'
			).children[0].style.color = '#282828';
			document.getElementById(
				'personIconContainer'
			).children[0].children[0].style.color = '#282828';
		}
	};

	// 滑鼠進入second nav
	const handleMenuSecondEnter = (e) => {
		const el = document.querySelectorAll('.nav-btn');
		const arr = Array.from(el);
		arr.map((e) => {
			return e.classList.remove('active-btn');
		});

		setListShow(true);
		Array.from(
			e.target.closest('li').children
		)[0].children[0].classList.add('active-btn');
	};

	useEffect(() => {
		//產品中心頁面 secondNav 永遠顯示
		if (params.includes('productcenter')) {
			setSecondNavIndex(0);
			setListShow(true);
			document.querySelector('#header-app').style.backgroundColor =
				'#fff';
		} else {
			document.querySelector('#header-app').style.backgroundColor = null;
		}
	}, [params]);

	// 滑鼠離開second nav
	const handleMenuSecondLeave = (e) => {
		setListShow(false);
		const el = document.querySelectorAll('.active-btn');
		const array = Array.from(el);
		array.map((e) => {
			return e.classList.remove('active-btn');
		});

		//產品中心頁面 secondNav 永遠顯示
		if (params.includes('productcenter')) {
			setSecondNavIndex(0);
			setListShow(true);
		}
	};

	// Open nav in RWD
	const handleToggleNavList = () => {
		setRwdNavShow(!rwdNavShow);

		document.querySelector('.rwd-navbar') &&
			document
				.querySelector('.rwd-navbar')
				.classList.toggle('rwd-navbar-transition');
	};

	// NavMenu.js
	const handleIsLogin = () => {
		if (!guid) {
			setRwdNavShow(false);
			return navigate('/login');
		} else return;
	};

	// 捲動頁面改變nav背景顏色
	// scrolling可以用來判斷卷軸向上或向下，目前未使用因此註解
	const [scrolling, setScrolling] = useState(false);
	const [scrollTop, setScrollTop] = useState(0);

	useEffect(() => {
		const onScroll = (e) => {
			setScrollTop(e.target.documentElement.scrollTop);
			setScrolling(e.target.documentElement.scrollTop > scrollTop);
		};
		window.addEventListener('scroll', onScroll);

		handleCheckTransitionOpening();
		return () => window.removeEventListener('scroll', onScroll);
	}, [scrollTop, params, homePageIndex, scrolling]);

	// 個人相關頁面-框架
	const handlePerson = () => {
		document.querySelector('.personIconBox').classList.toggle('d-none');

		// 由於可能會按到svg內的path，因此多加class給path，以利判斷
		document
			.querySelector('.personIcon')
			.childNodes[0].classList.add('MuiSvgIcon-root', 'personIcon');

		const personIcon = document.querySelector('.personIcon').children[0];
		scrollTop
			? (personIcon.style.color = '#fff')
			: (personIcon.style.color = '#282828');

		scrollTop
			? (document.getElementById(
					'personIconContainer'
			  ).children[0].children[0].style.color = '#fff')
			: (document.getElementById(
					'personIconContainer'
			  ).children[0].children[0].style.color = '#282828');

		handleCheckTransitionOpening();
	};

	const handleHeaderAppEnter = () => {
		document.getElementById('personIconContainer').children[0].style.color =
			'#282828';
		document.getElementById(
			'personIconContainer'
		).children[0].children[0].style.color = '#282828';
	};

	const handleHeaderAppLeave = () => {
		handleCheckListShow();
	};

	// 個人相關頁面-關閉視窗
	window.onclick = function (e) {
		if (
			document.querySelector('.personIconBox') &&
			e.target.classList[1] !== 'personIcon'
		) {
			document.querySelector('.personIconBox').classList.add('d-none');
		}
		// console.log(e.target.classList);
		// console.log(document.querySelector('.personIcon').childNodes[0]);
	};

	// 個人相關頁面-顯示名稱
	useEffect(() => {
		accountContext.accountInfo.fullname !== ''
			? setUserFullname(accountContext.accountInfo.fullname)
			: setUserFullname('使用者');

		// console.log(accountContext.accountInfo.fullname);
	}, [guid, accountContext.accountInfo.fullname]);

	// 登出
	function handleLogout() {
		setCookie('loginUser', { status: '1' }, { path: '/' });
		setCookie('page', [], { path: '/' });
		removeCookie(['AccessToken', 'RefreshToken']);
		localStorage.clear();
		navigate('/login');
	}
	//console.log("cookies", cookies);

	//自動登出
	const useUnload = (fn) => {
		const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

		useEffect(() => {
			cb.current = fn;
		}, [fn]);

		useEffect(() => {
			const onUnload = (...args) => cb.current?.(...args);

			window.addEventListener('onunload', onUnload);

			return () => window.removeEventListener('onunload', onUnload);
		}, []);
	};

	useUnload((e) => {
		e.preventDefault();
		if (cookies.loginAuto === 'false') {
			setCookie('loginUser', { status: '1' }, { path: '/' });
		}
	});

	// 自動登出-偵測關閉視窗事件
	document.addEventListener('mouseleave', (e) => {
		if (
			e.pageX > window.screen.width / 2 &&
			cookies.loginAuto === 'false'
		) {
			window.onbeforeunload = function () {
				setCookie('loginUser', { status: '1' }, { path: '/' });
			};
		}
	});

	document.addEventListener('mouseenter', (e) => {
		window.onbeforeunload = null;
		window.onunload = null;
	});

	return (
		<div className="container-fluid p-0 " id="nav-app">
			{/* navbar */}
			<header
				id="header-app"
				className={`w-100 position-fixed top-0 px-5 ${
					scrollTop === 0
						? ''
						: 'bg-white border-bottom border-grey2 rwdNavBg'
				}`}
				onMouseEnter={(e) => handleHeaderAppEnter(e)}
				onMouseLeave={(e) => handleHeaderAppLeave(e)}
			>
				<div
					className="h-100 d-flex align-items-center mx-auto"
					id="container-header"
				>
					{/* navbar left */}
					{/* 1.logo */}
					<div
						className="head-logo"
						// 關閉RwdNav
						onClick={() => setRwdNavShow(false)}
					>
						<Link className="" to="/">
							<img src="./images/pingtexLogo.png" alt="logo" />
						</Link>
					</div>

					{/* 1.rwd hamburger button */}
					<div
						className="control-nav d-lg-none"
						onClick={() => {
							handleToggleNavList();
							handleCheckTransitionOpening();
						}}
					>
						{rwdNavShow ? (
							<Close className="mb-4 mb-lg-0" />
						) : homePageIndex === 2 || params !== '/' ? (
							<Menu
								className="mb-4 mb-lg-0"
								style={{ color: 'black' }}
							/>
						) : (
							<Menu
								className="mb-4 mb-lg-0"
								style={{ color: 'white' }}
							/>
						)}
					</div>

					{/* navbar center */}
					{/* 2.head-menu */}
					<div className="head-menu d-none d-lg-block ps-5">
						<ul className="d-flex list-unstyled m-0 head-menu-ul">
							<li className="head-menu-item1 text-center" id="0">
								<Link
									onMouseEnter={(e) => {
										handleMenuSecondEnter(e);
										setSecondNavIndex(0);
									}}
									className="px-2 px-lg-3"
									to="/productcenter/productfeature"
								>
									<span className="pb-1 nav-btn">
										{t('navMenu:productCenter')}
									</span>
								</Link>
							</li>
							<li className="head-menu-item1 text-center" id="1">
								<Link
									onMouseEnter={(e) => {
										handleMenuSecondEnter(e);
										setSecondNavIndex(1);
									}}
									className="px-2 px-lg-3"
									to="/explore"
								>
									<span className="pb-1 nav-btn">
										{t('navMenu:onlineShowroom')}
									</span>
								</Link>
							</li>
							{/* <li
								className="head-menu-item1 text-center"
								id="2"
								
							>
								<Link
									onMouseEnter={(e) => {
										handleMenuSecondEnter(e);
										setSecondNavIndex(2);
									}}
									className="px-2 px-lg-3"
									to="/"
								>
									<span className="pb-1 nav-btn">
										新闻动态
									</span>
								</Link>
							</li>*/}
							<li className="head-menu-item1 text-center" id="3">
								<Link
									onMouseEnter={(e) => {
										handleMenuSecondEnter(e);
										setSecondNavIndex(3);
									}}
									className="px-2 px-lg-3"
									to="/physicalstore"
								>
									<span className="pb-1 nav-btn">
										{t('navMenu:purchasingChannel')}
									</span>
								</Link>
							</li>
							<li className="head-menu-item1 text-center" id="4">
								<Link
									onMouseEnter={(e) => {
										handleMenuSecondEnter(e);
										setSecondNavIndex(4);
									}}
									className="px-2 px-lg-3"
									to="/support/question"
								>
									<span className="pb-1 nav-btn">
										{t('navMenu:serviceSupport')}
									</span>
								</Link>
							</li>
							<li className="head-menu-item1 text-center" id="5">
								<Link
									onMouseEnter={(e) => {
										handleMenuSecondEnter(e);
										setSecondNavIndex(5);
									}}
									className="px-2 px-lg-3"
									to="/about"
								>
									<span className="pb-1 nav-btn">
										{t('navMenu:aboutUs')}
									</span>
								</Link>
							</li>
						</ul>
					</div>

					{/* navbar right */}
					<nav className="head-right d-flex ms-auto">
						<ul className="d-flex align-items-center list-unstyled m-0">
							<li className="d-none d-lg-flex align-items-center px-2">
								<Link to="/app/download">
									<div className="btn btn-outline-primary rounded-pill px-4">
										{t('navMenu:appDownload')}
									</div>
								</Link>
							</li>
							{/* 語言切換 */}
							{/* <li className="d-none d-lg-flex align-items-center px-2">
								<select
									className=""
									id="language"
									value={language}
									onChange={(e) => {
										localStorage.setItem(
											'i18n',
											e.target.value
										);
										setLang(e.target.value);
									}}
								>
									<option value="zh-CN">简体中文</option>
									<option value="zh-TW">繁體中文</option>
									<option value="en">English</option>
								</select>
							</li> */}

							{/* 使用者資訊 */}
							<li
								id="personIconContainer"
								className=" position-relative  px-2"
							>
								<PersonOutline
									className="personIcon mb-4 mb-lg-0"
									onClick={handlePerson}
								/>
								<div
									className="personIconBox py-3 bg-white rounded-3 d-none"
									// 關閉RwdNav
									onClick={() => setRwdNavShow(false)}
								>
									<ul className="h-100 px-3 d-flex flex-column align-items-start justify-content-evenly list-unstyled m-0">
										{/* <!-- 登入 --> */}
										<li
											className={`w-100 ${
												cookies.loginUser &&
												cookies.loginUser.status ===
													'0' &&
												'd-none'
											}`}
										>
											<Link
												className="d-flex align-items-center "
												to="/login"
											>
												<PersonOutline />
												<span className="ms-2">
													{t(
														'navMenu:loginOrRegister'
													)}
												</span>
											</Link>
										</li>

										{/* 顯示名稱 */}
										<li
											className={`w-100 pb-3 border-bottom  ${
												cookies.loginUser &&
												(cookies.loginUser.status ===
													'1' ||
													cookies.loginUser.status ===
														null) &&
												'd-none'
											}`}
										>
											<PersonOutline className="p-1 rounded-circle bg-grey1" />
											<span className="ms-2">
												{userFullname}
											</span>
										</li>

										{/* 基本資料 */}
										<li
											className={`baseinfo w-100 ${
												cookies.loginUser &&
												(cookies.loginUser.status ===
													'1' ||
													cookies.loginUser.status ===
														null) &&
												'd-none'
											}`}
										>
											<Link
												className="d-block pt-3 pb-2"
												to="/baseinfo"
											>
												基本数据
											</Link>
										</li>
										<li
											className={`w-100 ${
												cookies.loginUser &&
												(cookies.loginUser.status ===
													'1' ||
													cookies.loginUser.status ===
														null) &&
												'd-none'
											}`}
										>
											<Link
												className="d-block py-2"
												to="/pwdreset"
											>
												变更密码
											</Link>
										</li>
										<li
											className={`w-100 ${
												cookies.loginUser &&
												(cookies.loginUser.status ===
													'1' ||
													cookies.loginUser.status ===
														null) &&
												'd-none'
											}`}
										>
											<Link
												className="d-block py-2"
												to="/product"
											>
												我的产品
											</Link>
										</li>
										<li
											className={`w-100 ${
												cookies.loginUser &&
												(cookies.loginUser.status ===
													'1' ||
													cookies.loginUser.status ===
														null) &&
												'd-none'
											}`}
										>
											<Link
												className="d-block py-2"
												to="/member/productvip"
											>
												用户家族
											</Link>
										</li>

										{/* <!-- 登出 --> */}
										<li
											className={`w-100 ${
												cookies.loginUser &&
												(cookies.loginUser.status ===
													'1' ||
													cookies.loginUser.status ===
														null) &&
												'd-none'
											}`}
											onClick={handleLogout}
										>
											<Link
												className="d-block pt-2 pb-3"
												to="/logout"
											>
												注销
											</Link>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</nav>
				</div>
			</header>

			{/* second nav */}

			{listShow && secondNavIndex === 0 && (
				<div
					className={`head-menu-second-Bg productCenterNav position-fixed w-100 bg-white px-5 border-bottom border-grey2 d-none d-lg-block`}
					onMouseLeave={(e) => {
						handleMenuSecondLeave(e);
					}}
				>
					<ul className="d-flex list-unstyled my-0 mx-auto">
						<li className="item-li">
							<span className=" pb-1 nav-btn">
								宾乐达乒乓球智能发球机
							</span>
						</li>
						<li className="item-li ms-auto">
							<Link
								className="px-2 px-lg-3"
								to="/productcenter/productfeature"
							>
								<span
									className={`pb-1 nav-btn ${
										params.includes('productfeature') &&
										'text-primary'
									}`}
								>
									功能亮点
								</span>
							</Link>
						</li>
						<li className="item-li">
							<Link
								className="px-2 px-lg-3"
								to="/productcenter/productstat"
							>
								<span
									className={`pb-1 nav-btn ${
										params.includes('productstat') &&
										'text-primary'
									}`}
								>
									规格参数
								</span>
							</Link>
						</li>
						<li className="item-li">
							<Link className="px-2 px-lg-3" to="/app/download">
								<span className=" pb-1 nav-btn">宾乐达APP</span>
							</Link>
						</li>
					</ul>
				</div>
			)}

			{listShow && secondNavIndex === 1 && (
				<div
					className={`head-menu-second-Bg position-fixed w-100 bg-transparent px-5`}
					onMouseLeave={(e) => {
						handleMenuSecondLeave(e);
					}}
				>
					<ul className="d-flex list-unstyled my-0 mx-auto">
						<li className="item-li"></li>
						<li className="item-li"></li>
					</ul>
				</div>
			)}

			{listShow && secondNavIndex === 2 && (
				<div
					className={`head-menu-second-Bg position-fixed w-100 bg-white px-5`}
					onMouseLeave={(e) => {
						handleMenuSecondLeave(e);
					}}
				>
					<ul className="d-flex list-unstyled my-0 mx-auto">
						<li className="item-li">
							<Link className="px-2 px-lg-3" to="/">
								<span className="pb-1 nav-btn">XX</span>
							</Link>
						</li>
						<li className="item-li">
							<Link className="px-2 px-lg-3" to="/">
								<span className="pb-1 nav-btn">XX</span>
							</Link>
						</li>
					</ul>
				</div>
			)}

			{listShow && secondNavIndex === 3 && (
				<div
					className={`head-menu-second-Bg position-fixed w-100 bg-transparent px-5`}
					onMouseLeave={(e) => {
						handleMenuSecondLeave(e);
					}}
				>
					<ul className="d-flex list-unstyled my-0 mx-auto">
						<li className="item-li"></li>
						<li className="item-li"></li>
					</ul>
				</div>
			)}

			{listShow && secondNavIndex === 4 && (
				<div
					className={`head-menu-second-Bg position-fixed w-100 bg-white px-5`}
					onMouseLeave={(e) => {
						handleMenuSecondLeave(e);
					}}
				>
					<ul className="d-flex list-unstyled my-0 mx-auto">
						<li className="item-li">
							<Link
								className="px-2 px-lg-3"
								to={{
									pathname: '/support/question',
									state: {
										initialLocation: '.section2',
									},
								}}
							>
								<span className="pb-1 nav-btn">常见问题</span>
							</Link>
						</li>
						<li className="item-li">
							<Link
								className="px-2 px-lg-3"
								to={{
									pathname: '/support/downloadcenter',
									state: {
										initialLocation: '.section2',
									},
								}}
							>
								<span className="pb-1 nav-btn">下载中心</span>
							</Link>
						</li>
						<li className="item-li">
							<span
								className="navFakeLink px-2 px-lg-3"
								onClick={() => {
									if (
										cookies.loginUser &&
										cookies.loginUser.status === '0'
									) {
										navigate('/product');
									} else {
										navigate('/login?search=product');
									}
								}}
							>
								<span className="pb-1 nav-btn">产品注册</span>
							</span>
						</li>
						<li className="item-li">
							<Link
								className="px-2 px-lg-3"
								to={{
									pathname: '/support/contactus',
									state: {
										initialLocation: '.section2',
									},
								}}
							>
								<span className="pb-1 nav-btn">联系我们</span>
							</Link>
						</li>
					</ul>
				</div>
			)}

			{listShow && secondNavIndex === 5 && (
				<div
					className={`head-menu-second-Bg position-fixed w-100 bg-transparent px-5`}
					onMouseLeave={(e) => {
						handleMenuSecondLeave(e);
					}}
				>
					<ul className="d-flex list-unstyled my-0 mx-auto">
						<li className="item-li"></li>
						<li className="item-li"></li>
					</ul>
				</div>
			)}

			<div className="head-menu-second-bg position-fixed w-100 bg-white d-none"></div>

			{/* rwd navbar */}
			<div
				className={`rwd-navbar position-fixed ${
					rwdNavShow ? 'rwd-navbar-transition' : ''
				}`}
			>
				<div className="rwd-wrapper">
					<div
						className={
							window.location.pathname === '/'
								? 'rwd-nav-pages'
								: ''
						}
					>
						<Accordion
							flush
							className="d-flex flex-column flex-md-row align-items-start justify-content-md-center my-2"
						>
							<Accordion.Item
								eventKey="0"
								className="w-100 px-md-2 px-lg-4"
							>
								<Accordion.Header className="">
									产品中心
								</Accordion.Header>
								<Accordion.Body className="">
									<ul
										className="list-unstyled  ps-md-0"
										onClick={() => setRwdNavShow(false)}
									>
										<li>
											<Link to="/productcenter/productfeature">
												功能亮点
											</Link>
										</li>
										<li>
											<Link to="/productcenter/productstat">
												规格参数
											</Link>
										</li>
										<li>
											<Link to="/app/download">
												宾乐达APP下载
											</Link>
										</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item
								eventKey="1"
								className="w-100 px-md-2 px-lg-4"
							>
								<Accordion.Header className="">
									<Link
										to="/explore"
										style={{ color: '#282828' }}
										onClick={() => setRwdNavShow(false)}
									>
										<span className="pb-1 nav-btn">
											网上展示厅
										</span>
									</Link>
								</Accordion.Header>
							</Accordion.Item>

							{/* <Accordion.Item
								eventKey="2"
								className="w-100 px-md-2 px-lg-4"
							>
								<Accordion.Header className="">
									新闻动态
								</Accordion.Header>
								<Accordion.Body className="">
									<ul className="list-unstyled  ps-md-0">
										<li>
											<Link to="#">張愛玲</Link>
										</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item> */}

							<Accordion.Item
								eventKey="3"
								className="w-100 px-md-2 px-lg-4"
							>
								<Accordion.Header className="">
									<Link
										to="/physicalstore"
										style={{ color: '#282828' }}
										onClick={() => setRwdNavShow(false)}
									>
										<span className="pb-1 nav-btn">
											购买渠道
										</span>
									</Link>
								</Accordion.Header>
							</Accordion.Item>

							<Accordion.Item
								eventKey="4"
								className="w-100 px-md-2 px-lg-4"
							>
								<Accordion.Header className="">
									服务支持
								</Accordion.Header>
								<Accordion.Body className="">
									<ul
										className="list-unstyled  ps-md-0"
										onClick={() => setRwdNavShow(false)}
									>
										<li>
											<Link
												to={{
													pathname:
														'/support/question',
													state: {
														initialLocation:
															'.section2',
													},
												}}
											>
												常见问题
											</Link>
										</li>
										<li>
											<Link
												to={{
													pathname:
														'/support/downloadcenter',
													state: {
														initialLocation:
															'.section2',
													},
												}}
											>
												下载中心
											</Link>
										</li>
										<li>
											<span
												onClick={() => {
													if (
														cookies.loginUser &&
														cookies.loginUser
															.status === '0'
													) {
														navigate('/product');
													} else {
														navigate(
															'/login?search=product'
														);
													}
												}}
											>
												产品注册
											</span>
										</li>
										<li>
											<Link
												to={{
													pathname:
														'/support/contactus',
													state: {
														initialLocation:
															'.section2',
													},
												}}
											>
												联系我们
											</Link>
										</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item
								eventKey="5"
								className="w-100 px-md-2 px-lg-4"
							>
								<Accordion.Header className="">
									<Link
										to="/about"
										style={{ color: '#282828' }}
										onClick={() => setRwdNavShow(false)}
									>
										<span className="pb-1 nav-btn">
											关于宾乐达
										</span>
									</Link>
								</Accordion.Header>
								<Accordion.Body className="">
									{/* <ul className="list-unstyled  ps-md-0">
										<li>
											<Link to="#">关</Link>
										</li>
										<li>
											<Link to="#">于</Link>
										</li>
										<li>
											<Link to="#">宾</Link>
										</li>
										<li>
											<Link to="#">乐</Link>
										</li>
										<li>
											<Link to="#">达</Link>
										</li>
									</ul> */}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>

					<div className="rwd-nav-appDownload-btn px-3 mb-4">
						<Link
							to="/app/download"
							onClick={() => setRwdNavShow(false)}
						>
							<div className="btn btn-outline-primary rounded-pill px-3 px-lg-4 ">
								宾乐达APP下载
							</div>
						</Link>
					</div>

					{/* <ul className="rwd-nav-lang m-0 border-top mx-3 ps-0  py-3">
						<li className="d-flex align-items-center lang-box ">
							<Public />
							<select
								className="ps-1"
								id="language"
								value={intlContext.locale}
								onChange={intlContext.selectLanguage}
							>
								<option value="zh-CN">简体中文</option>
								<option value="zh-TW">繁體中文</option>
								<option value="en">English</option>
							</select>
						</li>
					</ul> */}

					{guid ? (
						<div className="rwd-nav-footer-login d-flex align-items-center border-top mx-3 py-3">
							{homePageIndex === 2 ? (
								<PersonOutline
									className="personIcon mb-4"
									style={{ color: 'black' }}
								/>
							) : (
								<PersonOutline
									className="personIcon mb-4"
									style={{ color: 'white' }}
								/>
							)}
							<span id="rwd-nav-footer-span">{userFullname}</span>
						</div>
					) : (
						<div
							className="rwd-nav-footer d-flex align-items-center border-top mx-3 py-3"
							onClick={handleIsLogin}
						>
							{homePageIndex === 2 ? (
								<PersonOutline
									className="personIcon mb-4"
									style={{ color: 'black' }}
								/>
							) : (
								<PersonOutline
									className="personIcon mb-4"
									style={{ color: 'white' }}
								/>
							)}
							<span id="rwd-nav-footer-span">登录 / 注册</span>
						</div>
					)}
					{guid && (
						<div
							// 關閉RwdNav
							onClick={() => setRwdNavShow(false)}
						>
							<div
								className="rwd-nav-page"
								onClick={() => navigate('/baseinfo')}
							>
								个人资料
							</div>
							<div
								className="rwd-nav-page"
								onClick={() => navigate('/pwdreset')}
							>
								密码设置
							</div>
							<div
								className="rwd-nav-page"
								onClick={() => navigate('/product')}
							>
								我的产品
							</div>
							<div
								className="rwd-nav-page"
								onClick={() => navigate('/member/productvip')}
							>
								用户家族
							</div>
							<div
								className="rwd-nav-page"
								onClick={() => navigate('/accountDelete')}
							>
								删除帐号
							</div>
							<div
								className="rwd-nav-page"
								onClick={handleLogout}
							>
								登出
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default NavMenu;
