import './productStat.scss';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/// 规格参数
/// Author : YL
function productStat() {
    return (
        <div className="container-fluid p-0">
            <article
                id="container-productStat"
                className="d-flex flex-column align-items-center px-0 mx-auto"
            >
                <section className="productStat-section section0 position-fixed w-100 row justify-content-center bg-white">
                    <div className="col-12 section0Wrapper p-0 text-center">
                        <div className="h-100 d-flex justify-content-between align-items-center px-4">
                            <h6 className="text-grey5 mb-0">
                                宾乐达乒乓球智能发球机
                            </h6>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="transparent"
                                    id="dropdown-basic"
                                >
                                    规格参数
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Link
                                            className="text-grey5"
                                            to="/productcenter/productfeature"
                                        >
                                            功能亮点
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link
                                            className="text-primary"
                                            to="/productcenter/productstat"
                                        >
                                            规格参数
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link
                                            className="text-grey5"
                                            to="/app/download"
                                        >
                                            宾乐达APP
                                        </Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </section>
                <div className="wrapper">
                    <section className="productStat-section section1 justify-content-center align-content-center text-center">
                        <div className="top"></div>
                        <div className="row middle">
                            <div className="left col-3 text-left">TS系列</div>
                            <div className="col productImageCard">
                                <div id="product1_img" className=""></div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="wrapper border-top">
                    <section className="productStat-section section2 section-info d-flex align-items-start justify-content-center">
                        <div className="left">
                            <div className="textZone">产品型号</div>
                        </div>
                        <div className="right">
                            <p>TS 系列</p>
                        </div>
                    </section>
                </div>

                <div className="wrapper border-top">
                    <section className="productStat-section section3 section-info d-flex align-items-start justify-content-center">
                        <div className="left">
                            <div className="textZone">产品规格</div>
                        </div>
                        <div className="right">
                            <div className="row">
                                <div className="right-1 col">
                                    <p>长度: </p>
                                </div>
                                <div className="col">
                                    <p>302 毫米(mm)</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">
                                    <p>宽度: </p>
                                </div>
                                <div className="col">
                                    <p>302 毫米(mm)</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">
                                    <p>高度:</p>
                                </div>
                                <div className="col">
                                    <p>560 毫米(mm)</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">重量:</div>
                                <div className="col">
                                    <p>5.5 公斤(kgs)</p>
                                    <p>
                                        *备注：实际尺寸与重量因测量方法的不同可能有所差异，产品请以实物为准，敬请见谅。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="wrapper border-top">
                    <section className="productStat-section section4 section-info d-flex align-items-start justify-content-center">
                        <div className="left">
                            <div className="textZone">出球特征</div>
                        </div>
                        <div className="right">
                            <div className="row">
                                <div className="right-1 col">
                                    <p>出球速度:</p>
                                </div>
                                <div className="col">
                                    <p>2~16 米/秒(m/sec)</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">
                                    <p>出球频率:</p>
                                </div>
                                <div className="col">
                                    <p>
                                        10~120
                                        球/分钟(ball/min)，最快0.5秒发一球
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">
                                    <p>旋球角度:</p>
                                </div>
                                <div className="col">
                                    <p>
                                        旋球角度360度，上旋、下旋、左侧旋、右侧旋等共有16个可调档位
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">
                                    出球角度(俯仰):
                                </div>
                                <div className="col">
                                    <p>俯仰角度80度(上下各40度)</p>
                                    <p>以0.5度为单位，可调档位达160档</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">
                                    出球角度(水平):
                                </div>
                                <div className="col">
                                    <p>水平角度60度(左右各30度)</p>
                                    <p>以1度为单位，可调档位达60档</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="wrapper border-top">
                    <section className="productStat-section section5 section-info d-flex align-items-start justify-content-center">
                        <div className="left">
                            <div className="textZone">连机模式</div>
                        </div>
                        <div className="right">
                            <div className="row">
                                <div className="right-1 col">
                                    <p>蓝牙：</p>
                                </div>
                                <div className="col">
                                    <p>BLE 4.0以上</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">Wi-Fi：</div>
                                <div className="col">
                                    <p>IEEE 802.11ax/g/n 2.4Ghz</p>
                                    <p>*备注：需要相应的路由器支持。</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="wrapper border-top">
                    <section className="productStat-section section6 section-info d-flex align-items-start justify-content-center">
                        <div className="left">
                            <div className="textZone">语音提示</div>
                        </div>
                        <div className="right">
                            <p>有，音量可调</p>
                        </div>
                    </section>
                </div>

                <div className="wrapper border-top">
                    <section className="productStat-section section7 section-info d-flex align-items-start justify-content-center">
                        <div className="left">
                            <div className="textZone">旋球指示灯</div>
                        </div>
                        <div className="right">
                            <p>有，亮度可调</p>
                        </div>
                    </section>
                </div>

                <div className="wrapper border-top">
                    <section className="productStat-section section8 section-info d-flex align-items-start justify-content-center">
                        <div className="left">
                            <div className="textZone">电池和电源</div>
                        </div>
                        <div className="right">
                            <p>DC 24V专用适配器(AC100V~240V)</p>
                        </div>
                    </section>
                </div>

                <div className="wrapper border-top">
                    <section className="productStat-section section9 section-info d-flex align-items-start justify-content-center">
                        <div className="left">
                            <div className="textZone">包装清单</div>
                        </div>
                        <div className="right">
                            <div className="row">
                                <div className="right-1 col">
                                    <p>包装尺寸：</p>
                                </div>
                                <div className="col">
                                    <p>70 x 60 x 43 厘米(cm)</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">
                                    <p>包装重量：</p>
                                </div>
                                <div className="col">
                                    <p>9.8 公斤(kgs)</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="right-1 col">包装内容物：</div>
                                <div className="col">
                                    <p>乒乓球发球机 x 1</p>
                                    <p>电源适配器 x 1</p>
                                    <p>三脚架 x 1</p>
                                    <p>托盘 x 1</p>
                                    <p>集球道 x 2</p>
                                    <p>拦球网铝管 x 2</p>
                                    <p>拦球网 x 1</p>
                                    <p>集球道桌夹 x 2</p>
                                    <p>拦球网桌夹 x 2</p>
                                    <p>快速安装手册 x 1</p>
                                    <p>
                                        *备注：最终具体信息请参照产品实物、产品说明书为准。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="wrapper">
                    <section className="productStat-section section10 w-100 row align-items-center justify-content-center px-0 bg-white">
                        <div className="smallWrapper">
                            <div className="col-12 desc px-3">
                                <ul className="text-grey3 ps-0 mb-4 mb-lg-5">
                                    <li>
                                        以上页面中的产品图片及屏幕内容仅作示意，实物产品效果（包括但不限于外观、颜色、尺寸）和屏幕显示内容（包括但不限于背景、UI、配图）可能略有差异，请以实物为准。
                                    </li>
                                    <li>
                                        以上页面中的数据为理论值，均来自宾乐达内部实验室，于特定测试环境下所得（请见各项具体说明），实际使用中可能因产品个体差异、软件版本、使用条件和环境因素不同略有不同，请以实际使用的情况为准。
                                    </li>
                                    <li>
                                        由于产品批次和生产供应因素实时变化，为尽可能提供准确的产品信息、规格参数、产品特性，宾乐达可能实时调整和修订以上页面中的文字表述、图片效果等内容，以求与实际产品性能、规格、指数、零部件等信息相匹配。
                                    </li>
                                    <li>
                                        如遇确有进行上述修改和调整必要的情形，恕不专门通知。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </article>
        </div>
    );
}

export default productStat;
