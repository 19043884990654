import './productionRecordChange.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LoadingAnimate } from '../../loadingAnimate/LoadingAnimate';
import { useTranslation } from 'react-i18next';
import { generateEditLog } from '../../../../utils';
import Swal from 'sweetalert2';
import { ReactComponent as Icn_More } from '../../../../assets/images/icn_More.svg';
import { ReactComponent as Icn_Log } from '../../../../assets/images/icn_Log.svg';
import { DialogConfirm } from './DialogConfirm';

const ProductionRecordChange = () => {
	const { t } = useTranslation(['common', 'productionRecord']); // i18n

	//#region [States initializations]
	const [isLoading, setIsLoading] = useState(false);

	const [data, setData] = useState({
		modelID: '',
		mbModelID: '',
		qcStaffID: '',
		qcStaffName: '',
		vipLevel: '',
		serialNo: '',
		mbSerialNo: '',
		qcTime: '',
		vipQuantity: '',
		factoryDate: '',
		rowStamp: '',
	});
	console.log('data', data);

	const [originalDataForLog, setOriginalDataForLog] = useState({
		// The parameters which were needed for logging page
		modelID: '',
		mbModelID: '',
		qcStaffID: '',
		qcStaffName: '',
		vipLevel: '',
		serialNo: '',
		mbSerialNo: '',
		qcTime: '',
		vipQuantity: '',
		factoryDate: '',
		rowStamp: '',
	});

	const [modelList, setModelList] = useState([]);
	const [mbModelList, setMbModelList] = useState([]);
	const [qcStaffList, setQcStaffList] = useState([]);
	const [userLevelList, setUserLevelList] = useState([]);

	const paramsGuid = useParams('productionRecordGuid');
	const navigate = useNavigate();

	const [showMoreBtn, setShowMoreBtn] = useState(false);

	//#endregion

	//#region [function]

	// edit
	const handleSubmit = async (e) => {
		e.preventDefault();

		console.log(data);
		console.log(originalDataForLog);

		const newData = { ...data };

		// 只取想要更新的log資料
		const { qcStaffID, ...newLogData } = newData;
		const { qcStaffID: qcStaffID2, ...newOriginalDataForLog } =
			originalDataForLog;

		console.log('newLogData', newLogData);
		console.log('newOriginalDataForLog', newOriginalDataForLog);

		const updateContent = generateEditLog(
			'productionRecord',
			t,
			newLogData,
			newOriginalDataForLog
		);

		console.log('updateContent', updateContent);
		console.log('newData', newData);

		if (updateContent === '') {
			Swal.fire({
				icon: 'info',
				title: '未發現欄位改動',
				text: '請再次確認。',
			});
		} else if (Object.keys(paramsGuid).length) {
			//edit
			setIsLoading(true);

			await axios
				.put(
					`/web/product/productionRecords/${paramsGuid.productionRecordGuid}`,
					{
						modelID: newData.modelID,
						mbModelID: newData.mbModelID,
						qcStaffID: newData.qcStaffID,
						qcStaffName: newData.qcStaffName,
						vipLevel: newData.vipLevel,
						serialNo: newData.serialNo,
						mbSerialNo: newData.mbSerialNo,
						qcTime: newData.qcTime,
						vipQuantity: newData.vipQuantity,
						factoryDate: newData.factoryDate,
						rowStamp: newData.rowStamp,
						updateContent: updateContent,
					}
				)
				.then((res) => {
					console.log(res.data);
					setIsLoading(false);
					navigate('/productionRecord');
				})

				.catch((err) => {
					setIsLoading(false);
					Swal.fire({
						icon: 'error',
						title: '網路不穩，儲存失敗',
						text: '請再次儲存。',
					});
					console.log(err);
				});
		} else {
			// create
			setIsLoading(true);

			const updateContent = t('log.create', {
				value: `${newData.modelID}-${newData.serialNo}`,
			});

			await axios
				.post(`/web/product/productionRecords`, {
					modelID: newData.modelID,
					mbModelID: newData.mbModelID,
					qcStaffID: newData.qcStaffID,
					qcStaffName: newData.qcStaffName,
					vipLevel: newData.vipLevel,
					serialNo: newData.serialNo,
					mbSerialNo: newData.mbSerialNo,
					qcTime: newData.qcTime,
					vipQuantity: newData.vipQuantity,
					factoryDate: newData.factoryDate,
					updateContent: updateContent,
				})
				.then((res) => {
					console.log(res.data);
					setIsLoading(false);
					navigate('/productionRecord');
				})

				.catch((err) => {
					setIsLoading(false);
					Swal.fire({
						icon: 'error',
						title: '網路不穩，儲存失敗',
						text: '請再次儲存。',
					});
					console.log(err);
				});
		}
	};

	// delete
	const handleDeleteItem = async () => {
		const newData = { ...data };
		const updateContent = t('log.delete', {
			value: `${newData.modelID}-${newData.serialNo}`,
		});

		await axios
			.delete(
				`/web/product/productionRecords/${paramsGuid.productionRecordGuid}`,
				{ data: { updateContent: updateContent } }
			)
			.then((res) => {
				console.log(res.data);
				navigate('/productionRecord');
			})

			.catch((err) => console.log(err));
	};

	//#endregion

	//#region [useEffect]

	// getDetails
	useEffect(() => {
		const fetchDetails = async () => {
			await axios
				.get(
					`/web/product/productionRecords/${paramsGuid.productionRecordGuid}`
				)
				.then((res) => {
					console.log(res.data);

					const data = res.data.result;

					setData({
						modelID: data.modelID,
						mbModelID: data.mbModelID,
						qcStaffID: data.qcStaffID,
						qcStaffName: res.data.qcStaffList.filter((el) => {
							return el.userGuid === data.qcStaffID;
						})[0].fullname,

						vipLevel: data.vipLevel,
						serialNo: data.serialNo,
						mbSerialNo: data.mbSerialNo,
						qcTime: data.qcTime?.split('+')[0],
						vipQuantity: data.vipQuantity,
						factoryDate: data.factoryDate?.split('+')[0] || '',
						rowStamp: data.rowStamp,
					});

					setOriginalDataForLog({
						modelID: data.modelID,
						mbModelID: data.mbModelID,
						qcStaffID: data.qcStaffID,
						qcStaffName: res.data.qcStaffList.filter((el) => {
							return el.userGuid === data.qcStaffID;
						})[0].fullname,

						vipLevel: data.vipLevel,
						serialNo: data.serialNo,
						mbSerialNo: data.mbSerialNo,
						qcTime: data.qcTime?.split('+')[0],
						vipQuantity: data.vipQuantity,
						factoryDate: data.factoryDate?.split('+')[0] || '',
						rowStamp: data.rowStamp,
					});

					setModelList(res.data.modelList);
					setMbModelList(res.data.mbModelList);
					setQcStaffList(res.data.qcStaffList);
					setUserLevelList(res.data.userLevelList);
				});
		};

		const fetchCreateInfo = async () => {
			await axios
				.get(`/web/product/productionRecordsData`)
				.then((res) => {
					console.log(res.data);

					const data = res.data.result;

					const now = new Date();
					const year = now.getFullYear();
					const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1，并使用padStart补零
					const day = String(now.getDate()).padStart(2, '0');
					const hours = String(now.getHours()).padStart(2, '0');
					const minutes = String(now.getMinutes()).padStart(2, '0');

					// 拼接日期和时间
					const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

					setData({
						vipLevel: '0',
						vipQuantity: '5',
						factoryDate: formattedDateTime,
					});

					setOriginalDataForLog({
						vipLevel: '0',
						vipQuantity: '5',
						factoryDate: formattedDateTime,

						modelID: '',
						mbModelID: '',
						qcStaffID: '',
						qcStaffName: '',
						serialNo: '',
						mbSerialNo: '',
						qcTime: '',
					});

					setModelList(res.data.modelList);
					setMbModelList(res.data.mbModelList);
					setQcStaffList(res.data.qcStaffList);
					setUserLevelList(res.data.userLevelList);
				});
		};

		if (Object.keys(paramsGuid).length) {
			fetchDetails();
		} else {
			fetchCreateInfo();
		}
	}, [paramsGuid]);

	//#endregion

	//#region DialogConfirm

	//  返回btn確認dialog
	const [dialogConfirmData, setDialogConfirmData] = useState({
		btnName: '',
		isOpen: false,
		goTo: '',
		title: '',
	});

	// DialogConfirm Modal open
	const handleDialogConfirm = () => {
		const newData = {
			btnName: 'goToRoot',
			isOpen: true,
			goTo: 'toRoot',
			title: t('lgsBuyOrderGoodsReturn:confirmLeaveReturnPage') + '?',
		};
		setDialogConfirmData(newData);
	};
	console.log(dialogConfirmData);

	//#endregion
	return (
		<>
			<LoadingAnimate isLoading={isLoading} />

			<section id="section-main">
				<form
					id="container-productionRecordChange"
					onSubmit={handleSubmit}
				>
					{/* DialogConfirm element */}
					<DialogConfirm
						dialogConfirmData={dialogConfirmData}
						setDialogConfirmData={setDialogConfirmData}
					/>

					<div className="d-flex position-relative">
						<h5 className="fw-bold mb-4">发球机生产记录</h5>
						{Object.keys(paramsGuid).length ? (
							<button
								className="btnDelete btn btn-outline-danger px-4 ms-auto me-2"
								onClick={(e) => {
									e.preventDefault();
									setDialogConfirmData({
										btnName: '',
										isOpen: true,
										goTo: 'delete',
										title: '確定刪除此筆資料',
										toDo: handleDeleteItem,
									});
								}}
							>
								刪除
							</button>
						) : (
							<></>
						)}
						<button
							className={`btnCancel btn btn-outline-primary px-4 ${
								Object.keys(paramsGuid).length ? '' : 'ms-auto'
							} me-2`}
							onClick={(e) => {
								e.preventDefault();
								navigate('/productionRecord');
							}}
						>
							取消
						</button>
						<button className="btn btn-primary text-white px-4 me-2">
							儲存
						</button>
						<button
							style={{ width: '32px' }}
							className="btn btn-outline-primary moreIconBtn"
							onClick={(e) => {
								e.preventDefault();
								setShowMoreBtn(!showMoreBtn);
							}}
						>
							<Icn_More />
						</button>

						{showMoreBtn ? (
							<div
								className="moreBtnBox position-absolute bg-white "
								style={{
									right: '0px',
									top: '40px',
									minWidth: '136px',
									boxShadow:
										'0px 4px 8px rgba(61, 61, 61, 0.51)',
									borderRadius: '0.25rem',
								}}
							>
								<div
									className="py-2 px-3"
									style={{ cursor: 'pointer' }}
								>
									<div
										className="d-flex justify-content-start align-items-center"
										onClick={() =>
											navigate(
												`/productionRecord/log/${paramsGuid.productionRecordGuid}`
											)
										}
									>
										<Icn_Log />
										<span className="fontSize875Rem">
											異動記錄
										</span>
									</div>
								</div>
							</div>
						) : null}
					</div>

					<div className="infoWrapper bg-white px-4 pt-4 pb-15 rounded-4">
						<div className="infoTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold mb-0">基本数据</h6>
						</div>
						<div className="infoBottom row">
							<div className="col-4">
								<label
									htmlFor="modelID"
									className="fontSize75Rem mb-2"
								>
									机型ID
								</label>
								<select
									className="form-control mb-3"
									name="modelID"
									id="modelID"
									onChange={(e) =>
										setData({
											...data,
											modelID: e.target.value,
										})
									}
									value={data.modelID || ''}
									required
								>
									<option value="" disabled>
										-- 請選擇 --
									</option>
									{modelList?.map((el) => {
										return (
											<option
												key={el.modelID}
												value={el.modelID}
											>
												{el.name_ZF}
											</option>
										);
									})}
								</select>

								<label
									htmlFor="mbModelID"
									className="fontSize75Rem mb-2"
								>
									{t('productionRecord:mbModelID')}
								</label>
								<select
									className="form-control mb-3"
									name="mbModelID"
									id="mbModelID"
									onChange={(e) =>
										setData({
											...data,
											mbModelID: e.target.value,
										})
									}
									value={data.mbModelID || ''}
									required
								>
									<option value="" disabled>
										-- 請選擇 --
									</option>
									{mbModelList?.map((el) => {
										return (
											<option
												key={el.modelID}
												value={el.modelID}
											>
												{el.modelID}
											</option>
										);
									})}
								</select>

								<label
									htmlFor="qcStaff"
									className="fontSize75Rem mb-2"
								>
									品管人员
								</label>
								<select
									className="form-control mb-3"
									name="qcStaff"
									id="qcStaff"
									onChange={(e) =>
										setData({
											...data,
											qcStaffID: e.target.value,
											qcStaffName:
												e.target.selectedOptions[0]
													.dataset.value2,
										})
									}
									value={data.qcStaffID || ''}
									required
								>
									<option value="" disabled>
										-- 請選擇 --
									</option>
									{qcStaffList?.map((el) => {
										return (
											<option
												key={el.userGuid}
												value={el.userGuid}
												data-value2={el.fullname}
											>
												{el.fullname}
											</option>
										);
									})}
								</select>

								<label
									htmlFor="vipLevel"
									className="fontSize75Rem mb-2"
								>
									䁬送的VIP等级
								</label>
								<select
									className="form-control mb-3"
									name="vipLevel"
									id="vipLevel"
									onChange={(e) =>
										setData({
											...data,
											vipLevel: e.target.value,
										})
									}
									value={data.vipLevel || ''}
									disabled
								>
									<option value="" disabled>
										-- 請選擇 --
									</option>
									{userLevelList?.map((el) => {
										return (
											<option
												key={el.userLevelCode}
												value={el.userLevelCode}
											>
												{el.name_ZH}
											</option>
										);
									})}
								</select>
							</div>

							<div className="col-4">
								<label
									htmlFor="serialNo"
									className="fontSize75Rem mb-2"
								>
									机器序号
								</label>
								<input
									type="text"
									id="serialNo"
									name="serialNo"
									className="form-control mb-3"
									value={data.serialNo}
									onChange={(e) =>
										setData({
											...data,
											serialNo: e.target.value,
										})
									}
									required
								/>

								<label
									htmlFor="mbSerialNo"
									className="fontSize75Rem mb-2"
								>
									主板序号
								</label>
								<input
									type="text"
									id="mbSerialNo"
									name="mbSerialNo"
									className="form-control mb-3"
									value={data.mbSerialNo}
									onChange={(e) =>
										setData({
											...data,
											mbSerialNo: e.target.value,
										})
									}
									required
								/>

								<label
									htmlFor="qcTime"
									className="fontSize75Rem mb-2"
								>
									品管/包装时间
								</label>
								<input
									type="datetime-local"
									id="qcTime"
									name="qcTime"
									className="form-control mb-3"
									value={data.qcTime || ''}
									onChange={(e) =>
										setData({
											...data,
											qcTime: e.target.value,
										})
									}
									required
								/>

								<label
									htmlFor="vipQuantity"
									className="fontSize75Rem mb-2"
								>
									可授权的VIP数量
								</label>
								<input
									type="text"
									id="vipQuantity"
									name="vipQuantity"
									className="form-control"
									value={data.vipQuantity}
									onChange={(e) =>
										setData({
											...data,
											vipQuantity: e.target.value,
										})
									}
									disabled
								/>
							</div>
							<div className="col-8">
								<label
									htmlFor="factoryDate"
									className="fontSize75Rem mb-2"
								>
									出厂时间
								</label>
								<input
									type="datetime-local"
									id="factoryDate"
									name="factoryDate"
									className="form-control mb-3"
									value={data.factoryDate}
									onChange={(e) =>
										setData({
											...data,
											factoryDate: e.target.value,
										})
									}
									disabled
								/>
							</div>
						</div>
					</div>
				</form>
			</section>
		</>
	);
};

export default ProductionRecordChange;
