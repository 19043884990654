import './agreement.scss';
import React from 'react';
import { Link } from 'react-router-dom';

/// 用戶協議
/// Author : YL
function Agreement() {
    return (
        <div className="container-fluid p-0">
            <article
                id="container-agreement"
                className="d-flex flex-column align-items-center px-0 mx-auto"
            >
                <div className="outerWrapper">
                    <div className="wrapper">
                        <div className="col-12 desc px-3">
                            <div className="text-grey5 ps-0 mb-4 mb-lg-5 row justify-content-center">
                                <main className="">
                                    <h1>宾乐达运动用户协议</h1>
                                    <section className="section0">
                                        <div className="subSection subSession1">
                                            <div className="wordWrap">
                                                <p>
                                                    海南翔睿德科技有限公司（简称“翔睿德”或“我们”，地址：“海南省海口市国家高新技术产业开发区南海大道266号创业孵化中心A楼5层A26-72室”）在此特别提醒您（简称“用户”或“您”）认真阅读并充分理解本用户协议（简称“本协议”）。
                                                </p>
                                                <p>
                                                    本协议是用户与翔睿德之间签订的法律协议，本协议是针对宾乐达乒乓球智能发球机（简称“宾乐达设备”
                                                    或“本设备”或“本产品”）上的软件、界面、图形、文字、数据、文件、后续可下载的更新等（简称“宾乐达知识产权”）而签订所共同约定的条款。
                                                </p>
                                                <p>
                                                    翔睿德有权修订本协议，订定后或更新后的协议，自公布之日起生效。
                                                </p>
                                                <p>
                                                    用户应认真阅读、充分理解本协议中，涉及免除或者限制翔睿德责任、争议解决和法律适用的条款，
                                                    <strong>
                                                        其中免除或者限制责任的条款将以粗体标识，您需要重点阅读。
                                                    </strong>
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <strong>
                                                    重要通知：在
                                                    “打开包装”或“使用产品”、以及“开启软件”前，请仔细阅读以下协议条款（未成年人应在法定监护人陪同下阅读）。
                                                </strong>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    “打开包装”或“使用产品”，即表示您同意受本协议的约束，如果您不同意其中任何条款，请
                                                    不要
                                                    “打开包装”或“使用产品”，则我们将
                                                    不会
                                                    向您许可宾乐达知识产权。且您必须依据翔睿德的退货政策，立即将宾乐达设备连同文档、配件等包装完好无损送回您购买本产品的零售商或购货地点，以进行退货程序。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    “开启软件”即表示您同意受本协议的约束，如果您不同意其中任何条款，请不要
                                                    “开启软件”，则我们将不会向您许可宾乐达知识产权，也不会向您提供宾乐达的软件（简称“本软件”），以及相关服务（简称“本软件及服务”），例如以下列示的内容，但具体将以翔睿德实际提供的为准。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    (1)
                                                    软件是用于宾乐达设备，为使用该智能设备的用户提供设备的连接与绑定、功能设置、设备管理与健康运动的数据服务。
                                                </p>
                                                <p>
                                                    (2)
                                                    位于synerter.com.cn和synerter.cn与子域中关于宾乐达设备的网站内容。
                                                </p>
                                                <p>
                                                    (3)
                                                    宾乐达设备通过网站获得的服务，包括过去与将来提供的所有服务。
                                                </p>
                                                <p>
                                                    (4)
                                                    可供下载的宾乐达软件、以及提供的所有更新与升级。
                                                </p>
                                                <p>
                                                    您的以下行为
                                                    不受到“本协议”的约束：
                                                </p>
                                                <p>
                                                    (5)您购买本产品的购买行为。
                                                </p>
                                                <p>
                                                    (6)您购买本产品的有限保修条款。
                                                </p>
                                                <p>
                                                    (7)您购买本产品所相关或衍生的商务行为与相关条款。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <strong>
                                                    您清楚理解，翔睿德仅为用户提供本软件及服务，但与其有关的其他费用（如本软件终端费用、为接入互联网而支付的上网费）均应由您自行负担。且您也清楚理解，在使用本软件及服务时，将会耗用您的设备、带宽、流量等资源。
                                                </strong>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="section1">
                                        <h2>一、授权范围：</h2>
                                        <div className="subSection subSection1">
                                            <div className="wordWrap">
                                                <p>
                                                    1.1
                                                    依据本协议条款，翔睿德特此授予您在中华人民共和国（简称“中国”）使用本软件及服务，该授权是给予用户一项个人、非专属、非独占、不可转让、不含知识产权授予的有限许可。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.2
                                                    用户不允许将本软件安装于未经翔睿德书面许可的其他设备。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.3
                                                    用户不允许对本软件或本软件运行过程中，释放到任何终端设备内存中的数据，以及本软件运行过程中客户端与服务器端的交互数据，进行复制、更改、修改、挂接运行或创作任何衍生作品。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.4
                                                    用户只允许将本设备用于非商业性目的时使用，也只允许以非商业目的在设备端安装、使用、显示、运行本软件。用户不允许为商业运营目的安装、使用、运行本软件，如果需要进行商业性的销售、复制和散发，必须事前取得翔睿德的书面许可。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    1.5
                                                    除本协议明示授权外，翔睿德未授权给用户其他权利，若用户有需要使用其他权利时，必须事前取得翔睿德的书面许可。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="section2">
                                        <h2>二、用户承诺：</h2>
                                        <div className="subSection subSection1">
                                            <p className="subtitle">
                                                2.1 对知识产权的保护规范：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    2.1.1
                                                    用户不允许复制宾乐达知识产权，除非是伴随正常使用本设备或因本软件而伴随发生的情形。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.1.2
                                                    用户不允许出租、出借、再许可、分发、翻译、改动、合并、更改、修改、变化、删除宾乐达知识产权的全部或任意部分。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.1.3
                                                    用户不允许删除本软件及其他副本上一切关于版权的信息，以及修改、删除或避开本软件为保护知识产权而设置的技术措施。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.1.4
                                                    用户不允许修改或伪造本软件中的指令、数据，不可以增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.1.5
                                                    用户不允许对本软件进行反向工程，如反汇编、反编译、或者其他试图获得本软件的源代码，或取得宾乐达知识产权的全部或任意部分内容，或根据其创造衍生作品，也不可以尝试进行此类任意行为。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.1.6
                                                    用户不允许以本软件进行任何危害网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众网络或者他人操作系统并删除、修改、增加存储信息；未经许可企图探查、扫描、测试本软件的系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.1.7
                                                    除因当地法律允许、或本协议明确许可、或事前取得翔睿德书面许可外，用户如有以上所述不允许的或类似的行为，无论是否明知或故意，均视为侵害翔睿德所拥有的宾乐达知识产权。
                                                </p>
                                            </div>
                                        </div>

                                        <div className="subSection subSection2">
                                            <p className="subtitle">
                                                2.2 信息发布规范：
                                            </p>

                                            <div className="wordWrap">
                                                <p>
                                                    2.2.1
                                                    用户对于所使用本软件发表的信息内容，应确信保证，您拥有您所上传信息内容的知识产权或已取得合法授权，您使用本软件及服务的任何行为未侵犯任何第三方之合法权益。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.2.2
                                                    用户在使用本软件时不得利用本软件从事以下未经授权、伤害他人、违反法规等行为，包括但不限于：
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    (1)制作、复制、发布、传播、储存违反当地国家法律法规的内容。
                                                </p>
                                                <p>
                                                    (2)发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容。
                                                </p>
                                                <p>
                                                    (3)虚构事实、隐瞒真相以误导、欺骗他人。
                                                </p>
                                                <p>
                                                    (4)发表、传送、传播广告信息及垃圾信息。
                                                </p>
                                                <p>
                                                    (5)从事其他违反当地法律法规的其他行为。
                                                </p>
                                                <p>
                                                    (6)未经翔睿德许可，您不得在本软件中进行任何诸如发布广告、销售商品的商业行为。
                                                </p>
                                            </div>
                                        </div>

                                        <div className="subSection subSection3">
                                            <p className="subtitle">
                                                2.3 合法合理使用规范：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    2.3.1
                                                    用户不允许通过非翔睿德公司开发、授权或认可的第三方兼容软件、系统登录或使用本软件及服务，或制作、发布、传播上述工具。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.3.2
                                                    事前未取得翔睿德的书面许可，用户不允许对本软件及其中的信息擅自实施编译或衍生行为，包括但不限于：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助本软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.3.3
                                                    用户不允许利用本软件发表、传送、传播、储存违反当地法律的内容。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.3.4
                                                    用户不允许利用本软件发表、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.3.5
                                                    用户不允许利用本软件批量发表、传送、传播广告信息及垃圾信息。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.3.6
                                                    用户不允许以其他任何不合法的方式、为任何不合法的目的、或以任何与本协议许可使用不一致的方式使用本软件及服务。
                                                </p>
                                            </div>
                                        </div>

                                        <div className="subSection subSection4">
                                            <p className="subtitle">
                                                2.4 合法转让规范：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    如果您向其他人转让本设备，您可以向该收受方（简称“新用户”）一次性永久转让本协议规定的使用宾乐达知识产权的权利，但必须符合以下规范：
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    (1)
                                                    转让必须包括您的宾乐达设备及其所有权与其他权利；
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    (2)
                                                    您不允许再持有宾乐达知识产权的任何副本，包括存储在计算机或其他存储设备上的副本；
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    (3)
                                                    您必须在转让前，告知新用户关于本协议的条款和条件，并明确告知新用户在接受转让的同时，即视为“打开包装”的行为，新用户必须清楚了解对宾乐达知识产权的使用将受到这些条款和条件的约束，以作为收受本设备的前提要件。
                                                </p>
                                            </div>
                                        </div>

                                        <div className="subSection subSection5">
                                            <p className="subtitle">
                                                2.5 用户理解并同意：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    2.5.1
                                                    翔睿德会对用户是否涉嫌违反上述使用规范做出认定，并根据认定结果中止、终止对您的使用许可或采取其他依本约定可采取的限制措施。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.5.2
                                                    对于用户使用许可软件时所发布涉嫌违法或涉嫌侵犯他人合法权利或违反本协议的信息，翔睿德会直接删除。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.5.3
                                                    对于用户违反上述使用规范的行为对第三方造成损害的，您需要以自己的名义独立承担法律责任，并应确保翔睿德免于因此产生损失或增加费用。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    2.5.4
                                                    若用户违反有关法律规定或协议约定，使翔睿德遭受损失，或受到第三方的索赔，或受到行政管理机关的处罚，用户应当赔偿翔睿德因此造成的损失和（或）发生的费用，包括合理的律师费、调查取证费用。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="section3">
                                        <h2>三、软件更新：</h2>
                                        <p>
                                            3.1
                                            本软件为了改善用户体验、漏洞修复程序、或完善服务内容，翔睿德有权不时地为您提供本软件的替换、更新、修改的升级版本。
                                        </p>
                                        <p>
                                            3.2
                                            本软件可能在不经另行通知用户或征得额外同意下自动安装。
                                        </p>
                                        <p>
                                            3.3
                                            如果您不愿意更新本软件，翔睿德不保证旧软件的继续可用状态。
                                        </p>
                                        <p>
                                            3.4
                                            翔睿德将依据用户使用的设备机型与使用条件而提供不同的软件版本，用户不得有异。
                                        </p>
                                    </section>

                                    <section className="section4">
                                        <h2>四、用户账号：</h2>
                                        <p>
                                            使用本软件的部分功能，用户可能需要注册翔睿德账号（简称“用户账号”），用户对于翔睿德账号的注册、使用行为等规范，必须遵守以下用户账号使用规定：
                                        </p>
                                        <div className="subSection subSection1">
                                            <p className="">
                                                4.1 账号使用权利：
                                            </p>
                                            <p>
                                                用户账号所有权属于翔睿德，您作为初始申请注册人，仅拥有您所注册账号的使用权。
                                            </p>
                                        </div>
                                        <div className="subSection subSection2">
                                            <p className="subtitle">
                                                4.2 账号注册条件：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    4.2.1
                                                    您必须确认在您开始注册程序使用本软件及服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    4.2.2
                                                    您应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽、准确的要求。您保证在注册时所提交的所有资料（包括但不限于手机号码、邮箱等）真实、准确、合法、有效且系您本人的资料。如果因注册信息不真实或更新不及时而引发的相关问题，我们将不负任何责任。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    4.2.3
                                                    如您的注册信息中出现或存在有违法和不良信息，我们将不予注册；同时，在注册后，如发现您以虚假信息骗取帐号名称注册，我们有权不经通知而单方采取限期改正、暂停使用、终止帐号等措施。
                                                </p>
                                            </div>
                                        </div>
                                        <div className="subSection subSection3">
                                            <p className="subtitle">
                                                4.3 账号行使规则：
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    4.3.1
                                                    账号使用规范：您必须对用户账号的所有行为负担全部法律责任。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    4.3.2
                                                    账号转让禁止：用户不得赠与、借用、租用、有偿或无偿转让或售卖用户帐号或者以其他方式许可非初始申请注册人使用用户帐号。如果我们发现使用者并非初始申请注册人，我们有权不经通知而单方终止该用户账号。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    4.3.3
                                                    账号安全保管：您的帐号为您自行设置并由您保管，我们在任何时候均不会主动要求您提供您的帐号密码。您的用户账号如因主动泄漏或遭受他人攻击而遭受损失，我们不承担因此的法律责任，您应通过法律或其他途径向侵权行为人追偿。
                                                </p>
                                            </div>
                                        </div>
                                        <div className="subSection subSection4">
                                            <p className="">4.4 账户注销：</p>
                                            <p>
                                                在需要终止您的用户账号时，您可以依照程序通知我们注销您的用户账号，您了解该用户账号一旦注销后，将从我们数据库中永远删除并无法回复。但对于您用户账号所相应的法律责任，不因注销账号而有任何影响。
                                            </p>
                                        </div>
                                        <div className="subSection subSection5">
                                            <p className="">4.5 账号回收：</p>
                                            <p>
                                                为了防止资源占用，如您连续24个月未使用您的用户帐号，我们有权对该帐号进行注销，您将不能再通过该帐号登录或使用相关服务。如该帐号有关联的待处理交易或余额等，我们会在合理范围内协助您处理。
                                            </p>
                                        </div>
                                        <div className="subSection subSection6">
                                            <p className="subtitle">
                                                4.6 免责条款
                                            </p>
                                            <div className="wordWrap">
                                                <p>
                                                    4.6.1
                                                    用户拒绝注册：关于本软件中需要注册账号的功能，用户如果选择拒绝注册，则相关功能的使用、存储或限制可能受到影响，用户必须自己承担责任。
                                                </p>
                                            </div>
                                            <div className="wordWrap">
                                                <p>
                                                    4.6.2
                                                    用户违反法规：如我们因您未依照本协议或法律规定，而拒绝申请、限期改正、暂停使用、或终止使用您的用户账号，所由此带来的各项损失（包括但不限于通信中断，用户资料、邮件和相关数据等的清空等），均由您自行承担。
                                                </p>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="section5">
                                        <h2>五、知识产权声明：</h2>
                                        <p>
                                            5.1
                                            翔睿德是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受当地法律法规和相应的国际条约保护，翔睿德享有上述知识产权。
                                        </p>
                                        <p>
                                            5.2
                                            您确认宾乐达知识产权的所有知识产权在全世界均属于宾乐达，宾乐达知识产权的权利仅是许可给您（而非售予您），以及除了本协议中各条款使用权外，您无权使用宾乐达知识产权。
                                        </p>
                                        <p>
                                            5.3
                                            除非事前取得翔睿德的书面许可，用户不允许为任何商业或非商业目的，自行或许可任何第三方实施、利用、转让上述知识产权。
                                        </p>
                                        <p>
                                            5.4
                                            翔睿德将保留追究上述行为法律责任的权利。
                                        </p>
                                    </section>

                                    <section className="section6">
                                        <h2>六、服务风险及免责声明：</h2>
                                        <p>
                                            6.1
                                            翔睿德是以目前的技术提供服务支持，不保证本软件及服务在操作上不会中断或没有错误，不保证会纠正本软件及服务的所有缺陷，也不保证本软件及服务能满足用户的所有要求。由此产生的后果，翔睿德不承担任何责任。
                                        </p>
                                        <p>
                                            6.2
                                            用户因第三方如通讯线路故障、技术问题、网络、电脑终端设备故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，翔睿德不承担责任。
                                        </p>
                                        <p>
                                            6.3
                                            翔睿德不保证通过本软件所获得信息内容（包括但不限于调用的第三方服务内容）的合法性、真实性、准确性、有效性，翔睿德不对用户基于查询、查看做出的任何行为的结果承担任何责任。
                                        </p>
                                        <p>
                                            6.4
                                            本软件如同大多数互联网软件一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其他软件或访问的其他网站中含有"特洛伊木马"等病毒，威胁到用户的终端设备信息和数据的安全，继而影响本软件的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。
                                        </p>
                                        <p>
                                            6.5
                                            因用户使用本软件或要求翔睿德提供特定服务时，本软件可能会调用第三方系统或第三方软件支持用户的使用或访问，使用或访问的结果由该第三方提供，用户除遵守本协议相关规则外，还应遵守第三方的协议、相关规则。用户应理解并同意，在使用第三方服务时，第三方可能会对用户数据进行读取，翔睿德不保证通过第三方系统或第三方软件支持实现的结果的安全性、准确性、有效性及其他不确定的风险，用户应审慎判断，由此若引发的任何争议及损害，翔睿德不承担任何责任。
                                        </p>
                                        <p>
                                            6.6
                                            用户应理解并同意，翔睿德为了保障公司业务发展和调整的自主权，翔睿德采取在合理的方式通知用户后，可随时修改或中断服务。
                                        </p>
                                        <p>
                                            6.7
                                            除法律法规有明确规定外，翔睿德将尽最大努力确保软件及其所涉及的技术及信息安全、有效、准确、可靠，但受限于现有技术，用户应理解并同意，翔睿德不能对此进行担保。
                                        </p>
                                        <div>
                                            6.8
                                            用户应自行承担因下述任一情况所引起或与此有关的人身伤害或附带的、间接的经济损害赔偿，包括但不限于利润损失、资料损失、业务中断的损害赔偿或其他商业损害赔偿或损失：架、有关数据或电子文档等）均受当地法律法规和相应的国际条约保护，翔睿德享有上述知识产权。
                                            <p>(1) 使用或未能使用软件。</p>
                                            <p>
                                                (2)
                                                第三方未经许可的使用软件或更改用户的数据。
                                            </p>
                                            <p>
                                                (3)
                                                用户使用软件进行的行为产生的费用及损失。
                                            </p>
                                            <p>(4) 用户对软件的误解。</p>
                                            <p>
                                                (5)
                                                非因翔睿德的原因引起的与软件有关的其他损失。
                                            </p>
                                        </div>
                                        <p>
                                            6.9
                                            用户同意翔睿德有权基于司法、监管部门、监督机构的要求或自身业务原因，暂停、中断或终止向用户提供全部或者部分本服务。对此翔睿德不承担任何责任。
                                        </p>
                                        <p>
                                            6.10
                                            用户理解并同意，翔睿德将会尽其商业上的合理努力保障用户在本软件及服务中的数据存储安全，但翔睿德不能提供完全保证，且翔睿德不对服务中的数据的删除或储存或备份失败负责。
                                        </p>
                                    </section>

                                    <section className="section7">
                                        <h2>七、第三方服务说明：</h2>
                                        <p>
                                            7.1
                                            用户理解并同意，本软件可能包含由翔睿德的关联方或第三方提供的服务，翔睿德只是为了用户便利操作而在系统中提供相关功能模块，提供第三方服务的使用入口。
                                        </p>
                                        <p>
                                            7.2
                                            无论第三方服务预置于本软件系统服务中，还是由用户自行开通或订购，用户均应理解并同意，翔睿德不对第三方服务提供方或用户行为的合法性、有效性，以及第三方服务的合法性、准确性、有效性、安全性进行任何明示或默示的保证或担保。
                                        </p>
                                        <p>
                                            7.3
                                            翔睿德并不监督第三方服务，不对其拥有任何控制权，也不对第三方服务提供任何形式的保证或担保，更不承担任何责任。
                                        </p>
                                        <p>
                                            7.4
                                            用户与第三方服务提供方之间发生的任何争议、纠纷应由用户与该方按照第三方服务提供方的业务流程及业务规范自行协商解决，翔睿德不承担任何责任。
                                        </p>
                                    </section>

                                    <section className="section8">
                                        <h2>八、未成年人使用条款：</h2>
                                        <p>
                                            8.1 用户如果是未满18
                                            周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下，阅读本协议和使用本软件及相关服务。
                                        </p>
                                    </section>

                                    <section className="section9">
                                        <h2>九、协议变更：</h2>
                                        <p>
                                            9.1
                                            翔睿德有权在必要时修改本协议条款，协议条款一旦发生变动，将会在相关页面上公布修改后的协议条款。如果不同意所改动的内容，用户应主动取消此项服务。如果用户继续使用服务，则视为接受协议条款的变动。
                                        </p>
                                        <p>
                                            9.2
                                            翔睿德有权依据需要修改或变更所提供的收费服务、收费标准、收费方式、服务费及服务条款。翔睿德在提供服务时，可能对部分服务的用户开始收取一定的费用，如用户拒绝支付该等费用或拒绝同意条款变更，则不能在收费的相关条款调整后，继续使用相关的服务。
                                        </p>
                                    </section>

                                    <section className="section10">
                                        <h2>十、违约责任：</h2>
                                        <p>
                                            10.1
                                            翔睿德有权判断用户的行为是否符合本协议条款规定，如果认为用户违反有关法律法规或者本协议、相关规则的规定，根据用户违规情形的严重程度，翔睿德有权对用户采取删除违规信息、限制、中止、终止用户使用本软件服务、追究用户的法律责任以及其他翔睿德认为适合的处理措施。如果使翔睿德遭受任何损失（包括但不限于收到任何第三方的索赔或任何行政管理部门的处罚），用户应当承担全部责任。
                                        </p>
                                    </section>

                                    <section className="section11">
                                        <h2>十一、适用法律及争议解决：</h2>
                                        <p>
                                            11.1
                                            本协议条款之效力和解释均适用中华人民共和国法律规定。如无相关法律规定的，则参照使用国际商业惯例和/或商业惯例。
                                        </p>
                                        <p>
                                            11.2 本协议的签订地是海南省海口市。
                                        </p>
                                        <p>
                                            11.3
                                            用户和翔睿德一致同意凡因本服务所产生的纠纷，双方应先友好的协商解决，不愿协商或协商不成的，任何一方均有权可提交本协议签订地有管辖权的法院诉讼解决。
                                        </p>
                                    </section>

                                    <section className="section12">
                                        <h2>十二、隐私政策与个人信息保护：</h2>
                                        <p>
                                            12.1
                                            翔睿德承诺按照本产品隐私政策保护用户的隐私，具体请见：
                                            <Link to="/privacy">
                                                宾乐达隐私政策
                                            </Link>
                                            。
                                        </p>
                                    </section>

                                    <section className="section13">
                                        <h2>十三、其他：</h2>
                                        <p>
                                            13.1
                                            翔睿德可能以电子邮件或短信或网页公告、认为适当的方式发送通知，告知用户服务条款的修改、服务变更及/或其它重要事项。翔睿德通过以上任一方式向用户发报消息后，用户未在
                                            3
                                            日内通过书面方式提出异议，视为接受新消息的内容。
                                        </p>
                                        <p>
                                            13.2
                                            本协议更新时间为【2022】年【11】月【28】日。
                                        </p>
                                        <p>
                                            13.3
                                            本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
                                        </p>
                                        <p>
                                            13.4
                                            本协议条款无论因何种原因以致部分无效或不可执行，其余条款仍有效，对双方具有约束力。
                                        </p>
                                    </section>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default Agreement;
