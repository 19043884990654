import './userDrillItem.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { LoadingAnimate } from '../loadingAnimate/LoadingAnimate';
import { axiosApi } from '../../../api';
import EditDrillItemModal from './Modals/EditDrillItemModal/EditDrillItemModal';
import InsertDrillItemModal from './Modals/InsertDrillItemModal/InsertDrillItemModal';

const UserDrillItem = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// edit : useParams('userDrillGuid')
	// create : location.state.paramsGuid
	const paramsGuid = useParams('userDrillGuid') || location.state.paramsGuid;
	const [modalShow, setModalShow] = useState(false);
	const [forUserModalShow, setForUserModalShow] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [userDrillInfo, setUserDrillInfo] = useState({
		drillClassGuid: null,
		description: null,
		displayOrder: null,
		frequency: null,
		serveOrder: null,
		name: null,
		quantity: null,
		serveAmount: null,
		speed: null,
		timer: null,
		userDrillGuid: null,
	});
	const [userDrillItemList, setUserDrillItemList] = useState([]);

	// 复制到宾乐达球谱 dialog
	const [drillCategoryGuid, setDrillCategoryGuid] = useState(null);
	const [drillCategoryItemGuid, setDrillCategoryItemGuid] = useState(null);
	const [drillCategoryList, setDrillCategoryList] = useState([]);
	const [drillCategoryItemList, setDrillCategoryItemList] = useState([]);
	const [drillClassList, setDrillClassList] = useState([]);
	const [languageCode, setLanguageCode] = useState('ZH');
	const [languageList, setLanguageList] = useState([
		{ languageCode: 'ZH', name: '简体中文' },
		{ languageCode: 'ZF', name: '繁體中文' },
		{ languageCode: 'EN', name: 'English' },
		{ languageCode: 'JA', name: '日本語' },
	]);

	// 复制到用户 dialog
	const [copyUserInfo, setCopyUserInfo] = useState(null);
	const [accountType, setAccountType] = useState('1');
	const [countryCode, setCountryCode] = useState('86');
	const [searchInputValue, setSearchInputValue] = useState(null);
	const [noOnFound, setNoOnFound] = useState(false);
	const [userDrillClassList, setUserDrillClassList] = useState([]);
	const [userDrillClassGuid, setUserDrillClassGuid] = useState(null);

	// 新增球路 dialog
	const [insertDrillModalOpen, setInsertDrillModalOpen] = useState(false);
	// 新球路 data
	const [newDrillItemData, setNewDrillItemData] = useState({
		delay: 0,
		delayUI: null,
		displayOrder: null,
		powerBLDC1: null,
		powerBLDC2: null,
		rowStamp: null,
		spin: 0,
		spinPower: 0,
		maxSpinPower: null,
		userDrillItemGuid: null,
		vertical: 0,
		verticalDirection: 1,
		horizontal: 0,
		horizontalDirection: 1,
	});

	// 編輯球路 dialog
	const [editDrillModalOpen, setEditDrillModalOpen] = useState(false);
	const [editDrillItemData, setEditDrillItemData] = useState({
		delay: null,
		delayUI: null,
		displayOrder: null,
		horizontal: null,
		powerBLDC1: null,
		powerBLDC2: null,
		rowStamp: null,
		spin: null,
		spinPower: null,
		maxSpinPower: null,
		userDrillItemGuid: null,
		vertical: null,
	});

	// warning
	const [displayOrderWarning, setDisplayOrderWarning] = useState('');
	const [nameWarning, setNameWarning] = useState('');
	const [frequencyWarning, setFrequencyWarning] = useState('');
	const [quantityWarning, setQuantityWarning] = useState('');
	const [timerWarning, setTimerWarning] = useState('');

	const spinNameTable = {
		0: '上旋',
		1: '左侧上旋↑',
		2: '左侧上旋',
		3: '左侧上旋↓',
		4: '左旋',
		5: '左側下旋↑',
		6: '左側下旋',
		7: '左側下旋↓',
		8: '下旋',
		9: '右側下旋↓',
		10: '右側下旋',
		11: '右側下旋↑',
		12: '右旋',
		13: '右側上旋↓',
		14: '右側上旋',
		15: '右側上旋↑',
		noSpin: '不旋球',
	};

	// 前端給予新GUID
	function generateGuid() {
		var d = Date.now();
		if (
			typeof performance !== 'undefined' &&
			typeof performance.now === 'function'
		) {
			d += performance.now(); //use high-precision timer if available
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
			}
		);
	}

	const handleAngleDisplay = (type, val, direction, BLDC1, BLDC2) => {
		let result = '';

		// 水平角度
		if (type === 0) {
			if (direction > 0) {
				result = `右 ${Math.abs(val / 10)}`;
			} else if (direction < 0) {
				result = `左 ${Math.abs(val / 10)}`;
			}

			if (val === 0) {
				result = val / 10;
			}
		}

		// 俯仰角度
		if (type === 1) {
			if (direction > 0) {
				result = `上 ${Math.abs(val / 10)}`;
			} else if (direction < 0) {
				result = `下 ${Math.abs(val / 10)}`;
			}

			if (val === 0) {
				result = val / 10;
			}
		}

		// 旋球角度
		if (type === 2) {
			if (BLDC1 === BLDC2) {
				result = spinNameTable['noSpin'];
			} else {
				val = val / 225;
				result = spinNameTable[val];
			}
		}

		// console.log(result);

		return result;
	};

	// --複製成功dialog --
	const successAlert = () => {
		Swal.fire({
			title: '複製成功',
			html: `<h6>宾乐达球谱复制成功!</h6>`,
			icon: 'success',
			confirmButtonText: '返回',
			allowOutsideClick: false,
		}).then((result) => {
			if (result.isConfirmed) {
				setModalShow(false);
			}
		});
	};

	const handleCopyUserDrill = async () => {
		await axios
			.post(`web/SysDrill/copyUserDrill/${paramsGuid.userDrillGuid}`, {
				drillCategoryGuid,
				drillCategoryItemGuid,
				languageCode,
			})
			.then((res) => {
				res.status === 200 && successAlert();
			})
			.catch((err) => console.log(err));
	};

	const handleCopyUserDrillToUser = async () => {
		console.log(copyUserInfo);

		await axios
			.post(
				`web/sysDrill/copyUserDrillToUser/${paramsGuid.userDrillGuid}`,
				{
					userGuid: copyUserInfo.userGuid,
					drillClassGuid: userDrillClassGuid,
				}
			)
			.then((res) => {
				res.status === 200 && successAlert();
				setForUserModalShow(false);
			})
			.catch((err) => console.log(err));
	};

	let callAPItimes = 0;
	const [inputDisabled, setInputDisabled] = useState(false);
	const getDrillCategoryItemList = async (categoryGuid) => {
		setDrillCategoryItemGuid('');

		const fetchData = async () => {
			try {
				const response = await axios.get(
					`web/sysDrill/drillCategoryItem?guid=${categoryGuid}`
				);
				const data = response.data.result;

				setDrillCategoryItemList(data);
				setDrillCategoryItemGuid(data[0].categoryItemGuid);
				setInputDisabled(false);
			} catch (error) {
				console.log(error);
				// 如果是由于 JWT 错误导致的错误，等待两秒后重新执行请求
				if (
					error.response &&
					error.response.status === 550 &&
					callAPItimes < 2
				) {
					callAPItimes++;
					console.log(callAPItimes);
					setInputDisabled(true); //鎖定確定按鈕
					await new Promise((resolve) => setTimeout(resolve, 2000)); // 等待两秒
					await fetchData(); // 重新执行请求
				}
			}
		};

		await fetchData();
	};

	const handleModalOpen = () => {
		setDrillCategoryGuid('');
		setDrillCategoryItemGuid('');

		const getDrillCategoryList = async () => {
			await axios
				.get(`web/sysDrill/drillCategory`)
				.then((res) => {
					const data = res.data.result;
					// console.log(data);

					setDrillCategoryList(data);
					setDrillCategoryGuid(data[0].categoryGuid);

					getDrillCategoryItemList(data[0].categoryGuid);
				})
				.catch((err) => console.log(err));
		};
		getDrillCategoryList();
		setModalShow(true);
	};

	const handleInsertDrillModalOpen = () => {
		setInsertDrillModalOpen(true);
	};

	const handleForUserModalShow = () => {
		setDrillCategoryGuid('');
		setDrillCategoryItemGuid('');

		const getDrillCategoryList = async () => {
			await axios
				.get(`web/sysDrill/drillCategory`)
				.then((res) => {
					const data = res.data.result;
					// console.log(data);

					setDrillCategoryList(data);
					setDrillCategoryGuid(data[0].categoryGuid);

					getDrillCategoryItemList(data[0].categoryGuid);
				})
				.catch((err) => console.log(err));
		};
		getDrillCategoryList();
		setForUserModalShow(true);
	};

	const handleGetUniqueUser = async () => {
		// uniqueUserInfo
		if (accountType && searchInputValue) {
			setCopyUserInfo(null);
			setNoOnFound(false);
			await axios
				.post(`web/sysDrill/uniqueUserInfo`, {
					accountType,
					email: accountType === '0' ? searchInputValue : null,
					phoneCode: accountType === '0' ? null : searchInputValue,
					countryCode,
				})
				.then(({ data }) => {
					console.log(data);
					const { fullname, photoLink, userGuid } = data;

					if (data) {
						setCopyUserInfo({
							fullname,
							photoLink,
							userGuid,
						});

						axios
							.get(`web/sysDrill/userDrillClass?guid=${userGuid}`)
							.then((res) => {
								const data = res.data.result;
								// console.log(data);

								setUserDrillClassList(data);
								setUserDrillClassGuid(data[0].classGuid);
							})
							.catch((err) => console.log(err));
					} else {
						setNoOnFound(true);
					}
				})
				.catch((err) => console.log(err));
		}
	};

	// 檢查必填
	let isValid = false;

	const isDisplayOrderValid = () => {
		if (userDrillInfo.displayOrder <= 0) {
			setDisplayOrderWarning('請輸入正確顯示順序 (大於 0)');
			return false;
		} else {
			return true;
		}
	};

	const isNameValid = () => {
		if (!userDrillInfo.name) {
			setNameWarning('請輸入球谱名称');
			return false;
		} else {
			return true;
		}
	};

	const isFrequencyValid = () => {
		if (
			userDrillInfo.frequency < 10 ||
			userDrillInfo.frequency > 120 ||
			!userDrillInfo.frequency
		) {
			console.log('isFrequencyValid');
			setFrequencyWarning('請輸入正確出球频率 (10 - 120)');
			return false;
		} else {
			return true;
		}
	};

	const isQuantityValid = () => {
		if (
			userDrillInfo.quantity < 0 ||
			userDrillInfo.quantity > 60000 ||
			!userDrillInfo.quantity
		) {
			console.log('isQuantityValid');
			setQuantityWarning('請輸入正確總球數 (最多 60000 球)');
			return false;
		} else {
			return true;
		}
	};

	const isTimerValid = () => {
		if (
			userDrillInfo.timer < 0 ||
			userDrillInfo.timer > 480 ||
			!userDrillInfo.timer
		) {
			console.log('isTimerValid');
			setTimerWarning('請輸入正確計時器 (最多 480 分鐘)');
			return false;
		} else {
			return true;
		}
	};

	// 新增球譜 確定
	const handleInsertUserDrillSubmit = () => {
		setDisplayOrderWarning('');
		setNameWarning('');
		setFrequencyWarning('');
		setQuantityWarning('');
		setTimerWarning('');
		const { action, userGuid } = location.state;
		console.log(userGuid, action);
		console.log(userDrillInfo);
		console.log(newDrillItemData);

		const copyInfo = { ...userDrillInfo, userGuid: userGuid };
		const { drillClassGuid, serveOrder } = copyInfo;
		if (!drillClassGuid) {
			copyInfo.drillClassGuid =
				location.state.userDrillClassList[0].classGuid;
		}
		if (!serveOrder) {
			copyInfo.serveOrder = '0';
		}

		// isDisplayOrderValid();
		isNameValid();
		isFrequencyValid();
		// isQuantityValid();
		// isTimerValid();

		if (
			// isDisplayOrderValid() &&
			isNameValid() &&
			isFrequencyValid()
			// isQuantityValid() &&
			// isTimerValid()
		) {
			isValid = true;
		}

		if (isValid) {
			const newList = userDrillItemList.map((el) => {
				if (el.verticalDirection < 0) {
					el.vertical = el.vertical * -1;
				}
				if (el.horizontalDirection < 0) {
					el.horizontal = el.horizontal * -1;
				}

				return el;
			});

			copyInfo.userDrillItemList = newList;

			setUserDrillInfo(copyInfo);

			axiosApi(`web/sysDrill/userDrillItem`, 'post', copyInfo).then(
				(res) => {
					navigate(`/userDrill/${location.state.userGuid}`, {
						state: {
							fullname: location.state.fullname,
						},
					});
				}
			);
		}

		// axios
		//     .post(`web/sysDrill/userDrillItem`, data)
		//     .then(({ data }) => {
		//         console.log(data);
		//     })
		//     .catch((err) => console.log(err));
	};

	// 新增球路 確定
	const handleInsertDrillItemSubmit = () => {
		const newData = { ...newDrillItemData };
		newData.displayOrder = userDrillItemList.length + 1;
		newData.userDrillItemGuid = generateGuid();
		newData.action = 'INSERT';
		newData.delay = newDrillItemData.delayUI * 10;

		const spin = newDrillItemData.spin;
		if (spin === 'noSpin') {
			console.log('noSpin');
			newData.powerBLDC1 = newDrillItemData.highSpeed;
			newData.powerBLDC2 = newDrillItemData.highSpeed;
			newData.lowSpeed = newDrillItemData.highSpeed;
			newData.spin = 0;
		} else if (spin >= 0 && spin <= 900) {
			console.log('BLCD1');
			newData.powerBLDC1 = newDrillItemData.highSpeed;
			newData.powerBLDC2 = newDrillItemData.lowSpeed;
		} else if (spin > 2700 && spin <= 3375) {
			console.log('BLCD1');
			newData.powerBLDC1 = newDrillItemData.highSpeed;
			newData.powerBLDC2 = newDrillItemData.lowSpeed;
		} else {
			console.log('BLCD2');
			newData.powerBLDC2 = newDrillItemData.highSpeed;
			newData.powerBLDC1 = newDrillItemData.lowSpeed;
		}

		const newList = [...userDrillItemList, newData];

		setUserDrillItemList(newList);
		setInsertDrillModalOpen(false);
		setNewDrillItemData({
			delay: 0,
			displayOrder: null,
			powerBLDC1: null,
			powerBLDC2: null,
			rowStamp: null,
			spin: 0,
			spinPower: 0,
			maxSpinPower: null,
			userDrillItemGuid: null,
			vertical: 0,
			verticalDirection: 1,
			horizontal: 0,
			horizontalDirection: 1,
		});
	};

	// 打開 編輯球路 Modal
	const handleEditDrillModalOpen = (data) => {
		console.log(data);
		setEditDrillItemData({
			...data,
		});

		const newData = { ...data };
		// newData.vertical = data.vertical;
		// newData.horizontal = data.horizontal;
		// newData.handleInsertDrillItemSubmit = data.handleInsertDrillItemSubmit > 0 ? 1 : -1;
		// newData.horizontalDirection = data.horizontal > 0 ? 1 : -1;
		console.log(data);

		newData.delayUI = data.delay / 10;

		if (data.powerBLDC1 === data.powerBLDC2) {
			newData.spin = 'noSpin';
		}

		let highSpeedWheel = null;
		let lowSpeedWheel = null;
		let highSpeed = 10;
		let lowSpeed = 0;
		if (data.spin >= 0 && data.spin <= 900) {
			console.log('BLCD1');
			highSpeedWheel = 'powerBLDC1';
			lowSpeedWheel = 'powerBLDC2';
			highSpeed = data.powerBLDC1;
			lowSpeed = data.powerBLDC2;
		} else if (data.spin > 2700 && data.spin <= 3375) {
			console.log('BLCD1');
			highSpeedWheel = 'powerBLDC1';
			lowSpeedWheel = 'powerBLDC2';
			highSpeed = data.powerBLDC1;
			lowSpeed = data.powerBLDC2;
		} else {
			console.log('BLCD2');
			highSpeedWheel = 'powerBLDC2';
			lowSpeedWheel = 'powerBLDC1';
			highSpeed = data.powerBLDC2;
			lowSpeed = data.powerBLDC1;
		}

		const maxSpinPower = Math.floor((highSpeed - 10) / 2 + 1);

		newData.highSpeedWheel = highSpeedWheel;
		newData.lowSpeedWheel = lowSpeedWheel;
		newData.maxSpinPower = maxSpinPower;
		newData.highSpeed = highSpeed;
		newData.lowSpeed = lowSpeed;

		console.log(maxSpinPower);

		setEditDrillItemData({ ...editDrillItemData, ...newData });
		setEditDrillModalOpen(true);
	};

	// 編輯球譜 確定
	const handleEditDrillItemSubmit = () => {
		console.log(editDrillItemData);

		const submitData = { ...editDrillItemData };

		// Action
		// UserDrillItemGuid
		// DisplayOrder
		// Delay
		// Action
		// Horizontal
		// Vertical
		// Spin
		// PowerBLDC1
		// PowerBLDC2
		// RowStamp

		submitData.action = 'UPDATE';
		submitData.delay = editDrillItemData.delayUI * 10;

		const spin = editDrillItemData.spin;
		console.log(spin);

		if (spin === 'noSpin') {
			console.log('noSpin');
			submitData.powerBLDC1 = editDrillItemData.highSpeed;
			submitData.powerBLDC2 = editDrillItemData.highSpeed;
			submitData.lowSpeed = editDrillItemData.highSpeed;
			submitData.spin = 0;
		} else if (spin >= 0 && spin <= 900) {
			console.log('BLCD1');
			submitData.powerBLDC1 = editDrillItemData.highSpeed;
			submitData.powerBLDC2 = editDrillItemData.lowSpeed;
		} else if (spin > 2700 && spin <= 3375) {
			console.log('BLCD1');
			submitData.powerBLDC1 = editDrillItemData.highSpeed;
			submitData.powerBLDC2 = editDrillItemData.lowSpeed;
		} else {
			console.log('BLCD2');
			submitData.powerBLDC2 = editDrillItemData.highSpeed;
			submitData.powerBLDC1 = editDrillItemData.lowSpeed;
		}

		const foundIndex = userDrillItemList.findIndex((el) => {
			return el.userDrillItemGuid === submitData.userDrillItemGuid;
		});

		const newList = [...userDrillItemList];
		newList[foundIndex] = submitData;

		console.log(submitData);
		console.log(newList);

		// console.log(foundIndex);
		setUserDrillItemList(newList);
		setEditDrillModalOpen(false);
	};

	// 編輯球路 確定
	const handleEditUserDrillSubmit = () => {
		const { action, userGuid } = location.state;
		// console.log(userDrillInfo);

		// UserDrillGuid
		// DisplayOrder
		// Name
		// Description
		// DrillClassGuid
		// Frequency
		// serveOrder
		// ServeAmount
		// ServeOrder
		// Timer
		// Quantity
		// RowStamp
		/// UserDrillItemList

		const copyInfo = { ...userDrillInfo, userGuid: userGuid };
		const { drillClassGuid, serveOrder } = copyInfo;

		isDisplayOrderValid();
		isNameValid();
		isFrequencyValid();
		// isQuantityValid();
		// isTimerValid();

		if (
			isDisplayOrderValid() &&
			isNameValid() &&
			isFrequencyValid()
			// isQuantityValid() &&
			// isTimerValid()
		) {
			isValid = true;
		}

		if (isValid) {
			if (!drillClassGuid) {
				copyInfo.drillClassGuid =
					location.state.userDrillClassList[0].classGuid;
			}
			if (!serveOrder) {
				copyInfo.serveOrder = '0';
			}

			const newList = userDrillItemList.map((el) => {
				if (el.verticalDirection < 0) {
					el.vertical = el.vertical * -1;
				}
				if (el.horizontalDirection < 0) {
					el.horizontal = el.horizontal * -1;
				}

				return el;
			});

			copyInfo.userDrillItemList = newList;

			console.log(copyInfo);

			const guid = paramsGuid.userDrillGuid;
			axiosApi(`web/sysDrill/userDrillItem`, 'put', copyInfo).then(
				(res) => {
					navigate(`/userDrill/${location.state.userGuid}`, {
						state: {
							fullname: location.state.fullname,
						},
					});
				}
			);
		}
	};

	// 刪除球譜
	const handleDeleteUserDrill = async () => {
		// console.log(paramsGuid.userDrillGuid);
		const guid = paramsGuid.userDrillGuid;

		await Swal.fire({
			html: `<h4>確定要刪除?</h4>`,
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: '取消',
			showConfirmButton: true,
			confirmButtonText: '確定',
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((res) => {
			if (res.dismiss === 'cancel') {
				console.log('cancelled');
				return;
			} else {
				console.log('confirmed delete');

				axiosApi(`web/sysDrill/userDrillItem/${guid}`, 'delete').then(
					(res) => {
						navigate(`/userDrill/${location.state.userGuid}`, {
							state: {
								fullname: location.state.fullname,
							},
						});
					}
				);
			}
		});
	};

	// 刪除球路
	const handleDeleteDrillItem = (guid) => {
		console.log(editDrillItemData);

		const newList = userDrillItemList.map((el) => {
			if (el.userDrillItemGuid === guid) {
				el.action = 'DELETE';
			}

			return el;
		});

		console.log(newList);
		setUserDrillItemList(newList);
		setEditDrillModalOpen(false);
	};

	// 將 fetched data 轉換成 UI 所需要的 info
	const handleFromDataToUI = (data) => {
		// console.log(data);
		const newData = data.map((el) => {
			const { powerBLDC1, powerBLDC2 } = el;

			/// 1. 旋球強度
			// 使用 高速輪 & 低速輪 算出 旋球強度
			// 旋球強度   ((絕對值(BLDC1-BLDC2))-基本強度值)/強度單位 +1
			// powerBLDC1 & powerBLDC2 -> 誰大誰就是 高速輪
			let spinPower = Math.floor(
				(Math.abs(powerBLDC1 - powerBLDC2) - 10) / 2 + 1
			);

			// 如果 powerBLDC1 等於 powerBLDC2 -> 一定是沒旋 -> 沒有旋球強度
			if (powerBLDC1 === powerBLDC2) {
				spinPower = 0;
			}

			el.spinPower = spinPower;

			/// 2. 水平角度 & 俯仰角度
			// vertical : Top = +ve, Down = -ve
			// 如果 vertical 少於 0 (-ve), 那方向就是下
			// horizontal : Right = +ve, Left = -ve
			// 如果 horizontal 少於 0 (-ve), 那方向就是左

			// 以 verticalDirection & horizontalDirection 代表其方向
			// 1 = 上 / 右
			// -1 = 下 / 左
			el.verticalDirection = el.vertical > 0 ? 1 : -1;
			el.horizontalDirection = el.horizontal > 0 ? 1 : -1;
			el.vertical = Math.abs(el.vertical);
			el.horizontal = Math.abs(el.horizontal);

			/// 3. 旋球角度
			// 以 四象限 為準, 以決定 powerBLDC1 & powerBLDC2 誰才是高速輪
			// 第一象限, 第二象限 -> powerBLDC1 是高速輪
			// 第三象限, 第四象限 -> powerBLDC2 是高速輪

			// 簡單來說, 就是抄 Gino 在 app 那邊的寫法的 :)
			const spin = el.spin;
			if (spin >= 0 && spin <= 900) {
				el.highSpeed = el.powerBLDC1;
				el.lowSpeed = el.powerBLDC2;
			} else if (spin > 2700 && spin <= 3375) {
				el.highSpeed = el.powerBLDC1;
				el.lowSpeed = el.powerBLDC2;
			} else {
				el.highSpeed = el.powerBLDC2;
				el.lowSpeed = el.powerBLDC1;
			}

			return el;
		});

		return newData;
	};

	// edit getInfo
	useEffect(() => {
		setUserDrillInfo({
			userDrillGuid: null,
			displayOrder: null,
			name: null,
			description: null,
			drillClassGuid: null,
			serveOrder: null,
			serveAmount: null,
			timer: null,
			quantity: null,
		});
		setUserDrillItemList([]);
		if (
			Object.keys(paramsGuid).length &&
			location.state.action !== 'create'
		)
			(async () => {
				await axios
					.get(
						`web/sysDrill/userDrillItem/${paramsGuid.userDrillGuid}`
					)
					.then((res) => {
						// console.log(
						//     Object.keys(paramsGuid).length,
						//     location.state.action !== 'create'
						// );
						const data = res.data.result;
						const {
							description,
							displayOrder,
							frequency,
							serveOrder,
							name,
							quantity,
							serveAmount,
							timer,
							userDrillGuid,
							drillClassGuid,
							rowStamp,
						} = data;

						setUserDrillInfo({
							...userDrillInfo,
							description,
							displayOrder,
							frequency,
							serveOrder,
							name,
							quantity,
							serveAmount,
							timer,
							userDrillGuid,
							drillClassGuid,
							rowStamp,
						});

						const UIUserDrillItemList = handleFromDataToUI(
							data.userDrillItemList
						);

						setUserDrillItemList(UIUserDrillItemList);
						setDrillClassList(data.userDrillClassList);
					})
					.catch((err) => console.log(err));
			})();
	}, [paramsGuid]);

	return (
		<>
			<LoadingAnimate isLoading={isLoading} />

			<section id="section-main">
				<div id="container-userDrillItem">
					<div className="d-flex">
						<h5 className="fw-bold mb-4">
							{location.state.fullname}的自编球谱
						</h5>

						{location.state.action === 'create' ? (
							<>
								<button
									className="btnCancel btn btn-outline-primary px-4 me-2 ms-auto"
									onClick={(e) => {
										console.log(location.state);
										e.preventDefault();
										navigate(
											`/userDrill/${location.state.userGuid}`,
											{
												state: {
													fullname:
														location.state.fullname,
												},
											}
										);
									}}
								>
									取消
								</button>
								<button
									type="button"
									className="btn btn-primary text-white px-4 "
									// onClick={handleCopyUserDrill}
									onClick={handleInsertUserDrillSubmit}
								>
									储存
								</button>
							</>
						) : (
							<>
								<button
									className="btn btn-outline-danger px-2 me-2 ms-auto"
									onClick={() => handleDeleteUserDrill()}
								>
									<svg
										id="icn_Trash"
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										viewBox="0 0 16 16"
									>
										<defs>
											<clipPath id="clipPath">
												<rect
													id="Rectangle_3041"
													data-name="Rectangle 3041"
													width="13.263"
													height="14"
													fill="#ea4132"
												/>
											</clipPath>
										</defs>
										<g
											id="Group_6120"
											data-name="Group 6120"
											transform="translate(1 1)"
										>
											<g
												id="Group_6119"
												data-name="Group 6119"
												clipPath="url(#clipPath)"
											>
												<path
													id="Path_1388"
													data-name="Path 1388"
													d="M10.316,2.21V1.473A1.474,1.474,0,0,0,8.842,0H4.421A1.473,1.473,0,0,0,2.948,1.473V2.21H.737a.737.737,0,1,0,0,1.474h.737v8.1A2.211,2.211,0,0,0,3.684,14h5.9a2.211,2.211,0,0,0,2.211-2.211v-8.1h.737a.737.737,0,0,0,0-1.474ZM8.842,1.473H4.421V2.21H8.842Zm1.474,2.211H2.948v8.1a.736.736,0,0,0,.736.737h5.9a.737.737,0,0,0,.737-.737Z"
													fill="#ea4132"
													fillRule="evenodd"
												/>
												<rect
													id="Rectangle_3039"
													data-name="Rectangle 3039"
													width="1.474"
													height="5.895"
													transform="translate(4.421 5.158)"
													fill="#ea4132"
												/>
												<rect
													id="Rectangle_3040"
													data-name="Rectangle 3040"
													width="1.474"
													height="5.895"
													transform="translate(7.368 5.158)"
													fill="#ea4132"
												/>
											</g>
										</g>
										<rect
											id="Rectangle_3042"
											data-name="Rectangle 3042"
											width="16"
											height="16"
											fill="none"
										/>
									</svg>
								</button>
								<div
									className="me-2"
									style={{
										height: '32px',
										borderLeft: '1px solid #DADADA',
									}}
								></div>
								<button
									className="btn btn-primary text-white me-2"
									onClick={handleForUserModalShow}
								>
									<svg
										id="icn_Create"
										className="me-2 mb-1"
										viewBox="0 0 12 12"
										width="12"
										height="12"
										fill="#fff"
									>
										<defs> </defs>
										<path
											className="cls-1"
											id="_Color"
											d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
											data-name=" ↳Color"
										></path>
									</svg>
									<span>复制給用戶</span>
								</button>
								<button
									className="btn btn-primary text-white me-2"
									onClick={handleModalOpen}
								>
									<svg
										id="icn_Create"
										className="me-2 mb-1"
										viewBox="0 0 12 12"
										width="12"
										height="12"
										fill="#fff"
									>
										<defs> </defs>
										<path
											className="cls-1"
											id="_Color"
											d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
											data-name=" ↳Color"
										></path>
									</svg>
									<span>复制到宾乐达球谱</span>
								</button>
								<button
									className="btnCancel btn btn-outline-primary px-4 me-2"
									onClick={(e) => {
										console.log(location.state);
										e.preventDefault();
										navigate(
											`/userDrill/${location.state.userGuid}`,
											{
												state: {
													fullname:
														location.state.fullname,
												},
											}
										);
									}}
								>
									取消
								</button>
								<button
									type="button"
									className="btn btn-primary text-white px-4 "
									onClick={handleEditUserDrillSubmit}
								>
									储存
								</button>
							</>
						)}
					</div>

					{/* 复制到宾乐达球谱 */}
					<Modal
						className="copyDrillModal modal"
						show={modalShow}
						onHide={() => setModalShow(false)}
						centered
					>
						<Modal.Header closeButton className="border-bottom">
							<Modal.Title>复制到宾乐达球谱</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="row g-3">
								<div className="col-12">
									<label
										htmlFor="fullname"
										className="fontSize75Rem mb-2"
									>
										球谱类型
									</label>
									<select
										className="form-control mb-3 w-100"
										name="phone1CountryCode"
										id="phone1CountryCode"
										value="0"
										disabled
									>
										<option value="">
											0 宾乐达预设球谱
										</option>
									</select>
									<label
										htmlFor="fullname"
										className="fontSize75Rem mb-2"
									>
										球谱型态
									</label>
									<select
										className="form-control mb-3 w-100"
										name="phone1CountryCode"
										id="phone1CountryCode"
										value={drillCategoryGuid}
										onChange={(e) => {
											setDrillCategoryGuid(
												e.target.value
											);
											getDrillCategoryItemList(
												e.target.value
											);
										}}
									>
										{drillCategoryList.map((el) => (
											<option
												value={el.categoryGuid}
												key={el.categoryGuid}
												id={el.categoryGuid}
											>
												{`${el.name_ZH}`}
											</option>
										))}
									</select>
									<label
										htmlFor="fullname"
										className="fontSize75Rem mb-2"
									>
										球谱子型态
									</label>
									<select
										className="form-control mb-3 w-100"
										name="phone1CountryCode"
										id="phone1CountryCode"
										value={drillCategoryItemGuid}
										onChange={(e) => {
											setDrillCategoryItemGuid(
												e.target.value
											);
										}}
									>
										{drillCategoryItemList.map((el) => (
											<option
												value={el.categoryItemGuid}
												key={el.categoryItemGuid}
												id={el.categoryItemGuid}
											>
												{el.name_ZH}
											</option>
										))}
									</select>
									<label
										htmlFor="fullname"
										className="fontSize75Rem mb-2"
									>
										语言
									</label>
									<select
										className="form-control mb-3 w-100"
										name="languageCode"
										id="languageCode"
										value={languageCode}
										onChange={(e) => {
											setLanguageCode(e.target.value);
										}}
									>
										{languageList.map((el) => (
											<option
												value={el.languageCode}
												key={el.languageCode}
												id={el.languageCode}
											>
												{el.name}
											</option>
										))}
									</select>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer className="justify-content-center mx-4">
							<button
								type="button"
								className="btn btn-primary text-white px-4 me-2"
								onClick={handleCopyUserDrill}
								disabled={inputDisabled}
							>
								確定
							</button>
							<button
								type="button"
								className="btnCancel btn btn-outline-primary px-4"
								onClick={() => setModalShow(false)}
							>
								取消
							</button>
						</Modal.Footer>
					</Modal>

					{/* 复制到用户 */}
					<Modal
						className="copyDrillModal modal"
						show={forUserModalShow}
						onHide={() => setForUserModalShow(false)}
						centered
					>
						<Modal.Header closeButton className="border-bottom">
							<Modal.Title>复制到用户</Modal.Title>
						</Modal.Header>
						<Modal.Body className="pb-0">
							<div className="searchBox row mb-3">
								<div className="col-2 mx-1 px-0">
									<select
										className="form-control me-2"
										name="searchColumn"
										id="searchColumn"
										onChange={(e) => {
											setAccountType(e.target.value);
											setSearchInputValue('');
										}}
										value={accountType}
										required
									>
										{/* 可以用 電話、郵件、姓名、用戶名 查詢 */}
										<option value="1" id="phone1Search">
											手机号
										</option>
										<option value="0" id="email1Search">
											邮箱
										</option>
									</select>
								</div>
								{accountType === '1' && (
									<div className="col-2 mx-1 px-0">
										<select
											className="form-control me-2"
											name="searchColumn"
											id="searchColumn"
											onChange={(e) => {
												setCountryCode(e.target.value);
											}}
											value={countryCode}
											required
											disabled
										>
											<option value="86" id="86">
												+86
											</option>
											<option value="886" id="886">
												+886
											</option>
											<option value="852" id="852">
												+852
											</option>
										</select>
									</div>
								)}
								<div className="col mx-1 px-0">
									<input
										type={
											accountType === '1'
												? 'number'
												: 'text'
										}
										className="searchInput form-control me-2"
										placeholder="搜寻"
										value={searchInputValue}
										// style={{ width: '120px' }}
										onChange={(e) =>
											setSearchInputValue(
												e.target.value.toLowerCase()
											)
										}
										// onKeyDown={(e) =>
										//     e.key === 'Enter' && filterUserList()
										// }
									/>
								</div>
								<div className="col-2 mx-1 px-0">
									<button
										className="btn btn-primary btn-size-s text-white"
										onClick={handleGetUniqueUser}
									>
										查询
									</button>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									{copyUserInfo ? (
										<div className="card-body d-flex flex-column align-items-center">
											<div className="card-avatar-outer">
												<div className="card-avatar-inner">
													<img
														className="card-avatar"
														src={
															copyUserInfo.photoLink ||
															'./images/user-default.png'
														}
														alt=""
													/>
												</div>
											</div>
											<div className="card-title">
												用户：{copyUserInfo.fullname}
											</div>

											<label
												htmlFor="userDrillClass"
												className="mb-2　d-inline"
											>
												请选择将对应的球谱型态：
											</label>
											<select
												className="form-control mb-5 w-50 d-inline"
												name="userDrillClass"
												id="userDrillClass"
												value={userDrillClassGuid}
												onChange={(e) => {
													setUserDrillClassGuid(
														e.target.value
													);
												}}
												disabled={
													userDrillClassList.length ===
													0
														? true
														: false
												}
											>
												{userDrillClassList.length !==
												0 ? (
													userDrillClassList.map(
														(el) => (
															<option
																value={
																	el?.classGuid
																}
																key={
																	el?.classGuid
																}
																id={
																	el?.classGuid
																}
															>
																{el?.name}
															</option>
														)
													)
												) : (
													<option value="noData">
														无资料，如需选择请先前往新增
													</option>
												)}
											</select>

											<p className="card-text">
												确定要将球普复制到此帐号？
											</p>
										</div>
									) : (
										<div className="row text-center justify-content-center">
											<div className="col">
												{noOnFound ? '查無此人' : ''}
											</div>
										</div>
									)}
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer className="justify-content-center mx-4">
							<button
								type="button"
								className="btn btn-primary text-white px-4 me-2"
								onClick={
									copyUserInfo && handleCopyUserDrillToUser
								}
							>
								確定
							</button>
							<button
								type="button"
								className="btnCancel btn btn-outline-primary px-4"
								onClick={() => setForUserModalShow(false)}
							>
								取消
							</button>
						</Modal.Footer>
					</Modal>

					{/* 新增球路 */}
					{insertDrillModalOpen && (
						<InsertDrillItemModal
							insertDrillModalOpen={insertDrillModalOpen}
							setInsertDrillModalOpen={setInsertDrillModalOpen}
							newDrillItemData={newDrillItemData}
							setNewDrillItemData={setNewDrillItemData}
							drillCategoryItemList={drillCategoryItemList}
							spinNameTable={spinNameTable}
							handleInsertDrillItemSubmit={
								handleInsertDrillItemSubmit
							}
							// handleSpinPowerChange={handleSpinPowerChange}
							// handleHighSpeedChange={handleHighSpeedChange}
							// handleSpinChange={handleSpinChange}
						/>
					)}

					{/* 編輯球路 */}
					{editDrillModalOpen && (
						<EditDrillItemModal
							editDrillModalOpen={editDrillModalOpen}
							setEditDrillModalOpen={setEditDrillModalOpen}
							editDrillItemData={editDrillItemData}
							setEditDrillItemData={setEditDrillItemData}
							drillCategoryItemList={drillCategoryItemList}
							spinNameTable={spinNameTable}
							handleEditDrillItemSubmit={
								handleEditDrillItemSubmit
							}
							handleDeleteDrillItem={handleDeleteDrillItem}
							// handleSpinPowerChange={handleSpinPowerChange}
							// handleHighSpeedChange={handleHighSpeedChange}
							// handleSpinChange={handleSpinChange}
						/>
					)}

					<div className="infoWrapper bg-white p-4 rounded-4 mb-3">
						<div className="infoTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold">球谱管理</h6>
						</div>
						<div className="infoBottom row">
							<div className="col-4">
								<label
									htmlFor="displayOrder"
									className="fontSize75Rem mb-2"
								>
									显示顺序
								</label>
								<input
									id="displayOrder"
									name="displayOrder"
									type="number"
									className="form-control"
									value={userDrillInfo.displayOrder || ''}
									onChange={(e) =>
										setUserDrillInfo({
											...userDrillInfo,
											displayOrder: e.target.value,
										})
									}
									required
									disabled={!Object.keys(paramsGuid).length}
								/>
								<span className="text-danger">
									{displayOrderWarning}
								</span>
							</div>
							<div className="col-4">
								<label
									htmlFor="familyMax"
									className="fontSize75Rem mb-2"
								>
									球谱类型
								</label>
								<select
									className="form-control mb-3"
									name="drillClassGuid"
									id="drillClassGuid"
									value={userDrillInfo.drillClassGuid || ''}
									onChange={(e) =>
										setUserDrillInfo({
											...userDrillInfo,
											drillClassGuid: e.target.value,
										})
									}
									required
								>
									{location.state.userDrillClassList.map(
										(el, index) => (
											<option
												key={el.classGuid}
												value={el.classGuid}
												// selected={index === 0}
											>
												{el.name}
											</option>
										)
									)}
								</select>
							</div>
							<div className="col-4">
								<label
									htmlFor="familyMax"
									className="fontSize75Rem mb-2"
								>
									球谱名称
								</label>
								<input
									id="familyMax"
									name="familyMax"
									className="form-control"
									value={userDrillInfo.name || ''}
									onChange={(e) =>
										setUserDrillInfo({
											...userDrillInfo,
											name: e.target.value,
										})
									}
								/>
								<span className="text-danger">
									{nameWarning}
								</span>
							</div>
						</div>
						<div className="infoBottom row">
							<div className="col-4">
								<label
									htmlFor="familyMax"
									className="fontSize75Rem mb-2"
								>
									出球频率(球/分钟)
								</label>
								<input
									id="familyMax"
									name="familyMax"
									className="form-control"
									type="number"
									value={userDrillInfo.frequency || ''}
									onChange={(e) =>
										setUserDrillInfo({
											...userDrillInfo,
											frequency: e.target.value,
										})
									}
								/>
								<span className="text-danger">
									{frequencyWarning}
								</span>
							</div>
							<div className="col-4">
								<label
									htmlFor="familyMax"
									className="fontSize75Rem mb-2"
								>
									出球频率变化
								</label>
								<select
									id="serveOrder"
									name="serveOrder"
									className="form-control mb-3"
									value={userDrillInfo.serveOrder || ''}
									onChange={(e) =>
										setUserDrillInfo({
											...userDrillInfo,
											serveOrder: e.target.value,
										})
									}
								>
									<option value="0">依球路设定</option>
									<option value="1">随机</option>
								</select>
							</div>
							<div className="col-2">
								<label
									htmlFor="serveAmount"
									className="fontSize75Rem mb-2"
								>
									出球量
								</label>
								<select
									className="form-control"
									name="serveAmount"
									id="serveAmount"
									value={userDrillInfo.serveAmount || ''}
									onChange={(e) =>
										setUserDrillInfo({
											...userDrillInfo,
											serveAmount: e.target.value,
											timer: null,
											quantity: null,
										})
									}
									required
								>
									<option value="0">0 计时</option>
									<option value="1">1 总球数</option>
								</select>
							</div>
							<div className="col-2">
								{userDrillInfo.serveAmount === '1' ? (
									<React.Fragment>
										<label
											htmlFor="quantity"
											className="fontSize75Rem mb-2"
										>
											计量 (球)
										</label>
										<input
											id="quantity"
											name="quantity"
											type="number"
											className={`form-control`}
											value={userDrillInfo.quantity || ''}
											onChange={(e) =>
												setUserDrillInfo({
													...userDrillInfo,
													quantity: e.target.value,
												})
											}
											required
										/>
										<span className="text-danger">
											{quantityWarning}
										</span>
									</React.Fragment>
								) : (
									<React.Fragment>
										<label
											htmlFor="timer"
											className="fontSize75Rem mb-2"
										>
											计时（分钟）
										</label>
										<input
											id="timer"
											name="timer"
											type="number"
											className={`form-control`}
											value={userDrillInfo.timer || ''}
											onChange={(e) =>
												setUserDrillInfo({
													...userDrillInfo,
													timer: e.target.value,
												})
											}
											required
										/>
										<span className="text-danger">
											{timerWarning}
										</span>
									</React.Fragment>
								)}
							</div>
						</div>
						<div className="infoBottom row">
							<div className="col">
								<label
									htmlFor="familyMax"
									className="fontSize75Rem mb-2"
								>
									说明
								</label>
								<input
									id="familyMax"
									name="familyMax"
									className="form-control mb-3"
									value={userDrillInfo.description || ''}
									onChange={(e) =>
										setUserDrillInfo({
											...userDrillInfo,
											description: e.target.value,
										})
									}
								></input>
							</div>
						</div>
					</div>
					<div className="searchTableWrapper bg-white p-4 rounded-4">
						<div className="searchTableTop d-flex align-items-center mb-3 ">
							<h6 className="fw-bold">球路资讯</h6>
							<button
								className="btn btn-primary text-white ms-auto"
								onClick={handleInsertDrillModalOpen}
							>
								<svg
									id="icn_Create"
									className="me-2 mb-1"
									viewBox="0 0 12 12"
									width="12"
									height="12"
									fill="#fff"
								>
									<defs> </defs>
									<path
										className="cls-1"
										id="_Color"
										d="M 12 6.857 H 6.857 V 12 H 5.143 V 6.857 H 0 V 5.143 H 5.143 V 0 H 6.857 V 5.143 H 12 Z"
										data-name=" ↳Color"
									></path>
								</svg>
								<span>新增球路</span>
							</button>
						</div>
						<div className="searchTableBottom">
							<section>
								<table
									className="UserDrillListTable table table-hover text-center"
									id="table-drill"
								>
									<thead>
										<tr className="bg-background">
											<th
												scope="col"
												className="text-grey4 border-0"
											>
												顺序<span></span>
											</th>
											<th
												scope="col"
												className="text-grey4 border-0"
												style={{ width: '15%' }}
											>
												水平角度<span></span>
											</th>
											<th
												scope="col"
												className="text-grey4 border-0"
												style={{ width: '15%' }}
											>
												俯仰角度<span></span>
											</th>
											<th
												scope="col"
												className="text-grey4 border-0"
											>
												出球速度<span></span>
											</th>
											<th
												scope="col"
												className="text-grey4 border-0"
											>
												旋球强度<span></span>
											</th>
											<th
												scope="col"
												className="text-grey4 border-0"
												style={{ width: '20%' }}
											>
												旋球角度<span></span>
											</th>
											<th
												scope="col"
												className="text-grey4 border-0"
											>
												高速轮<span></span>
											</th>
											<th
												scope="col"
												className="text-grey4 border-0"
											>
												低速轮<span></span>
											</th>
											<th
												scope="col"
												className="text-grey4 border-0"
											>
												出球延迟<span></span>
											</th>
										</tr>
									</thead>
									<tbody className="border-top-0">
										{userDrillItemList.length !== 0 ? (
											userDrillItemList
												?.filter(
													(el) =>
														el?.action !== 'DELETE'
												)
												?.map((list) => {
													return (
														<tr
															id={
																list.userDrillItemGuid
															}
															key={
																list.userDrillItemGuid
															}
															onClick={() =>
																handleEditDrillModalOpen(
																	list
																)
															}
														>
															<td>
																{
																	list.displayOrder
																}
															</td>
															<td>
																{handleAngleDisplay(
																	0,
																	list.horizontal,
																	list.horizontalDirection
																)}
															</td>
															<td>
																{handleAngleDisplay(
																	1,
																	list.vertical,
																	list.verticalDirection
																)}
															</td>
															<td>
																{list.highSpeed}
															</td>
															<td>
																{list.powerBLDC1 ===
																list.powerBLDC2
																	? '不旋球'
																	: list.spinPower}
															</td>
															<td>
																{handleAngleDisplay(
																	2,
																	list.spin,
																	undefined,
																	list.powerBLDC1,
																	list.powerBLDC2
																)}
															</td>
															<td>
																{list.highSpeed}
															</td>
															<td>
																{list.lowSpeed}
															</td>
															<td>
																{list.delay /
																	10}
															</td>
														</tr>

														// powerBLDC1 出球速度
													);
												})
										) : (
											<tr>
												<td colSpan="9">無球谱紀錄</td>
											</tr>
										)}
									</tbody>
								</table>
							</section>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default UserDrillItem;
